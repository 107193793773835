import React, { useEffect, useState } from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './blockquote.css'
import ICEIcon from '../../components/icons/ICEIcon';
import { SuperSEO } from 'react-super-seo';
import { Link } from 'react-router-dom';
import { Badge, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import CartItem from './cart-item';
import { toast } from 'react-toastify';
import Footer from './Footer';
import { formatCurrencys } from '../../helpers/functions.helpers';
import ROUTES from '../../Routes';
import ReactGA from "react-ga4";


const GetCard = ({ title, description, sub_title }) => {
    let [qty, setQty] = useState(1)
    let [color, setColor] = useState('White')
    let [errs, setErrs] = useState([])

    const getCartItemsFromLocalStorage = () => {
        const cartItems = localStorage.getItem('cartItems');
        if (cartItems) {
            return JSON.parse(cartItems);
        } else {
            return [];
        }
    };
    const getSubTotalFromLocalStorage = () => {
        const subTotal = localStorage.getItem('subToatal');
        if (subTotal) {
            return JSON.parse(subTotal);
        } else {
            return 0;
        }
    };

    let [cartItem, setCartItem] = useState(getCartItemsFromLocalStorage())
    let [subTotal, setSubTotal] = useState(getSubTotalFromLocalStorage())
    let [total, setTotal] = useState(0)
    let [shippingFee, setShippingFee] = useState(2500)
    const vat = 0.075
    const [vatPrice, setVatPrice] = useState(0)
    const [showCartModal, setShowCartModal] = useState(false);
    let intQty = parseInt(qty)

    useEffect(() => {
        let subTotalPrice = 0;
        cartItem.forEach((item) => {
            subTotalPrice += item.price * item.qty;
        });
        setSubTotal(subTotalPrice);
        localStorage.setItem('subTotal', JSON.stringify(subTotalPrice))
        let tempTotal = parseInt(subTotal)
        setVatPrice(tempTotal * vat)
        localStorage.setItem('vatPrice', JSON.stringify(vatPrice))
        localStorage.setItem('shippingFee', JSON.stringify(shippingFee))
        setTotal(tempTotal + (tempTotal * vat));
        localStorage.setItem('total', JSON.stringify(shippingFee + tempTotal + (tempTotal * vat)))

    }, [cartItem, shippingFee, subTotal, vatPrice]);

    const incQty = (e) => {
        e.preventDefault()
        setQty(intQty += 1)
    }

    const proceedToCheckout = () => {
        window.location.assign(ROUTES.link.checkout)
        ReactGA.event({
            category: 'Cart',
            action: 'Clicked Proceed to checkout',
            label: 'ISCE Card',
        });
    }

    const decQty = (e) => {
        e.preventDefault()
        qty <= 0 ? setQty(0) : setQty(intQty -= 1)
    }

    const removeCartItem = (itemId) => {
        const updatedCart = cartItem.filter((item) => item.id !== itemId);
        setCartItem(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart))
        toast.error(`${color} card removed from cart`, {
            position: "bottom-center",
            autoClose: 300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

    const updateQty = (e) => {
        e.preventDefault()
        const val = e.target.value
        val === "" ? setQty(0) : setQty(val)
    }

    const updateColor = (e) => {
        e.preventDefault()
        const val = e.target.value
        setColor(val)
    }

    const incItemQty = (itemId) => {
        const updatedCart = [...cartItem]
        const itemIndex = updatedCart.findIndex((item) => item.id === itemId);
        updatedCart[itemIndex].qty = parseInt(updatedCart[itemIndex].qty) + 1;
        setCartItem(updatedCart);
        localStorage.setItem('cartItems', JSON.stringify(updatedCart))
    }

    const decItemQty = (itemId) => {
        const updatedCart = [...cartItem]
        const itemIndex = updatedCart.findIndex((item) => item.id === itemId);
        if (parseInt(updatedCart[itemIndex].qty) < 2) {
            removeCartItem(itemId);
        } else {
            updatedCart[itemIndex].qty = parseInt(updatedCart[itemIndex].qty) - 1
            setCartItem(updatedCart);
            localStorage.setItem('cartItems', JSON.stringify(updatedCart))
        }
    }

    const handleShipping = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const intVal = parseInt(val)
        setShippingFee(intVal)
        localStorage.setItem('shippingFee', JSON.stringify(intVal))
    }

    const handleShowCartModal = () => {
        setShowCartModal(true);
    };

    const handleCloseCartModal = () => {
        setShowCartModal(false);
    };

    const updateCart = (e) => {
        e.preventDefault()
        let newId = 1
        newId = cartItem.length + 1
        if (qty === 0) {
            setErrs([...errs, {
                name: 'Quantity',
                message: 'Select Quantity'
            }])

            // console.log('Select Quantity')
        } else {
            setErrs(errs.filter(err => err.name !== 'Quantity'))
        }
        if (qty !== 0 && color !== '') {
            const existingItemIndex = cartItem.findIndex(
                (item) => item.color === color
            );
            if (existingItemIndex >= 0) {
                const updatedCart = [...cartItem];
                const existingItem = updatedCart[existingItemIndex];
                const newQty = existingItem.qty + parseInt(qty);
                updatedCart[existingItemIndex] = {
                    ...existingItem,
                    qty: newQty,
                };
                setCartItem(updatedCart);
                localStorage.setItem('cartItems', JSON.stringify(updatedCart))
                toast.info(`🦄 ${color} card updated in cart`, {
                    position: "bottom-center",
                    autoClose: 300,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setQty(1)
            } else {
                const newColor = color.toLowerCase()
                setCartItem([
                    ...cartItem,
                    {
                        id: newId,
                        color: color,
                        qty: qty,
                        image: `/assets/images/background/${newColor}.webp`,
                        price: 10000,
                    },
                ]);
                localStorage.setItem('cartItems', JSON.stringify([
                    ...cartItem,
                    {
                        id: newId,
                        color: color,
                        qty: qty,
                        image: `assets/images/background/${newColor}.webp`,
                        price: 10000,
                    },
                ]))
                toast.info(`🦄 ${color} card added to cart`, {
                    position: "bottom-center",
                    autoClose: 300,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setQty(1)
            }
        } else if (color === '') {
            // console.log(`Select Color`);
        }
        // console.log(cartItem)

    }
    return (
        <>
            <div className='bg-black text-white overflow-scroll pb-5'>
                <div className="container mx-auto p-0">
                    <div className="row mx-auto overflow-hidden mb-5" style={{
                        maxWidth: '1080px'
                    }}>
                        <SuperSEO
                            title={'Get ISCE Card'}
                            description={'Tap to do everything and anything'}
                            lang="en"
                        />

                        <div className="col overflow-scroll h-100">
                            <nav className="navbar navbar-expand-lg bg-black" style={{
                                borderBottom: 'solid .1px rgba(255,255,255,0.3)',
                            }}>
                                <div className="container py-2 px-md-5 justify-content-between ">
                                    <Link to={'/'}>
                                        <div className="text-white">
                                            <img src='assets/images/logo/logofull-dark.webp' alt='ISCE' height={20} />
                                        </div>
                                    </Link>
                                    <div className='d-lg-none'>
                                        <Button onClick={handleShowCartModal} className='text-decoration-none btn btn-light'>
                                            <ICEIcon iconType='cart' size={25} />
                                            <Badge pill bg="danger">
                                                {cartItem.length === 0 ? '' : cartItem.length}
                                            </Badge>
                                        </Button>
                                    </div>
                                </div>
                            </nav>
                            <div className="row">
                                <div className="col">
                                    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-inner ratio ratio-4x3 rounded-bottom mb-5">
                                            <div className="carousel-item active">
                                                <img src="/assets/images/background/cards_white.webp" alt="" className="d-block w-100" style={{ objectFit: 'cover', objectPosition: 'bottom' }} />
                                            </div>
                                            <div className="carousel-item">
                                                <img src="/assets/images/background/cards_black.webp" alt="" className="d-block w-100" style={{ objectFit: 'cover', objectPosition: 'bottom' }} />
                                            </div>
                                            <div className="carousel-item">
                                                <img src="/assets/images/background/cards_blue.webp" alt="" className="d-block w-100" style={{ objectFit: 'cover', objectPosition: 'bottom' }} />
                                            </div>
                                            <div className="carousel-item">
                                                <img src="/assets/images/background/cards_green.webp" alt="" className="d-block w-100" style={{ objectFit: 'cover', objectPosition: 'bottom' }} />
                                            </div>
                                            <div className="carousel-item">
                                                <img src="/assets/images/background/cards_pink.webp" alt="" className="d-block w-100" style={{ objectFit: 'cover', objectPosition: 'bottom' }} />
                                            </div>
                                        </div>
                                        <div className="mb-4 d-flex justify-content-center align-items-center">
                                            <span className="p-3 rounded-circle border border-2 border-dark mx-1 active" data-bs-target="#myCarousel" data-bs-slide-to="0" style={{
                                                backgroundColor: '#ffffff'
                                            }}></span>
                                            <span className="p-3 rounded-circle border border-2 border-dark mx-1" data-bs-target="#myCarousel" data-bs-slide-to="1" style={{
                                                backgroundColor: '#000000'
                                            }}></span>
                                            <span className="p-3 rounded-circle border border-2 border-dark mx-1" data-bs-target="#myCarousel" data-bs-slide-to="2" style={{
                                                backgroundColor: '#0000ff'
                                            }}></span>
                                            <span className="p-3 rounded-circle border border-2 border-dark mx-1" data-bs-target="#myCarousel" data-bs-slide-to="3" style={{
                                                backgroundColor: '#00ff00'
                                            }}></span>
                                            <span className="p-3 rounded-circle border border-2 border-dark mx-1" data-bs-target="#myCarousel" data-bs-slide-to="4" style={{
                                                backgroundColor: '#FFC0CB'
                                            }}></span>
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>



                                    <div className="d-flex justify-content-between">
                                        <div className="ts-lg fw-bold mb-2">ISCE Card</div>
                                        <div className="ts-lg fw-bold">₦10,000</div>
                                    </div>
                                    <div className="ts-md mb-4">
                                        No matter who you are or what you do, With one tap, you can share and connect with people wherever you go.
                                    </div>
                                    <div className="mb-4 d-flex justify-content-between align-items-center">
                                        <div className="flex-grow-1 pe-3">
                                            <Form.Select
                                                aria-label="Color"
                                                defaultValue={color}
                                                onChange={updateColor}
                                            >
                                                {/* <option disabled>Color</option> */}
                                                <option value="White">White</option>
                                                <option value="Black">Black</option>
                                                <option value="Blue">Blue</option>
                                                <option value="Green">Green</option>
                                                <option value="Pink">Pink</option>
                                            </Form.Select>
                                        </div>
                                        <div className="">
                                            <InputGroup className="">
                                                <Button onClick={decQty} className="btn btn-light" variant="light" id="button-addon1">
                                                    -
                                                </Button>
                                                <Form.Control
                                                    type='number'
                                                    id='qty'
                                                    name='qty'
                                                    onChange={updateQty}
                                                    value={qty}
                                                    min={0}
                                                    className={'text-center mx-1 px-0'}
                                                    style={{ width: '40px' }}
                                                />
                                                <Button onClick={incQty} className="btn btn-light" variant="light" id="button-addon1">
                                                    +
                                                </Button>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <Button onClick={updateCart} className="text-white btn btn-primary">Add to cart</Button>
                                    </div>
                                </div>
                                <div className="col-lg-5 d-none d-lg-block mt-3">
                                    <div className="rounded rounded-3 bg-dark px-3 pt-2 pb-4 ">
                                        {
                                            cartItem.length > 0 &&
                                            <>
                                                {cartItem.length > 0 && cartItem.map((item) => {
                                                    return (
                                                        <CartItem
                                                            key={item.id}
                                                            item={item}
                                                            incItemQty={() => incItemQty(item.id)}
                                                            decItemQty={() => decItemQty(item.id)}
                                                            // updateItemQty={() => updateItemQty(item.id)}
                                                            removeCartItem={() => removeCartItem(item.id)}
                                                        />
                                                    )
                                                })}


                                                <div className="d-flex pb-2 mb-2 border-bottom">
                                                    <div className="flex-grow-1 text-uppercase fw-bold">
                                                        Subtotal
                                                    </div>
                                                    <div className="">
                                                        {formatCurrencys(subTotal)}
                                                    </div>
                                                </div>


                                                <div className="flex-grow-1">
                                                    <div className="mb-2 text-uppercase fw-bold">Shipping</div>
                                                    <div className="mb-4 d-flex justify-content-between align-items-center">
                                                        <div className="">
                                                            <Form.Select
                                                                name='shippingFee'
                                                                aria-label="Shipping Fee"
                                                                defaultValue={shippingFee}
                                                                onChange={handleShipping}
                                                            >
                                                                {/* <option disabled>Color</option> */}
                                                                {/* <option value={'0'}>Pickup</option> */}
                                                                <option value={'2500'}>Within Lagos</option>
                                                                <option value={'5000'}>Outside Lagos</option>
                                                            </Form.Select>
                                                        </div>
                                                        <div className="">
                                                            {shippingFee === 0 ? '-' : formatCurrencys(shippingFee)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex pb-2 mb-2 border-bottom">
                                                    <div className="flex-grow-1 text-uppercase fw-bold">
                                                        VAT
                                                    </div>
                                                    <div className="">
                                                        {formatCurrencys(vatPrice)}
                                                    </div>
                                                </div>

                                                <div className="d-flex fw-bold mb-4 py-2 border-bottom">
                                                    <div className="flex-grow-1">
                                                        Total(NGN)
                                                    </div>
                                                    <div className="">
                                                        {formatCurrencys(total + shippingFee)}
                                                    </div>
                                                </div>

                                                <div className="d-grid">
                                                    <Button onClick={proceedToCheckout} className="d-grid text-white btn btn-primary mb-5 text-uppercase">Proceed to checkout</Button>
                                                </div>
                                            </>
                                        }

                                        {
                                            cartItem.length === 0 &&
                                            <div className='ts-md text-center'>
                                                <img src="https://i.imgur.com/dCdflKN.png" alt='' width="130" height="130" className="img-fluid mb-4 me-3" />
                                                <div><strong>Your Cart is Empty</strong></div>
                                                <div>{'Add something to make me happy :)'}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
                <Modal className='mt-5 rounded-5' show={showCartModal} fullscreen={'md-down'} onHide={() => setShowCartModal(false)}>
                    <Modal.Header className="bg-dark text-white" >
                        <Modal.Title>Cart</Modal.Title>
                        <div className="">
                            <Button onClick={handleCloseCartModal} variant="outline-light">
                                <i className="fa-solid fa-xmark"></i>
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="bg-dark text-white">

                        {
                            cartItem.length > 0 &&
                            <>
                                {cartItem.length > 0 && cartItem.map((item) => {
                                    return (
                                        <CartItem
                                            key={item.id}
                                            item={item}
                                            incItemQty={() => incItemQty(item.id)}
                                            decItemQty={() => decItemQty(item.id)}
                                            // updateItemQty={() => updateItemQty(item.id)}
                                            removeCartItem={() => removeCartItem(item.id)}
                                        />
                                    )
                                })}


                                <div className="d-flex pb-2 mb-2 border-bottom">
                                    <div className="flex-grow-1 text-uppercase fw-bold">
                                        Subtotal
                                    </div>
                                    <div className="">
                                        {formatCurrencys(subTotal)}
                                    </div>
                                </div>


                                <div className="flex-grow-1">
                                    <div className="mb-2 text-uppercase fw-bold">Shipping</div>
                                    <div className="mb-4 d-flex justify-content-between align-items-center">
                                        <div className="">
                                            <Form.Select
                                                name='shippingFee'
                                                aria-label="Shipping Fee"
                                                defaultValue={shippingFee}
                                                onChange={handleShipping}
                                            >
                                                {/* <option disabled>Color</option> */}
                                                {/* <option value={'0'}>Pickup</option> */}
                                                <option value={'2500'}>Within Lagos</option>
                                                <option value={'5000'}>Outside Lagos</option>
                                            </Form.Select>
                                        </div>
                                        <div className="">
                                            {shippingFee === 0 ? '-' : formatCurrencys(shippingFee)}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex pb-2 mb-2 border-bottom">
                                    <div className="flex-grow-1 text-uppercase fw-bold">
                                        VAT
                                    </div>
                                    <div className="">
                                        {formatCurrencys(vatPrice)}
                                    </div>
                                </div>

                                <div className="d-flex fw-bold mb-4 py-2 border-bottom">
                                    <div className="flex-grow-1">
                                        Total(NGN)
                                    </div>
                                    <div className="">
                                        {formatCurrencys(total + shippingFee)}
                                    </div>
                                </div>

                                <div className="d-grid">
                                    <Button onClick={proceedToCheckout} className="d-grid text-white btn btn-primary mb-5 text-uppercase">Proceed to checkout</Button>
                                </div>
                            </>
                        }

                        {
                            cartItem.length === 0 &&
                            <div className='ts-md text-center'>
                                <img src="https://i.imgur.com/dCdflKN.png" alt='' width="130" height="130" className="img-fluid mb-4 me-3" />
                                <div><strong>Your Cart is Empty</strong></div>
                                <div>{'Add something to make me happy :)'}</div>
                            </div>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default GetCard;