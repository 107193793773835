import React from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { API, REQUEST2 } from '../../../api/Request';
import ICEIcon from '../../../components/icons/ICEIcon';
import LoadingButton from '../../../components/LoadingButton.component';
import { displayDate } from '../../../helpers/functions.helpers';
import { http } from '../../../http-common';

function ViewAtendeeDetails(props) {
     const {
          modal,
          event,
          handleClose,
          refreshPage
     } = props;

     const [checkedIn, setCheckedIn] = React.useState(modal?.attendee?.checked_in);
     const [checkingIn, setCheckingIn] = React.useState(false);
     // console.log(modal)

     const handleChange = async (e) => {
          const link = API.events?.checkinUser?.replace(':id', modal?.attendee?.id);
          setCheckingIn(true);
          const { data: response } = await http({
               baseURL: REQUEST2.url,
               Authorization: true
          }).post(link, {
               event_id: event?.id,
               url_id: event?.id
          });
          refreshPage(event?.id)
          if (response?.success === 'true') setCheckedIn(response?.data?.status);
          else setCheckedIn(false)
          setCheckingIn(false)
     }

     return (
          <>
               <Modal show={modal.display} onHide={handleClose}>
                    <Modal.Body className=''>
                         <div
                              className='col-6 col-lg-4 mb-2 position-relative mx-auto' title={modal?.attendee?.name}
                         >
                              <div
                                   hidden={!modal?.attendee || !modal?.attendee?.checked_in}
                                   className="ts-md bg-success p-1 rounded-circle position-absolute border border-2 border-light"
                                   style={{
                                        top: '20px',
                                        end: '20px',
                                   }}
                              >
                                   <ICEIcon iconType='check' size={20} fcolor='white' />
                              </div>
                              <div className='sphere border border-3 rounded-circle p-0 m-2 mb-2'>
                                   <img src={modal?.attendee?.image} className='img-fluid rounded-circle' alt='attendees' />
                              </div>
                         </div>
                         <div>
                              <span className='fw-bold mb-2'> Name:</span>
                              {' '}{modal?.attendee?.name}
                         </div>
                         <div>
                              <span className='fw-bold mb-2'> Ticket Type:</span>
                              {' '}{modal?.attendee?.price_category}
                         </div>
                         <div>
                              <span className='fw-bold mb-2'> Date Regitered:</span>
                              {' '}{displayDate(modal?.attendee?.createdAt)}
                         </div>
                         <div>
                              <span className='fw-bold mb-2'> Email:</span>
                              {' '}{modal?.attendee?.email}
                         </div>
                         <div>
                              <span className='fw-bold mb-2'> Phone Number:</span>
                              {' '}{modal?.attendee?.phone}
                         </div>
                         <div>
                              <span className='fw-bold mb-2'> Attendance:</span>

                              {
                                   !checkingIn &&
                                   <>
                                        <Form.Switch
                                             type="switch"
                                             id="custom-switch"
                                             label={(checkedIn) ? 'Present' : 'Absent'}
                                             defaultChecked={checkedIn}
                                             onChange={handleChange}
                                        />
                                   </>
                              }
                              {
                                   checkingIn && <LoadingButton />
                              }
                         </div>
                    </Modal.Body>
                    <Modal.Footer>
                         <Button variant="danger" onClick={handleClose}>
                              Close
                         </Button>
                    </Modal.Footer>
               </Modal>
          </>
     );
}

export default ViewAtendeeDetails