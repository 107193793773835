import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ICEIcon from '../../../components/icons/ICEIcon';
import LoadingButton from '../../../components/LoadingButton.component';
import { displayDate } from '../../../helpers/functions.helpers';
import ROUTES from '../../../Routes'
import EventsService from '../../../services/events.service';
import SideBar from '../component/nav/SideBar';
import Notification from '../component/Notification';
import ViewAtendeeDetails from './ViewAtendeeDetails';

const ViewAttendees = () => {
     const [event, setEvent] = React.useState(null);
     // const [data, setUser] = useState(null);
     const { id } = useParams();
     const [showModal, setShowModal] = useState({
          display: false,
          attendee: null
     });

     const fetchUserEvents = async (id) => {
          const { data: response } = await EventsService.get(id);
          //console.log(response)
          if (response?.success === 'true') {
               setEvent(response?.data);
               // setUser(response?.data);
          }
     }

     React.useEffect(() => {
          fetchUserEvents(id);
     }, [id]);


     const attending = () => {
          return event?.attendees?.length;
     }

     const total_attendees = () => {
          return event?.prices.reduce((prev, current) => {
               return +prev + +current?.attendees;
          }, 0);
     }

     return (
          <>
               <div className="container mx-auto p-0 h-screen">
                    <div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
                         maxWidth: '1080px'
                    }}>
                         <SideBar />

                         <div className="col overflow-scroll h-100 border-0 border-start border-end">
                              <div className="py-3 d-flex justify-content-between">
                                   <Link to={ROUTES.link.view_event.replace(':id', event?.id)} className='text-decoration-none btn btn-light'>
                                        <ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Events</span>
                                   </Link>
                              </div>
                              <div>
                                   <div className='mb-3 mx-auto overflow-hidden px-2'>
                                        <div className='d-flex justify-content-between mb-2'>
                                             <div>
                                                  <div className='text-uppercase '>{event?.title}</div>
                                                  <div hidden={!event?.start_date} className='ts-sm'>
                                                       {displayDate(event?.start_date)}
                                                  </div>
                                             </div>
                                             <div hidden={!event} className='col-6 text-end'>
                                                  <Link className='text-decoration-none text-black p-0' to={ROUTES?.link?.get_cards.replace(':id', event?.id)}>
                                                       <button className='rounded btn btn-dark btn-sm'>Get Cards</button>
                                                  </Link>
                                             </div>
                                        </div>
                                        <div hidden={event}>
                                             <LoadingButton />
                                        </div>
                                        <div className='d-flex justify-content-between py-2'>
                                             <div hidden={!event} className=''>
                                                  <div className='ts-md fw-bold text-uppercase'>
                                                       Attendees
                                                  </div>
                                             </div>
                                             <div hidden={!event} className=''>
                                                  <div className='ts-md fw-bold'>
                                                       {attending() + '/' + total_attendees()}
                                                  </div>
                                             </div>
                                        </div>
                                        <div hidden={!event} className='all-attendees row row-cols-3 g-2'>
                                             {
                                                  event?.attendees?.map((attendee, index) => {
                                                       return (
                                                            <div
                                                                 key={`attendee_view_${index}_${event?.id}`}
                                                                 className='col-4 mb-2 position-relative' title={attendee?.name}
                                                                 onClick={() => setShowModal({ display: true, attendee })}
                                                            >
                                                                 <div
                                                                      hidden={!attendee?.checked_in}
                                                                      className="ts-md bg-success p-1 rounded-circle position-absolute border border-2 border-light"
                                                                      style={{
                                                                           top: '20px',
                                                                           end: '20px',
                                                                      }}
                                                                 >
                                                                      <ICEIcon iconType='check' size={20} fcolor='white' />
                                                                 </div>
                                                                 <div className='sphere border border-3 rounded-circle p-0 m-2 mb-2'>
                                                                      <img src={attendee?.image || TestImg} className='img-fluid rounded-circle' alt='attendees' />
                                                                 </div>
                                                                 <div className='text-center ts-sm fw-bold trunc' >{attendee?.name}</div>
                                                                 <div className='text-center ts-sm'>{attendee?.price_category}</div>
                                                            </div>
                                                       );
                                                  })
                                             }
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <ViewAtendeeDetails
                              event={event}
                              modal={showModal}
                              handleClose={() => setShowModal({ display: false, attendee: null })}
                              handleShow={() => setShowModal({ ...setShowModal, display: true })}
                              refreshPage={fetchUserEvents}
                         />
                         <div className="col d-xl-block d-none p-3" style={{
                              maxWidth: '300px'
                         }}>
                              <Notification />
                         </div>
                    </div>
               </div>
          </>
     )
}

export default ViewAttendees
const TestImg = 'https://avatars.dicebear.com/api/initials/Test-Image.svg'