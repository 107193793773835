import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { API, REQUEST2 } from '../../../api/Request';
import { http } from '../../../http-common';
import ROUTES from '../../../Routes';
import EventsService from '../../../services/events.service';
import Swal from 'sweetalert2'
import ICEIcon from '../../../components/icons/ICEIcon';
import { displayDate, checkDate } from '../../../helpers/functions.helpers';
import { toast } from 'react-toastify';
import SideBar from '../component/nav/SideBar';
import Notification from '../component/Notification';
// import ImageSlider from '../../../components/images/ImageSlider';

const ViewEvent = () => {
     const [event, setEvent] = React.useState(null);
     const { id } = useParams();
     // const past = CheckDa

     React.useEffect(() => {
          const fetchUserEvents = async (id) => {
               const { data: response } = await EventsService.get(id);
               //console.log(response)
               if (response?.success === 'true') {
                    setEvent(response?.data);
               }
          }
          fetchUserEvents(id);
     }, [id]);

     const deleteEvent = async (e) => {
          e.preventDefault();
          Swal.fire({
               icon: 'question',
               title: 'Are you sure you want to delete this event?',
               showCancelButton: true,
               confirmButtonText: 'Yes',
               cancelButtonText: `No`,
          }).then((result) => {
               /* Read more about isConfirmed, isDenied below */
               if (result?.isConfirmed) {
                    (async () => {
                         const { data: response } = await http({
                              baseURL: REQUEST2.url,
                              Authorization: true
                         }).delete(API.get?.events?.delete.replace(':id', event?.id))
                         if (response?.success === "true") window.location.href = ROUTES.link?.event;
                    })();
               }
          })
     }

     const copyLinkToClipBoard = (e) => {
          e.preventDefault();
          const link = window.location?.origin + ROUTES.link.event_open.replace(':id', event?.clean_name);
          navigator.clipboard.writeText(link)
               .then(() => {
                    toast("Copied Event Link");
               })
               .catch(() => {
                    toast("Unable to copy link");
               });

     }

     const hasPriceWithChips = event?.prices.some(price => price.withChips === 'with');

     return (
          <>
               <div className="container mx-auto p-0">
                    <div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
                         maxWidth: '1080px'
                    }}>
                         <SideBar />
                         <div className="col overflow-scroll h-100 border-0 border-start border-end">
                              <div className="py-3 d-flex justify-content-between">
                                   <Link to={ROUTES.link.event} className='text-decoration-none btn btn-light'>
                                        <ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Events</span>
                                   </Link>
                              </div>
                              <div className='mb-3 mx-auto overflow-hidden'>
                                   <div>
                                        <div className='d-flex justify-content-between text-uppercase'>
                                             {
                                                  !event?.title &&
                                                  <div className='profile_username_pulse pulse'></div>
                                             }
                                             {
                                                  event?.title &&
                                                  <div className='fw-light ts-md truncate-md pt-2'>{event?.title}</div>
                                             }
                                             <div>
                                                  {checkDate(event?.start_date) <= 0 &&
                                                       <span className='rounded me-2' hidden={false}>
                                                            <Link className='text-decoration-none text-black' to={ROUTES.link.update_event?.replace(':id', event?.id)}>
                                                                 <ICEIcon iconType='edit' size={21} fcolor={'green'} />
                                                            </Link>
                                                       </span>
                                                  }
                                                  <span className='rounded' onClick={deleteEvent}>
                                                       <ICEIcon iconType='delete' size={21} fcolor={'red'} />
                                                  </span>
                                             </div>
                                        </div>
                                   </div>
                                   {!event?.image &&
                                        <div className='latest_card_pulse position-relative my-2 pulse'></div>
                                   }
                                   {event?.image &&
                                        <div className='latest_card position-relative my-2 rounded overflow-hidden bg-dark'>
                                             <img src={event?.image} alt='...' className='bg-dark w-100 h-100' style={{
                                                  objectFit: 'cover',
                                                  objectPosition: 'top',
                                             }} />

                                             <div className='position-absolute bottom-0 latest-card-details w-100 bg-black text-white'>
                                                  <div className="d-grid p-2">
                                                       <Link className='fw-bold text-white text-decoration-none' to={ROUTES.link.view_event.replace(':id', event?.id)}>
                                                            <div className='fw-bold ts-md text-uppercase'>{event?.title}</div>
                                                       </Link>
                                                       <div hidden={!event?.start_date} className='ts-md text-uppercase'>{displayDate(event?.start_date)}</div>
                                                  </div>
                                             </div>
                                        </div>
                                   }

                                   {
                                        event?.description &&

                                        <div className='pb-2 pt-2'>
                                             <div className="d-grid text-start">
                                                  <div className='ticket-card ts-md fw-bold mb-2'>
                                                       <span> Event Description</span>
                                                  </div>
                                                  <div className='ts-md'>{event?.description}</div>
                                             </div>
                                        </div>
                                   }
                                   {
                                        event?.location &&

                                        <div className='pb-2 pt-2'>
                                             <div className="d-grid text-start">
                                                  <div className='ticket-card ts-md fw-bold mb-2'>
                                                       <span> Event Location</span>
                                                  </div>
                                                  <div className='ts-md'>{event?.location}</div>
                                             </div>
                                        </div>
                                   }
                                   {
                                        event?.description &&
                                        <div className="d-grid text-center my-3">
                                             <div className='text-decoration-none text-secondary btn btn-outline-secondary' onClick={copyLinkToClipBoard} style={{
                                                  cursor: 'copy',
                                             }}>
                                                  <span><ICEIcon iconType='ticket' size={20} /></span>
                                                  <small className='rounded ts-md text-secondary p-2'>{'Share Now'}</small>
                                             </div>
                                        </div>
                                   }

                                   {
                                        event?.prices &&
                                        <>
                                             <div className="row text-center mb-3">
                                                  {(checkDate(event?.start_date) <= 0 && hasPriceWithChips) &&
                                                       <Link className='col text-decoration-none text-white' to={ROUTES.link.get_cards.replace(':id', event?.id)}>
                                                            <div className='rounded ts-md bg-black p-2 text-uppercase'>Get Chips</div>
                                                       </Link>
                                                  }
                                                  <Link className='col text-decoration-none text-white' to={ROUTES.link?.admin_event_chat?.replace(':id', event?.id)}>
                                                       <div className='rounded ts-md bg-black p-2 text-uppercase'> <span className='me-2'><ICEIcon iconType='chat' size={20} /></span>{checkDate(event?.start_date) > 0 ? "Chat History" : "Chats"}</div>
                                                  </Link>
                                             </div>

                                             <div className="rounded border border-dark p-2 mb-3">
                                                  <div className='ticket-card ts-md fw-bold mb-2'>
                                                       <span><ICEIcon iconType='ticket' size={20} /> Tickets</span>
                                                  </div>
                                                  {
                                                       event?.prices?.map((price, index) => {
                                                            return (
                                                                 <div className='d-flex justify-content-between border-1 border-bottom mb-2' key={`${price?.title}_${index}`} data-id={`${index}`}>
                                                                      <div>{price?.title} - ₦{price?.amount}</div>
                                                                      <div>{price?.attendees} in stock</div>
                                                                 </div>
                                                            );
                                                       })
                                                  }
                                             </div>
                                             <div className="mb-3">
                                                  <div className='ticket-card ts-md fw-bold mb-2'>
                                                       <span><ICEIcon iconType='attendees' size={20} /> Attendees</span>
                                                  </div>
                                                  <div className='position-relative attendees_4 w-100'>
                                                       {
                                                            !event?.attendees?.length && <div className="text-start text-uppercase">No attendees yet</div>
                                                       }
                                                       {
                                                            event?.attendees?.slice(0, 7)?.map((attendee, index) => {
                                                                 return (
                                                                      <div
                                                                           key={`attendee_${index}_${event?.id}`}
                                                                           className={`attendee_card border border-3 rounded-circle shadow-sm position-absolute sphere${index + 1}`}
                                                                      >
                                                                           <img
                                                                                src={attendee?.image}
                                                                                className='img-fluid rounded-circle'
                                                                                alt='attendees'
                                                                           />
                                                                      </div>
                                                                 );
                                                            })
                                                       }
                                                       {
                                                            event?.attendees?.length > 0 && <Link className='text-decoration-none text-black p-0' to={ROUTES.link.event_attendees.replace(':id', event.id)}><div className='view_all'>View All</div></Link>
                                                       }
                                                  </div>
                                             </div>
                                        </>
                                   }
                                   {
                                        event?.gallery.length > 0 &&
                                        <div className="mb-3">
                                             <div className='ticket-card ts-md fw-bold mb-2'>
                                                  <span><ICEIcon iconType='collage' size={20} /> Media Gallery</span>
                                             </div>
                                             <div className="row">
                                                  {
                                                       event?.gallery?.map((item, index) => {
                                                            return (
                                                                 <div key={`gallery_view_${index}_${event?.id}`} className='col-4 mb-2 rounded '>
                                                                      <div className="text-center border border-1 rounded">
                                                                           <img src={item?.image} className="w-100 mx-auto" style={{ height: '100px', objectFit: 'cover' }} alt="preview"></img>
                                                                      </div>
                                                                 </div>
                                                            );
                                                       })
                                                  }
                                             </div>
                                             {/* <ImageSlider images={event?.gallery} /> */}
                                        </div>
                                   }
                              </div>
                         </div>
                         <div className="col d-xl-block d-none p-3" style={{
                              maxWidth: '300px'
                         }}>
                              <Notification />
                         </div>
                    </div>
               </div>
          </>
     )
}

export default ViewEvent 