import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import ICEIcon from '../../../components/icons/ICEIcon';
import FullLogo from '../../../images/airpod-pro.webp'
import DeleteService from './DeleteService';

export default function ImageCard({
     height = '100px',
     width = '150px',
     img = FullLogo,
     title = 'Service One',
     description = 'Test Description',
     id
}) {
     const [show, setShow] = useState(false);

     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
     return (
          <>
               <div className='mb-3 position-relative overflow-hidden rounded-3' onClick={handleShow} style={{ width: width, height: height }}>
                    <div className='bg-black opacity-50 position-absolute' style={{ height: height, width: width }}></div>
                    <img src={img} className="images" alt={title} />
                    <div className='position-absolute top-50 start-50 text-white translate-middle'>{title}</div>
               </div>
               <Modal
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
               >
                    <Modal.Body>
                         <div className="">
                              <div className="d-flex justify-content-between mb-3">
                                   <DeleteService id={id} />
                                   <button className='btn btn-light' onClick={handleClose}>
                                        <ICEIcon iconType='close' size={20} />
                                   </button>
                              </div>
                              <div className='mb-2 mx-auto rounded overflow-hidden border border-dark' style={{
                                   height: '200px',
                                   width: '100%'
                              }}>
                                   <img src={img} alt={title} className="images" />
                              </div>
                              <h5 className='text-uppercase'>{title}</h5>
                              <p className="mb-3 ts-md overflow-hidden">
                                   {description}
                              </p>
                         </div>
                    </Modal.Body>
               </Modal>
          </>
     )
}
