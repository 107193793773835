import { useLocation, Navigate } from 'react-router-dom';
import { COOKIE_STORE } from '../api/Request';
import ROUTES from '../Routes';
import * as ls from 'local-storage';
import { empty } from '../helpers/functions.helpers';


const RequireAuth = ({ children }) => {
    const auth = ls.get(COOKIE_STORE.auth)
                    ? ls.get(COOKIE_STORE.auth)
                    : null;

    const location = useLocation();

    if(empty(auth)){
        return <Navigate to={ ROUTES.link.login } state={{ from: location }} replace />;
    }

    return children;
}

export default RequireAuth;