import { PREDEFINED_PROFILES } from './profiles/';
import { TYPE_DESKTOP, TYPE_DEFAULT } from './types';

export { PREDEFINED_PROFILES };
export * from './types';

const PROFILE_ID = '[A-Za-z0-9_\\-\\.]+';
const QUERY_PARAM = '(\\?.*)?';

const createRegexp = (profileMatch, config) => {
  const str = profileMatch.match.replace('{PROFILE_ID}', `${PROFILE_ID}`);
  const isTyped = typeof profileMatch.type !== 'undefined';
  const regexp = new RegExp([
    '^', str, ...(config.allowQueryParams && isTyped ? [QUERY_PARAM] : []), '$'
  ].join(''));
  return regexp;
};

const findIndex = (
  matches,
  link,
  config
  ) => {
  return (matches ?? []).findIndex(match => createRegexp(match, config).test(link));
};

export const DEFAULT_CONFIG = {
  usePredefinedProfiles: true,
  trimInput: true,
  allowQueryParams: false,
};

export class SocialLinks {
    profiles;
    config;

  constructor(config = DEFAULT_CONFIG) {
    if (typeof config === 'boolean') {
      config = { usePredefinedProfiles: config };
    }
    this.config = { ...DEFAULT_CONFIG, ...config };

    this.profiles = new Map();

    if (this.config.usePredefinedProfiles) {
      PREDEFINED_PROFILES.map(({ name, matches }) => this.addProfile(name, matches));
    }
  }

  trim(input) {
    return this.config.trimInput ? input.trim() : input;
  }

  addProfile(profileName, profileMatches) {
    if (this.hasProfile(profileName)) return false;
    this.profiles.set(profileName, profileMatches);
    return true;
  }

  cleanProfiles() {
    this.profiles.clear();
  }

  isValid(profileName, link) {
    if (!this.hasProfile(profileName)) return false;
    const matches = this.profiles.get(profileName);
    return findIndex(matches, this.trim(link), this.config) !== -1;
  }

  getProfileId(profileName, link) {
    if (!this.hasProfile(profileName)) throw new Error(`There is no profile ${profileName} defined`);
    const matches = this.profiles.get(profileName) ?? [];
    const trimmed = this.trim(link);
    const idx = findIndex(matches, trimmed, this.config);
    if (idx === -1) throw new Error(`Link has not matched with profile ${profileName}`);
    return (trimmed.match(createRegexp(matches[idx], this.config)) ?? [])[matches[idx].group];
  }

  getLink(profileName, id, type = TYPE_DEFAULT) {
    if (!this.hasProfile(profileName)) throw new Error(`There is no profile ${profileName} defined`);
    const matches = this.profiles.get(profileName) ?? [];
    const weakType = type === TYPE_DEFAULT ? TYPE_DESKTOP : type;
    const idx = matches.findIndex((match) => {
      if (type === TYPE_DEFAULT) return true;
      return match.type === weakType;
    });
    if (idx === -1) throw new Error(`There is no pattern for profile ${profileName}`);
    return (matches[idx].pattern ?? '').replace('{PROFILE_ID}', `${this.trim(id)}`);
  }

  sanitize(profileName, link, type = TYPE_DEFAULT) {
    const trimmed = this.trim(link);
    const profileId = this.getProfileId(profileName, trimmed);
    const matches = this.profiles.get(profileName) ?? [];
    const idx = findIndex(matches, trimmed, this.config);
    const matchedType = type !== TYPE_DEFAULT ? type : (matches[idx].type ?? TYPE_DEFAULT);
    return this.getLink(profileName, profileId, matchedType);
  }

  hasProfile(profileName) {
    return this.profiles.has(profileName);
  }

  getProfileNames() {
    return [...this.profiles.keys()];
  }
}