import React, { useState } from 'react'
import Navbar from '../Navbar'
import axios from '../../../hooks/axios';
import { API } from '../../../api/Request';
import { toast } from 'react-toastify';
import ROUTES from '../../../Routes';
import { checkValidity, validateEmailCodeSchema, validateEmailSchema } from '../../../helpers/forms/validator.schema';
import ProgressBar from '../../../components/progressBar/ProgressBar';

const verifyMail = async (data) => {
     // API call to send the 4 digit code to verify the email
     return axios.post(API.verifyMail, data);
}

const verifyCodeSent = async (data) => {
     // API call to send the 4 digit code to verify the email
     return axios.post(API.verifyCodeSent, data);
}


const OnboardingStepOne = ({ query, inputChange, nextStep }) => {
     const [email, setEmail] = useState('')
     const [code, setCode] = useState('')
     const [errors, setErrors] = useState([]);
     const [isCodeSent, setIsCodeSent] = useState(false);

     const verifyMyMail = async (e) => {
          e.preventDefault();
          const isValid = await checkValidity(validateEmailSchema, { email });

          if (isValid.success === false) {
               setErrors(isValid.errors);
               return null;
          }

          const emailSaving = toast.loading("Sending verification code...");
          const { data } = await verifyMail({
               email
          });

          setTimeout(() => toast.dismiss(), 2000);
          if (data && data.success === 'true') {
               toast.update(emailSaving, { render: "We have sent you a verification code.", type: "success", isLoading: false, autoClose: 3000 });
               setIsCodeSent(true);
          } else {
               toast.update(emailSaving, { render: "Invalid email!", type: "error", isLoading: false, autoClose: 3000 });
          }
     }

     const handleSubmit = async (e) => {
          e.preventDefault();
          const isValid = await checkValidity(validateEmailCodeSchema, { email, code });
          if (isValid.success === false) {
               setErrors(isValid.errors);
               return null;
          }

          const codeMailSchemaSending = toast.loading("Verifying your email...");
          const { data } = await verifyCodeSent({
               email, token: code
          });

          setTimeout(() => toast.dismiss(), 3000);
          if (data && data.success === 'true') {
               toast.update(codeMailSchemaSending, { render: "We have sent you a verification code.", type: "success", isLoading: false, autoClose: 3000 });
               // console.log("success")
               inputChange({
                    user: { email, auth_type: 'Email' },
               });
               nextStep();
          } else {
               toast.update(codeMailSchemaSending, { render: "Unable to send verification code!", type: "error", isLoading: false, autoClose: 3000 });
          }
     }

     const resendCode = async (e) => {
          e.preventDefault();
          setIsCodeSent(false);
     }

     return (
          <div>
               <div className=''>
                    <div className='text-center' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image'>
                                   <img src="assets/images/background/banner.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'center center'
                                   }} />
                              </div>
                              <div className='col-md login-screen-mobile h-screen'>
                                   <Navbar
                                        backlink={'/' + ROUTES.onboarding.path + query.link}
                                   />
                                   <div className="d-flex justify-content-center flex-column py-5 mx-auto" style={{ height: 630, maxWidth: 500 }}>
                                        <div className='pt-5'>
                                             <h1 className='fw-bold'>Get Started</h1>
                                             <span className='small-text'>Create your digital culture</span>
                                        </div>
                                        <form className='pt-4'>
                                             <div className='d-grid justify-content-end mb-2'>
                                             </div>
                                             <div className="input-group mb-3">
                                                  <input className='form-control border-dark' type={'text'} placeholder='Enter Email' onChange={e => setEmail(e.target.value.toLowerCase())} value={email} />
                                                  <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={verifyMyMail}>Get Code</button>
                                             </div>
                                             <small className='text-start text-danger mb-3'>{errors.find(e => e.includes('email'))}</small>
                                             {isCodeSent &&
                                                  <div className="d-grid gap-2 mb-3">
                                                       <input className='form-control border-dark' type={'text'} placeholder='Enter verification code' onChange={e => setCode(e.target.value)} value={code} />
                                                       <small className='text-start text-danger'>{errors.find(e => e.includes('code'))}</small>
                                                  </div>
                                             }

                                             <button hidden={isCodeSent} className='btn btn-light text-dark btn-sm d-flex justify-content-start align-items-center' onClick={resendCode}><i className="fa fa-refresh mx-2" aria-hidden="true"></i><span className='ts-sm me-2'>Resend Code</span></button>
                                             <div className='py-4 d-grid'>
                                                  <div className='py-4 d-grid'>
                                                       <button hidden={!isCodeSent} onClick={handleSubmit} className='btn btn-dark text-uppercase'>{"Next"}</button>
                                                  </div>
                                             </div>
                                        </form>
                                        <ProgressBar currentState={'1'} />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}
export default OnboardingStepOne


const Styles = {
     height: '100vh'
}