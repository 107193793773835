import React from 'react'
import { Link } from 'react-router-dom'
import { truncate } from '../../../helpers/functions.helpers';
import { getCardQueryParams } from '../../../helpers/queryParams'
import ROUTES from '../../../Routes'

function EventCardVC({ event, past }) {
     const query = getCardQueryParams();

     return (
          <div className="event_card mb-3 border-bottom" style={{ height: '80px' }}>
               <div className='d-flex pb-1' style={{ height: '100%' }}>
                    <div className='col-3 event_card_image' >
                         <Link className='text-decoration-none text-black' to={ROUTES.link.vcard_event_view.replace(':id', event?.id) + query?.params_link}>
                              <img src={event?.image} alt={event?.description} className='images rounded' />
                         </Link>
                    </div>
                    <div className='col-9 ps-2 ts-sm'>
                         <div className='d-flex flex-column'>
                              <div className="d-flex justify-content-between">
                                   <Link className='text-decoration-none text-black' to={ROUTES.link.vcard_event_view.replace(':id', event?.id) + query?.params_link}>
                                        <div className='fw-bold text-uppercase'>{truncate(event?.title, 22)}</div>
                                   </Link>
                              </div>
                              <div className='ts-sm fw-light mb-2' style={{ display: 'inline-block', lineHeight: '0.7rem' }}>{truncate(event?.description, 60)}</div>
                              <div className="d-flex justify-content-between align-items-end">
                                   <div className='text-uppercase ts-xs fw-bold text-black'>{new Date(event?.start_date).toDateString()}</div>
                                   {!past &&
                                        <Link to={ROUTES?.link?.vcard_request_card?.replace(':id', event?.id) + query?.params_link} className=' text-decoration-none'>
                                             <div className='agrad rounded px-3 py-1 text-white text-uppercase'>Register</div>
                                        </Link>
                                   }
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default EventCardVC