import React from 'react'
import ICEIcon from '../../components/icons/ICEIcon'

export default function Future() {
     return (
          <div className='overflow-hidden'>
               <div className="container">
                    <div className="row justify-content-center align-items-center py-5">
                         <div className="col-md-6 my-0" style={{
                              minHeight: '500px',
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundImage: "url('assets/images/background/cardprofile.webp')"
                         }}>
                         </div>
                         <div className="col-md-6">
                              <div className="justify-content-center align-items-center text-center">
                                   <h1 className="fw-bold mb-3 text-center py-5" style={{
                                        fontSize: '3rem'
                                   }}>
                                        The Future of Digital Business Cards.
                                   </h1>
                                   <p className="mb-5 isce_text">
                                        Now you can cater for all your digital needs with one tap. Share contacts, manage events, manage store and more, all in one nfc enabled card.
                                   </p>

                                   <div className="d-flex">
                                        <a href={'get-started'} className="btn bg-white text-black rounded-pill">Get Cards</a>
                                        <a href={'login'} className="btn vc-white ms-2 rounded-pill">Login <ICEIcon iconType='forward' size={20} />  </a>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}
