import React from 'react'
import { Link } from 'react-router-dom'
import ICEIcon from '../../../components/icons/ICEIcon'
import ROUTES from '../../../Routes'
import SideBar from '../component/nav/SideBar'
import Notification from '../component/Notification'

export default function Settings() {
	return (
		<>
			<div className="container mx-auto p-0 h-screen">
				<div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
					maxWidth: '1080px'
				}}>
					<SideBar />
					<div className="col overflow-scroll h-100 border-0 border-start border-end">
						<div className="py-3 px-2 d-flex justify-content-between">
							<Link to={ROUTES.link.user_connect} className='text-decoration-none btn btn-light'>
								<ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Connect</span>
							</Link>
						</div>
						<div>
							<div className='mb-3 overflow-hidden px-2'>
								<div className="fw-bold mt-2 ts-md text-uppercase">Security</div>
								<div className='mb-1'>
									<Link className='text-decoration-none p-0' to={'/settings/change-password'}>
										<span className="">
											Change Password
										</span>
									</Link>
								</div>
								<hr />
								<div className="fw-bold mt-2 ts-md text-uppercase">Vcard</div>
								<div className='mb-1'>
									<span className="text-secondary">
										Default Page
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col d-xl-block d-none p-3" style={{
						maxWidth: '300px'
					}}>
						<Notification />
					</div>
				</div>
			</div>
		</>
	)
}
