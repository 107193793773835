import { API, REQUEST2 } from '../api/Request';
import { http } from '../http-common';

class EventsService {
  getAll() {
    return http({
        baseURL: REQUEST2.url,
        Authorization: true
    }).get(API.events.getAll);
  }

  get(id) {
    return http({
        baseURL: REQUEST2.url,
        Authorization: true
    }).get(API.events.getById.replace(':id', id));
  }
  create(data) {
    return http({
        baseURL: REQUEST2.url,
        Authorization: true
    }).post(API.events.create, data);
  }

  update(data) {
    return http({
        baseURL: REQUEST2.url,
        Authorization: true
    }).post(API.events.update, data);
  }

  delete(id) {
    return http({
        baseURL: REQUEST2.url,
        Authorization: true
    }).delete(API.events.delete.replace(':id', id));
  }

  findByTitle(title) {
    return http({
        baseURL: REQUEST2.url,
        Authorization: true
    }).post(
        API.events.findByTitle.replace(':title', title));
  }
}

export default new EventsService();