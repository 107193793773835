import { LOCAL_STORAGE_KEY } from "../hooks/useLocalStorage";

const env = {
    PROD: 'prod',
    LOCAL: 'local'
}

const environment = env.PROD;

export const REQUEST = {
    url: (environment === 'local') ? 'http://localhost:3011/www' : 'https://developer.isce.app/v1'
}

export const REQUEST2 = {
    url: (environment === 'local') ? 'http://localhost:5000' : 'https://lobster-app-wxw8t.ondigitalocean.app'
}

export const API = {
    confirmToken: '/connect/public/api/confirm-token',
    uploadProfile: '/spaces/api/uploadFile',
    signupUser: '/auth/public/api/create-user',
    hasEmail: '/auth/public/api/has-email',
    verifyMail: '/auth/api/verify-mail',
    verifyCodeSent: '/auth/api/verify-code',
    createCard: '/connect/public/api/create-card',
    checkUser: '/auth/public/api/user-profile',
    login: '/auth/public/api/login',
    getCard: '/connect/public/api/card',
    saveCard: '/connect/public/api/save-card',
    googleUrl: '/auth/public/google-login',
    googleCallback: '/auth/public/sign-in-with-login',
    confirmCardId: '/connect/public/api/confirm-card-id',
    loadRouteLink: '/connect/public/api/load-route',
    vcardView: '/connect/public/api/connect-vcard',
    vcardDownload: '/connect/public/api/vcard-download',
    contactDelete: '/api/contacts/',
    addCardToDevice: '/connect/public/api/add-device',
    exchangeContact: '/connect/public/api/exchange-contact',
    getUserContacts: '/connect/public/api/get-contacts',
    changeUserPassword: '/auth/public/api/change-password',
    forgotPassword: '/auth/public/api/forgot-password',
    resetPassword: '/auth/public/api/reset-password',
    events: {
        getAll: '/api/events?page=1&limit=100', //GET
        getById: '/api/events/:id', //GET
        create: '/api/events/create', //POST
        update: '/api/events/:id', //POST
        delete: '/api/events/:id', //DELETE
        findByTitle: '/api/events?title=:title', //GET
        createPrice: '/api/price/create', //Check this
        createGallery: '/api/gallery/create', //check this
        get_cards: '/api/events/:id/get-cards',
        card_get_events: '/api/card/events',
        card_get_open: '/api/card/events/open',
        register_user: '/api/card/events/register',
        event_chip: '/api/card/events/chip/:id',
        event_token: '/api/card/events/token/:id',
        event_payment_successful: '/api/card/events/payment/success',
        createChat: '/api/events/arena/create/:id', 
        createPrivateChat: '/api/events/arena/private/:id',
        checkinUser: '/api/events/attendees/check/:id' 
    }
}

const REQUESTS = {};
Object.keys(API).forEach((key) => {
    REQUESTS[key] = API[key];
});
API.get = REQUESTS;

export const REQUEST_URL = (url) => {
    return REQUEST.url + API[url];
}

export const DATA_STORAGE = {
    'auth': 'authToken',
    'signup': 'signupData',
    'google': 'googleQuery',
    'dashboard': 'dashboardData'
}

export const COOKIE_STORE = {
    'auth': LOCAL_STORAGE_KEY + 'authToken',
    'signup': LOCAL_STORAGE_KEY + 'signupData',
    'google': LOCAL_STORAGE_KEY + 'googleQuery',
    'dashboard': LOCAL_STORAGE_KEY + 'dashboardData'
}