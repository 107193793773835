import useLocalStorage, { deleteLocalStorage } from './useLocalStorage';
import { empty } from '../helpers/functions.helpers'

const useStorage = (storage_key, initial = {}) => {
    const [data, setData] = useLocalStorage(storage_key, initial);

    const deleteItem = () => {
        deleteLocalStorage(storage_key)
    }

    const hasData = () => {
        if(empty(data)) return false;
        return true;
    }

    return {
        setData,
        data,
        delete: deleteItem,
        hasData: hasData
    }
}

export default useStorage;