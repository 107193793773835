import React from 'react'
import './Dashboard.css'
import profileIcon from '../../images/profile-icon.png'
import DasboardCard from '../DasboardCard'
import { Link } from 'react-router-dom'
import { DATA_STORAGE } from '../../api/Request'
import LoadingButton from '../LoadingButton.component'
import useStorage from '../../hooks/useStorage'

function Swipeup(props) {
     const ls = useStorage(DATA_STORAGE.dashboard, {});
     if(!ls.hasData()) return <LoadingButton />
     return (
          <section className='bg-black shadow-sm d-sm-none'>
               <nav className="bg-white nav-dashboard">
                    <div className='nav-top'></div>
                    <div className='row px-2 py-2' hidden={false}>
                         <div className='col-10'>
                              <div className="input-group input-group-sm">
                                   <span className="input-group-text bg-black border-0">
                                        <i className="text-white fa-solid fa-magnifying-glass"></i>
                                   </span>
                                   <input type="text"
                                        className="form-control bg-black border-0 text-white"
                                        aria-label={'Search dashboard'}
                                        placeholder={'Search dashboard'} />
                                   <span className="input-group-text bg-black border-0"><i className="text-white fa-solid fa-microphone"></i></span>
                              </div>
                         </div>
                         <div className='col-2 text-center justify-content-center' onClick={event => props.handleClick(event)}><i className="fa-solid fa-xmark fa-xl"></i></div>
                    </div>
               </nav>
               <div className='mb-3 bg-white px-3 pb-3 profile-card'>
                    {/* <div className='mb-3 w-100 bg-black rounded' id="liveAlertPlaceholder">
                         <small className="alert bg-black text-white m-0 p-0 w-100" role="alert">
                              Welcome to you dashboard! <i className="fa-solid fa-xmark ps-3" role="alert" data-bs-dismiss="alert" aria-label="Close"></i>
                         </small>
                    </div> */}
                    <div className='row g-0 text-start pt-2'>
                         <div className='col-3 rounded-circle dashboard-img border-dark border-3 border overflow-hidden'>
                              <img src={ ls.data.image || profileIcon } alt={''} className={'img-fluid sb-img images'} />
                         </div>
                         <div className='col-9 ps-3'>
                              <span className='fw-bold'>{ ls.data.name || 'Hi, User'}</span>
                              <p className='smaller-text'>
                                   { ls.data.description || 'You are awesome!'}
                              </p>
                         </div>
                    </div>
               </div>

               <div className='text-start connect-section mx-auto py-3 bg-black'>

                    <div className='social-header h4 mx-3 px-2 fw-bold text-white'>Socials</div>
                    <div className='social bg-white border-2 rounded mx-3 py-1'>
                         <div className='row row-cols-3 m-2'>
                              <Link className='text-decoration-none' to={'/connect'} style={Styles}>
                                   <DasboardCard
                                        description='Your Card'
                                        name='connect'
                                        icon={'fa-solid fa-circle-nodes'}
								color={'bg-black'}
                                   />
                              </Link>
                              <Link className='text-decoration-none' to={'/event'} style={Styles}>
                                   <DasboardCard
                                        description='Your Events'
                                        name='events'
                                        icon={'fa-solid fa-calendar'}
								color={'bg-black'}
                                   />
                              </Link>
                              <Link className='text-decoration-none' to={'/contact'} style={Styles}>
                                   <DasboardCard
                                        description='Your Contacts'
                                        name='contacts'
                                        icon={'fa-solid fa-phone'}
								color={'bg-black'}
                                   />
                              </Link>
                              <DasboardCard
                                   description='COMING SOON'
                                   name='my identity'
                                   color={'bg-secondary'}
                                   icon={'fa-solid fa-fingerprint'}
                              />
                         </div>
                    </div>
                    <div className='social-header h4 mx-3 px-2 fw-bold text-white mt-3'>Business</div>
                    <div className='social bg-white border-2 rounded mx-3 py-1'>
                         <div className='row row-cols-3 m-2'>
                              <DasboardCard
                                   description='COMING SOON'
                                   name='Services'
                                   color={'bg-secondary'}
                                   icon={'fa-solid fa-gear'}
                              />
                         </div>
                    </div>
               </div>
          </section>
     )
}

export default Swipeup
const Styles = {
     margin: 0,
     padding: 0,
}