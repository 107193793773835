import React from 'react';
import { toast } from 'react-toastify';
import { http } from '../../../http-common';

const DownloadContact = (props) => {


     const handleDownload = async (event) => {
          event.preventDefault();
          try {
               const url = `https://developer.isce.app/v1/connect/api/contacts/${props.contactId}/download`;
               const { data: response } = await http({
                    // Authorization: true
               }).get(url);
               doDownload(response, props.name.replace(" ", "_"));
               toast.success("Contact downloaded successfully!");
          } catch (error) {
               toast.error("Error downloading contact. Please try again.");
               // console.log(error)
          }
     }

     const doDownload = (apiResponse, name) => {
          const vcfBlob = new Blob([apiResponse], { type: "text/x-vCard;charset=utf-8" });
          const vcfUrl = URL.createObjectURL(vcfBlob);
          const vcfLink = document.createElement("a");
          vcfLink.href = vcfUrl;
          vcfLink.setAttribute("download", name + ".vcf");
          vcfLink.click();
     };

     return (
          !props.full
               ?
               <button className="btn btn-light" onClick={handleDownload}>
                    <i className="fa-solid fa-download fa-md"></i>
               </button>
               :
               <div className="d-grid pb-2">
                    <button onClick={handleDownload} className='btn ts-md bg-black text-white rounded mt-1'>
                         <i className="fa-solid fa-download fa-md"></i>
                         <span className='ps-2'>Download Contact File</span>
                    </button>
               </div>
     )
}

export default DownloadContact;
