import React from 'react'

export default function Notification() {
     return (
          <>
               <div className="p-2">
                    <div className='fs-5 mb-3'>Notifications</div>
                    <div className="alert alert-dark p-2 ts-md mb-2" role="alert">
                         <p>Your notifications will appear here!!!</p>
                    </div>
               </div>
          </>
     )
}
