import { TYPE_DESKTOP, TYPE_MOBILE } from '../types';

export const website = {
  name: 'website',
  matches: [
    {
      match: '(https?://)?(www.)?({PROFILE_ID})?(.com)?', group: 3, type: TYPE_DESKTOP,
      pattern: 'https://{PROFILE_ID}.com'
    },
    {
      match: '(https?://)?(www.)?({PROFILE_ID})?(.com)?', group: 2, type: TYPE_MOBILE,
      pattern: 'https://{PROFILE_ID}.com'
    },
    { match: '({PROFILE_ID})?(.com)?', group: 1 },
  ]
};