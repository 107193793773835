import React, { useState } from 'react';

export default function SearchBar({ data, searchProp, onSearch, isSearching }) {
     const [searchValue, setSearchValue] = useState(' ');

     const handleSearch = event => {
          event.preventDefault();

          const filteredData = data.filter(item =>
               item[searchProp].toLowerCase().includes(event.target.value.toLowerCase())
          );

          setSearchValue(event.target.value);
          onSearch(filteredData);
          isSearching(true)
     };

     return (
          <div>
               <div className="input-group input-group-sm mb-3">
                    <input type="text" onChange={handleSearch} className="ts-sm border-dark border-end-0 form-control" value={searchValue} placeholder="Search contacts" aria-label="Search contacts" aria-describedby="button-addon2" />
                    <span className="input-group-text border-dark border-start-0 bg-white" id="span-addon2">
                         <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
               </div>
          </div>
     );
}
