import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Logout() {

	const navigate = useNavigate();

	// HANDLE LOGOUT EVENT
	const logout = (e) => {
		e.preventDefault();
		// console.log('Logout');

		// CLEAR DATA FROM STORAGE
		localStorage.clear();
		sessionStorage.clear();

		navigate("/login");
		toast.success('Logged Out Succesfully!', {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 3000
		})
	}

	return (
		<Link
			className="text-decoration-none"
			to="#"
			onClick={logout}
		>
			Logout
		</Link>
	)
}

export default Logout;