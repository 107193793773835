import React from 'react'
import { API, REQUEST2 } from '../../../api/Request';
import LoadingIndicator from '../../../components/LoadingIndicator.component';
import { allQueryParams } from '../../../helpers/queryParams';
import { httpNoInterceptor } from '../../../http-common';

const PaystackSuccess = () => {
    const query = allQueryParams()

    React.useEffect(() => {
		const fetchUserEvents = async (q) => {
			const link = API.events.event_payment_successful;
			const { data: response } = await httpNoInterceptor({
				baseURL: REQUEST2.url
			}).post(link, q);
            if(response?.success === 'true'){
                window.location.href = response?.link
            }else{
                window.history.back();
            }
		}
		fetchUserEvents(query);
	}, [ query ]);

    return <LoadingIndicator />
}

export default PaystackSuccess;

