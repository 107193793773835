export const Icons = () => {
    const icons = [
        "fab fa-behance",
        "fab fa-discord",
        "fab fa-dribbble",
        "fab fa-facebook-messenger",
        "fab fa-github",
        "fab fa-google",
        "fab fa-google-drive",
        "fab fa-google-play",
        "fab fa-google-plus-g",
        "fab fa-line",
        "fab fa-linkedin-in",
        "fab fa-medium-m",
        "fas fa-music",
        "fab fa-patreon",
        "fab fa-paypal",
        "fab fa-pinterest-p",
        "fab fa-reddit-alien",
        "fab fa-skype",
        "fab fa-slack",
        "fas fa-shopping-bag",
        "fas fa-shopping-basket",
        "fas fa-shopping-cart",
        "fab fa-spotify",
        "fab fa-trello",
        "fab fa-tumblr",
        "fab fa-twitch",
        "fab fa-viber",
        "fab fa-vimeo",
        "fab fa-vk",
        "fab fa-youtube",
    ];

    return icons;
}

// https://www.flaticon.com/packs/social-logo-3