import * as ls from 'local-storage';
import { empty } from './functions.helpers';

export const LOCAL_STORAGE_KEY = 'djkfsfsjdfssfjsfunsmnsssdhfs_';

const LocalStorage = (key, initial = {}) => {
    const storage_key = LOCAL_STORAGE_KEY + key;
    const data = ls.get(storage_key) || initial;

    const deleteItem = () => {
        ls.remove(storage_key);
    }

    const hasData = () => {
        if(empty(data)) return false;
        return true;
    }

    const setData = (data_to_set) => {
        ls.set(storage_key,data_to_set);
    } 

    const appendData = (data_to_append) => {
        const existing_data = data;
        ls.set(storage_key, {
            ...existing_data, data_to_append
        });
    }

    return {
        set: setData,
        append: appendData,
        data,
        delete: deleteItem,
        hasData: hasData
    }
}

export default LocalStorage;