import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { API, REQUEST, REQUEST2 } from '../../../api/Request';
import ICEIcon from '../../../components/icons/ICEIcon';
import FileUploader from '../../../components/images/fileuploader';
import GalleryEditor from '../../../components/images/gallery-editor.component';
import { checkValidity, validateEventForm1Schema, validateEventForm2, validatePriceFormSchema } from '../../../helpers/forms/validator.schema';
import { uniqid } from '../../../helpers/functions.helpers';
import { http } from '../../../http-common';
import ROUTES from '../../../Routes';
import EventsService from '../../../services/events.service';
import SideBar from '../component/nav/SideBar';
import Notification from '../component/Notification';

class UpdateEvent extends Component {
     constructor(props) {
          super(props);
          this.state = {
               currentStep: 1,
               title: '',
               description: '',
               location: '',
               showPriceForm: false,
               start_date: '',
               start_time: '',
               end_time: '',
               end_date: '',
               withChips: "",
               prices: [],
               image: 'https://isce-image.fra1.digitaloceanspaces.com/public/Rectangle%20138.jpg',
               gallery: [],
               priceForm: { index: -1, title: '', amount: '', attendees: '', withChips: '' },
               formSubmitted: false,
               hasEndDate: false,
               imageName: 'banner_',
               user: null,
               errors: [],
               hasErrors: true,
               free: true,
               hideTicketName: true
          }
          this.updateFormState = this.updateFormState.bind(this);
          this.togglePriceForm = this.togglePriceForm.bind(this);
          this.updatePrice = this.updatePrice.bind(this);
          this.updatePriceForm = this.updatePriceForm.bind(this);
          this.fillPriceForm = this.fillPriceForm.bind(this);
          this.removePrice = this.removePrice.bind(this);
          this.updateMainImage = this.updateMainImage.bind(this);
          this.updateGalleryImage = this.updateGalleryImage.bind(this);
          this.hasEndDateUpdate = this.hasEndDateUpdate.bind(this);
          this.submitForm = this.submitForm.bind(this);
          this.updateTicketType = this.updateTicketType.bind(this);
     }

     componentDidMount() {
          const fetchUserEvents = async (id) => {
               const { data: response } = await EventsService.get(id);
               if (response?.success === 'true') {
                    const data = response?.data;
                    // console.log(data);
                    const imageName = [];
                    this.setState({
                         image: data?.image,
                         title: data?.title || '',
                         description: data?.description || '',
                         start_date: data?.start_date?.split('T')[0] || '',
                         end_date: data?.end_date?.split('T')[0] || '',
                         location: data?.location || '',
                         prices: data?.prices || [],
                         gallery: data?.gallery || [],
                         hasEndDate: data?.end_date ? true : false,
                         user: data?.user,
                         imageName: imageName,
                         id: data?.id
                    });
               }
          }
          const [id] = window.location.pathname.split('/').filter((path) => path !== "" && path !== "event");
          this.id = id;
          fetchUserEvents(id);
     }

     updateTicketName = (e) => {
          e.preventDefault();
          let val = e.target.value;
          if (val === 'Others') {
               this.setState({
                    priceForm: {
                         ...this.state.priceForm,
                         title: '',
                    },
                    hideTicketName: false
               })
          } else {
               this.setState({
                    priceForm: {
                         ...this.state.priceForm,
                         title: val,
                    },
                    hideTicketName: true
               })
          };
     }

     updateTicketType = (e) => {
          e.preventDefault();
          let val = e.target.value;
          if (val === 'Free') {
               this.setState({
                    priceForm: {
                         ...this.state.priceForm,
                         title: 'Free',
                         amount: '0',
                         withChips: 'without',
                    },
                    free: true
               })
          } else {
               this.setState({
                    priceForm: {
                         ...this.state.priceForm,
                         title: '',
                         amount: '',
                         withChips: '',
                    },
                    free: false
               })
          }
     }

     updateFormState = (e) => {
          e.preventDefault();
          this.setState({
               [e.target.name]: e.target.value
          });
     }

     togglePriceForm = (e) => {
          e.preventDefault();
          this.setState({
               showPriceForm: !this.state.showPriceForm
          });
     }

     nextStep = async () => {
          const validationSchema = [{
               scheme: validateEventForm1Schema,
               values: {
                    title: this.state.title,
                    description: this.state.description,
                    location: this.state.location,
                    start_date: this.state.start_date,
                    end_date: this.state.end_date
               }
          }, {
               scheme: validateEventForm2,
               values: this.state.prices
          }];
          const schema = validationSchema[this.state.currentStep - 1];
          const isValid = await checkValidity(schema.scheme, schema.values);

          // console.log(isValid);
          if (isValid.success === false) {
               this.setState({
                    errors: isValid.errors,
                    hasErrors: true
               });
               return null;
          }
          // return null;
          this.setState({
               currentStep: this.state.currentStep + 1
          });
     };

     previousStep = () => {
          this.setState({
               currentStep: this.state.currentStep - 1
          });
     };

     updatePriceForm = (e) => {
          e.preventDefault();
          let val = e.target.value;
          if (e.target.name === 'withChips' && e.target.value === "") val = "without";
          this.setState({
               priceForm: {
                    ...this.state.priceForm,
                    [e.target.name]: val
               }
          });
     }

     fillPriceForm = (e) => {
          e.preventDefault();
          const index = e.currentTarget?.getAttribute('data-id');
          const price = this.state.prices[+index];
          this.setState({
               priceForm: { index, ...price }
          })
     }

     removePrice = (e) => {
          e.preventDefault();
          const index = +e.currentTarget?.getAttribute('data-id');
          const prices = this.state.prices;
          prices.splice(index, 1);
          this.setState({
               prices,
               priceForm: { index: -1, title: '', amount: '', attendees: '' },
          });
     }

     updatePrice = async (e) => {
          e.preventDefault();

          e.preventDefault();
          // validatePriceFormSchema.validate(this.state.priceForm)
          const isValid2 = await checkValidity(validatePriceFormSchema, this.state.priceForm);

          if (isValid2.success === false) {
               this.setState({
                    errors: isValid2.errors,
                    hasErrors: true
               });
               return null;
          }

          if (this.state.priceForm?.index < 0) {
               const priceForm = { ...this.state.priceForm, index: this.state.prices?.length }
               this.setState({
                    prices: [
                         ...this.state.prices,
                         priceForm
                    ],
                    priceForm: { index: -1, title: '', amount: '', attendees: '' },
                    showPriceForm: !this.state.showPriceForm
               });
          } else {
               const index = this.state.priceForm.index;
               let prices = this.state.prices;
               prices[index] = this.state.priceForm;
               this.setState({
                    prices: prices,
                    priceForm: { index: -1, title: '', amount: '', attendees: '' },
               });
          }
     }

     updateMainImage = (response) => {
          this.setState({
               image: response.url
          });
     }

     updateGalleryImage = (response) => {
          this.setState({
               gallery: response
          });
     }

     hasEndDateUpdate = (e) => {
          this.setState({
               hasEndDate: e.target.checked,
               end_date: (e.target.checked) ? this.state.end_date : ''
          });
     }

     submitForm = (e) => {
          e.preventDefault();

          const saveEvent = async () => {
               const { title, image, description, start_date, end_date, location, prices, gallery } = this.state;
               await this.props.updateEvent(this?.id, {
                    title, image, description, start_date: new Date(start_date), end_date: new Date(end_date), location, prices, gallery
               });
               this.setState({
                    formSubmitted: false
               });
               window.location.href = ROUTES.link.event;
          }

          this.setState({
               formSubmitted: true
          })
          saveEvent();
     }

     render() {

          const { currentStep } = this.state;
          switch (currentStep) {
               case 1:
                    return (
                         <div className='mb-3 mx-auto overflow-hidden text-uppercase'>
                              <div className='ts-md mt-2'>Event Banner</div>
                              <div className='ratio ratio-16x9 rounded-3 overflow-hidden shadow-sm position-relative mb-3'>
                                   <FileUploader
                                        image={this.state.image}
                                        handleFile={this.updateMainImage}
                                        canUpload={true}
                                        folder={'events_banner'}
                                        fileName={uniqid()}
                                   />

                              </div>
                              <div className='mb-3'>
                                   <label htmlFor='name' className='ts-md'>Event Name</label>
                                   <input id='title' name="title" className='form-control border-dark ts-md' type={'type'} placeholder='Enter name of event' onChange={this.updateFormState} value={this.state.title} />
                                   <small className='ts-sm text-start text-danger'>{this.state.errors.find(e => e.includes('name'))}</small>
                              </div>
                              <div className='mb-3'>
                                   <label htmlFor='description' className='ts-md'>Event Description</label>
                                   <textarea name="description" className='form-control border-dark ts-md' rows="4" placeholder='Event Description' onChange={this.updateFormState} value={this.state.description} />
                                   <small className='ts-sm text-start text-danger'>{this.state.errors.find(e => e.includes('description'))}</small>
                              </div>
                              <div className='mb-3'>
                                   <label htmlFor='location' className='ts-md'>Event Location</label>
                                   <input name="location" className='form-control border-dark ts-md' type={'type'} placeholder='Location' onChange={this.updateFormState} value={this.state.location} />
                                   <small className='ts-sm text-start text-danger'>{this.state.errors.find(e => e.includes('location'))}</small>
                              </div>
                              <div className='mb-3'>
                                   <label htmlFor='start_date' className='ts-md'>Event Start Date</label>
                                   <input name="start_date" className='form-control border-dark ts-md' type={'date'} onChange={this.updateFormState} value={this.state.start_date} />
                              </div>
                              {/* <div className='row row-cols-2 mb-3'>
                                   <div className="col">
                                        <label htmlFor='start_time' className='ts-md'>Event Start Time</label>
                                        <input name="start_time" step={300} className='form-control border-dark ts-md' type={'time'} onChange={this.updateFormState} value={this.state.start_time} />
                                   </div>
                                   <div className="col">
                                        <label htmlFor='end_time' className='ts-md'>Event End Time</label>
                                        <input name="end_time" step={300} className='form-control border-dark ts-md' type={'time'} onChange={this.updateFormState} value={this.state.end_time} />
                                   </div>
                              </div> */}
                              <div className='mb-3'>
                                   <div className="form-check mb-3">
                                        <input name="hasEndDate" className="form-check-input" checked={this.state.hasEndDate} type="checkbox" id="flexCheckDefault" onChange={this.hasEndDateUpdate} />
                                        <button className='ts-sm rounded bg-dark text-uppercase text-light' onClick={this.hasEndDateUpdate}>{!this.state.hasEndDate ? 'Add End Date' : 'remove end date'}</button>
                                   </div>
                                   <label hidden={!this.state.hasEndDate} htmlFor='end_date' className='ts-md'>Event End Date</label>
                                   <input
                                        hidden={!this.state.hasEndDate}
                                        name="end_date" className='form-control border-dark ts-md' type={'date'} placeholder='End Date'
                                        value={this.state.hasEndDate ? this.state.end_date : ''}
                                        onChange={this.updateFormState} />
                              </div>


                              <div className='d-flex justify-content-end p-2'>
                                   <button type='button' className={'btn text-uppercase btn-light'} onClick={this.nextStep}>
                                        <span className={'me-2 ts-sm'}>NEXT</span>
                                        <span><ICEIcon iconType={'forward'} size={20} /></span>
                                   </button>
                              </div>
                         </div>
                    );

               case 2:
                    return (
                         <div>
                              <div className='ts-md text-uppercase mb-3'>Ticket Details</div>
                              <div className='mb-3' >

                                   <div className="d-grid gap-3">
                                        <input name="index" className='form-control border-dark ts-md' type={'hidden'} placeholder='Index Value' value={this.state.priceForm?.index} onChange={this.updatePriceForm} />

                                        <div className="">
                                             <select name="ticketType" className='form-control border-dark ts-md' defaultValue={this.state.ticketType} onChange={this.updateTicketType}>
                                                  <option value={"Free"}>Free</option>
                                                  <option value={"Paid"}>Paid</option>
                                             </select>
                                        </div>

                                        <div className="" hidden={this.state.free}>
                                             <select name="title" className='form-control border-dark ts-md mb-1' defaultValue={this.state.title} onChange={this.updateTicketName}>
                                                  <option value={""}>Select Ticket Name</option>
                                                  <option value={"Regular"}>Regular</option>
                                                  <option value={"VIP"}>VIP</option>
                                                  <option value={"Others"}>Others</option>
                                             </select>
                                             <input name="title" hidden={this.state.hideTicketName} className='form-control border-dark ts-md' type={'text'} placeholder='Ticket Name (Table For 10)' value={this.state.priceForm?.title} onChange={this.updatePriceForm} />
                                             <small className='text-start ts-sm text-uppercase text-danger'>{this.state.errors.find(e => e.includes('title'))}</small>
                                        </div>
                                        <div className="" hidden={this.state.free}>
                                             <input name="amount" className='form-control border-dark ts-md' type={'number'} min={0} placeholder='Price' value={this.state.priceForm?.amount} onChange={this.updatePriceForm} />
                                             <small className='text-start ts-sm text-uppercase text-danger'>{this.state.errors.find(e => e.includes('amount'))}</small>
                                        </div>
                                        <div className="">
                                             <input name="attendees" className='form-control border-dark ts-md' type={'number'} min={0} placeholder='Total in Stock' value={this.state.priceForm?.attendees} onChange={this.updatePriceForm} />
                                             <small className='text-start ts-sm text-uppercase text-danger'>{this.state.errors.find(e => e.includes('stock'))}</small>
                                        </div>
                                        <div className="" hidden={this.state.free}>
                                             <select name="withChips" className='form-control border-dark ts-md' defaultValue={this.state.withChips} onChange={this.updatePriceForm}>
                                                  <option value="">Select chip option</option>
                                                  <option value={"without"}>Without Chips</option>
                                                  <option value={"with"}>With Chips</option>
                                             </select>
                                             <small className='text-start ts-sm text-uppercase text-danger'>{this.state.errors.find(e => e.includes('withChips'))}</small>
                                        </div>
                                   </div>

                                   <div className='pt-3 d-grid'>
                                        <button className='btn btn-dark' onClick={this.updatePrice}>Add</button>
                                   </div>
                                   <small className='text-start ts-sm text-uppercase text-danger'>{this.state.errors.find(e => e.includes('(ONE)'))}</small>
                              </div>
                              <div className='price-category mb-3'>
                                   {
                                        this.state.prices.map((price, index) => {
                                             return (
                                                  <div className='d-flex align-items-center bg-dark text-white rounded-3 mb-3' key={`${price.title}.${index}`}>
                                                       <div data-id={`${index}`} className='col-10 px-3 py-2 border-dark d-flex  justify-content-between align-items-center' onClick={this.fillPriceForm}>
                                                            <div className="">
                                                                 <div className='text-uppercase ts-md mb-0'>{price.title} - ₦{price.amount}</div>
                                                                 <div className='ts-md'>{price.attendees} in stock</div>
                                                                 <div className='ts-md text-capitalize'>{price.withChips} chips</div>
                                                            </div>
                                                            <div className="">
                                                                 <ICEIcon iconType='edit' size={20} scolor='none' fcolor='white' />
                                                            </div>
                                                       </div>
                                                       <div className='col-2 p-2 d-flex align-items-center' onClick={this.removePrice}>
                                                            <ICEIcon data-id={`${index}`} iconType='delete' size={20} scolor='none' fcolor='white' />
                                                       </div>
                                                  </div>
                                             )
                                        })
                                   }
                              </div>

                              <GalleryEditor
                                   settings={{ allowUpload: true }}
                                   updateGallery={this.updateGalleryImage}
                              />

                              <div className='mb-2 d-flex justify-content-between py-2'>
                                   <button type='button' className={'btn text-uppercase btn-light'} onClick={this.previousStep}>
                                        <span><ICEIcon iconType={'back'} size={20} /></span>
                                        <span className={'ms-2 ts-sm'}>Previous</span>
                                   </button>
                                   <button type='button' className={'btn text-uppercase btn-light'} onClick={this.nextStep}>
                                        <span className={'me-2 ts-sm'}> Next</span>
                                        <span><ICEIcon iconType={'forward'} size={20} /></span>
                                   </button>
                              </div>
                         </div>
                    );
               default:
                    return (
                         <div>
                              <div className='ts-md text-center text-uppercase fw-bold mb-3'>Review Event</div>
                              <div className='mt-3'>
                                   <div className='mb-2'>
                                        <div className='fw-bold ts-md'>
                                             Event Image:
                                        </div>
                                        <div className="ratio ratio-16x9 rounded-3 overflow-hidden">
                                             <img src={this.state.image} className="images" height={300} alt="Editor File" />
                                        </div>
                                   </div>
                                   <div className='mb-2'>
                                        <div className='fw-bold ts-md'>
                                             Event Title:
                                        </div>
                                        <div className='ts-md'>
                                             {this.state.title}
                                        </div>
                                   </div>
                                   <div className='mb-2'>
                                        <div className='fw-bold ts-md'>
                                             Event Description:
                                        </div>
                                        <div className='ts-md'>
                                             {this.state.description}
                                        </div>
                                   </div>
                                   <div className='mb-2'>
                                        <div className='fw-bold ts-md'>
                                             Event Location:
                                        </div>
                                        <div className='ts-md'>
                                             {this.state.location}
                                        </div>
                                   </div>
                                   <div className='mb-2'>
                                        <div className='fw-bold ts-md'>
                                             Event Start Date:
                                        </div>
                                        <div className='ts-md'>
                                             {this.state.start_date}
                                        </div>
                                   </div>
                                   <div className='mb-2'>
                                        <div className='fw-bold ts-md'>
                                             Event Start Time:
                                        </div>
                                        <div className='ts-md'>
                                             {this.state.start_time}
                                        </div>
                                   </div>
                                   {this.state.end_date !== '' && <div className='mb-2'>
                                        <div className='fw-bold ts-md'>
                                             Event End Date:
                                        </div>
                                        <div className='ts-md'>
                                             {this.state.end_date}
                                        </div>
                                   </div>}
                                   <div className="mb-2">
                                        <div className='fw-bold ts-md'>
                                             Event Tickets:
                                        </div>
                                        {this.state.prices.length > 0 ?
                                             this.state.prices.map((price, index) => {
                                                  return (
                                                       <>
                                                            <div className='d-flex align-items-center bg-dark text-white rounded-3 mb-2' key={`${price.title}_${index}`}>
                                                                 <div data-id={`${index}`} className='col-10 px-3 py-2 border-dark d-flex flex-column justify-content-start align-items-start' onClick={this.fillPriceForm}>
                                                                      <div className='text-uppercase ts-md mb-0'>{price.title} - ₦{price.amount}</div>
                                                                      <div className='ts-md'>{price.attendees} in stock</div>
                                                                      <div className='ts-md text-capitalize'>{price.withChips} chips</div>
                                                                 </div>
                                                            </div>
                                                       </>
                                                  )
                                             }) :
                                             "There are no tickets for this event"
                                        }
                                   </div>
                                   {
                                        this.state.gallery.length > 0 &&
                                        <div className="mb-2">
                                             <div className='fw-bold ts-md'>
                                                  Gallery Images:
                                             </div>
                                             <div className="row">
                                                  {
                                                       this.state.gallery?.map((item, index) => {
                                                            return (
                                                                 <div key={`gallery_view_${index}_${this.state?.id}`} className='col-4 mb-2 rounded '>
                                                                      <div className="text-center border border-1 rounded">
                                                                           <img src={item?.image} className="w-100 mx-auto" style={{ height: '100px', objectFit: 'cover' }} alt="preview"></img>
                                                                      </div>
                                                                 </div>
                                                            );
                                                       })
                                                  }
                                             </div>
                                        </div>
                                   }
                              </div>
                              <div className='mb-2 d-flex justify-content-between py-2'>
                                   <button type='button' className={'btn text-uppercase btn-light'} onClick={this.previousStep}>
                                        <span><ICEIcon iconType={'back'} size={20} /></span>
                                        <span className={'ms-2 ts-sm'}>Previous</span>
                                   </button>
                                   <button type='button' className='btn btn-dark text-uppercase' onClick={this.submitForm}>
                                        <span className={'ts-sm me-2'}>Save</span>
                                        <span><ICEIcon iconType={'forward'} size={20} /></span>
                                   </button>
                              </div>
                         </div>
                    );
          }
          // return (
          //      <>
          //           <div className="container mx-auto p-0 h-screen">
          //                <div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
          //                     maxWidth: '1080px'
          //                }}>
          //                     <SideBar />
          //                     <div className="col overflow-scroll h-100 border-0 border-start border-end">
          //                          <div className="py-3 d-flex justify-content-between">
          //                               <Link to={ROUTES.link.event} className='text-decoration-none btn btn-light'>
          //                                    <ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Events</span>
          //                               </Link>
          //                          </div>
          //                          <div>
          //                               <div className='px-2 mb-3 mx-auto overflow-hidden'>
          //                                    <div className='d-flex justify-content-between mb-2'>
          //                                         <div className='ts-md text-uppercase fw-bold'>Update Event</div>
          //                                    </div>
          //                                    {!this.state?.title && <LoadingButton />}
          //                                    {
          //                                         this.state.title && <div className=''>
          //                                              <form>
          //                                                   <div className='mb-2'>
          //                                                        <label htmlFor='name' className='ts-md'>Event Name</label>
          //                                                        <input id='title' name="title" className='form-control border-dark ts-md' type={'type'} value={this.state.title} placeholder='Enter name of event' onChange={this.updateFormState} />
          //                                                   </div>
          //                                                   <div className='mb-2'>
          //                                                        <label htmlFor='description' className='ts-md'>Event Description</label>
          //                                                        <textarea name="description" className='form-control border-dark ts-md' rows="4" maxlength="150" value={this.state.description} placeholder='Event Description' onChange={this.updateFormState} />
          //                                                   </div>
          //                                                   <div className='mb-2'>
          //                                                        <label htmlFor='start_date' className='ts-md'>Event Start Date</label>
          //                                                        <input name="start_date" className='form-control border-dark ts-md' value={this.state.start_date} type={'date'} onChange={this.updateFormState} />
          //                                                   </div>
          //                                                   <div className='mb-2'>
          //                                                        <div className="form-check">
          //                                                             <input name="hasEndDate" className="form-check-input " checked={this.state.hasEndDate} type="checkbox" value="" id="flexCheckDefault" onChange={this.hasEndDateUpdate} />
          //                                                             <label className="form-check-label ts-md" htmlFor="flexCheckDefault">
          //                                                                  Add End Date
          //                                                             </label>
          //                                                        </div>
          //                                                        <label hidden={!this.state.hasEndDate} htmlFor='end_date' className='ts-md'>Event End Date</label>
          //                                                        <input hidden={!this.state.hasEndDate} name="end_date" className='form-control border-dark ts-md' value={this.state.end_date} type={'date'} placeholder='End Date' onChange={this.updateFormState} />
          //                                                   </div>
          //                                                   <div className='mb-2'>
          //                                                        <label htmlFor='location' className='ts-md'>Event Location</label>
          //                                                        <input name="location" className='form-control border-dark ts-md' type={'type'} value={this.state.location} placeholder='Location' onChange={this.updateFormState} />
          //                                                   </div>

          //                                                   <div className='rounded-3 shadowinner bg-light p-3 mb-2' >
          //                                                        <div className="d-grid gap-2">
          //                                                             <input name="index" className='form-control border-dark ts-md' type={'hidden'} placeholder='Index Value' value={this.state.priceForm?.index} onChange={this.updatePriceForm} />
          //                                                             <input name="title" className='form-control border-dark ts-md' type={'text'} placeholder='Ticket Name (Free/Regular/VIP)' value={this.state.priceForm?.title} onChange={this.updatePriceForm} />
          //                                                             <input name="amount" className='form-control border-dark ts-md' type={'number'} min={0} placeholder='Price' value={this.state.priceForm?.amount} onChange={this.updatePriceForm} />
          //                                                             <input name="attendees" className='form-control border-dark ts-md' type={'number'} min={0} placeholder='Total in Stock' value={this.state.priceForm?.attendees} onChange={this.updatePriceForm} />
          //                                                             <select name="withChips" className='form-control border-dark ts-md' value={this.state.priceForm?.withChips} onChange={this.updatePriceForm}>
          //                                                                  <option value="">Select chip option</option>
          //                                                                  <option value="without">Without ISCE Chips</option>
          //                                                                  <option value="with">With ISCE Chips</option>
          //                                                             </select>
          //                                                        </div>

          //                                                        <div className='pt-3 d-grid'>
          //                                                             <button className='btn btn-dark' onClick={this.updatePrice}>Add</button>
          //                                                        </div>
          //                                                   </div>
          //                                                   <div className='price-category mb-2'>
          //                                                        {
          //                                                             this.state.prices.map((price, index) => {
          //                                                                  return (
          //                                                                       <div className='d-flex align-items-center bg-dark text-white rounded-3 mb-2' key={`${price.title}_${index}`}>
          //                                                                            <div data-id={`${index}`} className='col-10 px-3 py-2 border-dark d-flex flex-column justify-content-start align-items-start' onClick={this.fillPriceForm}>
          //                                                                                 <div className='text-uppercase ts-md mb-0'>{price.title} - ₦{price.amount}</div>
          //                                                                                 <div className='ts-md'>{price.attendees} in stock</div>
          //                                                                                 <div className='ts-md text-capitalize'>{price.withChips} chips</div>
          //                                                                            </div>
          //                                                                            <div className='col-2 p-2 d-flex align-items-center' onClick={this.removePrice}>
          //                                                                                 <ICEIcon data-id={`${index}`} iconType='delete' size={20} scolor='none' fcolor='white' />
          //                                                                            </div>
          //                                                                       </div>
          //                                                                  )
          //                                                             })
          //                                                        }
          //                                                   </div>
          //                                                   <GalleryEditor
          //                                                        settings={{ allowUpload: true }}
          //                                                        updateGallery={this.updateGalleryImage}
          //                                                        images={this.state.gallery}
          //                                                   />
          //                                                   <div className='ts-md mt-2'>Add Event Banner</div>
          //                                                   <div className='connect-cover shadow-sm position-relative'>
          //                                                        <FileUploader
          //                                                             image={this.state.image}
          //                                                             handleFile={this.updateMainImage}
          //                                                             canUpload={true}
          //                                                             folder={'events/banner'}
          //                                                             fileName={uniqid()}
          //                                                        />

          //                                                   </div>
          //                                                   <div className='d-grid my-4'>
          //                                                        <button disabled={this.state.formSubmitted} className='btn btn-dark' onClick={this.submitForm}>
          //                                                             Save
          //                                                        </button>
          //                                                   </div>
          //                                              </form>
          //                                         </div>
          //                                    }
          //                               </div>
          //                          </div>
          //                     </div>
          //                     <div className="col d-xl-block d-none p-3" style={{
          //                          maxWidth: '300px'
          //                     }}>
          //                          <Notification />
          //                     </div>
          //                </div>
          //           </div>
          //      </>
          // )
     }
}

UpdateEvent.defaultProps = {
     event: null,
     updateEvent: function (id, data) {
          return http({
               baseURL: REQUEST2.url,
               Authorization: true
          }).post(API.get.events.update.replace(':id', id), data);
     },
     uploadImage: (data) => {
          return http({
               'baseURL': REQUEST.url,
               "Content-Type": "multipart/form-data"
          }).post(API.uploadProfile, data)
     }
}

export default function UpdateEventForm() {
     return (
          <>
               <div className="container mx-auto p-0">
                    <div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
                         maxWidth: '1080px'
                    }}>
                         <SideBar />

                         <div className="col overflow-scroll h-100 border-0 border-start border-end">
                              <div className="py-3 d-flex justify-content-between">
                                   <div>
                                        <span className=''>
                                             <Link to={ROUTES.link.event} className='text-decoration-none btn btn-light'>
                                                  <ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Events</span>
                                             </Link>
                                        </span>
                                   </div>
                              </div>
                              <div>
                                   <div className='mb-3 mx-auto overflow-hidden'>
                                        <div className=''>
                                             <UpdateEvent />
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="col d-xl-block d-none p-3" style={{
                              maxWidth: '300px'
                         }}>
                              <Notification />
                         </div>
                    </div>
               </div>
          </>
     )
};

