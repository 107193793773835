import React from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { API, REQUEST2 } from '../../../api/Request'
import ICEIcon from '../../../components/icons/ICEIcon'
import { truncate } from '../../../helpers/functions.helpers'
import { http } from '../../../http-common'
import ROUTES from '../../../Routes'

function EventCard({ event, past, rerender }) {
     const deleteEvent = async (e) => {
          e.preventDefault();
          const deleteItem = async () => {
               await http({
                    baseURL: REQUEST2.url,
                    Authorization: true
               }).delete(API.get?.events?.delete.replace(':id', event?.id))
               window.location.reload(false);
          }
          Swal.fire({
               title: 'Are you sure?',
               confirmButtonColor: '#ff0000',
               showCancelButton: true,
               confirmButtonText: 'Delete',
          }).then((result) => {
               /* Read more about isConfirmed, isDenied below */
               if (result.isConfirmed) {
                    deleteItem();
                    Swal.fire('Saved!', '', 'success')
               } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
               }
          })
     }
     const hasPriceWithChips = event.prices.some(price => price.withChips === 'with');

     return (
          <div className='mb-3 event_card shadow-mini rounded mx-1' style={{ height: '80px' }}>
               <div className='d-flex p-1' style={{ height: '100%' }}>
                    <div className='col-3 event_card_image' >
                         <Link to={ROUTES.link.view_event.replace(':id', event?.id)} className='text-decoration-none text-black'>
                              <img src={event?.image} alt='Event Name' className='images rounded' />
                         </Link>
                    </div>
                    <div className='col-9 ps-2 ts-sm h-100 overflow-hidden'>
                         <div className='d-flex flex-column'>
                              <div className="d-flex justify-content-between">
                                   <Link to={ROUTES.link.view_event.replace(':id', event?.id)} className='text-decoration-none'>
                                        <div className='fw-bold text-uppercase'>{truncate(event?.title, 13)}</div>
                                   </Link>
                                   <Link to={ROUTES.link.update_event.replace(':id', event?.id)} className=' text-decoration-none'>
                                        {
                                             <span className='px-2' onClick={deleteEvent}><ICEIcon iconType='delete' fcolor='red' size={20} /> </span>
                                        }
                                        {
                                             (!past) &&
                                             <span className='px-2'><ICEIcon iconType='edit' size={20} /> </span>
                                        }

                                   </Link>
                              </div>
                              <div className='ts-sm fw-light mb-1' style={{ display: 'inline-block', lineHeight: '0.7rem' }}>{truncate(event?.description, 50)}</div>
                              <div className="d-flex justify-content-between align-items-end">
                                   <div className='text-uppercase fw-bold text-black ts-xs'>{new Date(event?.start_date).toDateString()}</div>
                                   {
                                        (!past && hasPriceWithChips) &&
                                        <Link to={ROUTES.link.get_cards.replace(':id', event?.id)} className=' text-decoration-none'>
                                             <div className='bg-black rounded px-3 py-1 text-white'>Get Chips</div>
                                        </Link>
                                   }
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}


export default EventCard