import React from 'react'
import { Link } from 'react-router-dom'
import ICEIcon from '../../../../components/icons/ICEIcon'

export default function DashboardCard({
     name = 'Connect',
     bc1 = '#000000',
     bc2 = '#000000',
     ic = 'yellow',
     href = '#!',
     iconType = 'connect',
     fc = '#ffffff',

}) {
     return (
          <div className='dashboard-card'>
               <Link to={href} className='text-decoration-none'>
                    <div className={'text-decoration-none mx-auto mb-3 p-3 position-relative shadow-mini'} style={{ backgroundImage: 'linear-gradient(to right, ' + bc1 + ', ' + bc2 + ')', color: fc, borderRadius: '20px' }}>
                         <div className='mt-4 mb-2'><ICEIcon fcolor={ic} scolor={'none'} iconType={iconType} size={50} /></div>
                         <div className='d-flex justify-content-between'>
                              <div className='fw-bold'>{name}</div>
                              <div>{'>'}</div>
                         </div>
                    </div>
               </Link>
          </div>
     )
}
