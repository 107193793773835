// List of state and countries
export const CONTRIES_ALL =
     [
          {
               "name": "Afghanistan",
               "slug": "afghanistan",
               "iso": "AFG",
               "iso2": "AF",
               "prefix": "+93",
               "currency": "AFN",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [33, 65]
          },
          {
               "name": "Albania",
               "slug": "albania",
               "iso": "ALB",
               "iso2": "AL",
               "prefix": "+355",
               "currency": "ALL",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [41, 20]
          },
          {
               "name": "Algeria",
               "slug": "algeria",
               "iso": "DZA",
               "iso2": "DZ",
               "prefix": "+213",
               "currency": "DZD",
               "region": "Africa",
               "subregion": "Northern Africa",
               "latlng": [28, 3]
          },
          {
               "name": "American Samoa",
               "slug": "american-samoa",
               "iso": "ASM",
               "iso2": "AS",
               "prefix": "+1684",
               "currency": "USD",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-14.33333333, -170]
          },
          {
               "name": "Andorra",
               "slug": "andorra",
               "iso": "AND",
               "iso2": "AD",
               "prefix": "+376",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [42.5, 1.5]
          },
          {
               "name": "Angola",
               "slug": "angola",
               "iso": "AGO",
               "iso2": "AO",
               "prefix": "+244",
               "currency": "AOA",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [-12.5, 18.5]
          },
          {
               "name": "Anguilla",
               "slug": "anguilla",
               "iso": "AIA",
               "iso2": "AI",
               "prefix": "+1264",
               "currency": "XCD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [18.25, -63.16666666]
          },
          {
               "name": "Antigua and Barbuda",
               "slug": "antigua-and-barbuda",
               "iso": "ATG",
               "iso2": "AG",
               "prefix": "+1268",
               "currency": "XCD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [17.05, -61.8]
          },
          {
               "name": "Argentina",
               "slug": "argentina",
               "iso": "ARG",
               "iso2": "AR",
               "prefix": "+54",
               "currency": "ARS",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-34, -64]
          },
          {
               "name": "Armenia",
               "slug": "armenia",
               "iso": "ARM",
               "iso2": "AM",
               "prefix": "+374",
               "currency": "AMD",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [40, 45]
          },
          {
               "name": "Aruba",
               "slug": "aruba",
               "iso": "ABW",
               "iso2": "AW",
               "prefix": "+297",
               "currency": "AWG",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [12.5, -69.96666666]
          },
          {
               "name": "Australia",
               "slug": "australia",
               "iso": "AUS",
               "iso2": "AU",
               "prefix": "+61",
               "currency": "AUD",
               "region": "Oceania",
               "subregion": "Australia and New Zealand",
               "latlng": [-27, 133]
          },
          {
               "name": "Austria",
               "slug": "austria",
               "iso": "AUT",
               "iso2": "AT",
               "prefix": "+43",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [47.33333333, 13.33333333]
          },
          {
               "name": "Azerbaijan",
               "slug": "azerbaijan",
               "iso": "AZE",
               "iso2": "AZ",
               "prefix": "+994",
               "currency": "AZN",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [40.5, 47.5]
          },
          {
               "name": "Bahamas",
               "slug": "bahamas",
               "iso": "BHS",
               "iso2": "BS",
               "prefix": "+1242",
               "currency": "BSD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [24.25, -76]
          },
          {
               "name": "Bahrain",
               "slug": "bahrain",
               "iso": "BHR",
               "iso2": "BH",
               "prefix": "+973",
               "currency": "BHD",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [26, 50.55]
          },
          {
               "name": "Bangladesh",
               "slug": "bangladesh",
               "iso": "BGD",
               "iso2": "BD",
               "prefix": "+880",
               "currency": "BDT",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [24, 90]
          },
          {
               "name": "Barbados",
               "slug": "barbados",
               "iso": "BRB",
               "iso2": "BB",
               "prefix": "+1246",
               "currency": "BBD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [13.16666666, -59.53333333]
          },
          {
               "name": "Belarus",
               "slug": "belarus",
               "iso": "BLR",
               "iso2": "BY",
               "prefix": "+375",
               "currency": "BYN",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [53, 28]
          },
          {
               "name": "Belgium",
               "slug": "belgium",
               "iso": "BEL",
               "iso2": "BE",
               "prefix": "+32",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [50.83333333, 4]
          },
          {
               "name": "Belize",
               "slug": "belize",
               "iso": "BLZ",
               "iso2": "BZ",
               "prefix": "+501",
               "currency": "BZD",
               "region": "Americas",
               "subregion": "Central America",
               "latlng": [17.25, -88.75]
          },
          {
               "name": "Benin",
               "slug": "benin",
               "iso": "BEN",
               "iso2": "BJ",
               "prefix": "+229",
               "currency": "XOF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [9.5, 2.25]
          },
          {
               "name": "Bermuda",
               "slug": "bermuda",
               "iso": "BMU",
               "iso2": "BM",
               "prefix": "+1441",
               "currency": "BMD",
               "region": "Americas",
               "subregion": "Northern America",
               "latlng": [32.33333333, -64.75]
          },
          {
               "name": "Bhutan",
               "slug": "bhutan",
               "iso": "BTN",
               "iso2": "BT",
               "prefix": "+975",
               "currency": "BTN",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [27.5, 90.5]
          },
          {
               "name": "Bolivia",
               "slug": "bolivia",
               "iso": "BOL",
               "iso2": "BO",
               "prefix": "+591",
               "currency": "BOB",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-17, -65]
          },
          {
               "name": "Bosnia and Herzegovina",
               "slug": "bosnia-and-herzegovina",
               "iso": "BIH",
               "iso2": "BA",
               "prefix": "+387",
               "currency": "BAM",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [44, 18]
          },
          {
               "name": "Botswana",
               "slug": "botswana",
               "iso": "BWA",
               "iso2": "BW",
               "prefix": "+267",
               "currency": "BWP",
               "region": "Africa",
               "subregion": "Southern Africa",
               "latlng": [-22, 24]
          },
          {
               "name": "Brazil",
               "slug": "brazil",
               "iso": "BRA",
               "iso2": "BR",
               "prefix": "+55",
               "currency": "BRL",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-10, -55]
          },
          {
               "name": "Brunei",
               "slug": "brunei",
               "iso": "BRN",
               "iso2": "BN",
               "prefix": "+673",
               "currency": "BND",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [4.5, 114.66666666]
          },
          {
               "name": "Bulgaria",
               "slug": "bulgaria",
               "iso": "BGR",
               "iso2": "BG",
               "prefix": "+359",
               "currency": "BGN",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [43, 25]
          },
          {
               "name": "Burkina Faso",
               "slug": "burkina-faso",
               "iso": "BFA",
               "iso2": "BF",
               "prefix": "+226",
               "currency": "XOF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [13, -2]
          },
          {
               "name": "Burundi",
               "slug": "burundi",
               "iso": "BDI",
               "iso2": "BI",
               "prefix": "+257",
               "currency": "BIF",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-3.5, 30]
          },
          {
               "name": "Cambodia",
               "slug": "cambodia",
               "iso": "KHM",
               "iso2": "KH",
               "prefix": "+855",
               "currency": "KHR",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [13, 105]
          },
          {
               "name": "Cameroon",
               "slug": "cameroon",
               "iso": "CMR",
               "iso2": "CM",
               "prefix": "+237",
               "currency": "XAF",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [6, 12]
          },
          {
               "name": "Canada",
               "slug": "canada",
               "iso": "CAN",
               "iso2": "CA",
               "prefix": "+1",
               "currency": "CAD",
               "region": "Americas",
               "subregion": "Northern America",
               "latlng": [60, -95]
          },
          {
               "name": "Cape Verde",
               "slug": "cape-verde",
               "iso": "CPV",
               "iso2": "CV",
               "prefix": "+238",
               "currency": "CVE",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [16, -24]
          },
          {
               "name": "Cayman Islands",
               "slug": "cayman-islands",
               "iso": "CYM",
               "iso2": "KY",
               "prefix": "+1345",
               "currency": "KYD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [19.5, -80.5]
          },
          {
               "name": "Central African Republic",
               "slug": "central-african-republic",
               "iso": "CAF",
               "iso2": "CF",
               "prefix": "+236",
               "currency": "XAF",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [7, 21]
          },
          {
               "name": "Chad",
               "slug": "chad",
               "iso": "TCD",
               "iso2": "TD",
               "prefix": "+235",
               "currency": "XAF",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [15, 19]
          },
          {
               "name": "Chile",
               "slug": "chile",
               "iso": "CHL",
               "iso2": "CL",
               "prefix": "+56",
               "currency": "CLF",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-30, -71]
          },
          {
               "name": "China",
               "slug": "china",
               "iso": "CHN",
               "iso2": "CN",
               "prefix": "+86",
               "currency": "CNY",
               "region": "Asia",
               "subregion": "Eastern Asia",
               "latlng": [35, 105]
          },
          {
               "name": "Colombia",
               "slug": "colombia",
               "iso": "COL",
               "iso2": "CO",
               "prefix": "+57",
               "currency": "COP",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [4, -72]
          },
          {
               "name": "Comoros",
               "slug": "comoros",
               "iso": "COM",
               "iso2": "KM",
               "prefix": "+269",
               "currency": "KMF",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-12.16666666, 44.25]
          },
          {
               "name": "Republic of the Congo",
               "slug": "republic-of-the-congo",
               "iso": "COG",
               "iso2": "CG",
               "prefix": "+242",
               "currency": "XAF",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [-1, 15]
          },
          {
               "name": "DR Congo",
               "slug": "dr-congo",
               "iso": "COD",
               "iso2": "CD",
               "prefix": "+242",
               "currency": "CDF",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [0, 25]
          },
          {
               "name": "Cook Islands",
               "slug": "cook-islands",
               "iso": "COK",
               "iso2": "CK",
               "prefix": "+682",
               "currency": "NZD",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-21.23333333, -159.76666666]
          },
          {
               "name": "Costa Rica",
               "slug": "costa-rica",
               "iso": "CRI",
               "iso2": "CR",
               "prefix": "+506",
               "currency": "CRC",
               "region": "Americas",
               "subregion": "Central America",
               "latlng": [10, -84]
          },
          {
               "name": "Ivory Coast",
               "slug": "ivory-coast",
               "iso": "CIV",
               "iso2": "CI",
               "prefix": "+225",
               "currency": "XOF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [8, -5]
          },
          {
               "name": "Croatia",
               "slug": "croatia",
               "iso": "HRV",
               "iso2": "HR",
               "prefix": "+385",
               "currency": "HRK",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [45.16666666, 15.5]
          },
          {
               "name": "Cuba",
               "slug": "cuba",
               "iso": "CUB",
               "iso2": "CU",
               "prefix": "+53",
               "currency": "CUC",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [21.5, -80]
          },
          {
               "name": "Cyprus",
               "slug": "cyprus",
               "iso": "CYP",
               "iso2": "CY",
               "prefix": "+357",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [35, 33]
          },
          {
               "name": "Czech Republic",
               "slug": "czech-republic",
               "iso": "CZE",
               "iso2": "CZ",
               "prefix": "+420",
               "currency": "CZK",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [49.75, 15.5]
          },
          {
               "name": "Denmark",
               "slug": "denmark",
               "iso": "DNK",
               "iso2": "DK",
               "prefix": "+45",
               "currency": "DKK",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [56, 10]
          },
          {
               "name": "Djibouti",
               "slug": "djibouti",
               "iso": "DJI",
               "iso2": "DJ",
               "prefix": "+253",
               "currency": "DJF",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [11.5, 43]
          },
          {
               "name": "Dominica",
               "slug": "dominica",
               "iso": "DMA",
               "iso2": "DM",
               "prefix": "+1767",
               "currency": "XCD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [15.41666666, -61.33333333]
          },
          {
               "name": "Dominican Republic",
               "slug": "dominican-republic",
               "iso": "DOM",
               "iso2": "DO",
               "prefix": "+1809",
               "currency": "DOP",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [19, -70.66666666]
          },
          {
               "name": "Ecuador",
               "slug": "ecuador",
               "iso": "ECU",
               "iso2": "EC",
               "prefix": "+593",
               "currency": "USD",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-2, -77.5]
          },
          {
               "name": "Egypt",
               "slug": "egypt",
               "iso": "EGY",
               "iso2": "EG",
               "prefix": "+20",
               "currency": "EGP",
               "region": "Africa",
               "subregion": "Northern Africa",
               "latlng": [27, 30]
          },
          {
               "name": "El Salvador",
               "slug": "el-salvador",
               "iso": "SLV",
               "iso2": "SV",
               "prefix": "+503",
               "currency": "SVC",
               "region": "Americas",
               "subregion": "Central America",
               "latlng": [13.83333333, -88.91666666]
          },
          {
               "name": "Equatorial Guinea",
               "slug": "equatorial-guinea",
               "iso": "GNQ",
               "iso2": "GQ",
               "prefix": "+240",
               "currency": "XAF",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [2, 10]
          },
          {
               "name": "Eritrea",
               "slug": "eritrea",
               "iso": "ERI",
               "iso2": "ER",
               "prefix": "+291",
               "currency": "ERN",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [15, 39]
          },
          {
               "name": "Estonia",
               "slug": "estonia",
               "iso": "EST",
               "iso2": "EE",
               "prefix": "+372",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [59, 26]
          },
          {
               "name": "Ethiopia",
               "slug": "ethiopia",
               "iso": "ETH",
               "iso2": "ET",
               "prefix": "+251",
               "currency": "ETB",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [8, 38]
          },
          {
               "name": "Falkland Islands",
               "slug": "falkland-islands",
               "iso": "FLK",
               "iso2": "FK",
               "prefix": "+500",
               "currency": "FKP",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-51.75, -59]
          },
          {
               "name": "Faroe Islands",
               "slug": "faroe-islands",
               "iso": "FRO",
               "iso2": "FO",
               "prefix": "+298",
               "currency": "DKK",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [62, -7]
          },
          {
               "name": "Fiji",
               "slug": "fiji",
               "iso": "FJI",
               "iso2": "FJ",
               "prefix": "+679",
               "currency": "FJD",
               "region": "Oceania",
               "subregion": "Melanesia",
               "latlng": [-18, 175]
          },
          {
               "name": "Finland",
               "slug": "finland",
               "iso": "FIN",
               "iso2": "FI",
               "prefix": "+358",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [64, 26]
          },
          {
               "name": "France",
               "slug": "france",
               "iso": "FRA",
               "iso2": "FR",
               "prefix": "+33",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [46, 2]
          },
          {
               "name": "French Guiana",
               "slug": "french-guiana",
               "iso": "GUF",
               "iso2": "GF",
               "prefix": "+594",
               "currency": "EUR",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [4, -53]
          },
          {
               "name": "French Polynesia",
               "slug": "french-polynesia",
               "iso": "PYF",
               "iso2": "PF",
               "prefix": "+689",
               "currency": "XPF",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-15, -140]
          },
          {
               "name": "Gabon",
               "slug": "gabon",
               "iso": "GAB",
               "iso2": "GA",
               "prefix": "+241",
               "currency": "XAF",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [-1, 11.75]
          },
          {
               "name": "Gambia",
               "slug": "gambia",
               "iso": "GMB",
               "iso2": "GM",
               "prefix": "+220",
               "currency": "GMD",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [13.46666666, -16.56666666]
          },
          {
               "name": "Georgia",
               "slug": "georgia",
               "iso": "GEO",
               "iso2": "GE",
               "prefix": "+995",
               "currency": "GEL",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [42, 43.5]
          },
          {
               "name": "Germany",
               "slug": "germany",
               "iso": "DEU",
               "iso2": "DE",
               "prefix": "+49",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [51, 9]
          },
          {
               "name": "Ghana",
               "slug": "ghana",
               "iso": "GHA",
               "iso2": "GH",
               "prefix": "+233",
               "currency": "GHS",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [8, -2]
          },
          {
               "name": "Gibraltar",
               "slug": "gibraltar",
               "iso": "GIB",
               "iso2": "GI",
               "prefix": "+350",
               "currency": "GIP",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [36.13333333, -5.35]
          },
          {
               "name": "Greece",
               "slug": "greece",
               "iso": "GRC",
               "iso2": "GR",
               "prefix": "+30",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [39, 22]
          },
          {
               "name": "Greenland",
               "slug": "greenland",
               "iso": "GRL",
               "iso2": "GL",
               "prefix": "+299",
               "currency": "DKK",
               "region": "Americas",
               "subregion": "Northern America",
               "latlng": [72, -40]
          },
          {
               "name": "Grenada",
               "slug": "grenada",
               "iso": "GRD",
               "iso2": "GD",
               "prefix": "+1473",
               "currency": "XCD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [12.11666666, -61.66666666]
          },
          {
               "name": "Guadeloupe",
               "slug": "guadeloupe",
               "iso": "GLP",
               "iso2": "GP",
               "prefix": "+590",
               "currency": "EUR",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [16.25, -61.583333]
          },
          {
               "name": "Guam",
               "slug": "guam",
               "iso": "GUM",
               "iso2": "GU",
               "prefix": "+1671",
               "currency": "USD",
               "region": "Oceania",
               "subregion": "Micronesia",
               "latlng": [13.46666666, 144.78333333]
          },
          {
               "name": "Guatemala",
               "slug": "guatemala",
               "iso": "GTM",
               "iso2": "GT",
               "prefix": "+502",
               "currency": "GTQ",
               "region": "Americas",
               "subregion": "Central America",
               "latlng": [15.5, -90.25]
          },
          {
               "name": "Guinea",
               "slug": "guinea",
               "iso": "GIN",
               "iso2": "GN",
               "prefix": "+224",
               "currency": "GNF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [11, -10]
          },
          {
               "name": "Guinea-Bissau",
               "slug": "guinea-bissau",
               "iso": "GNB",
               "iso2": "GW",
               "prefix": "+245",
               "currency": "XOF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [12, -15]
          },
          {
               "name": "Guyana",
               "slug": "guyana",
               "iso": "GUY",
               "iso2": "GY",
               "prefix": "+592",
               "currency": "GYD",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [5, -59]
          },
          {
               "name": "Haiti",
               "slug": "haiti",
               "iso": "HTI",
               "iso2": "HT",
               "prefix": "+509",
               "currency": "HTG",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [19, -72.41666666]
          },
          {
               "name": "Vatican City",
               "slug": "vatican-city",
               "iso": "VAT",
               "iso2": "VA",
               "prefix": "+39",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [41.9, 12.45]
          },
          {
               "name": "Honduras",
               "slug": "honduras",
               "iso": "HND",
               "iso2": "HN",
               "prefix": "+504",
               "currency": "HNL",
               "region": "Americas",
               "subregion": "Central America",
               "latlng": [15, -86.5]
          },
          {
               "name": "Hong Kong",
               "slug": "hong-kong",
               "iso": "HKG",
               "iso2": "HK",
               "prefix": "+852",
               "currency": "HKD",
               "region": "Asia",
               "subregion": "Eastern Asia",
               "latlng": [22.267, 114.188]
          },
          {
               "name": "Hungary",
               "slug": "hungary",
               "iso": "HUN",
               "iso2": "HU",
               "prefix": "+36",
               "currency": "HUF",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [47, 20]
          },
          {
               "name": "Iceland",
               "slug": "iceland",
               "iso": "ISL",
               "iso2": "IS",
               "prefix": "+354",
               "currency": "ISK",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [65, -18]
          },
          {
               "name": "India",
               "slug": "india",
               "iso": "IND",
               "iso2": "IN",
               "prefix": "+91",
               "currency": "INR",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [20, 77]
          },
          {
               "name": "Indonesia",
               "slug": "indonesia",
               "iso": "IDN",
               "iso2": "ID",
               "prefix": "+62",
               "currency": "IDR",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [-5, 120]
          },
          {
               "name": "Iran",
               "slug": "iran",
               "iso": "IRN",
               "iso2": "IR",
               "prefix": "+98",
               "currency": "IRR",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [32, 53]
          },
          {
               "name": "Iraq",
               "slug": "iraq",
               "iso": "IRQ",
               "iso2": "IQ",
               "prefix": "+964",
               "currency": "IQD",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [33, 44]
          },
          {
               "name": "Ireland",
               "slug": "ireland",
               "iso": "IRL",
               "iso2": "IE",
               "prefix": "+353",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [53, -8]
          },
          {
               "name": "Israel",
               "slug": "israel",
               "iso": "ISR",
               "iso2": "IL",
               "prefix": "+972",
               "currency": "ILS",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [31.47, 35.13]
          },
          {
               "name": "Italy",
               "slug": "italy",
               "iso": "ITA",
               "iso2": "IT",
               "prefix": "+39",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [42.83333333, 12.83333333]
          },
          {
               "name": "Jamaica",
               "slug": "jamaica",
               "iso": "JAM",
               "iso2": "JM",
               "prefix": "+1876",
               "currency": "JMD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [18.25, -77.5]
          },
          {
               "name": "Japan",
               "slug": "japan",
               "iso": "JPN",
               "iso2": "JP",
               "prefix": "+81",
               "currency": "JPY",
               "region": "Asia",
               "subregion": "Eastern Asia",
               "latlng": [36, 138]
          },
          {
               "name": "Jordan",
               "slug": "jordan",
               "iso": "JOR",
               "iso2": "JO",
               "prefix": "+962",
               "currency": "JOD",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [31, 36]
          },
          {
               "name": "Kazakhstan",
               "slug": "kazakhstan",
               "iso": "KAZ",
               "iso2": "KZ",
               "prefix": "+7",
               "currency": "KZT",
               "region": "Asia",
               "subregion": "Central Asia",
               "latlng": [48, 68]
          },
          {
               "name": "Kenya",
               "slug": "kenya",
               "iso": "KEN",
               "iso2": "KE",
               "prefix": "+254",
               "currency": "KES",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [1, 38]
          },
          {
               "name": "Kiribati",
               "slug": "kiribati",
               "iso": "KIR",
               "iso2": "KI",
               "prefix": "+686",
               "currency": "AUD",
               "region": "Oceania",
               "subregion": "Micronesia",
               "latlng": [1.41666666, 173]
          },
          {
               "name": "North Korea",
               "slug": "north-korea",
               "iso": "PRK",
               "iso2": "KP",
               "prefix": "+850",
               "currency": "KPW",
               "region": "Asia",
               "subregion": "Eastern Asia",
               "latlng": [40, 127]
          },
          {
               "name": "South Korea",
               "slug": "south-korea",
               "iso": "KOR",
               "iso2": "KR",
               "prefix": "+82",
               "currency": "KRW",
               "region": "Asia",
               "subregion": "Eastern Asia",
               "latlng": [37, 127.5]
          },
          {
               "name": "Kuwait",
               "slug": "kuwait",
               "iso": "KWT",
               "iso2": "KW",
               "prefix": "+965",
               "currency": "KWD",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [29.5, 45.75]
          },
          {
               "name": "Kyrgyzstan",
               "slug": "kyrgyzstan",
               "iso": "KGZ",
               "iso2": "KG",
               "prefix": "+996",
               "currency": "KGS",
               "region": "Asia",
               "subregion": "Central Asia",
               "latlng": [41, 75]
          },
          {
               "name": "Laos",
               "slug": "laos",
               "iso": "LAO",
               "iso2": "LA",
               "prefix": "+856",
               "currency": "LAK",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [18, 105]
          },
          {
               "name": "Latvia",
               "slug": "latvia",
               "iso": "LVA",
               "iso2": "LV",
               "prefix": "+371",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [57, 25]
          },
          {
               "name": "Lebanon",
               "slug": "lebanon",
               "iso": "LBN",
               "iso2": "LB",
               "prefix": "+961",
               "currency": "LBP",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [33.83333333, 35.83333333]
          },
          {
               "name": "Lesotho",
               "slug": "lesotho",
               "iso": "LSO",
               "iso2": "LS",
               "prefix": "+266",
               "currency": "LSL",
               "region": "Africa",
               "subregion": "Southern Africa",
               "latlng": [-29.5, 28.5]
          },
          {
               "name": "Liberia",
               "slug": "liberia",
               "iso": "LBR",
               "iso2": "LR",
               "prefix": "+231",
               "currency": "LRD",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [6.5, -9.5]
          },
          {
               "name": "Libya",
               "slug": "libya",
               "iso": "LBY",
               "iso2": "LY",
               "prefix": "+218",
               "currency": "LYD",
               "region": "Africa",
               "subregion": "Northern Africa",
               "latlng": [25, 17]
          },
          {
               "name": "Liechtenstein",
               "slug": "liechtenstein",
               "iso": "LIE",
               "iso2": "LI",
               "prefix": "+423",
               "currency": "CHF",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [47.26666666, 9.53333333]
          },
          {
               "name": "Lithuania",
               "slug": "lithuania",
               "iso": "LTU",
               "iso2": "LT",
               "prefix": "+370",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [56, 24]
          },
          {
               "name": "Luxembourg",
               "slug": "luxembourg",
               "iso": "LUX",
               "iso2": "LU",
               "prefix": "+352",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [49.75, 6.16666666]
          },
          {
               "name": "Macau",
               "slug": "macau",
               "iso": "MAC",
               "iso2": "MO",
               "prefix": "+853",
               "currency": "MOP",
               "region": "Asia",
               "subregion": "Eastern Asia",
               "latlng": [22.16666666, 113.55]
          },
          {
               "name": "Macedonia",
               "slug": "macedonia",
               "iso": "MKD",
               "iso2": "MK",
               "prefix": "+389",
               "currency": "MKD",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [41.83333333, 22]
          },
          {
               "name": "Madagascar",
               "slug": "madagascar",
               "iso": "MDG",
               "iso2": "MG",
               "prefix": "+261",
               "currency": "MGA",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-20, 47]
          },
          {
               "name": "Malawi",
               "slug": "malawi",
               "iso": "MWI",
               "iso2": "MW",
               "prefix": "+265",
               "currency": "MWK",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-13.5, 34]
          },
          {
               "name": "Malaysia",
               "slug": "malaysia",
               "iso": "MYS",
               "iso2": "MY",
               "prefix": "+60",
               "currency": "MYR",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [2.5, 112.5]
          },
          {
               "name": "Maldives",
               "slug": "maldives",
               "iso": "MDV",
               "iso2": "MV",
               "prefix": "+960",
               "currency": "MVR",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [3.25, 73]
          },
          {
               "name": "Mali",
               "slug": "mali",
               "iso": "MLI",
               "iso2": "ML",
               "prefix": "+223",
               "currency": "XOF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [17, -4]
          },
          {
               "name": "Malta",
               "slug": "malta",
               "iso": "MLT",
               "iso2": "MT",
               "prefix": "+356",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [35.83333333, 14.58333333]
          },
          {
               "name": "Marshall Islands",
               "slug": "marshall-islands",
               "iso": "MHL",
               "iso2": "MH",
               "prefix": "+692",
               "currency": "USD",
               "region": "Oceania",
               "subregion": "Micronesia",
               "latlng": [9, 168]
          },
          {
               "name": "Martinique",
               "slug": "martinique",
               "iso": "MTQ",
               "iso2": "MQ",
               "prefix": "+596",
               "currency": "EUR",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [14.666667, -61]
          },
          {
               "name": "Mauritania",
               "slug": "mauritania",
               "iso": "MRT",
               "iso2": "MR",
               "prefix": "+222",
               "currency": "MRO",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [20, -12]
          },
          {
               "name": "Mauritius",
               "slug": "mauritius",
               "iso": "MUS",
               "iso2": "MU",
               "prefix": "+230",
               "currency": "MUR",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-20.28333333, 57.55]
          },
          {
               "name": "Mexico",
               "slug": "mexico",
               "iso": "MEX",
               "iso2": "MX",
               "prefix": "+52",
               "currency": "MXN",
               "region": "Americas",
               "subregion": "Central America",
               "latlng": [23, -102]
          },
          {
               "name": "Micronesia",
               "slug": "micronesia",
               "iso": "FSM",
               "iso2": "FM",
               "prefix": "+691",
               "currency": "USD",
               "region": "Oceania",
               "subregion": "Micronesia",
               "latlng": [6.91666666, 158.25]
          },
          {
               "name": "Moldova",
               "slug": "moldova",
               "iso": "MDA",
               "iso2": "MD",
               "prefix": "+373",
               "currency": "MDL",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [47, 29]
          },
          {
               "name": "Monaco",
               "slug": "monaco",
               "iso": "MCO",
               "iso2": "MC",
               "prefix": "+377",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [43.73333333, 7.4]
          },
          {
               "name": "Mongolia",
               "slug": "mongolia",
               "iso": "MNG",
               "iso2": "MN",
               "prefix": "+976",
               "currency": "MNT",
               "region": "Asia",
               "subregion": "Eastern Asia",
               "latlng": [46, 105]
          },
          {
               "name": "Montserrat",
               "slug": "montserrat",
               "iso": "MSR",
               "iso2": "MS",
               "prefix": "+1664",
               "currency": "XCD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [16.75, -62.2]
          },
          {
               "name": "Morocco",
               "slug": "morocco",
               "iso": "MAR",
               "iso2": "MA",
               "prefix": "+212",
               "currency": "MAD",
               "region": "Africa",
               "subregion": "Northern Africa",
               "latlng": [32, -5]
          },
          {
               "name": "Mozambique",
               "slug": "mozambique",
               "iso": "MOZ",
               "iso2": "MZ",
               "prefix": "+258",
               "currency": "MZN",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-18.25, 35]
          },
          {
               "name": "Myanmar",
               "slug": "myanmar",
               "iso": "MMR",
               "iso2": "MM",
               "prefix": "+95",
               "currency": "MMK",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [22, 98]
          },
          {
               "name": "Namibia",
               "slug": "namibia",
               "iso": "NAM",
               "iso2": "NA",
               "prefix": "+264",
               "currency": "NAD",
               "region": "Africa",
               "subregion": "Southern Africa",
               "latlng": [-22, 17]
          },
          {
               "name": "Nauru",
               "slug": "nauru",
               "iso": "NRU",
               "iso2": "NR",
               "prefix": "+674",
               "currency": "AUD",
               "region": "Oceania",
               "subregion": "Micronesia",
               "latlng": [-0.53333333, 166.91666666]
          },
          {
               "name": "Nepal",
               "slug": "nepal",
               "iso": "NPL",
               "iso2": "NP",
               "prefix": "+977",
               "currency": "NPR",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [28, 84]
          },
          {
               "name": "Netherlands",
               "slug": "netherlands",
               "iso": "NLD",
               "iso2": "NL",
               "prefix": "+31",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [52.5, 5.75]
          },
          {
               "name": "New Caledonia",
               "slug": "new-caledonia",
               "iso": "NCL",
               "iso2": "NC",
               "prefix": "+687",
               "currency": "XPF",
               "region": "Oceania",
               "subregion": "Melanesia",
               "latlng": [-21.5, 165.5]
          },
          {
               "name": "New Zealand",
               "slug": "new-zealand",
               "iso": "NZL",
               "iso2": "NZ",
               "prefix": "+64",
               "currency": "NZD",
               "region": "Oceania",
               "subregion": "Australia and New Zealand",
               "latlng": [-41, 174]
          },
          {
               "name": "Nicaragua",
               "slug": "nicaragua",
               "iso": "NIC",
               "iso2": "NI",
               "prefix": "+505",
               "currency": "NIO",
               "region": "Americas",
               "subregion": "Central America",
               "latlng": [13, -85]
          },
          {
               "name": "Niger",
               "slug": "niger",
               "iso": "NER",
               "iso2": "NE",
               "prefix": "+227",
               "currency": "XOF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [16, 8]
          },
          {
               "name": "Nigeria",
               "slug": "nigeria",
               "iso": "NGA",
               "iso2": "NG",
               "prefix": "+234",
               "currency": "NGN",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [10, 8]
          },
          {
               "name": "Niue",
               "slug": "niue",
               "iso": "NIU",
               "iso2": "NU",
               "prefix": "+683",
               "currency": "NZD",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-19.03333333, -169.86666666]
          },
          {
               "name": "Norfolk Island",
               "slug": "norfolk-island",
               "iso": "NFK",
               "iso2": "NF",
               "prefix": "+672",
               "currency": "AUD",
               "region": "Oceania",
               "subregion": "Australia and New Zealand",
               "latlng": [-29.03333333, 167.95]
          },
          {
               "name": "Northern Mariana Islands",
               "slug": "northern-mariana-islands",
               "iso": "MNP",
               "iso2": "MP",
               "prefix": "+1670",
               "currency": "USD",
               "region": "Oceania",
               "subregion": "Micronesia",
               "latlng": [15.2, 145.75]
          },
          {
               "name": "Norway",
               "slug": "norway",
               "iso": "NOR",
               "iso2": "NO",
               "prefix": "+47",
               "currency": "NOK",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [62, 10]
          },
          {
               "name": "Oman",
               "slug": "oman",
               "iso": "OMN",
               "iso2": "OM",
               "prefix": "+968",
               "currency": "OMR",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [21, 57]
          },
          {
               "name": "Pakistan",
               "slug": "pakistan",
               "iso": "PAK",
               "iso2": "PK",
               "prefix": "+92",
               "currency": "PKR",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [30, 70]
          },
          {
               "name": "Palau",
               "slug": "palau",
               "iso": "PLW",
               "iso2": "PW",
               "prefix": "+680",
               "currency": "USD",
               "region": "Oceania",
               "subregion": "Micronesia",
               "latlng": [7.5, 134.5]
          },
          {
               "name": "Panama",
               "slug": "panama",
               "iso": "PAN",
               "iso2": "PA",
               "prefix": "+507",
               "currency": "PAB",
               "region": "Americas",
               "subregion": "Central America",
               "latlng": [9, -80]
          },
          {
               "name": "Papua New Guinea",
               "slug": "papua-new-guinea",
               "iso": "PNG",
               "iso2": "PG",
               "prefix": "+675",
               "currency": "PGK",
               "region": "Oceania",
               "subregion": "Melanesia",
               "latlng": [-6, 147]
          },
          {
               "name": "Paraguay",
               "slug": "paraguay",
               "iso": "PRY",
               "iso2": "PY",
               "prefix": "+595",
               "currency": "PYG",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-23, -58]
          },
          {
               "name": "Peru",
               "slug": "peru",
               "iso": "PER",
               "iso2": "PE",
               "prefix": "+51",
               "currency": "PEN",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-10, -76]
          },
          {
               "name": "Philippines",
               "slug": "philippines",
               "iso": "PHL",
               "iso2": "PH",
               "prefix": "+63",
               "currency": "PHP",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [13, 122]
          },
          {
               "name": "Pitcairn Islands",
               "slug": "pitcairn-islands",
               "iso": "PCN",
               "iso2": "PN",
               "prefix": "+0",
               "currency": "NZD",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-25.06666666, -130.1]
          },
          {
               "name": "Poland",
               "slug": "poland",
               "iso": "POL",
               "iso2": "PL",
               "prefix": "+48",
               "currency": "PLN",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [52, 20]
          },
          {
               "name": "Portugal",
               "slug": "portugal",
               "iso": "PRT",
               "iso2": "PT",
               "prefix": "+351",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [39.5, -8]
          },
          {
               "name": "Puerto Rico",
               "slug": "puerto-rico",
               "iso": "PRI",
               "iso2": "PR",
               "prefix": "+1787",
               "currency": "USD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [18.25, -66.5]
          },
          {
               "name": "Qatar",
               "slug": "qatar",
               "iso": "QAT",
               "iso2": "QA",
               "prefix": "+974",
               "currency": "QAR",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [25.5, 51.25]
          },
          {
               "name": "Réunion",
               "slug": "reunion",
               "iso": "REU",
               "iso2": "RE",
               "prefix": "+262",
               "currency": "EUR",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-21.15, 55.5]
          },
          {
               "name": "Romania",
               "slug": "romania",
               "iso": "ROU",
               "iso2": "RO",
               "prefix": "+40",
               "currency": "RON",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [46, 25]
          },
          {
               "name": "Russia",
               "slug": "russia",
               "iso": "RUS",
               "iso2": "RU",
               "prefix": "+70",
               "currency": "RUB",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [60, 100]
          },
          {
               "name": "Rwanda",
               "slug": "rwanda",
               "iso": "RWA",
               "iso2": "RW",
               "prefix": "+250",
               "currency": "RWF",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-2, 30]
          },
          {
               "name": "Saint Kitts and Nevis",
               "slug": "saint-kitts-and-nevis",
               "iso": "KNA",
               "iso2": "KN",
               "prefix": "+1869",
               "currency": "XCD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [17.33333333, -62.75]
          },
          {
               "name": "Saint Lucia",
               "slug": "saint-lucia",
               "iso": "LCA",
               "iso2": "LC",
               "prefix": "+1758",
               "currency": "XCD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [13.88333333, -60.96666666]
          },
          {
               "name": "Saint Pierre and Miquelon",
               "slug": "saint-pierre-and-miquelon",
               "iso": "SPM",
               "iso2": "PM",
               "prefix": "+508",
               "currency": "EUR",
               "region": "Americas",
               "subregion": "Northern America",
               "latlng": [46.83333333, -56.33333333]
          },
          {
               "name": "Saint Vincent and the Grenadines",
               "slug": "saint-vincent-and-the-grenadines",
               "iso": "VCT",
               "iso2": "VC",
               "prefix": "+1784",
               "currency": "XCD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [13.25, -61.2]
          },
          {
               "name": "Samoa",
               "slug": "samoa",
               "iso": "WSM",
               "iso2": "WS",
               "prefix": "+684",
               "currency": "WST",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-13.58333333, -172.33333333]
          },
          {
               "name": "San Marino",
               "slug": "san-marino",
               "iso": "SMR",
               "iso2": "SM",
               "prefix": "+378",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [43.76666666, 12.41666666]
          },
          {
               "name": "São Tomé and Príncipe",
               "slug": "sao-tome-and-principe",
               "iso": "STP",
               "iso2": "ST",
               "prefix": "+239",
               "currency": "STD",
               "region": "Africa",
               "subregion": "Middle Africa",
               "latlng": [1, 7]
          },
          {
               "name": "Saudi Arabia",
               "slug": "saudi-arabia",
               "iso": "SAU",
               "iso2": "SA",
               "prefix": "+966",
               "currency": "SAR",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [25, 45]
          },
          {
               "name": "Senegal",
               "slug": "senegal",
               "iso": "SEN",
               "iso2": "SN",
               "prefix": "+221",
               "currency": "XOF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [14, -14]
          },
          {
               "name": "Serbia",
               "slug": "serbia",
               "iso": "SRB",
               "iso2": "CS",
               "prefix": "+381",
               "currency": "RSD",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [44, 21]
          },
          {
               "name": "Montenegro",
               "slug": "montenegro",
               "iso": "MNE",
               "iso2": "CS",
               "prefix": "+382",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [42.5, 19.3]
          },
          {
               "name": "Seychelles",
               "slug": "seychelles",
               "iso": "SYC",
               "iso2": "SC",
               "prefix": "+248",
               "currency": "SCR",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-4.58333333, 55.66666666]
          },
          {
               "name": "Sierra Leone",
               "slug": "sierra-leone",
               "iso": "SLE",
               "iso2": "SL",
               "prefix": "+232",
               "currency": "SLL",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [8.5, -11.5]
          },
          {
               "name": "Singapore",
               "slug": "singapore",
               "iso": "SGP",
               "iso2": "SG",
               "prefix": "+65",
               "currency": "SGD",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [1.36666666, 103.8]
          },
          {
               "name": "Slovakia",
               "slug": "slovakia",
               "iso": "SVK",
               "iso2": "SK",
               "prefix": "+421",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Central Europe",
               "latlng": [48.66666666, 19.5]
          },
          {
               "name": "Slovenia",
               "slug": "slovenia",
               "iso": "SVN",
               "iso2": "SI",
               "prefix": "+386",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [46.11666666, 14.81666666]
          },
          {
               "name": "Solomon Islands",
               "slug": "solomon-islands",
               "iso": "SLB",
               "iso2": "SB",
               "prefix": "+677",
               "currency": "SBD",
               "region": "Oceania",
               "subregion": "Melanesia",
               "latlng": [-8, 159]
          },
          {
               "name": "Somalia",
               "slug": "somalia",
               "iso": "SOM",
               "iso2": "SO",
               "prefix": "+252",
               "currency": "SOS",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [10, 49]
          },
          {
               "name": "South Africa",
               "slug": "south-africa",
               "iso": "ZAF",
               "iso2": "ZA",
               "prefix": "+27",
               "currency": "ZAR",
               "region": "Africa",
               "subregion": "Southern Africa",
               "latlng": [-29, 24]
          },
          {
               "name": "Spain",
               "slug": "spain",
               "iso": "ESP",
               "iso2": "ES",
               "prefix": "+34",
               "currency": "EUR",
               "region": "Europe",
               "subregion": "Southern Europe",
               "latlng": [40, -4]
          },
          {
               "name": "Sri Lanka",
               "slug": "sri-lanka",
               "iso": "LKA",
               "iso2": "LK",
               "prefix": "+94",
               "currency": "LKR",
               "region": "Asia",
               "subregion": "Southern Asia",
               "latlng": [7, 81]
          },
          {
               "name": "Sudan",
               "slug": "sudan",
               "iso": "SDN",
               "iso2": "SD",
               "prefix": "+249",
               "currency": "SDG",
               "region": "Africa",
               "subregion": "Northern Africa",
               "latlng": [15, 30]
          },
          {
               "name": "Suriname",
               "slug": "suriname",
               "iso": "SUR",
               "iso2": "SR",
               "prefix": "+597",
               "currency": "SRD",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [4, -56]
          },
          {
               "name": "Svalbard and Jan Mayen",
               "slug": "svalbard-and-jan-mayen",
               "iso": "SJM",
               "iso2": "SJ",
               "prefix": "+47",
               "currency": "NOK",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [78, 20]
          },
          {
               "name": "Swaziland",
               "slug": "swaziland",
               "iso": "SWZ",
               "iso2": "SZ",
               "prefix": "+268",
               "currency": "SZL",
               "region": "Africa",
               "subregion": "Southern Africa",
               "latlng": [-26.5, 31.5]
          },
          {
               "name": "Sweden",
               "slug": "sweden",
               "iso": "SWE",
               "iso2": "SE",
               "prefix": "+46",
               "currency": "SEK",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [62, 15]
          },
          {
               "name": "Switzerland",
               "slug": "switzerland",
               "iso": "CHE",
               "iso2": "CH",
               "prefix": "+41",
               "currency": "CHE",
               "region": "Europe",
               "subregion": "Western Europe",
               "latlng": [47, 8]
          },
          {
               "name": "Syria",
               "slug": "syria",
               "iso": "SYR",
               "iso2": "SY",
               "prefix": "+963",
               "currency": "SYP",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [35, 38]
          },
          {
               "name": "Taiwan",
               "slug": "taiwan",
               "iso": "TWN",
               "iso2": "TW",
               "prefix": "+886",
               "currency": "TWD",
               "region": "Asia",
               "subregion": "Eastern Asia",
               "latlng": [23.5, 121]
          },
          {
               "name": "Tajikistan",
               "slug": "tajikistan",
               "iso": "TJK",
               "iso2": "TJ",
               "prefix": "+992",
               "currency": "TJS",
               "region": "Asia",
               "subregion": "Central Asia",
               "latlng": [39, 71]
          },
          {
               "name": "Tanzania",
               "slug": "tanzania",
               "iso": "TZA",
               "iso2": "TZ",
               "prefix": "+255",
               "currency": "TZS",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-6, 35]
          },
          {
               "name": "Thailand",
               "slug": "thailand",
               "iso": "THA",
               "iso2": "TH",
               "prefix": "+66",
               "currency": "THB",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [15, 100]
          },
          {
               "name": "Togo",
               "slug": "togo",
               "iso": "TGO",
               "iso2": "TG",
               "prefix": "+228",
               "currency": "XOF",
               "region": "Africa",
               "subregion": "Western Africa",
               "latlng": [8, 1.16666666]
          },
          {
               "name": "Tokelau",
               "slug": "tokelau",
               "iso": "TKL",
               "iso2": "TK",
               "prefix": "+690",
               "currency": "NZD",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-9, -172]
          },
          {
               "name": "Tonga",
               "slug": "tonga",
               "iso": "TON",
               "iso2": "TO",
               "prefix": "+676",
               "currency": "TOP",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-20, -175]
          },
          {
               "name": "Trinidad and Tobago",
               "slug": "trinidad-and-tobago",
               "iso": "TTO",
               "iso2": "TT",
               "prefix": "+1868",
               "currency": "TTD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [11, -61]
          },
          {
               "name": "Tunisia",
               "slug": "tunisia",
               "iso": "TUN",
               "iso2": "TN",
               "prefix": "+216",
               "currency": "TND",
               "region": "Africa",
               "subregion": "Northern Africa",
               "latlng": [34, 9]
          },
          {
               "name": "Turkey",
               "slug": "turkey",
               "iso": "TUR",
               "iso2": "TR",
               "prefix": "+90",
               "currency": "TRY",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [39, 35]
          },
          {
               "name": "Turkmenistan",
               "slug": "turkmenistan",
               "iso": "TKM",
               "iso2": "TM",
               "prefix": "+7370",
               "currency": "TMT",
               "region": "Asia",
               "subregion": "Central Asia",
               "latlng": [40, 60]
          },
          {
               "name": "Turks and Caicos Islands",
               "slug": "turks-and-caicos-islands",
               "iso": "TCA",
               "iso2": "TC",
               "prefix": "+1649",
               "currency": "USD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [21.75, -71.58333333]
          },
          {
               "name": "Tuvalu",
               "slug": "tuvalu",
               "iso": "TUV",
               "iso2": "TV",
               "prefix": "+688",
               "currency": "AUD",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-8, 178]
          },
          {
               "name": "Uganda",
               "slug": "uganda",
               "iso": "UGA",
               "iso2": "UG",
               "prefix": "+256",
               "currency": "UGX",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [1, 32]
          },
          {
               "name": "Ukraine",
               "slug": "ukraine",
               "iso": "UKR",
               "iso2": "UA",
               "prefix": "+380",
               "currency": "UAH",
               "region": "Europe",
               "subregion": "Eastern Europe",
               "latlng": [49, 32]
          },
          {
               "name": "United Arab Emirates",
               "slug": "united-arab-emirates",
               "iso": "ARE",
               "iso2": "AE",
               "prefix": "+971",
               "currency": "AED",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [24, 54]
          },
          {
               "name": "United Kingdom",
               "slug": "united-kingdom",
               "iso": "GBR",
               "iso2": "GB",
               "prefix": "+44",
               "currency": "GBP",
               "region": "Europe",
               "subregion": "Northern Europe",
               "latlng": [54, -2]
          },
          {
               "name": "United States",
               "slug": "united-states",
               "iso": "USA",
               "iso2": "US",
               "prefix": "+1",
               "currency": "USD",
               "region": "Americas",
               "subregion": "Northern America",
               "latlng": [38, -97]
          },
          {
               "name": "Uruguay",
               "slug": "uruguay",
               "iso": "URY",
               "iso2": "UY",
               "prefix": "+598",
               "currency": "UYI",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [-33, -56]
          },
          {
               "name": "Uzbekistan",
               "slug": "uzbekistan",
               "iso": "UZB",
               "iso2": "UZ",
               "prefix": "+998",
               "currency": "UZS",
               "region": "Asia",
               "subregion": "Central Asia",
               "latlng": [41, 64]
          },
          {
               "name": "Vanuatu",
               "slug": "vanuatu",
               "iso": "VUT",
               "iso2": "VU",
               "prefix": "+678",
               "currency": "VUV",
               "region": "Oceania",
               "subregion": "Melanesia",
               "latlng": [-16, 167]
          },
          {
               "name": "Venezuela",
               "slug": "venezuela",
               "iso": "VEN",
               "iso2": "VE",
               "prefix": "+58",
               "currency": "VEF",
               "region": "Americas",
               "subregion": "South America",
               "latlng": [8, -66]
          },
          {
               "name": "Vietnam",
               "slug": "vietnam",
               "iso": "VNM",
               "iso2": "VN",
               "prefix": "+84",
               "currency": "VND",
               "region": "Asia",
               "subregion": "South-Eastern Asia",
               "latlng": [16.16666666, 107.83333333]
          },
          {
               "name": "British Virgin Islands",
               "slug": "british-virgin-islands",
               "iso": "VGB",
               "iso2": "VG",
               "prefix": "+1284",
               "currency": "USD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [18.431383, -64.62305]
          },
          {
               "name": "United States Virgin Islands",
               "slug": "united-states-virgin-islands",
               "iso": "VIR",
               "iso2": "VI",
               "prefix": "+1340",
               "currency": "USD",
               "region": "Americas",
               "subregion": "Caribbean",
               "latlng": [18.35, -64.933333]
          },
          {
               "name": "Wallis and Futuna",
               "slug": "wallis-and-futuna",
               "iso": "WLF",
               "iso2": "WF",
               "prefix": "+681",
               "currency": "XPF",
               "region": "Oceania",
               "subregion": "Polynesia",
               "latlng": [-13.3, -176.2]
          },
          {
               "name": "Western Sahara",
               "slug": "western-sahara",
               "iso": "ESH",
               "iso2": "EH",
               "prefix": "+212",
               "currency": "MAD",
               "region": "Africa",
               "subregion": "Northern Africa",
               "latlng": [24.5, -13]
          },
          {
               "name": "Yemen",
               "slug": "yemen",
               "iso": "YEM",
               "iso2": "YE",
               "prefix": "+967",
               "currency": "YER",
               "region": "Asia",
               "subregion": "Western Asia",
               "latlng": [15, 48]
          },
          {
               "name": "Zambia",
               "slug": "zambia",
               "iso": "ZMB",
               "iso2": "ZM",
               "prefix": "+260",
               "currency": "ZMW",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-15, 30]
          },
          {
               "name": "Zimbabwe",
               "slug": "zimbabwe",
               "iso": "ZWE",
               "iso2": "ZW",
               "prefix": "+263",
               "currency": "ZWL",
               "region": "Africa",
               "subregion": "Eastern Africa",
               "latlng": [-20, 30]
          }
     ]

export const STATES_NIGERIA =
     [
          {
               "name": "Abia",
               "slug": "nigeria_abia",
               "stateSlug": "abia",
               "country": "nigeria"
          },
          {
               "name": "Adamawa",
               "slug": "nigeria_adamawa",
               "stateSlug": "adamawa",
               "country": "nigeria"
          },
          {
               "name": "Akwa Ibom",
               "slug": "nigeria_akwa-ibom",
               "stateSlug": "akwa-ibom",
               "country": "nigeria"
          },
          {
               "name": "Anambra",
               "slug": "nigeria_anambra",
               "stateSlug": "anambra",
               "country": "nigeria"
          },
          {
               "name": "Bauchi",
               "slug": "nigeria_bauchi",
               "stateSlug": "bauchi",
               "country": "nigeria"
          },
          {
               "name": "Bayelsa",
               "slug": "nigeria_bayelsa",
               "stateSlug": "bayelsa",
               "country": "nigeria"
          },
          {
               "name": "Benue",
               "slug": "nigeria_benue",
               "stateSlug": "benue",
               "country": "nigeria"
          },
          {
               "name": "Borno",
               "slug": "nigeria_borno",
               "stateSlug": "borno",
               "country": "nigeria"
          },
          {
               "name": "Cross River",
               "slug": "nigeria_cross-river",
               "stateSlug": "cross-river",
               "country": "nigeria"
          },
          {
               "name": "Delta",
               "slug": "nigeria_delta",
               "stateSlug": "delta",
               "country": "nigeria"
          },
          {
               "name": "Ebonyi",
               "slug": "nigeria_ebonyi",
               "stateSlug": "ebonyi",
               "country": "nigeria"
          },
          {
               "name": "Edo",
               "slug": "nigeria_edo",
               "stateSlug": "edo",
               "country": "nigeria"
          },
          {
               "name": "Ekiti",
               "slug": "nigeria_ekiti",
               "stateSlug": "ekiti",
               "country": "nigeria"
          },
          {
               "name": "Enugu",
               "slug": "nigeria_enugu",
               "stateSlug": "enugu",
               "country": "nigeria"
          },
          {
               "name": "Fct",
               "slug": "nigeria_fct",
               "stateSlug": "fct",
               "country": "nigeria"
          },
          {
               "name": "Gombe",
               "slug": "nigeria_gombe",
               "stateSlug": "gombe",
               "country": "nigeria"
          },
          {
               "name": "Imo",
               "slug": "nigeria_imo",
               "stateSlug": "imo",
               "country": "nigeria"
          },
          {
               "name": "Jigawa",
               "slug": "nigeria_jigawa",
               "stateSlug": "jigawa",
               "country": "nigeria"
          },
          {
               "name": "Kaduna",
               "slug": "nigeria_kaduna",
               "stateSlug": "kaduna",
               "country": "nigeria"
          },
          {
               "name": "Kano",
               "slug": "nigeria_kano",
               "stateSlug": "kano",
               "country": "nigeria"
          },
          {
               "name": "Katsina",
               "slug": "nigeria_katsina",
               "stateSlug": "katsina",
               "country": "nigeria"
          },
          {
               "name": "Kebbi",
               "slug": "nigeria_kebbi",
               "stateSlug": "kebbi",
               "country": "nigeria"
          },
          {
               "name": "Kogi",
               "slug": "nigeria_kogi",
               "stateSlug": "kogi",
               "country": "nigeria"
          },
          {
               "name": "Kwara",
               "slug": "nigeria_kwara",
               "stateSlug": "kwara",
               "country": "nigeria"
          },
          {
               "name": "Lagos",
               "slug": "nigeria_lagos",
               "stateSlug": "lagos",
               "country": "nigeria"
          },
          {
               "name": "Nasarawa",
               "slug": "nigeria_nasarawa",
               "stateSlug": "nasarawa",
               "country": "nigeria"
          },
          {
               "name": "Niger",
               "slug": "nigeria_niger",
               "stateSlug": "niger",
               "country": "nigeria"
          },
          {
               "name": "Ogun",
               "slug": "nigeria_ogun",
               "stateSlug": "ogun",
               "country": "nigeria"
          },
          {
               "name": "Ondo",
               "slug": "nigeria_ondo",
               "stateSlug": "ondo",
               "country": "nigeria"
          },
          {
               "name": "Osun",
               "slug": "nigeria_osun",
               "stateSlug": "osun",
               "country": "nigeria"
          },
          {
               "name": "Oyo",
               "slug": "nigeria_oyo",
               "stateSlug": "oyo",
               "country": "nigeria"
          },
          {
               "name": "Plateau",
               "slug": "nigeria_plateau",
               "stateSlug": "plateau",
               "country": "nigeria"
          },
          {
               "name": "Rivers",
               "slug": "nigeria_rivers",
               "stateSlug": "rivers",
               "country": "nigeria"
          },
          {
               "name": "Sokoto",
               "slug": "nigeria_sokoto",
               "stateSlug": "sokoto",
               "country": "nigeria"
          },
          {
               "name": "Taraba",
               "slug": "nigeria_taraba",
               "stateSlug": "taraba",
               "country": "nigeria"
          },
          {
               "name": "Yobe",
               "slug": "nigeria_yobe",
               "stateSlug": "yobe",
               "country": "nigeria"
          },
          {
               "name": "Zamfara",
               "slug": "nigeria_zamfara",
               "stateSlug": "zamfara",
               "country": "nigeria"
          }
     ]

export const PICKUP_LOCATIONS = [
     {
          "Address": "1st Floor, Festac Towers, 22 Road",
          "Landmark": "Chicken Republic Building",
          "City": "Festac Town",
          "State": "Lagos",
          "Country": "Nigeria"
     },
     {
          "Address": "217, Ipaja Road, Mulero Bus Stop",
          "Landmark": "NNPC Filling Station",
          "City": "Agege",
          "State": "Lagos",
          "Country": "Nigeria"
     },
     {
          "Address": "3rd Floor, Polystar Building, Remi Olowude Street",
          "Landmark": "Marwa Bus Stop",
          "City": "lekki",
          "State": "Lagos",
          "Country": "Nigeria"
     },
     // {
     //      "Address": "5, University Road",
     //      "Landmark": "University of Ibadan",
     //      "City": "Ibadan",
     //      "State": "Oyo",
     //      "Country": "Nigeria"
     // },
     // {
     //      "Address": "1, Hassan Usman Katsina Road",
     //      "Landmark": "Kano Trade Fair Complex",
     //      "City": "Kano",
     //      "State": "Kano",
     //      "Country": "Nigeria"
     // },
     // {
     //      "Address": "1600 Amphitheatre Parkway",
     //      "Landmark": "Googleplex",
     //      "City": "Mountain View",
     //      "State": "California",
     //      "Country": "USA"
     // },
     // {
     //      "Address": "10 Downing Street",
     //      "Landmark": "",
     //      "City": "London",
     //      "State": "",
     //      "Country": "UK"
     // }
]
