import React from 'react'
import './index.css'
import { useLongPress } from 'use-long-press';

function SocialCard(props) {

	const handleElementClick = (e) => {
		e.preventDefault();
		if (!props.editable) {
			props.handleClick(props.params);
		}
	}

	const bind = useLongPress((e) => {
		if (!props.editable) {
			props.editableModal(true);
		} else {
			props.editableModal(false);
		}
	}, {
		threshold: 1000
	});

	const deleteField = (e) => {
		e.preventDefault();
		props.onDelete(props.params);
	}

	if (!props) return null;

	return (
		<div className='position-relative quick-link-card rounded' {...bind()} onClick={handleElementClick} data-bs-toggle={(!props.editable) ? 'modal' : ''} data-bs-target="#exampleModal" style={{ cursor: 'pointer' }}>
			<span hidden={!props.editable} onClick={deleteField} className='delete-social-card rounded-circle position-absolute btn bg-white text-black p-0'><i className="fa-solid fa-xmark"></i></span>
			<div className='position-absolute top-50 start-50 text-center translate-middle'>
				<div className='social-icon'><i className={props.params.icon + ' fa-xl'}></i></div>
			</div>
		</div>
	)
}

export default SocialCard;