import React from 'react'
import { Dropdown } from 'react-bootstrap'
import ICEIcon from '../../components/icons/ICEIcon'
// import Navigation from './Navigation'

export default function Navbar() {
     return (
          <nav className="navbar navbar-expand-lg bg-black" style={{
               borderBottom: 'solid .1px rgba(255,255,255,0.3)',
          }}>
               <div className="container py-2 px-md-5 justify-content-between ">
                    <div className="text-white">
                         <Dropdown>
                              <Dropdown.Toggle variant='dark' id="dropdown-basic" className='noarrowdropdown bg-black '>
                                   <ICEIcon iconType='more' size={24} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                   <Dropdown.Item href="#about">ABOUT US</Dropdown.Item>
                                   <Dropdown.Item href="#connect">CONNECT</Dropdown.Item>
                                   <Dropdown.Item href="#event">EVENT</Dropdown.Item>
                                   <Dropdown.Item href="/faq">FAQs</Dropdown.Item>
                              </Dropdown.Menu>
                         </Dropdown>
                         {/* <Navigation /> */}
                    </div>
                    <div className="text-white">
                         <img src='/assets/images/logo/logofull-dark.webp' alt='ISCE' height={20} />
                    </div>
                    <div className="text-white">
                         <div className="d-none d-md-flex">
                              <a href={'/get-started'} className="btn bg-light text-black rounded-pill">Get Cards</a>
                              <a href='/login' className="btn vc-white ms-2 rounded-pill">Login</a>
                         </div>
                    </div>
               </div>
          </nav>
     )
}
