import React, { Component } from 'react';
import Swipeup from './swipeup.component';
import Swipedown from './swipedown.component';

class Swipe extends Component {
    static defaultProps = {
        swipe: {
            maxHeight: '100vh',
            minHeight: '6vh',
            initialState: 'down',
            data: null
        }
    }

    constructor(props){
        super(props);
        this.state = {
            maxHeight: this.props.swipe.maxHeight,
            minHeight: this.props.swipe.minHeight,
            height: this.props.swipe.minHeight,
            heightStat: this.props.swipe.initialState,
            data: this.props.data || null,
            xDown: null,
            yDown: null
        }
        this.handleClick = this.handleClick.bind(this);
        /* this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this); */
    }

    handleClick = e => {
        e.preventDefault();
        const curHeight = this.state.heightStat === 'down' 
                    ? this.state.maxHeight : 
                    this.state.minHeight;
        const stat = this.state.heightStat === 'down' ? 'up' : 'down';
        this.setState(() => {
            return {
                height: curHeight,
                heightStat: stat
            }
        });
    }

    /* getTouches = (evt) => {
        return evt.touches ||             // browser API
         evt.originalEvent.touches; // jQuery
    }

    handleTouchStart = e => {
        const firstTouch = this.getTouches(e)[0];    
        this.setState({
            xDown: firstTouch.clientX,                                      
            yDown: firstTouch.clientY
        })                                  
    }

    handleTouchMove = e => {
        if ( ! this.state.xDown || ! this.state.yDown ) {
            return;
        }
    
        let xUp = e.touches[0].clientX;                                    
        let yUp = e.touches[0].clientY;
    
        let xDiff = this.state.xDown - xUp;
        let yDiff = this.state.yDown - yUp;
                                                                             
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) { 
            const curHeight = yDiff > 0 ? this.state.maxHeight : this.state.minHeight;
            const stat = yDiff > 0 ? 'up' : 'down';
            this.setState(() => {
                return {
                    height: curHeight,
                    heightStat: stat
                }
            });                 
        } 
        this.setState({
            xDown: null,
            yDown: null
        });
    } */

    render(){
        let barContent = (this.state.heightStat === 'down') 
                                    ? <Swipedown handleClick={this.handleClick} /> : 
                                        <Swipeup handleClick={this.handleClick} data={ this.state.data } />;

        return (
            <div className={ (this.state.heightStat === 'down') ? 'fixed-bottom d-sm-none' : 'fixed-bottom overflow-scroll d-sm-none' }>
                <div className="bg-black slideUp" style={{ height: this.state.height }}>
                    <div className="text-center">
                        { barContent } 
                    </div>
                </div>
            </div>
        );
    }
}

export default Swipe;