import React, { useEffect, useState } from 'react'
import "./index.css"
import Navbar from "./Navbar/Navbar";
import Earzsection from './Earzsection/Earzsection';
import Footer from "./Footer/Footer";
import Cardsection from './Cardsection/Cardsection';
import Watchsection from './Watchsection/Watchsection';
import Ecosection from './Ecosection/Ecosection';
import { useNavigate } from 'react-router-dom';
import { API } from '../../api/Request';
import axios from '../../hooks/axios';
import useCardQuery from '../../hooks/useCardQuery';
import ROUTES from '../../Routes';
import LoadingIndicator from '../../components/LoadingIndicator.component';

const loadRouteLink = async (data) => {
  return axios.post(API.loadRouteLink, data)
}

function Preorder() {
  const [ loading, setLoading ] = useState(false);
  const navigate = useNavigate();
  const query = useCardQuery();
  const order_link = 'https://flutterwave.com/store/isce';

  useEffect(() => {
    const gotoRoute = (route) => navigate(route, { replace: true })
    const fetchRoute = async ({ card_id, type }) => {
          const { data: routes } = await loadRouteLink({ card_id, type });
          setLoading(false);
          if(routes.success === 'true'){
            gotoRoute(routes.data.redirect_url);
          }else{
            if(query.link()) navigate('/' + ROUTES.error404.path, { replace: true})
          }
    }
    if(query.link()){
      setLoading(true)
      fetchRoute({
        card_id: query.id,
        type: query.type
      });
    }
  }, [query, navigate]);

  if(loading) return <LoadingIndicator />
  if(!loading && query.link()) return <LoadingIndicator />

  return (
    <div>
     <Navbar store={order_link} />
     <Earzsection store={ order_link } />
     {/* <Header /> */}
     <Cardsection store={ order_link } />
     <Watchsection />
     <Ecosection />
     <Footer store={ order_link } />
    </div>
  )
}

export default Preorder