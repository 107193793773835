import React from 'react'

export default function Connect() {
     return (
          <>
               <div className="container py-5" id='connect'>
                    <h1 className="fw-bold isce_h1 mb-3 d-flex justify-content-center align-items-center">
                         <img src='assets/images/logo/logofull-dark.webp' className='me-3' alt='ISCE' height={33} />
                         {'CONNECT'}
                    </h1>
                    <div className="row pb-md-5">
                         <div className="col-md-6 ">
                              <img src='assets/images/background/tab_connect.png' alt='' className='pt-5 mx-auto' style={{
                                   maxHeight: '550px'
                              }} />
                         </div>
                         <div className="col-md-6  pt-md-5 mt-md-5">
                              <div className="py-5 px-2">
                                   <h2 className="py-2">Personal Use</h2>
                                   <div className="py-2 isce_text">
                                        <p>
                                             With just one tap, share more than just your contact.
                                             Share your personality, your business, have a backup for your contacts and so much more.
                                        </p>
                                        <p>
                                             Connect with style. Connect with ISCE
                                        </p>
                                   </div>
                                   <h2 className="py-2">Business</h2>
                                   <div className="py-2 isce_text">
                                        <p>Tired of the limitations of paper cards? Have a smarter, sleeker and more efficient way of increasing your business reach, turning handshakes to profitable connections. </p>
                                        <p>Connect with style. Connect with ISCE</p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}
