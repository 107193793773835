import React from 'react'

function Navbar(props) {
     if (!props) return null;

     return (
          <nav className="navbar">
               <div className="">
                    <a href={props.backlink} className="btn btn-light">
                         <i className="fa-solid fa-arrow-left pe-2"></i> BACK
                    </a>
               </div>
          </nav>
     )
}

export default Navbar;