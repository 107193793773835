import React from "react";
import useStorage from "../hooks/useStorage";
import { DATA_STORAGE } from "../api/Request";

export const withUseStorageHOC = (Component) => {
  return (props) => {
    const authData = useStorage(DATA_STORAGE.auth);
    const dashboardData = useStorage(DATA_STORAGE.dashboard);

    return <Component auth={authData} dashboard={dashboardData} {...props} />;
  };
};