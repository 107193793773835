// axios.js
import axios from "axios";
import { COOKIE_STORE, REQUEST } from "../api/Request";
import * as ls from 'local-storage';

const auth = ls.get(COOKIE_STORE.auth)
  ? ls.get(COOKIE_STORE.auth)
  : null;

export default axios.create({
  baseURL: REQUEST.url,
  headers: {
    'API-KEY': '',
    'API-SECRET': ''
  }
});

export const axiosWithHeaders = (token = null) => axios.create({
  baseURL: REQUEST.url,
  headers: {
    'Authorization': (token) ? token : auth,
    'API-KEY': ''
  }
});

export const axiosWithAuth = (headers = { }) => {
  axios.create({
    baseURL: REQUEST.url,
    headers: {
      ...headers,
      'Authorization': ls.get(COOKIE_STORE.auth) ? ls.get(COOKIE_STORE.auth) : null,
      'Content-Type': 'application/json'
    }
  });
  //axios.interceptors.response.use(handleSuccess, handleError);
  return axios;
};

/* const handleSuccess = (response) => {
  console.log('success');
  return ({data: response?.data});
}

const handleError = (error) => {
  console.log(error);
  if (error.message === 'Network Error') {
     // The user doesn't have internet
      return Promise.reject(error);
   }
   switch (error.response.status) {
      case 400:
        break;
      case 401:
        // Go to login
        break;
      case 404:
        // Show 404 page
        break;
      case 500:
        // Serveur Error redirect to 500
        break;
      default:
        // Unknow Error
       break;
    }
    return Promise.reject(error);
} */