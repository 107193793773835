import React from 'react'
import ICEIcon from '../../../components/icons/ICEIcon'

export default function ContactUs() {
     return (
          <>
               <div className="col d-xl-block d-none px-2 py-3" style={{
                    maxWidth: '300px'
               }}>
                    <h4 className='px-2 h2 text-center text-uppercase mb-3'>Join the ISCE Ecosystem Now</h4>
                    <div className='text-center mb-2'>
                         Contact us to get a card.
                    </div>
                    <div className="d-grid text-uppercase mb-2">
                         <a href='https://wa.me/2348124339827' className='btn btn-outline-dark'>
                              <ICEIcon iconType='whatsapp' size={30} />
                         </a>
                    </div>
                    <div className="d-grid text-uppercase mb-2">
                         <a href='mailto:support@isce.app' className='btn btn-outline-dark'>
                              <ICEIcon iconType='mail' size={30} />
                         </a>
                    </div>
                    <div className="d-grid text-uppercase mb-2">
                         <a href='tel:08124339827' className='btn btn-outline-dark'>
                              <ICEIcon iconType='phone' size={30} />
                         </a>
                    </div>
               </div>
          </>
     )
}
