import * as yup from 'yup';

export const validationError = (errors, name = '') => {
    if (!errors) return null;
    if (!name) return null;
    return errors.find(e => e.includes(name))
}

export const checkValidity = async (validator, data) => {
    // check validity
    try {
        const valid = await validator.validate(data, { abortEarly: false });
        return { success: true, data: valid }
    } catch (err) {
        return { success: false, errors: err.errors }
    }
}

export const loginFormValidation = {
    // email: yup.string().required().email(),  return .email afterwards
    email: yup.string().required(),
    password: yup.string().required().min(8)//.matches(RegExp(/[a-z]/), 'at least one lowercase char')
    //.matches(RegExp(/[A-Z]/), 'at least one uppercase char').matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'at least 1 number or special char (@,!,#, etc).'),
}
export const loginValidationSchema = yup.object().shape(loginFormValidation);

export const tokenFormValidation = {
    token: yup.string().required().min(6).max(6).matches(RegExp(/^\w+$/i), "token is invalid")
}
export const tokenValidationSchema = yup.object().shape(tokenFormValidation);

export const passwordValidation = {
    password: yup.string().required().min(8, "Password must be at least 8 characters").matches(RegExp(/[a-z]/), 'At least one lowercase character').matches(RegExp(/[A-Z]/), 'At least one uppercase character').matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'At least 1 number or special character (@,!,#, etc).'),
    confirm_password: yup.string().required("Confirm your password").oneOf([yup.ref('password'), null], 'Passwords must match')
}
export const passwordValidationSchema = yup.object().shape(passwordValidation);

export const addEventValidation = {
    title: yup.string().required(),
    description: yup.string().required(),
    start_date: yup.date().min(new Date(), 'Please choose future date!').typeError("please enter a valid date"),
    end_date: yup.date().when('start_date',
        (start_date, schema) => {
            if (start_date) {
                const dayAfter = new Date(start_date.getTime() + 86400000);

                return schema.min(dayAfter, 'End date has to be after than start date');
            }

            return schema;
        }),
    location: yup.string().required(),
    prices: yup.array()
        .of(
            yup.object().shape({
                price_category: yup.string(),
                price_type: yup.string(),
                price_attendees: yup.string()
            })
        )
        .required('Required')
}

/* 
Onboarding Validation
*/
//Onboarding Step One
export const validateEmailCode = {
    // email: yup.string().required().email(),  return .email afterwards
    email: yup.string().required(),
    code: yup.number().min(4).required()
}
export const validateEmailCodeSchema = yup.object().shape(validateEmailCode);

export const validateEmail = {
    email: yup.string().required().email()
}
export const validateEmailSchema = yup.object().shape(validateEmail);

//Onboarding Step Three
export const validatePersonalData = {
    name: yup.string().required(),
    subTitle: yup.string().required("Tell us what you do?"),
    description: yup.string(),
    phone: yup.string().required().matches(RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,7}$"), "phone is invalid"),
};
export const validatePersonalDataSchema = yup.object().shape(validatePersonalData);

//Onboarding Step Four
export const validateSocialLinks = {
    facebook: yup.string(),
    twitter: yup.string(),
    snapchat: yup.string(),
    website: yup.string()
}

//Onboarding Step Five
export const validateImage = {
    image: yup.mixed().test("imageSize", "The image is too large", (value) => {
        if (!value.length) return true // attachment is optional
        return value[0].size <= 20000
    }).required('image is required'),
}

export const validateFile = {
    image: yup.mixed().test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true // attachment is optional
        return value[0].size <= 2000000
    }).required('file is required')
}

export const registerAttendee = {
    name: yup.string().required(),
    // email: yup.string().required().email(),  return .email afterwards
    email: yup.string().required(),
    phone: yup.string().required().matches(RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,7}$"), "phone is invalid"),
    event_prices_id: yup.string().required()
}
export const validateRegisterAttendee = yup.object().shape(registerAttendee);

export const eventTokenFormValidation = {
    token: yup.string("Token must be a string").required("Token is required")
}
export const validateEventTokenFormValidation = yup.object().shape(eventTokenFormValidation);


export const validateEventForm1 = {
    title: yup.string().required('Please enter event name'),
    description: yup.string().required(),
    location: yup.string().required(),
    start_date: yup.string().required(),
    end_date: yup.string(),
}
export const validateEventForm1Schema = yup.object().shape(validateEventForm1);

export const priceFormSchema = yup.object().shape({
    title: yup.string().trim().required('Ticket Name is required'),
    amount: yup.number().min(0, 'Price must be at least 0').required('Price is required'),
    attendees: yup.number().min(1, 'Total in Stock must be at least 1').required('Total in Stock is required'),
    withChips: yup.string().required(),
});

// export const pricesSchema = yup.array().of(priceFormSchema).min(1, 'Please create at least one ticket').required();

export const validateEventForm2 = yup.array().of(priceFormSchema).min(1, 'Please create at least 1 (ONE) ticket').required();

export const validatePriceForm = {
    title: yup.string().trim().required('Ticket title is required'),
    amount: yup.string().trim().test('is-number', 'Ticket amount must be a valid number', (value) => {
        return !isNaN(value);
    }).test('not-negative', 'Ticket amount must be greater than or equal to 0', (value) => {
        return parseFloat(value) >= 0;
    }).required('Ticket amount is required'),
    attendees: yup.string().trim().test('is-number', 'Ticket in stock must be a valid number', (value) => {
        return !isNaN(value);
    }).test('not-negative', 'Ticket in stock must be at least 1', (value) => {
        return parseFloat(value) > 0;
    }).required('Enter total tickets in stock'),
    withChips: yup.string().required(),
}
export const validatePriceFormSchema = yup.object().shape(validatePriceForm);
