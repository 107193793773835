import React, { Component } from 'react'
import { Icons } from './FontAwesomeIcons'

class Modal extends Component {
     constructor(props) {
          super(props);
          this.state = { ...this.props.params }
          this.handleInputChange = this.handleInputChange.bind(this);
          this.handleTypeChange = this.handleTypeChange.bind(this);
          this.saveFieldData = this.saveFieldData.bind(this);
          this.closeModal = React.createRef();
     }

     handleInputChange(e) {
          e.preventDefault();
          this.setState({
               [e.target.name]: e.target.value,
               errors: false
          });
     }

     handleTypeChange(e) {
          e.preventDefault();
          const has_icon = Object.keys(constant_labels).includes(e.target.value)
               ? constant_labels[e.target.value]['icon'] : '';
          const has_label = Object.keys(constant_labels).includes(e.target.value)
               ? constant_labels[e.target.value]['label'] : '';
          this.setState({
               [e.target.name]: e.target.value,
               icon: has_icon,
               label: has_label
          });
     }

     static getDerivedStateFromProps(props, state) {
          if (!props || !props.params) return null;

          if (props.params.position !== state.position) {
               const hidden_type = props.params.type !== '';
               return { ...props.params, hidden_type, errors: false };
          }

          return null;
     }

     saveFieldData(e) {
          e.preventDefault();
          const type_urls = Object.keys(constant_labels).slice(5);
          const getType = (type_urls.includes(this.state.type)) ? 'url' : this.state.type;
          const validate = Object.keys(this.state).filter((item) => {
               if (item !== 'position' && this.state[item] === '') return true;
               return false;
          })
          if (validate.length < 1) {
               this.props.receiveFields({ ...this.state, type: getType });
               this.closeModal.current.click();
          }
          else {
               this.setState({ errors: true })
          }
     }

     hiddenField() {
          const arr = Object.keys(constant_labels);
          return arr.includes(this.state.type) || this.state.type === '';
     }

     renderIcons() {
          const icons = Icons();
          return icons.map((icon, index) => {
               const val = icon.split('fa-')[1];
               return (<option key={`connectModalIcon${index}`} value={icon}>{titleCase(val.replace('-', ' '))}</option>);
          });
     }

     render() {
          return (
               <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                         <div className="modal-content">
                              <div className="modal-body">
                                   <div className="alert alert-danger" role="alert" hidden={!this.state.errors}>
                                        Please fill in all information
                                   </div>
                                   <div className="mb-3" hidden={this.state.hidden_type}>
                                        <div className="form-floating">
                                             <select className="form-select" name="type" id="floatingSelectGrid" value={this.state.type || ''} onChange={this.handleTypeChange}>
                                                  <option value="" disabled>Select type</option>
                                                  <option value="address">Address</option>
                                                  <option value="email">Email</option>
                                                  <option value="facebook">Facebook</option>
                                                  <option value="instagram">Instagram</option>
                                                  <option value="url">Website</option>
                                                  <option value="tel">Phone</option>
                                                  {/* <option value="pay">Pay Me</option> */}
                                                  {/* <option value="paypal">PayPal</option> */}
                                                  <option value="snapchat">Snapchat</option>
                                                  <option value="tiktok">TikTok</option>
                                                  <option value="twitter">Twitter</option>
                                                  <option value="wa">Whatsapp</option>
                                                  <option value="text">Others</option>
                                             </select>
                                             <label htmlFor="floatingSelectGrid">Select type</label>
                                        </div>
                                   </div>
                                   <div className="mb-3 align-items-center">
                                        <div className="form-floating" hidden={this.hiddenField()}>
                                             <select className="form-select" name="icon" id="floatingSelectGrid" value={this.state.icon || ''} onChange={this.handleInputChange}>
                                                  <option value="">Select icon</option>
                                                  {this.renderIcons()}
                                             </select>
                                             <label htmlFor="floatingSelectGrid">Select an icon</label>
                                        </div>
                                   </div>
                                   {/* <div className="mb-3" hidden={this.hiddenField()}>
                                        <label htmlFor="conectModalDetailsLabel" className="form-label">Content</label>
                                        <input type="text" className="form-control" id="conectModalDetailsLabel" name="label" value={this.state.label || ''} onChange={this.handleInputChange} />
                                   </div> */}
                                   <div className="input-group mb-3" hidden={this.hiddenField()}>
                                        <span className="input-group-text bg-black text-white" id="basic-addon1">Label</span>
                                        <input type="text" className="form-control" name="label" value={this.state.label || ''} onChange={this.handleInputChange} />
                                   </div>
                                   <div className="input-group mb-3" hidden={this.state.type === ''}>
                                        <span className="input-group-text bg-black text-white" id="basic-addon1"><i className={(this.state.icon || 'fa-solid fa-icons')}></i></span>
                                        <input type="text" className="form-control" name="content" value={this.state.content || ''} onChange={this.handleInputChange}
                                             placeholder={
                                                  this.state.type === 'address'
                                                       ? '123, example street, Nigeria' :
                                                       this.state.type === 'email'
                                                            ? 'example@email.com' :
                                                            this.state.type === 'facebook'
                                                                 ? 'https://facebook.com/username' :
                                                                 this.state.type === 'instagram'
                                                                      ? 'https://instagram.com/username' :
                                                                      this.state.type === 'url'
                                                                           ? 'www.yourwebsite.com' :
                                                                           this.state.type === 'tel'
                                                                                ? '+2348123456790' :
                                                                                this.state.type === 'snapchat'
                                                                                     ? 'https://www.snapchat.com/add/username' :
                                                                                     this.state.type === 'tiktok'
                                                                                          ? 'https://www.tiktok.com/@username' :
                                                                                          this.state.type === 'twitter'
                                                                                               ? 'https://www.twitter.com/username' :
                                                                                               this.state.type === 'wa'
                                                                                                    ? '+2348123456790' :
                                                                                                    'Enter content'
                                             }
                                        />
                                   </div>
                                   {/* <div className="mb-3" hidden={this.state.type === ''}>
                                        <label htmlFor="conectModalDetailsContent" className="form-label">Content</label>
                                        <input type="text" className="form-control" id="conectModalDetailsContent" name="content" value={this.state.content || ''} onChange={this.handleInputChange} />
                                   </div> */}
                              </div>
                              <div className="modal-footer">
                                   <button type="button" className="btn btn-danger closeModal" data-bs-dismiss="modal" ref={this.closeModal}>Close</button>
                                   <button type="button" className="btn bg-black text-white" onClick={this.saveFieldData}>Save changes</button>
                              </div>
                         </div>
                    </div>
               </div>
          )
     }
}

const titleCase = (str) => {
     var splitStr = str.toLowerCase().split(' ');
     for (var i = 0; i < splitStr.length; i++) {
          // You do not need to check if i is larger than splitStr length, as your for does that for you
          // Assign it back to the array
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
     }
     // Directly return the joined string
     return splitStr.join(' ');
}

const constant_labels = {
     tel: { label: 'Phone', icon: 'fa-solid fa-phone' },
     wa: { label: 'Whatsapp', icon: 'fa-brands fa-whatsapp' },
     email: { label: 'Email', icon: 'fa-solid fa-envelope' },
     address: { label: 'Address', icon: 'fa-solid fa-location-dot' },
     url: { label: 'Website', icon: 'fa-solid fa-globe' },
     facebook: { label: 'Facebook', icon: 'fa-brands fa-facebook' },
     instagram: { label: 'Instagram', icon: 'fa-brands fa-instagram' },
     // pay: { label: 'Pay Me', icon: 'fa-solid fa-credit-card' },
     // paypal: { label: 'PayPal', icon: 'fa-brands fa-paypal' },
     snapchat: { label: 'SnapChat', icon: 'fa-brands fa-snapchat' },
     tiktok: { label: 'TikTok', icon: 'fa-brands fa-tiktok' },
     twitter: { label: 'Twitter', icon: 'fa-brands fa-twitter' }
}


Modal.defaultProps = {
     params: {
          label: '',
          content: '',
          icon: '',
          status: '',
          type: '',
          position: '',
          errors: false,
          hidden_type: false
     }
}

export default Modal