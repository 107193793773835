import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { DATA_STORAGE } from '../../../../api/Request';
import ICEIcon from '../../../../components/icons/ICEIcon';
import LoadingButton from '../../../../components/LoadingButton.component';
import useStorage from '../../../../hooks/useStorage';
import ROUTES from '../../../../Routes';
import Notification from '../Notification';
import DashboardCard from './DashboardCard'

export default function Dashboard() {
     const navigate = useNavigate();
     const auth = useStorage(DATA_STORAGE?.auth)
     const dashboard = useStorage(DATA_STORAGE?.dashboard);

     const gotoLogin = () => navigate(ROUTES.link?.login, { replace: true });
     const logout = () => {
          dashboard?.delete();
          auth?.delete();
          gotoLogin();
     }

     const ls = useStorage(DATA_STORAGE.dashboard, {});
     if (!ls.hasData()) return <LoadingButton />
     return (
          <div className='h-screen'>
               <div className='tabs_page mb-3 overflow-hidden mx-auto'>
                    <div className='d-flex justify-content-between p-2 mb-2'>
                         <div className='d-flex shadow-mini overflow-hidden' style={{ height: 59, borderRadius: '10px' }}>
                              <img src={ls.data.image} alt='' style={{ backgroundColor: 'black', height: 59, width: 59, borderRadius: '10px', objectFit: 'cover', objectPosition: 'center' }} />
                              <span className='mx-3'>
                                   <div className='ts-sm'>
                                        Welcome
                                   </div>
                                   <div className='fw-bold ts-md'>
                                        {ls.data.name || 'Hi, User'}
                                   </div>
                                   <div className="ts-sm" onClick={logout}>Logout</div>
                              </span>
                         </div>
                         <div className='d-flex'>
                              <div className='ms-2'>
                                   <Link to={ROUTES.link.settings} className="btn btn-light">
                                        <ICEIcon iconType='settings' fcolor='none' scolor='black' swidth='20px' size={20} />
                                   </Link>
                              </div>
                         </div>
                    </div>

                    <nav className='mb-3'>
                         <div className="nav_dasboard_tab text-center d-flex justify-content-between overflow-scroll position-relative" id="nav-tab" role="tablist">
                              <div className=''>
                                   <div className="dashboard_tab d-flex active" id="nav-socials-tab" data-bs-toggle="tab" data-bs-target="#nav-socials" role="tab" aria-controls="nav-socials" aria-selected="true">
                                        <ICEIcon iconType='connect' size={20} />
                                        <span className='ms-2 me-3'>Socials</span>
                                   </div>
                              </div>
                              <div className=''>
                                   <div className="dashboard_tab d-flex " id="nav-business-tab" data-bs-toggle="tab" data-bs-target="#nav-business" role="tab" aria-controls="nav-business" aria-selected="false">
                                        <ICEIcon iconType='business' size={20} />
                                        <span className='ms-2 me-3'>Business</span>
                                   </div>
                              </div>
                              <div className=''>
                                   <div className="dashboard_tab d-flex " id="nav-finance-tab" data-bs-toggle="tab" data-bs-target="#nav-finance" role="tab" aria-controls="nav-finance" aria-selected="false">
                                        <ICEIcon iconType='finance' size={20} />
                                        <span className='ms-2 me-3'>Finance</span>
                                   </div>
                              </div>
                              <div className=''>
                                   <div className="dashboard_tab d-flex " id="nav-others-tab" data-bs-toggle="tab" data-bs-target="#nav-others" role="tab" aria-controls="nav-others" aria-selected="false">
                                        <ICEIcon iconType='more' size={20} />
                                        <span className='ms-2 me-3'>Others</span>
                                   </div>
                              </div>
                         </div>
                    </nav>

                    <div className="tab-content px-2" id="nav-tabContent">
                         <div className="tab-pane fade show active" id="nav-socials" role="tabpanel" aria-labelledby="nav-socials-tab" tabIndex="0">
                              <div className='row row-cols-2'>
                                   <DashboardCard
                                        href={ROUTES.link.user_connect}
                                        ic='#FFEE56'
                                        bc1='#1B2233'
                                        bc2='#192546'
                                   />
                                   <DashboardCard
                                        href={ROUTES.link.event}
                                        name='Events'
                                        iconType='event'
                                        ic='#3BFF7E'
                                        bc1='#1B2233'
                                        bc2='#192546'
                                   />
                                   <DashboardCard
                                        name='Payment'
                                        iconType='payment'
                                        ic='#999999'
                                        bc1='#f0f0f0'
                                        bc2='#f0f0f0'
                                        fc='#999999'
                                        href='#'
                                   />
                                   <DashboardCard
                                        name='Store'
                                        iconType='cart'
                                        ic='#999999'
                                        bc1='#f0f0f0'
                                        bc2='#f0f0f0'
                                        fc='#999999'
                                        href='#'
                                   />
                              </div>
                         </div>
                         <div className="tab-pane pt-4 fade" id="nav-business" role="tabpanel" aria-labelledby="nav-business-tab" tabIndex="0">
                              <div className='row row-cols-2 p-3'>
                                   <DashboardCard
                                        name='Store'
                                        iconType='cart'
                                        ic='#999999'
                                        bc1='#f0f0f0'
                                        bc2='#f0f0f0'
                                        fc='#999999'
                                        href='#'
                                   />
                              </div>
                         </div>
                         <div className="tab-pane pt-4 fade" id="nav-finance" role="tabpanel" aria-labelledby="nav-finance-tab" tabIndex="0">
                              <div className='row row-cols-2  p-3'>
                                   <DashboardCard
                                        name='Payment'
                                        iconType='payment'
                                        ic='#999999'
                                        bc1='#f0f0f0'
                                        bc2='#f0f0f0'
                                        fc='#999999'
                                        href='#'
                                   />
                              </div>
                         </div>
                         <div className="tab-pane pt-4 fade" id="nav-others" role="tabpanel" aria-labelledby="nav-others-tab" tabIndex="0">
                              <div className='position-relative w-100 p-3' style={{ height: 300 }}>
                                   <div className="position-absolute top-50 start-50 translate-middle" style={{ height: 300 }}>
                                        <img src='/assets/images/cartoon/cartoon_coming_soon.svg' alt='' style={{ height: 300 }} />
                                   </div>
                              </div>
                              <div className="pt-3 text-uppercase text-center ts-md">
                                   Watch this space!!!
                              </div>
                         </div>
                    </div>

                    <Notification />
               </div>
          </div>
     )
}
