import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate, Link } from 'react-router-dom';
import axios from '../../../hooks/axios';
//import Googlebtn from '../../../images/glogo.webp'
import { API, DATA_STORAGE } from '../../../api/Request';
import useStorage from '../../../hooks/useStorage';
import LoadingButton from '../../../components/LoadingButton.component';
import Error404 from '../../../components/Error404.component';
import LoadingIndicator from '../../../components/LoadingIndicator.component';
import { toast } from 'react-toastify'
import ROUTES from '../../../Routes';
import EnterToken from '../enter-token/EnterToken';
import { getCardQueryParams } from '../../../helpers/queryParams';
import ICEIcon from '../../../components/icons/ICEIcon';
import { SuperSEO } from 'react-super-seo';

const LoginUser = async (credentials) => {
     return axios.post(API.login, credentials);
}

/* const googleLoginUrl = async (link) => {
     const set_link = (link) ? API.googleUrl + link : API.googleUrl;
     //console.log(set_link)
     return axios.get(set_link);
} */

const confirmCardId = async (data) => {
     return axios.post(API.confirmCardId, data)
}

const Login = (props) => {
     const [email, setEmail] = useState("");
     const [password, setPassword] = useState("");
     const [showPassword, setShowPassword] = useState(false);
     const [passwordType, setPasswordType] = useState("password");
     const [loading, setLoading] = useState(false);
     //const [googleUrl, setGoogleUrl] = useState(null);
     const [cardData, setCardData] = useState(null);
     const [invalidDevice, setInvalidDevice] = useState(false)
     const cardQuery = getCardQueryParams();
     const [canAddDevice, setCanAddDevice] = useState({ state: false, token: '' });

     const navigate = useNavigate();
     const auth = useStorage(DATA_STORAGE.auth);

     //Get Google Authentication URL
     /* useEffect(() => {
          const fetchgoogleUrl = async () => {
               const { data: googleauth } = await googleLoginUrl(cardQuery.link);
               //console.log(googleauth)
               if (googleauth.success === 'true') {
                    setGoogleUrl(googleauth.data.url)
               }
          }
          fetchgoogleUrl();
     }, [ cardQuery ]); */

     //Confirm Card Id
     useEffect(() => {
          const cardQuery = getCardQueryParams();
          const fetchCardData = async ({ card_id }) => {
               const { data: card } = await confirmCardId({ card_id });
               if (card.success === 'true') {
                    setCardData(card.data.info)
               } else {
                    if (card_id) setInvalidDevice(true);
               }
          }
          setInvalidDevice(false);
          fetchCardData({
               card_id: cardQuery.id
          });
     }, []);

     const validateForm = () => {
          return (email.length > 0) && (password.length > 0);
     }

     const handleSubmit = async (e) => {
          e.preventDefault();
          if (!validateForm()) return;
          toast.dismiss();
          setLoading(true)

          let token_link = cardQuery.link;

          const loggingIn = toast.loading("Logging in...");
          const { data: login } = await LoginUser({ email, password });
          setLoading(false);
          setTimeout(() => toast.dismiss(), 2000);
          if (login.success === 'true') {
               const auth_response = login.data;
               const auth_token = `${auth_response.token_type} ${auth_response.access_token}`;
               if (token_link) {
                    toast.update(loggingIn, { render: "Enter device token", type: "success", isLoading: false, autoClose: 3000 });
                    setCanAddDevice({ state: true, token: auth_token });
               }
               else {
                    toast.update(loggingIn, { render: "Login Success", type: "success", isLoading: false, autoClose: 3000 });
                    auth.setData(auth_token);
                    navigate('/' + ROUTES.user_connect.path, { replace: true });
               }
          } else {
               toast.update(loggingIn, { render: "Unable to Login", type: "error", isLoading: false, autoClose: 3000 });
          }
     }

     const togglePassword = (e) => {
          const passwordState = showPassword ? [false, 'password'] : [true, 'text'];
          setShowPassword(passwordState[0]);
          setPasswordType(passwordState[1]);
     }

     if (auth.hasData()) {
          return (<Navigate to={'/' + ROUTES.user_connect.path} replace={true} />);
     }

     if (invalidDevice) return <Error404 />;
     if (cardQuery.id && !cardData) return <LoadingIndicator />;
     if (canAddDevice.state === true) {
          return <EnterToken
               query={cardQuery}
               authToken={canAddDevice.token}
          />
     }

     const logoStyle = {
          marginLeft: cardQuery?.id ? '-40px' : '0px'
     }

     return (
          <div className=''>
               <SuperSEO
                    title={"ISCE - Login"}
               ></SuperSEO>
               <div className=''>
                    <div className='text-center ' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image position-relative'>
                                   <img src="assets/images/background/security.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'bottom'
                                   }} />
                              </div>
                              <div className='col-md login-screen-mobile h-screen'>
                                   <div className='mb-5'>
                                        {
                                             cardQuery?.id && (<Link to={ROUTES?.link?.onboarding + cardQuery?.link} className={'btn btn-light float-start'}>
                                                  <ICEIcon iconType='back' size={20} /> <span className="ps-2">BACK</span>
                                             </Link>)
                                        }
                                   </div>
                                   <div className="d-flex justify-content-between flex-column py-5" style={{ height: 630 }}>
                                        <div className="login-form w-100">

                                             <div className="ps-5 mb-5">
                                                  <Link to={'/'} className={'text-decoration-none'} style={logoStyle}>
                                                       <img src='/assets/images/logo_full_black.svg' height={30} alt='' />
                                                  </Link>
                                             </div>

                                             <div className='mb-4'>
                                                  <div className='fs-3'>WELCOME,</div>
                                                  <div className="ts-md">Glad to have you back.</div>
                                             </div>
                                             {/* {
                                        googleUrl &&
                                        (
                                             <div className='py-4 d-grid'>
                                                  <a href={googleUrl} className='btn btn-outline-dark d-flex flex-row justify-content-center align-items-center'>Continue with <span className="ms-1"><img src={Googlebtn} height='18' alt='' /></span></a>
                                             </div>
                                        )} */}
                                             {/* <hr className='my-3 mx-auto w-50' /> */}

                                             <form className='text-start' onSubmit={handleSubmit}>
                                                  <label>Email Address</label>
                                                  <div className="d-grid mb-2">
                                                       <input className='form-control border-dark py-2 my-1' type={'text'} placeholder='example@email.com' onChange={(e) => setEmail(e.target.value)} />
                                                  </div>
                                                  <label>Password</label>
                                                  <div className="input-group mb-2">
                                                       <input className='form-control border-dark py-1' type={passwordType} placeholder='Enter Password' onChange={(e) => setPassword(e.target.value)} />
                                                       <span className="input-group-text border-dark" onClick={togglePassword}><i className={'far ' + ((showPassword) ? 'fa-eye' : 'fa-eye-slash')} id="togglePassword"></i></span>
                                                  </div>
                                                  <div className='text-end'>
                                                       <Link to={'/forgot-password'} className={'text-decoration-none'}>
                                                            <span className='ts-md'>Forgot Password? Click here.</span>
                                                       </Link>
                                                  </div>

                                                  <div className='pt-4 pb-2 d-grid'>
                                                       {
                                                            (loading) ?
                                                                 <LoadingButton /> :
                                                                 <button type="submit" className='btn btn-dark'>
                                                                      {(cardQuery.id) ? 'Link my device' : 'Log in'}
                                                                 </button>
                                                       }
                                                  </div>

                                             </form>
                                        </div>
                                        <div className='ts-md'>
                                             <Link to={'/'} className={'text-uppercase btn btn-light'}>
                                                  Take Me Home
                                             </Link>
                                        </div>
                                   </div>


                              </div>
                         </div>
                    </div>
               </div>
          </div >
     )
}

Login.defaultProps = {
     userLoggedin: null
}

export default Login

const Styles = {
     height: '100vh'
}