import React from 'react'
import EventCardVC from './EventCard'
import { Link } from 'react-router-dom'
import ROUTES from '../../../Routes'
import ViewEventVC from './ViewEvent'
import LoadingIndicator from '../../../components/LoadingIndicator.component'
import RequestCard from './RequestCard'
import EventToken from './EventToken'
import ViewAttendeesVC from './EventAttendees'
import { httpNoInterceptor } from '../../../http-common'
import { API, REQUEST2 } from '../../../api/Request'
import { getCardQueryParams } from '../../../helpers/queryParams'
import ICEIcon from '../../../components/icons/ICEIcon'
import { checkDate, displayDate, truncate } from '../../../helpers/functions.helpers'
import PurchaseCards from '../../home/PurchaseCards'
import ContactUs from '../component/ContactUs'
import { SuperSEO } from 'react-super-seo'

const EventVC = () => {
	const [data, setData] = React.useState(null);
	const [latestEvent, setLatestEvent] = React.useState(null);
	const [counter, setCounter] = React.useState(0);
	const [id, goto] = window.location.pathname.split('/').filter((path) => {
		return !['', 'card', 'event'].includes(path)
	});
	const query = getCardQueryParams();

	React.useEffect(() => {
		const fetchUserEvents = async (id) => {
			const query = getCardQueryParams();
			const link = API.events.card_get_events + query?.link;
			const { data: response } = await httpNoInterceptor({
				baseURL: REQUEST2.url
			}).get(link);
			if (response?.success === 'true') {
				setData(response?.data);
				setLatestEvent(response?.data?.upcoming[0]);
			}
		}
		fetchUserEvents();
	}, []);

	const forceUpdate = () => {
		setCounter(counter + 1);
	}

	const rerender = () => {
		forceUpdate();
	}


	if (id) {
		const filteredEvents = data?.all?.filter((event) => {
			return id.toString() === event.id.toString()
		});

		if (!filteredEvents || filteredEvents?.length < 1) {
			return <LoadingIndicator />
		}
		const current_event = filteredEvents[0];

		if (checkDate(current_event?.start_date) === 1) { //If event is past
			return <PurchaseCards
				title={'ISCE Digital Lifestyle'}
				description={"Let's get you started"}
			/>
		}

		if (goto === 'register') {
			return <RequestCard
				event={current_event || null}
			/>
		}

		if (goto === 'token') {
			return <EventToken
				event={current_event || null}
			/>
		}

		if (goto === 'attendees') {
			return <ViewAttendeesVC
				event={current_event || null}
			/>
		}

		return <ViewEventVC
			event={current_event || null}
		/>
	}

	return (
		<>
			<SuperSEO
				title={'ISCE || Events'}
				description="Events powered by ISCE"
				lang="en"
			>
			</SuperSEO>
			<div className="container mx-auto p-0">
				<div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
					maxWidth: '1080px'
				}}>
					<div className="col d-md-block d-none ps-0 pe-2 py-3" style={{
						maxWidth: '300px'
					}}>
						<a href={ROUTES.link.vcard_connect + query?.params_link} className='side_bar_link d-flex'>
							<ICEIcon iconType='connect' /> <span className="py-1">Connect</span>
						</a>
						<a href={ROUTES.link.vcard_event + query?.params_link} className='side_bar_link d-flex'>
							<ICEIcon iconType='event' />  <span className="py-1">Events</span>
						</a>
					</div>
					<div className="col overflow-scroll h-100 border-0 border-start border-end">
						<div className='pt-2'>
							<div className="d-flex justify-content-between">
								<div>
									<span className='d-md-none'>
										<Link to={ROUTES.link.vcard_dashboard + query?.params_link} className='text-decoration-none'>
											<span className='ts-sm'>{'<'}</span><ICEIcon iconType='dashboard' size={20} /> <span className='ts-sm'>Dashboard</span>
										</Link>
									</span>
								</div>
								<div>
								</div>
							</div>

							<div className="mb-3 scrollbar-hidden mx-auto">
								<div className='event-image position-relative text-decoration-none'>
									{
										!data && !latestEvent &&
										<div className="mt-3">
											<div className='latest_card_pulse rounded-3 mb-3 pulse'></div>
											<div className='event_card_pulse rounded-3 mb-1 pulse'></div>
											<div className='event_card_pulse rounded-3 mb-1 pulse'></div>
											<div className='event_card_pulse rounded-3 mb-1 pulse'></div>
										</div>

									}

									{data && latestEvent &&
										<div>
											<div className='fs-5'>
												Latest Event
											</div>
											<div className='latest_card rounded-3 position-relative mb-3 overflow-hidden'>
												<Link to={ROUTES.link.vcard_event_view.replace(':id', latestEvent?.id) + query?.params_link}>
													<img src={latestEvent?.image} alt='...' className='' style={{ objectFit: 'cover', objectPosition: 'top', height: '300px', width: '100%' }} />
												</Link>

												<div className='position-absolute bottom-0 latest-card-details w-100 bg-black text-white'>
													<div className='row g-2 p-2'>
														<div className="col-8 d-grid text-start">
															<Link className='fw-bold text-white text-decoration-none' to={ROUTES.link.vcard_event_view.replace(':id', latestEvent?.id) + query?.params_link}>
																<div className='fw-bold ts-sm text-uppercase'>{latestEvent?.title}</div>
															</Link>
															<div className='ts-sm'>{truncate(latestEvent?.description, 50)}</div>
															<div className='fw-bold ts-sm text-uppercase'>{displayDate(latestEvent?.start_date)}</div>
														</div>
														<div className="col-4 text-center">
															<Link className='text-decoration-none text-white' to={ROUTES.link.vcard_request_card.replace(':id', latestEvent?.id) + query?.params_link}>
																<div className='rounded fw-bold ts-sm agrad p-2'>Register</div>
															</Link>
														</div>
													</div>
												</div>
											</div>
											<nav>
												<div className="text-center row border-bottom border-dark" id="nav-tab" role="tablist">
													<div className='col-6 events_tab px-2'>
														<div className="text-black text-uppercase mx-2 py-2 rounded-top active" id="nav-upcoming-tab" data-bs-toggle="tab" data-bs-target="#nav-upcoming" role="tab" aria-controls="nav-upcoming" aria-selected="true">
															Upcoming
														</div>
													</div>
													<div className='col-6 events_tab'>
														<div className="text-black text-uppercase mx-2 py-2 rounded-top" id="nav-past-tab" data-bs-toggle="tab" data-bs-target="#nav-past" role="tab" aria-controls="nav-past" aria-selected="false">
															Past
														</div>
													</div>
												</div>
											</nav>
										</div>
									}
									{data && data?.upcoming.length === 0 &&
										<div className='mt-3'>
											<nav>
												<div className="text-center row border-bottom border-dark" id="nav-tab" role="tablist">
													<div className='col-6 events_tab px-2'>
														<div className="text-black text-uppercase mx-2 py-2 rounded-top active" id="nav-upcoming-tab" data-bs-toggle="tab" data-bs-target="#nav-upcoming" role="tab" aria-controls="nav-upcoming" aria-selected="true">
															Upcoming
														</div>
													</div>
													<div className='col-6 events_tab'>
														<div className="text-black text-uppercase mx-2 py-2 rounded-top" id="nav-past-tab" data-bs-toggle="tab" data-bs-target="#nav-past" role="tab" aria-controls="nav-past" aria-selected="false">
															Past
														</div>
													</div>
												</div>
											</nav>
										</div>
									}
									<div className="tab-content" id="nav-tabContent">
										<div className="tab-pane slit-in-vertical show active pt-2" id="nav-upcoming" role="tabpanel" aria-labelledby="nav-upcoming-tab" tabIndex="0">
											{
												data?.upcoming.length === 0 ?
													<div className='rounded-3 mb-3 d-flex justify-content-center align-items-center'>
														<div className='text-center'>
															<img
																src='/assets/images/flaticons/flaticons_event_cancelled.svg'
																alt='No Events'
																height={100}
															/>
															<div className="text-center fs-3">No Upcoming Events.</div>
															<div className="text-center">Your upcoming events will appear here</div>
														</div>
													</div> :
													data?.upcoming?.map((event) => {
														return <EventCardVC
															key={event.id}
															event={event}
															rerender={rerender}
														/>
													})
											}
										</div>
										<div className="tab-pane slit-in-vertical pt-4" id="nav-past" role="tabpanel" aria-labelledby="nav-past-tab" tabIndex="0">
											{
												data?.past.length === 0 ?
													<div className='rounded-3 mb-3 d-flex justify-content-center align-items-center'>
														<div className='text-center'>
															<img
																src='/assets/images/flaticons/flaticons_event_cancelled.svg'
																alt='No Events'
																height={100}
															/>
															<div className="text-center fs-3">No Past Events.</div>
															<div className="text-center">Your past events will appear here</div>
														</div>
													</div> :
													data?.past?.map((event) => {
														return <EventCardVC
															key={event.id}
															event={event}
															rerender={rerender}
															past
														/>
													})
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<ContactUs />
				</div>
			</div>
		</>
	)
}

export default EventVC