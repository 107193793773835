import React, { Fragment, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo'
import { toast } from 'react-toastify';
import ICEIcon from '../../components/icons/ICEIcon';
import { CONTRIES_ALL, PICKUP_LOCATIONS, STATES_NIGERIA } from '../../helpers/data';
import { formatCurrencys } from '../../helpers/functions.helpers';
import axios from '../../hooks/axios';
import ROUTES from '../../Routes';
import Footer from './Footer';
import ReactGA from "react-ga4";
import RadioOptions from '../../components/ui/RadioOptions';

export default function CheckOutCard() {
     const [formErrors, setFormErrors] = useState({});
     const options = [
          { label: 'Pick Up', value: 'pickup', icon: <i className="fa-solid fa-xl fa-person-walking"></i> },
          { label: 'Delivery', value: 'delivery', icon: <i className="fa-solid fa-xl fa-truck"></i> },
     ];

     function searchAddress(searchTerm) {
          const result = PICKUP_LOCATIONS.find(obj => obj.Address === searchTerm);
          return result || null; // Return the found object or null if not found
     }
     const getCartItemsFromLocalStorage = () => {
          const cartItems = localStorage.getItem('cartItems');
          if (cartItems) {
               return JSON.parse(cartItems);
          } else {
               return [];
          }
     };
     const getSubTotalFromLocalStorage = () => {
          const subTotal = localStorage.getItem('subTotal');
          if (subTotal) {
               return JSON.parse(subTotal);
          } else {
               return 0;
          }
     };
     const getVatPriceFromLocalStorage = () => {
          const vat = localStorage.getItem('vatPrice');
          if (vat) {
               return JSON.parse(vat);
          } else {
               return 0;
          }
     };
     const cartItems = getCartItemsFromLocalStorage()
     const subTotal = getSubTotalFromLocalStorage()
     const [deliveryMethod, setDeliveryMethod] = useState('pickup')
     const [shipping, setShipping] = useState(0)
     const vat = getVatPriceFromLocalStorage()
     const hostUrl = window.location.protocol + "//" + window.location.host;
     const callbackUrl = hostUrl + "/payment/status";

     const handleDeliveryMethod = (event) => {
          const { name, value } = event.target;
          setDeliveryMethod(value);
          setFormData(prevState => ({
               ...prevState,
               [name]: value,
               shipping: value === 'pickup' ? 0 : 2500,
               pickup: value === 'pickup' ? '' : prevState.pickup
          }));
          // console.log(formData);
     };


     const handleShipping = (e) => {
          e.preventDefault();
          const val = e.target.value;
          const intVal = parseInt(val)
          setShipping(intVal)
          setFormData({ ...formData, shipping: intVal })

          localStorage.setItem('shipping', JSON.stringify(intVal))
     }

     const [formData, setFormData] = useState({
          name: '',
          phoneNumber: '',
          email: '',
          address: '',
          city: '',
          state: 'Lagos',
          country: 'Nigeria',
          cart: cartItems,
          total: subTotal + shipping,
          vat: vat,
          pickup: '',
          shipping: shipping,
          method: deliveryMethod,
          callback_url: callbackUrl
     });
     if (typeof cartItems !== 'undefined' && cartItems.length === 0) {
          return (<Navigate to={'/' + ROUTES.getStarted.path} replace={true} />);
     }

     const handleInputChange = (event) => {
          const { name, value } = event.target;
          const fulladdress = name === 'pickup' && searchAddress(value);
          if (fulladdress) {
               const city = fulladdress.City;
               const state = fulladdress.State;
               const country = fulladdress.Country;
               setFormData(prevState => ({ ...prevState, address: value, pickup: value, city, state, country }));
          } else {
               setFormData(prevState => ({ ...prevState, [name]: value }));
          }
          // console.log(formData);
     };

     const handleSubmit = (event) => {
          event.preventDefault();
          // validate form data
          const url = "https://developer.isce.app/v1/connect/api/orders";
          const errors = validateForm(formData);
          if (Object.keys(errors).length === 0) {
               // submit form data
               axios.post(url, formData, {
                    headers: {
                         'Content-Type': 'application/json'
                    }
               })
                    .then(response => {
                         // Get the response content as a string
                         const responseText = response.data;
                         // const transactionRef = response.data.reference;
                         ReactGA.event({
                              category: 'Checkout',
                              action: 'Form Submitted',
                              label: 'Checkout Form',
                         });

                         // Print the response text
                         // console.log(responseText);
                         // console.log(transactionRef);


                         // Check if the response indicates success
                         if (responseText.success === 'true') {
                              const transactionRef = responseText.data.reference;
                              localStorage.setItem('transactionRef', JSON.stringify(transactionRef))
                              // Redirect to the URL in the response data object
                              window.location.href = responseText.data.url;
                         } else {
                              // Display an error message
                              console.error(responseText.message);
                         }
                    })
                    .catch(error => {
                         console.error(error);
                    });


               // console.log(formData);
               // toast.success('Form Submited', {
               //      position: "bottom-center",
               //      autoClose: 6000,
               //      hideProgressBar: false,
               //      closeOnClick: true,
               //      pauseOnHover: true,
               //      draggable: true,
               //      progress: undefined,
               //      theme: "dark",
               // })
          } else {
               // set form errors
               setFormErrors(errors);
               // console.log(errors);
               toast.error(`Fill all fields`, {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
               });
          }

     };

     const validateForm = (formData) => {
          const errors = {};
          if (!formData.name) {
               errors.name = 'Name is required';
          }
          if (!formData.phoneNumber) {
               errors.phoneNumber = 'Phone number is required';
          } else if (!isValidPhoneNumber(formData.phoneNumber)) {
               errors.phoneNumber = 'Invalid phone number';
          }
          if (!formData.email) {
               errors.email = 'Email is required';
          } else if (!isValidEmail(formData.email)) {
               errors.email = 'Invalid email address';
          }
          if (formData.method !== 'pickup') {
               if (!formData.address) {
                    errors.address = 'Address is required';
               }
               if (!formData.city) {
                    errors.city = 'City is required';
               }
               if (!formData.state) {
                    errors.state = 'State is required';
               }
               if (!formData.country) {
                    errors.country = 'State is required';
               }
          }
          if (formData.method === 'pickup') {
               if (!formData.pickup) {
                    errors.pickup = 'Choose pick up location';
               }
          }
          return errors;
     };

     const isValidPhoneNumber = (phone) => {
          const phoneRegex = /^(?:\+234|0)[\d]{10}$/; // matches Nigerian phone numbers
          return phoneRegex.test(phone);
     };

     const isValidEmail = (email) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // matches common email format
          return emailRegex.test(email);
     };





     return (
          <Fragment>
               <SuperSEO
                    title={'ISCE || Checkout'}
                    description="Complete your purchase"
               >
               </SuperSEO>
               <div className="bg-black h-screen overflow-scroll">
                    <div className="mx-auto text-white px-3 mb-5" style={{
                         maxWidth: '1080px'
                    }}>
                         <nav className="navbar bg-black px-2" style={{
                              borderBottom: 'solid .1px rgba(255,255,255,0.3)',
                         }}>
                              <Link to={'/'}>
                                   <div className="text-white">
                                        <img src='assets/images/logo/logofull-dark.webp' alt='ISCE' height={20} />
                                   </div>
                              </Link>
                              <Link to={'/get-started'} className=''>
                                   <ICEIcon iconType='cart' fcolor='white' scolor='none' size={25} />
                                   {/* <Badge pill bg="danger">
                                   {cartItems.length === 0 ? '' : cartItems.length}
                              </Badge> */}
                              </Link>
                         </nav>

                         <div className="container mx-auto p-0">

                              <div className="row overflow-hidden">
                                   <div className="col">
                                        <h3 className="mt-4 mb-2 fw-bold text-uppercase">Customer Information</h3>
                                        <Form onSubmit={handleSubmit}>
                                             <Form.Group className="mb-4">
                                                  <Form.Label>Name</Form.Label>
                                                  <Form.Control name={'name'} type="text" placeholder="Full Name" value={formData.name} onChange={handleInputChange} />
                                                  {formErrors.name && <Form.Text className='text-danger ts-sm'>{formErrors.name}</Form.Text>}
                                             </Form.Group>

                                             <Form.Group className="mb-4">
                                                  <Form.Label>Mobile Number</Form.Label>
                                                  <Form.Control name={'phoneNumber'} type="tel" placeholder="08012345678" value={formData.phoneNumber} onChange={handleInputChange} />
                                                  {formErrors.phoneNumber && <Form.Text className='text-danger ts-sm'>{formErrors.phoneNumber}</Form.Text>}
                                             </Form.Group>

                                             <Form.Group className="mb-4">
                                                  <Form.Label>Email address</Form.Label>
                                                  <Form.Control name={'email'} type="email" placeholder="Sample@email.com" value={formData.email} onChange={handleInputChange} />
                                                  {formErrors.email && <Form.Text className='text-danger ts-sm'>{formErrors.email}</Form.Text>}
                                             </Form.Group>

                                             <div className="mb-4">
                                                  <RadioOptions
                                                       label={'Delivery Method'}
                                                       options={options}
                                                       name='method'
                                                       value={deliveryMethod}
                                                       onChange={handleDeliveryMethod}
                                                  />
                                             </div>
                                             {deliveryMethod === 'delivery'
                                                  ?
                                                  (
                                                       <div className="">
                                                            <div className="flex-grow-1">
                                                                 <div className="mb-2 text-uppercase fw-bold">Shipping</div>
                                                                 <div className="mb-4 d-flex justify-content-between align-items-center">
                                                                      <div className="">
                                                                           <Form.Select
                                                                                name='shipping'
                                                                                aria-label="Shipping Fee"
                                                                                defaultValue={formData.shipping}
                                                                                onChange={handleShipping}
                                                                           >
                                                                                {/* <option>Choose One</option> */}
                                                                                <option value={'2500'}>Within Lagos</option>
                                                                                <option value={'5000'}>Outside Lagos</option>
                                                                           </Form.Select>
                                                                      </div>
                                                                      <div className="">
                                                                           {formData.shipping === 0 ? '-' : formatCurrencys(formData.shipping)}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                            <Form.Group className="mb-4">
                                                                 <Form.Label>Address</Form.Label>
                                                                 <Form.Control name={'address'} type="address" placeholder="123, street address" value={formData.address} onChange={handleInputChange} />
                                                                 {formErrors.address && <Form.Text className='text-danger ts-sm'>{formErrors.address}</Form.Text>}
                                                            </Form.Group>

                                                            <div className="row">
                                                                 <div className="col-lg-4">
                                                                      <Form.Group className="mb-4">
                                                                           <Form.Label>Country</Form.Label>
                                                                           {/* <Form.Control name={'country'} type="text" placeholder="Lagos" value={formData.country} onChange={handleInputChange} /> */}
                                                                           <Form.Select
                                                                                aria-label="country"
                                                                                name={'country'}
                                                                                defaultValue={formData.country}
                                                                                onChange={handleInputChange}
                                                                           >
                                                                                <option disabled>Choose Country</option>
                                                                                {CONTRIES_ALL.map((country) => (
                                                                                     <option key={country.iso} value={country.name}>
                                                                                          {country.name}
                                                                                     </option>
                                                                                ))}
                                                                           </Form.Select>
                                                                           {formErrors.country && <Form.Text className='text-danger ts-sm'>{formErrors.country}</Form.Text>}
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-lg-4">
                                                                      <Form.Group className="mb-4">
                                                                           <Form.Label>State</Form.Label>
                                                                           {/* <Form.Control name={'state'} type="text" placeholder="Lagos" value={formData.state} onChange={handleInputChange} /> */}
                                                                           <Form.Select
                                                                                aria-label="state"
                                                                                name={'state'}
                                                                                defaultValue={formData.state}
                                                                                onChange={handleInputChange}
                                                                           >
                                                                                <option disabled>Choose Country</option>
                                                                                {
                                                                                     formData.country === 'Nigeria' &&
                                                                                     STATES_NIGERIA.map((state) => (
                                                                                          <option key={state.slug} value={state.name}>
                                                                                               {state.name}
                                                                                          </option>
                                                                                     ))
                                                                                }
                                                                           </Form.Select>
                                                                           {formErrors.state && <Form.Text className='text-danger ts-sm'>{formErrors.state}</Form.Text>}
                                                                      </Form.Group>
                                                                 </div>
                                                                 <div className="col-lg-4">
                                                                      <Form.Group className="mb-4">
                                                                           <Form.Label>City</Form.Label>
                                                                           <Form.Control name={'city'} type="text" placeholder="Lekki" value={formData.city} onChange={handleInputChange} />
                                                                           {formErrors.city && <Form.Text className='text-danger ts-sm'>{formErrors.city}</Form.Text>}
                                                                      </Form.Group>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  ) :
                                                  (
                                                       <Form.Group className="mb-4">
                                                            <Form.Label>Pickup Address</Form.Label>
                                                            {/* <Form.Control name={'state'} type="text" placeholder="Lagos" value={formData.state} onChange={handleInputChange} /> */}
                                                            <Form.Select
                                                                 aria-label="pickup"
                                                                 name={'pickup'}
                                                                 defaultValue={formData.pickup}
                                                                 onChange={handleInputChange}
                                                            >
                                                                 <option>Choose Pickup Address</option>
                                                                 {
                                                                      PICKUP_LOCATIONS.map((location, idx) =>
                                                                      (
                                                                           // <option key={idx} value={`${location.Address}, ${location.Landmark}, ${location.City}, ${location.State}, ${location.Country}`}>
                                                                           <option key={idx} value={`${location.Address}`}>
                                                                                {location.Address}, {location.Landmark}, {location.City}, {location.State}, {location.Country}
                                                                           </option>
                                                                      ))
                                                                 }
                                                            </Form.Select>
                                                            {formErrors.pickup && <Form.Text className='text-danger ts-sm'>{formErrors.pickup}</Form.Text>}
                                                       </Form.Group>
                                                  )
                                             }

                                             <div className="d-grid">
                                                  <Button type='submit'>
                                                       Checkout
                                                  </Button>
                                             </div>

                                        </Form>
                                   </div>

                                   <div className="col-lg-5 d-none d-lg-block mt-3">
                                        <div className="rounded rounded-3 bg-dark px-3 pt-2 pb-4 ">
                                             <h3>Order Summary</h3>
                                             {
                                                  cartItems.length > 0 &&
                                                  <>
                                                       {cartItems.length > 0 && cartItems.map((item) => {
                                                            return (
                                                                 <div className="d-flex align-items-center mb-4 border-bottom" key={item.id}>
                                                                      <div className="p-2 rounded">
                                                                           <div className="ratio ratio-1x1" style={{ width: '50px' }}>
                                                                                <img src={item?.image} className='images' alt={item?.color} />
                                                                           </div>
                                                                      </div>
                                                                      <div className="flex-grow-1 mx-2 rounded p-2">
                                                                           <div className="fw-bold text-uppercase">
                                                                                {item?.color}
                                                                           </div>
                                                                           <div className="ts-md">
                                                                                {`${formatCurrencys(item.price, false, 'NGN')}/Card`}
                                                                           </div>
                                                                      </div>
                                                                      <div className="ts-md text-uppercase">
                                                                           {formatCurrencys((item.price * item.qty), true, 'NGN')}
                                                                      </div>
                                                                 </div>
                                                            )
                                                       })}


                                                       <div className="d-flex pb-2 mb-2 border-bottom">
                                                            <div className="flex-grow-1 text-uppercase fw-bold">
                                                                 Subtotal
                                                            </div>
                                                            <div className="">
                                                                 {formatCurrencys(subTotal)}
                                                            </div>
                                                       </div>


                                                       <div className="flex-grow-1">
                                                            <div className="mb-4 d-flex justify-content-between align-items-center">
                                                                 <div className="mb-2 text-uppercase fw-bold">Shipping</div>
                                                                 <div className="">
                                                                      {formData.shipping === 0 ? '-' : formatCurrencys(formData.shipping)}
                                                                 </div>
                                                            </div>
                                                       </div>

                                                       <div className="d-flex pb-2 mb-2 border-bottom">
                                                            <div className="flex-grow-1 text-uppercase fw-bold">
                                                                 VAT
                                                            </div>
                                                            <div className="">
                                                                 {formatCurrencys(vat)}
                                                            </div>
                                                       </div>

                                                       <div className="d-flex fw-bold mb-4 py-2 border-bottom">
                                                            <div className="flex-grow-1">
                                                                 Total(NGN)
                                                            </div>
                                                            <div className="">
                                                                 {formatCurrencys(subTotal + formData.shipping + vat)}
                                                            </div>
                                                       </div>
                                                  </>
                                             }
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <Footer />
                    </div>
               </div>
          </Fragment>
     )
}
