import React from 'react'
import { useParams } from 'react-router-dom';
import { API, REQUEST2 } from '../../../../api/Request';
import { isToday } from '../../../../helpers/functions.helpers';
import useAuth from '../../../../hooks/useAuth';
import { http, httpNoInterceptor } from '../../../../http-common';
import ROUTES from '../../../../Routes';
import PurchaseCards from '../../../home/PurchaseCards';
import Chat from '../../component/Chat';

const EventChipChat = () => {
    const [data, setData] = React.useState(null);
    const { chip_id } = useParams();
    const auth = useAuth();

    const fetchUserEvents = async (id) => {
        const link = API.events?.event_chip?.replace(':id', id);
        const { data: response } = await http({
            baseURL: REQUEST2.url,
            Authorization: true
        }).get(link);
        //console.log(response)
        if (response?.success === 'true') setData(response?.data);
    }

    const handleSubmittedChat = async (message) => {
        const saveChatMessage = async (data, message) => {
            const link = API.events?.createChat?.replace(':id', data?.event_attendee_id);
            await httpNoInterceptor({
                baseURL: REQUEST2.url
            }).post(link, {
                event_id: data?.event?.id,
                attendee_id: data?.event_attendee_id,
                message,
                image: data?.attendee?.image
            });
            fetchUserEvents(chip_id);
        }
        saveChatMessage(data, message)
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            fetchUserEvents(chip_id);
        }, 1000);
        return () => clearInterval(interval);
    }, [chip_id, auth]);

    if(!isToday(data?.event?.start_date)){
        return <PurchaseCards 
                  title={ 'ISCE Digital Lifestyle' } 
                  description={ "Let's get you started" }
                />
    }

    return <Chat
        user_id={data?.event_attendee_id}
        data={data}
        submitChat={handleSubmittedChat}
        back={ROUTES.link?.event_chip?.replace(':chip_id', chip_id)}
    />
}

export default EventChipChat;