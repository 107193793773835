import React from 'react'
import LogoDark from '../images/logo-dark.webp'

export default function LoadingButton(props) {
     return (
          <div className='d-grid'>
               {/* <button className={ 'btn bg-' + props.buttonColor + ' text-' + props.textColor + ' '}>
                    {props.text}
               </button> */}
               <span className='text-center p-0 position-relative'>
                    <div className={ 'spinner-border text-' + props.buttonColor } role="status">
                    </div>
                    <div className='spinner-icon-sm position-absolute overflow-hidden'>
                         <img className='img-fluid' src={LogoDark} alt='' />
                    </div>
               </span>
          </div>
     )
}
