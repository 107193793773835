import React from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../../../Routes'

const EventOpenAttendees = ({ event }) => {

     const attending = () => {
          return event?.attendees?.length;
     }

     const total_attendees = () => {
          return event?.prices.reduce((prev, current) => {
               return +prev + +current?.attendees;
          }, 0);
     }

     return (
          <section className=''>
               <div className='bg-white shadow-sm px-3 py-2'>
                    <Link className='text-decoration-none text-black p-0' to={ ROUTES.link.event_open.replace(':id', event?.clean_name) }><div className='fw-bold'><i className="fa-solid fa-arrow-left-long"></i><span className='ps-2 fs-5'>Events</span></div></Link>
               </div>
               <div className='px-3'>

                    <div className='row py-2'>
                         <div className='col-6 text-start'>
                              <div className='d-grid'>
                                   <div className='fw-bold'>
                                        { event?.title }
                                   </div>
                                   <div>
                                        { new Date(event?.start_date).toLocaleDateString() }
                                   </div>
                              </div>
                         </div>
                         <div className='col-6 text-end'>
                              <Link className='text-decoration-none text-black p-0' to={ ROUTES.link.event_open_register.replace(':id', event?.clean_name) }>
                                   <button className='rounded-pill btn btn-dark btn-sm'>Register</button>
                              </Link>
                         </div>
                    </div>

                    <div className='row py-2'>
                         <div className='col-6 text-start'>
                              <div className='fw-bold'>
                                   Attendees
                              </div>
                         </div>
                         <div className='col-6 text-end'>
                              <div className='fw-bold'>
                                   { attending() + '/' + total_attendees() }
                              </div>
                         </div>
                    </div>

                    <div className='all-attendees row justify-content-evenly g-2'>
                         {
                              event?.attendees?.map((attendee, index) => {
                                   return (
                                   <div key={ `attendee_view_${index}_${event?.id}` } className='col-4'>
                                        <div className='sphere border border-3 rounded-circle p-0'>
                                             <img src={attendee?.image || TestImg} className='img-fluid rounded-circle' alt='attendees' />
                                        </div>
                                        <div className='text-center small'>{ attendee?.name }</div>
                                   </div>
                                   );
                              })
                         }
                    </div>
               </div>
          </section>
     )
}

export default EventOpenAttendees
const TestImg = 'https://via.placeholder.com/200'