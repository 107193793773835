import { TYPE_DESKTOP, TYPE_MOBILE } from '../types';

export const snapchat = {
  name: 'snapchat',
  matches: [
    {
      match: '(https?://)?(www.)?snapchat.com/@?({PROFILE_ID})/?', group: 3, type: TYPE_DESKTOP,
      pattern: 'https://snapchat.com/{PROFILE_ID}'
    },
    {
      match: '(https?://)?mobile.snapchat.com/@?({PROFILE_ID})/?', group: 2, type: TYPE_MOBILE,
      pattern: 'https://mobile.snapchat.com/{PROFILE_ID}'
    },
    { match: '@?({PROFILE_ID})', group: 1 },
  ]
};