import React from 'react'
import ICEIcon from '../../../components/icons/ICEIcon';
import { getCardQueryParams } from '../../../helpers/queryParams';
import ROUTES from '../../../Routes';

export default function SideBarVC() {
     const query = getCardQueryParams();
     return (
          <>
               <div className="col d-md-block d-none ps-0 pe-2 py-3" style={{
                    maxWidth: '300px'
               }}>
                    <a href={ROUTES.link.vcard_connect + ((query?.params_link) ? query?.params_link : '')} className='side_bar_link d-flex'>
                         <ICEIcon iconType='connect' /> <span className="py-1">Connect</span>
                    </a>
                    <a href={ROUTES.link.vcard_event + ((query?.params_link) ? query?.params_link : '')} className='side_bar_link d-flex'>
                         <ICEIcon iconType='event' />  <span className="py-1">Events</span>
                    </a>
               </div>
          </>
     )
}
