import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { formatCurrencys } from '../../helpers/functions.helpers'

export default function CartItem({ item, incItemQty, decItemQty, updateItemQty, removeCartItem }) {

     return (
          <div className="d-flex align-items-center mb-4 border-bottom ">
               <div className="p-2 rounded">
                    <div className="ratio ratio-1x1" style={{ width: '50px' }}>
                         <img src={item?.image} className='images' alt={item?.color} />
                    </div>
               </div>
               <div className="flex-grow-1 mx-2 rounded p-2">
                    <div className="fw-bold text-uppercase">
                         {item?.color}
                    </div>
                    <div className="ts-md">
                         {`${formatCurrencys(item.price, false, 'NGN')}/Card`}
                    </div>
                    <div className="ts-md text-uppercase">
                         {formatCurrencys((item.price * item.qty), true, 'NGN')}
                    </div>
               </div>
               <div className="">
                    <InputGroup className="">
                         <Button onClick={decItemQty} className="btn btn-light" variant="light" id="button-addon1">
                              -
                         </Button>
                         <Form.Control
                              type='number'
                              id='qty'
                              name='qty'
                              readOnly
                              value={item?.qty}
                              min={0}
                              className={'text-center mx-1 px-0'}
                              style={{ width: '40px' }}
                         />
                         <Button onClick={incItemQty} className="btn btn-light" variant="light" id="button-addon1">
                              +
                         </Button>
                    </InputGroup>
               </div>
               {/* <Button onClick={removeCartItem} className="ms-2 btn btn-outline-light">
                    x
               </Button> */}
          </div>
     )
}
