import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ICEIcon from '../../../components/icons/ICEIcon';
import 'react-image-crop/dist/ReactCrop.css';
import { http } from '../../../http-common';
import { toast } from 'react-toastify';
import FileUploader from '../../../components/images/fileuploader';

export default function AddService(cardId) {
     const CardId = cardId.cardId
     const defaultImg = '/assets/images/placeholder/add_image.png'
     const [file, setFile] = useState(null)

     const imageUpload = file => {
          setFile(file);
     };

     const handleSubmit = async (e) => {
          e.preventDefault();
          const formData = new FormData();
          formData.append('service_image', file?.url || defaultImg);
          formData.append('service_name', document.getElementById('service-name').value);
          formData.append('service_description', document.getElementById('service-description').value);
          formData.append('phone', document.getElementById('service-phone').value);
          formData.append('link', document.getElementById('service-link').value);
          formData.append('enable_enquiry', true);

          // send formData to the server using fetch or axios
          // for example with fetch:
          try {
               const url = 'https://developer.isce.app/v1/connect/api/add-service/' + CardId;
               await http({
                    "Content-Type": "multipart/form-data",
                    Authorization: true
               }).post(url, formData);
               toast.success("Service Added successfully!");
               // console.log(response);
               window.location.reload()
          } catch (error) {
               toast.error("Error Adding Service. Please try again.");
               // console.log(error)
          }
     };

     const [show, setShow] = useState(false);

     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);

     return (
          <>
               <button className="btn btn-outline-success" onClick={handleShow}>
                    <ICEIcon iconType="add" size={20} />
                    <span className="ms-2 ts-sm">Add New</span>
               </button>
               <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
               >
                    <Modal.Body>
                         <form onSubmit={handleSubmit}>
                              <div className="">
                                   <div className='mb-3'>
                                        <div className="service_image_upload">
                                             <FileUploader
                                                  image={file?.url || defaultImg}
                                                  canUpload={true}
                                                  handleFile={imageUpload}
                                                  folder={'services'}
                                                  fileName={file?.name || null}
                                             />
                                        </div>
                                   </div>
                                   <div className="mb-3">
                                        <label htmlFor="service-name" className="form-label">Service Name</label>
                                        <input type="text" className="form-control" id="service-name" placeholder="Enter Name Of Service" name="service-name" required />
                                   </div>
                                   <div className="mb-3">
                                        <label htmlFor="service-description" className="form-label">Service Description</label>
                                        <textarea type="text" className="form-control" id="service-description" name="service-description" required />
                                   </div>
                                   <div className="mb-3">
                                        <label htmlFor="service-phone" className="form-label">Service Phone</label>
                                        <input type="text" className="form-control" id="service-phone" placeholder="Enter Service Phone" name="service-phone" required />
                                   </div>
                                   <div className="mb-3">
                                        <label htmlFor="service-link" className="form-label">Service Link</label>
                                        <input type="url" className="form-control" id="service-link" placeholder="Enter Service Link" name="service-link" required />
                                   </div>
                                   <div className='d-flex justify-content-between'>
                                        <Button variant="danger" onClick={handleClose}>
                                             Close
                                        </Button>
                                        <Button variant="dark" type="submit" >Save</Button>
                                   </div>
                              </div>
                         </form>
                    </Modal.Body>
               </Modal>
          </>
     );
}





// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import ICEIcon from '../../../components/icons/ICEIcon';

// export default function AddService(cardId) {
//      const [file, setFile] = useState();
//      function handleChange(e) {
//           console.log(e.target.files);
//           setFile(URL.createObjectURL(e.target.files[0]));
//      }

//      const handleSubmit = e => {
//           e.preventDefault();
//           console.log("form Submitted")
//      };

//      const [show, setShow] = useState(false);

//      const handleClose = () => setShow(false);
//      const handleShow = () => setShow(true);
//      const sample = "/assets/images/placeholder/add_image.png"

//      return (
//           <>
//                <button className="btn btn-outline-success" onClick={handleShow}>
//                     <ICEIcon iconType="add" size={20} />
//                     <span className="ms-2 ts-sm">Add New</span>
//                </button>
//                <Modal
//                     show={show}
//                     onHide={handleClose}
//                     backdrop="static"
//                     keyboard={false}
//                >
//                     <Modal.Body>
//                          <Modal.Header>
//                               Add New Service
//                          </Modal.Header>
//                          <form onSubmit={handleSubmit}>
//                               <div className="">
//                                    <div className='mb-3'>
//                                         <label htmlFor="service-image rounded">
//                                              <input hidden type="file" id="service-image" onChange={handleChange} name="service-image" required />
//                                              <img src={file} height={120} width={160} alt={''}
//                                                   style={{
//                                                        objectFit: 'cover',
//                                                        objectPosition: 'center',
//                                                   }}
//                                              />
//                                         </label>
//                                    </div>
//                                    <div className="mb-3">
//                                         <label htmlFor="service-name" className="form-label">Service Name</label>
//                                         <input type="text" className="form-control" id="service-name" placeholder="Enter Name Of Service" name="service-name" required />
//                                    </div>
//                                    <div className="mb-3">
//                                         <label htmlFor="service-description" className="form-label">Service Description</label>
//                                         <textarea type="text" className="form-control" id="service-description" name="service-description" required />
//                                    </div>
//                                    <div className='d-flex justify-content-between'>
//                                         <Button variant="danger" onClick={handleClose}>
//                                              Close
//                                         </Button>
//                                         <Button variant="dark" type="submit" >Save</Button>
//                                    </div>
//                               </div>
//                          </form>
//                     </Modal.Body>
//                </Modal>
//           </>
//      );
// }