import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SuperSEO } from 'react-super-seo'
import { API } from '../../api/Request'
import LoadingIndicator from '../../components/LoadingIndicator.component'
import { changeTabColor } from '../../helpers/functions.helpers'
import axios from '../../hooks/axios'
import useCardQuery from '../../hooks/useCardQuery'
import ROUTES from '../../Routes'
import About from './About'
import Connect from './Connect'
import Event from './Event'
import Footer from './Footer'
import Future from './Future'
import Host from './Host'
import Navbar from './Navbar'
import Network from './Network'
// import Security from './Security'
// import Tabs from './Tabs'

const loadRouteLink = async (data) => {
     return axios.post(API.loadRouteLink, data)
}

export default function Home() {
     changeTabColor('#000')

     const [loading, setLoading] = useState(false);
     const navigate = useNavigate();
     const query = useCardQuery();

     React.useEffect(() => {
          const gotoRoute = (route) => navigate(route, { replace: true })
          const fetchRoute = async ({ card_id, type }) => {
               const { data: routes } = await loadRouteLink({ card_id, type });
               // console.log(routes);
               setLoading(false);
               if (routes.success === 'true') {
                    gotoRoute(routes?.data?.redirect_url);
               } else {
                    if (query.link()) navigate('/' + ROUTES.error404?.path, { replace: true })
               }
          }
          if (query.link()) {
               setLoading(true)
               fetchRoute({
                    card_id: query.id,
                    type: query.type
               });
          }
     }, [query, navigate]);

     if (loading) return <LoadingIndicator />
     if (!loading && query.link()) return <LoadingIndicator />

     return (
          <section className='bg-black text-white homepage'>
               <SuperSEO
                    title="ISCE APP - Your Digital Culture"
                    description="Create Your Digital Culture Withe One Card - ISCE Card"
                    lang="en"
                    openGraph={{
                         ogImage: {
                              ogImage: 'https://isce-image.fra1.digitaloceanspaces.com/public/isce_cards.jpg',
                              ogImageAlt: 'isce nfc card',
                              ogImageWidth: 1080,
                              ogImageHeight: 720,
                              ogImageType: 'image/jpeg',
                         },
                    }}
                    twitter={{
                         twitterSummaryCard: {
                              summaryCardImage: 'https://isce-image.fra1.digitaloceanspaces.com/public/isce_cards.jpg',
                              summaryCardImageAlt: 'isce nfc card',
                              summaryCardSiteUsername: 'isceapp',
                         },
                    }}
               >
                    <meta name="keywords" content="isce, iscecards, isce card, digital business card, digitalbusinesscard, nfc card, nfccard," />
               </SuperSEO>
               <Navbar />
               <Future />
               <About />
               <Network />
               {/* <Security /> */}
               <Connect />
               <Event />
               {/* <Tabs /> */}
               <Host />
               <Footer />
          </section>
     )
}




