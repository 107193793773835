import React from 'react'
import { API } from '../../../api/Request';
import ICEIcon from '../../../components/icons/ICEIcon';
import { serverUrl } from '../../../helpers/functions.helpers';
import { getCardQueryParams } from '../../../helpers/queryParams';
import axios from '../../../hooks/axios';
import ROUTES from '../../../Routes';
import DashboardCard from '../../user/component/nav/DashboardCard';

const getCard = async (url) => {
     if (!url) return { data: null };
     const link = API.vcardView + url;
     return axios.get(link);
}

export default function DashboardVC() {
     const query = getCardQueryParams();
     const [state, setState] = React.useState(null);

     React.useEffect(() => {
          const fetchCardData = async () => {
               const { data } = await getCard(query.link);
               if (data?.success === 'true') {
                    const cardD = data.data;
                    setState({
                         image: serverUrl(cardD.card.profile),
                         title: cardD.card.title,
                    });
               }
          };

          fetchCardData();
     }, [query?.link]);

     return (
          <>
               <div className="h-screen">
                    <div className='tabs_page mb-3 overflow-hidden mx-auto'>
                         <div className='d-flex justify-content-between p-2 mb-2'>
                              {
                                   !state?.image &&
                                   <div className='viewdashboard_pulse pulse'></div>
                              }
                              {
                                   state?.image &&
                                   <div className='d-flex shadow-mini overflow-hidden' style={{ height: 59, borderRadius: '10px' }}>
                                        <img src={state?.image} alt='' style={{ backgroundColor: 'black', height: 59, width: 59, borderRadius: '10px', objectFit: 'cover', objectPosition: 'center' }} />
                                        <span className='mx-3'>
                                             <div className='ts-sm'>
                                                  Welcome
                                             </div>
                                             <div className='fw-bold'>
                                                  {state?.title}
                                             </div>
                                        </span>
                                   </div>
                              }
                         </div>

                         <nav className='mb-3'>
                              <div className="nav_dasboard_tab text-center d-flex justify-content-between overflow-scroll" id="nav-tab" role="tablist">
                                   <div className=''>
                                        <div className="dashboard_tab d-flex text-black active" id="nav-socials-tab" data-bs-toggle="tab" data-bs-target="#nav-socials" role="tab" aria-controls="nav-socials" aria-selected="true">
                                             <ICEIcon iconType='connect' size={20} />
                                             <span className='ms-2 me-3'>Socials</span>
                                        </div>
                                   </div>
                                   <div className=''>
                                        <div className="dashboard_tab d-flex text-black" id="nav-business-tab" data-bs-toggle="tab" data-bs-target="#nav-business" role="tab" aria-controls="nav-business" aria-selected="false">
                                             <ICEIcon iconType='business' size={20} />
                                             <span className='ms-2 me-3'>Business</span>
                                        </div>
                                   </div>
                                   <div className=''>
                                        <div className="dashboard_tab d-flex text-black" id="nav-finance-tab" data-bs-toggle="tab" data-bs-target="#nav-finance" role="tab" aria-controls="nav-finance" aria-selected="false">
                                             <ICEIcon iconType='finance' size={20} />
                                             <span className='ms-2 me-3'>Finance</span>
                                        </div>
                                   </div>
                                   <div className=''>
                                        <div className="dashboard_tab d-flex text-black" id="nav-others-tab" data-bs-toggle="tab" data-bs-target="#nav-others" role="tab" aria-controls="nav-others" aria-selected="false">
                                             <ICEIcon iconType='more' size={20} />
                                             <span className='ms-2 me-3'>Others</span>
                                        </div>
                                   </div>
                              </div>
                         </nav>

                         <div className="tab-content px-2" id="nav-tabContent">
                              <div className="tab-pane fade show active" id="nav-socials" role="tabpanel" aria-labelledby="nav-socials-tab" tabIndex="0">
                                   <div className='row row-cols-2'>
                                        <DashboardCard
                                             href={ROUTES.link.vcard_connect + (query.link)}
                                             ic='#FFEE56'
                                             bc1='#1B2233'
                                             bc2='#192546'
                                        />
                                        <DashboardCard
                                             href={ROUTES.link.vcard_event + (query.link)}
                                             name='Events'
                                             iconType='event'
                                             ic='#3BFF7E'
                                             bc1='#1B2233'
                                             bc2='#192546'
                                        />
                                        <DashboardCard
                                             name='Payment'
                                             iconType='payment'
                                             ic='#999999'
                                             bc1='#f0f0f0'
                                             bc2='#f0f0f0'
                                             fc='#999999'
                                             href='#'
                                        />
                                        <DashboardCard
                                             name='Store'
                                             iconType='cart'
                                             ic='#999999'
                                             bc1='#f0f0f0'
                                             bc2='#f0f0f0'
                                             fc='#999999'
                                             href='#'
                                        />
                                   </div>
                              </div>
                              <div className="tab-pane fade" id="nav-business" role="tabpanel" aria-labelledby="nav-business-tab" tabIndex="0">
                                   <div className='row row-cols-2 g-0'>
                                        <DashboardCard
                                             name='Store'
                                             iconType='cart'
                                             ic='#999999'
                                             bc1='#f0f0f0'
                                             bc2='#f0f0f0'
                                             fc='#999999'
                                             href='#'
                                        />
                                   </div>
                              </div>
                              <div className="tab-pane fade" id="nav-finance" role="tabpanel" aria-labelledby="nav-finance-tab" tabIndex="0">
                                   <div className='row row-cols-2 g-0'>
                                        <DashboardCard
                                             name='Payment'
                                             iconType='payment'
                                             ic='#999999'
                                             bc1='#f0f0f0'
                                             bc2='#f0f0f0'
                                             fc='#999999'
                                             href='#'
                                        />
                                   </div>
                              </div>
                              <div className="tab-pane fade" id="nav-others" role="tabpanel" aria-labelledby="nav-others-tab" tabIndex="0">
                                   <div className='position-relative w-100' style={{ height: 300 }}>
                                        <div className="position-absolute top-50 start-50 translate-middle" style={{ height: 300 }}>
                                             <img src='/assets/images/cartoon/cartoon_coming_soon.svg' alt='' style={{ height: 300 }} />
                                        </div>
                                   </div>
                                   <div className="pt-3 text-uppercase text-center ts-md">
                                        Watch this space!!!
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}