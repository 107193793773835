import React from 'react'
import { DATA_STORAGE } from '../../../../api/Request';
import useStorage from '../../../../hooks/useStorage';
import Logout from '../../auth/Logout';

export default function ProfileDetails() {
     const profile = useStorage(DATA_STORAGE?.dashboard);
     return (
          <>
               <div className='my-3 px-3 d-flex text-decoration-none text-black'>
                    {
                         !profile?.data?.image &&
                         <div className='profile_image_pulse pulse me-2'></div>
                    }
                    <img hidden={!profile?.data?.image} src={profile?.data?.image} height={50} width={50} alt={'Logo Black'} className='me-2 bg-dark rounded-circle' />
                    <span className='fw-bold'>
                         {
                              !profile?.data?.name &&
                              <div className='profile_username_pulse pulse'></div>
                         }
                         {
                              profile?.data?.name &&
                              <div className="text-capitalize">{profile?.data?.name}</div>
                         }
                         <div className="ts-md fw-light"><Logout /></div>
                    </span>
               </div>
          </>
     )
}
