import React, { Component } from "react";
import "./index.css";
import SocialCard from "./SocialCard";
import { Link } from "react-router-dom";
import axios from "../../../hooks/axios";
import { API } from "../../../api/Request";
import ConnectListViewVC from "./ConnectListView";
import Error404 from "../../../components/Error404.component";
import { serverUrl } from "../../../helpers/functions.helpers";
import { getCardQueryParams } from "../../../helpers/queryParams";
import ROUTES from "../../../Routes";
import ICEIcon from "../../../components/icons/ICEIcon";
import { SuperSEO } from "react-super-seo";
// import ImageCardVC from "../../user/component/ImageCardVC";
import ContactUs from "../component/ContactUs";
import ImageCardVC from "../../user/component/ImageCardVC";
const getCard = async (url) => {
	if (!url) return { data: null };
	const link = API.vcardView + url;
	return axios.get(link);
}
class ConnectVC extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...cardData,
			showMenu: false,
			user_id: null
		};
		this.downloadCard = this.downloadCard.bind(this);
		this.onListViewChange = this.onListViewChange.bind(this);
		this.saveCardLink = React.createRef();
		this.getCurrentAddress = this.getCurrentAddress.bind(this);
		this.clickShowMenu = this.clickShowMenu.bind(this);
		this.queryparams = getCardQueryParams();
	}

	componentDidMount() {
		setTimeout(() => this.setState({ showPurchaseUrl: true }), 5 * 60 * 1000);
		const fetchCardData = async () => {
			const { data } = await getCard(this.queryparams.link);
			if (data && data?.success === 'true') {
				//toast.update(loadingVcardData, { render: "Card Loaded Successfully", type: "success", isLoading: false, autoClose: 3000 });
				const cardD = data.data;
				// console.log(cardD.services)
				this.setState({
					image: serverUrl(cardD.card.profile),
					title: cardD.card.title,
					description: cardD.card.description,
					sub_title: cardD.card.sub_title,
					fields: cardD.fields,
					download_url: cardD.download_url,
					loaded: true,
					user_id: cardD.card.user_id,
					services: cardD.services
				});
			}
		};
		if (!this.state.loaded) {
			fetchCardData();
			this.getCurrentAddress();
		}
	}

	getCurrentAddress() {
		const addresses = this.state.fields.filter((field) => {
			return field.type === 'address';
		})
		const address = (addresses.length < 1) ? '' : addresses[0].content;
		this.setState(({
			address: address
		}))
	}

	onListViewChange(e) {
		e.preventDefault();
		this.setState(prev => {
			return {
				listView: !prev.listView
			}
		})
	}

	downloadCard(e) {
		e.preventDefault();
		this.saveCardLink.current.click();
	}

	clickShowMenu = (val = null) => {
		if (val === 'close') {
			this.setState({
				showMenu: false
			});
		} else {
			this.setState({
				showMenu: !this.state.showMenu
			});
		}
	}

	openGraph = () => {
		return {
			ogImage: {
				ogImage: this.state?.image,
				ogImageAlt: 'isce nfc card',
				ogImageWidth: 512,
				ogImageHeight: 512,
				ogImageType: 'image/jpeg',
			},
		}
	}

	twitter = () => {
		return {
			twitterSummaryCard: {
				summaryCardImage: this.state?.image,
				summaryCardImageAlt: 'isce nfc card',
				summaryCardSiteUsername: 'isceapp',
			},
		};
	}



	render() {
		const waContent = this.state.fields.filter(card => card.type === "wa").map(card => card.content)[0];
		if (!this.queryparams.link) return <Error404 />
		if (this.state.showPurchaseUrl) return <PurchaseUrl />
		return (
			<>
				<div className="container mx-auto p-0">
					<div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
						maxWidth: '1080px'
					}}>
						<SuperSEO
							title={"ISCE - " + (this.state.title || 'connect')}
							description={this.state.description}
							lang="en"
							openGraph={this.openGraph()}
							twitter={this.twitter()}
						>
							<meta name="keywords" content="isce, iscecards, isce card, digital business card, digitalbusinesscard, nfc card, nfccard," />
						</SuperSEO>
						<div className="col d-md-block d-none ps-0 pe-2 py-3" style={{
							maxWidth: '300px'
						}}>
							<a href={ROUTES.link.vcard_connect + ((this.queryparams.link) ? this.queryparams.link : '')} className='side_bar_link d-flex'>
								<ICEIcon iconType='connect' /> <span className="py-1">Connect</span>
							</a>
							<a href={ROUTES.link.vcard_event + ((this.queryparams.link) ? this.queryparams.link : '')} className='side_bar_link d-flex'>
								<ICEIcon iconType='event' />  <span className="py-1">Events</span>
							</a>
						</div>
						<div className="col overflow-scroll h-100 border-0 border-start border-end py-3">
							<div className="mb-1 d-flex justify-content-between">
								<div>
									<span className='d-md-none'>
										<Link to={ROUTES.link.vcard_dashboard + ((this.queryparams.link) ? this.queryparams.link : '')} className='text-decoration-none'>
											<span className='ts-sm'>{'<'}</span><ICEIcon iconType='dashboard' size={20} /> <span className='ts-sm'>Dashboard</span>
										</Link>
									</span>
								</div>
								<div className='mb-2 text-uppercase' style={{
									fontWeight: '600'
								}}>Connect</div>
							</div>

							<div className="mb-3 scrollbar-hidden mx-auto">
								<div className="position-relative pb-2">
									<ConnectListViewVC
										image={this.state.image}
										title={this.state.title}
										sub_title={this.state.sub_title}
										description={this.state.description}
									/>
									<a className="d-none" ref={this.saveCardLink} href={(this.state.download_url) ? this.state.download_url : '#!'}>Download Stream</a>
									<div className='quick-buttons d-grid gap-1 mb-3'>
										<div className="">
											<Link to={ROUTES.link.vcard_contact_exchange + ((this.queryparams.link) ? this.queryparams.link : '')} className='text-decoration-none m-0 d-grid '>
												<div className='btn btn-sm bg-black text-white agrad'>EXCHANGE CONTACT</div>
											</Link>
										</div>
										<div className="">
											<Link to={ROUTES.link.user_contact} className='text-decoration-none d-grid'>
												<div className='text-white btn btn-sm agrad bg-black' onClick={this.downloadCard}>
													SAVE CONTACT
												</div>
											</Link>
										</div>
									</div>

									<div className='' style={{
										fontWeight: '600',
									}}>Quick Links</div>
									{/* <div className="fw-light ts-sm text-secondary mb-3">(*) Long press to switch between delete mode</div> */}
									{
										!this.state.loaded &&
										<div className="pulse row row-cols-4 g-0 rounded-3 mb-3">
											<div className='quick_link_pulse bg-secondary'></div>
											<div className='quick_link_pulse bg-secondary'></div>
										</div>
									}
									<div className='row row-cols-4 g-0 rounded-3 mb-3'>
										{
											this.state.loaded &&
											this.state.fields.map((field, index) => {
												// console.log(field)
												return (
													<div className="mb-2 px-1"
														key={"social-card" + index}
													>
														<SocialCard
															params={field}
															handleClick={this.handleCardClick}
															editableModal={this.canModifyCard}
															editable={this.state.canModifyCard}
															onDelete={this.deleteCardField}
														>
														</SocialCard>
													</div>
												);
											})}
									</div>

									{
										this.state.services.length > 0 &&
										(
											<div className='d-flex justify-content-between align-items-top'>
												<div className="" style={{ fontWeight: 600 }}>Services</div>
											</div>
										)
									}
									{
										this.state.loaded && (
											<nav className=''>
												<div className="nav_dasboard_tab text-center d-flex overflow-scroll position-relative">
													{
														this.state.services.map(service => {
															return (
																<div className='me-2' key={service.id}>
																	<div className="d-flex services_tab">
																		<ImageCardVC
																			img={service.service_image}
																			title={service.service_name}
																			description={service.service_description}
																			id={service.id}
																			phone={service.phone || waContent}
																			link={service.link}
																		/>
																	</div>
																</div>
															);
														})
													}
												</div>
											</nav>
										)
									}
								</div>
							</div>
						</div>
						<ContactUs />
					</div>
				</div>
			</>
		);
	}
}

function PurchaseUrl() {
	// 👇️ redirect to external URL
	//window.location.replace('https://flutterwave.com/store/isce/t7hsxruajqdksx');
	window.location.href = ROUTES.link.login;

	return null;
}

const emptyField = {
	label: "",
	content: "",
	icon: "",
	status: "active",
	type: "",
	position: "",
};

const cardData = {
	title: 'Loading...',
	sub_title: 'Loading...',
	description: 'Loading...',
	current_field: emptyField,
	download_url: null,
	address: '',
	save: {
		can_save: false,
		saving: false,
	},
	fields: [
	],
	loaded: false,
	canModifyCard: false,
	listView: false,
	showPurchaseUrl: false,
	services: {}
};

export default ConnectVC;
