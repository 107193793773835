import React from 'react'

function Earzsection(props) {
  return (
     <section className='pt-5 bg-light overflow-hidden h-screen position-relative bg-image'>
          <div className='container rectearz-bg pt-5'>
               <div className='col-md text-center text-black mb-4 pt-5'>
                    <h1 className=''>
                         <div><span className='fw-bold header-title agrad-color'>Your Digital Culture</span><br/>
                         <span className='text-black header-text'>In One Card</span></div>

                         <div className="pt-5">
                              <a href={ props.store || '#isce' } type="button" className="btn btn-dark agrad">Order Now</a>
                         </div>
                    </h1>
               </div>
               <div className='pt-5 text-black hand-card'>
                    <div className='col-md text-center pt-3 position-absolute bottom-0'>
                    </div>
               </div>
          </div>
     </section>
  )
}
export default Earzsection