import React from 'react'
import ICEIcon from '../../components/icons/ICEIcon'

export default function Footer() {
     return (

          <div className='bg-black text-white mt-2 pt-3'>
               <div className="container">
                    <footer className="pt-3">
                         <div className="row">

                              <div className="col-md-5 mb-3">
                                   <h3><img src='/assets/images/logo/logofull-dark.webp' height={30} alt='' /></h3>
                                   <p>Get a smoother way to network with just one tap. Not limited to business owners and events, you can freely vibe and share your details with anyone, anywhere, turning handshakes into meaningful connections.</p>
                              </div>

                              <div className="col-6 col-md-3 mb-3">
                                   <h5>Head Office</h5>
                                   <p className="">
                                        Polystar Building, 3rd Floor, Remi Olowude Street, <br />
                                        Maruwa Bus Stop, Lekki, Lagos, Nigeria <br />
                                   </p>
                              </div>

                              <div className="col-6 col-md-2 mb-3">
                                   <h5>Find Out More</h5>
                                   <ul className="nav flex-column">
                                        <li className="nav-item mb-2">
                                             <a href="#!"
                                                  className="nav-link p-0 link-light"
                                             >
                                                  About
                                             </a>
                                        </li>
                                        <li className="nav-item mb-2">
                                             <a href="/faq"
                                                  className="nav-link p-0 link-light"
                                             >
                                                  FAQs
                                             </a>
                                        </li>
                                        <li className="nav-item mb-2">
                                             <a href="/privacy-policy"
                                                  className="nav-link p-0 link-light"
                                             >
                                                  Privacy Policy
                                             </a>
                                        </li>
                                        <li className="nav-item mb-2">
                                             <a href="/return-policy"
                                                  className="nav-link p-0 link-light"
                                             >
                                                  Return Policy
                                             </a>
                                        </li>
                                   </ul>
                              </div>

                              <div className="col-6 col-md-2 mb-3">
                                   <h5>Follow Us</h5>
                                   <ul className="nav flex-column">
                                        <li className="text-decoration-none mb-2 ">
                                             <a className="link-light text-decoration-none" target={"_blank"} rel="noreferrer"
                                                  href="https://facebook.com/isceapp">
                                                  <ICEIcon iconType='facebook' size={20} />
                                                  <span className="ms-2">Facebook</span>
                                             </a>
                                        </li>
                                        <li className="text-decoration-none mb-2 ">
                                             <a className="link-light text-decoration-none" target={"_blank"} rel="noreferrer"
                                                  href="https://instagram.com/isce.app">
                                                  <ICEIcon iconType='instagram' size={20} />
                                                  <span className="ms-2">Instagram</span>
                                             </a>
                                        </li>
                                        <li className="text-decoration-none mb-2 ">
                                             <a className="link-light text-decoration-none" target={"_blank"} rel="noreferrer"
                                                  href="https://linkedin.com/company/isceapp/">
                                                  <ICEIcon iconType='linkedin' size={20} />
                                                  <span className="ms-2">LinkedIn</span>
                                             </a>
                                        </li>
                                        <li className="text-decoration-none mb-2 ">
                                             <a className="link-light text-decoration-none" target={"_blank"} rel="noreferrer"
                                                  href="https://twitter.com/isceapp">
                                                  <ICEIcon iconType='twitter' size={20} />
                                                  <span className="ms-2">Twitter</span>
                                             </a>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    </footer>
               </div>
               <div className="d-flex flex-column flex-sm-row justify-content-center py-2 my-2 border-top">
                    <div className="container text-center pt-3">
                         <p className="d-flex justify-content-center align-items-center">
                              {'© 2023 '}
                              ISCE Digital Concept
                         </p>
                    </div>
               </div>
          </div>
     )
}
