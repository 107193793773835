import React from 'react'
import NavLinkList from './NavLinkList'
import ProfileDetails from './ProfileDetails'

export default function SideBar() {
     return (
          <div className="col d-md-block d-none ps-0 pe-2" style={{
               maxWidth: '300px'
          }}>
               <ProfileDetails />
               <NavLinkList />
          </div>
     )
}
