import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { API, REQUEST2 } from '../../../../api/Request';
import ICEIcon from '../../../../components/icons/ICEIcon';
import LoadingIndicator from '../../../../components/LoadingIndicator.component';
import { changeTabColor, displayDate, isToday } from '../../../../helpers/functions.helpers';
import useAuth from '../../../../hooks/useAuth';
import { http } from '../../../../http-common';
import ROUTES from '../../../../Routes';
import PurchaseCards from '../../../home/PurchaseCards';
import EventChipAttending from './EventChipAttending';
import EventChipToken from './EventChipToken';

const EventChips = () => {
    const [data, setData] = React.useState(null);
    const { chip_id } = useParams();
    const auth = useAuth();

    React.useEffect(() => {
        const fetchUserEvents = async (id) => {
            const link = API.events?.event_chip?.replace(':id', id);
            const { data: response } = await http({
                baseURL: REQUEST2.url,
                Authorization: true
            }).get(link);
            //console.log(response)
            if (response?.success === 'true') setData(response?.data);
        }
        fetchUserEvents(chip_id);
    }, [chip_id, auth]);
    changeTabColor('#000000')

    //if token is blank and does not contain event 
    if (!data) return <LoadingIndicator />

    if(!isToday(data?.event?.start_date)){
        return <PurchaseCards 
                  title={ 'ISCE Digital Lifestyle' } 
                  description={ "Let's get you started" }
                />
    }

    if (auth?.hasToken()) return <EventChipAttending data={data} />

    if (!data?.event_attendee_id) return <EventChipToken chip_id={chip_id} />

    return (
        <section className='col-xl-4 col-lg-6 col-md-7 px-lg-2 scrollbar-hidden mx-auto h-screen overflow-hidden'>
            <img
                className='img-fluid'
                alt=''
                src={data?.event?.image || 'https://isce-image.fra1.digitaloceanspaces.com/public/Rectangle%20138.jpg'}
                style={{
                    height: '250px',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'top',
                }}
            />
            <div className='px-3 my-2'>
                <div className="text-start mb-3">
                    <div className=''>
                        <div className='ts-xl'>{data?.event?.title || 'Event Title'}</div>
                        <div className='fw-light ts-md'>{data?.event?.description || 'Event discription goes here. Anything you want to write at all about the event'}</div>
                        <div className='fw-bold ts-md'>{displayDate(data?.event?.start_date) || 'EVENT DATE'}</div>
                    </div>
                </div>

                <div className="mb-3">
                    <div className='d-flex flex-row justify-content-center'>
                        <div className="text-center">
                            <img src={data?.attendee?.image || 'https://isce-image.fra1.digitaloceanspaces.com/public/Rectangle%20138.jpg'} alt="user" className='rounded-circle mb-2' style={{
                                width: 100,
                                height: 100,
                                objectFit: 'cover',
                                objectPosition: 'top',
                                borderBottomLeftRadius: '20px',
                                borderBottomRightRadius: '20px',
                            }} />
                            <div className='display-6'>{data?.attendee?.name || 'Member Name'}</div>
                            <div className='h5 text-uppercase fw-light'>{data?.price?.title || 'Ticket Type'}</div>
                        </div>
                    </div>
                </div>

                <div className='row row-cols-12'>
                    <div className="p-2 d-grid">
                        <Link to={ ROUTES.link?.event_chip_chat?.replace(':chip_id', chip_id) } className='text-decoration-none'>
                            <div className="btn bg-black text-white w-100">
                                <span className='me-2'><ICEIcon iconType='chat' size={20} /></span>
                                <span className='me-2'>Chat</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

EventChips.defaultProps = {
    event: null,
    retrieveEvent: function (id) {
        return http({
            baseURL: REQUEST2.url
        }).get(API.get.events.event_chip.replace(':id', id));
    },
}

export default EventChips;

