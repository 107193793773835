import React from "react";

const ConnectListViewVC = (props) => {
	return (
		<div>
			<div className='mb-2 mx-auto'>
				<div className='connect-card rounded-3 position-relative mb-2 overflow-hidden bg-black text-end p-2' style={{
					width: '100%',
					minHeight: '195px',
				}}>
					<img
						src="https://isce-image.fra1.digitaloceanspaces.com/public/Rectangle%20138.jpg"
						className="position-absolute top-0 start-0 images"
						alt="background"
					/>
					<div className="position-relative">
						<div className="d-flex justify-content-between">
							<div className='connect-profile-container'>
								{
									!props.image && <div className='connect-profile rounded-circle bg-light overflow-hidden pulse'></div>
								}
								{
									props.image &&
									<div className='connect-profile rounded-circle bg-light overflow-hidden'>
										<img src={props.image} alt='' className="img-fluid images" />
									</div>
								}
							</div>
							<div className="">
								<div className="mb-3">
									<div className={'mb-1 connect-user-container text-end '} >
										{
											!props.image && <div className='connect_view_title pulse'></div>
										}
										{
											props.image &&
											<div
												className='text-white text-uppercase fs-3'
												name="title"
												suppressContentEditableWarning={true}
											>
												{props?.title}
											</div>
										}
									</div>
									<div className={'connect-user-container fw-bold text-end '} >
										{
											!props.image && <div className='connect_view_subtitle pulse'></div>
										}
										{
											props.image &&
											<div
												className='text-white fw-light ts-md'
												name="sub_title"
												suppressContentEditableWarning={true}>
												{props?.sub_title}
											</div>
										}
									</div>
								</div>
							</div>
						</div>
						{
							!props.image &&
							<div className='pulse mt-5 w-100'>
								<div className="connect_view_description"></div>
								<div className="connect_view_description"></div>
								<div className="connect_view_description"></div>
							</div>
						}
						{
							props.image &&
							<div className='text-white fw-light text-center ts-sm mt-5'
								name="description"
								suppressContentEditableWarning={true}>
								{props?.description}
							</div>
						}
					</div>
				</div>
			</div>
		</div >
	);
}

export default ConnectListViewVC;