import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Styles from './RadioOptions.module.css'

const RadioOptions = ({ options, label, name, value, onChange }) => {
     return (
          <div>
               <Form.Label>{label}</Form.Label>
               <Form.Group>
                    {options.map((option) => (
                         <div className={`form-check-inline ${Styles.radio}`} key={option.value}>
                              <input hidden value={option.value} checked={value === option.value} className="form-check-input" type="radio" name={name} id={option.value} onChange={onChange} />
                              <label className={`text-center d-flex align-items-center justify-content-center ${Styles.radioLabel}`} htmlFor={option.value}>
                                   <div>
                                        <div>
                                             {option.icon}
                                        </div>
                                        {option.label}
                                   </div>
                              </label>
                         </div>
                    ))}
               </Form.Group>
          </div>
     );
};

RadioOptions.propTypes = {
     options: PropTypes.arrayOf(
          PropTypes.shape({
               label: PropTypes.string,
               value: PropTypes.string.isRequired,
               icon: PropTypes.node,
          })
     ).isRequired,
     label: PropTypes.string,
     name: PropTypes.string.isRequired,
     value: PropTypes.string.isRequired,
     onChange: PropTypes.func.isRequired,
};

export default RadioOptions;
