import React from 'react'

export default function Event() {
     return (
          <>
               <div className="container py-5" id='event'>
                    <h1 className="fw-bold isce_h1 mb-3 d-flex justify-content-center align-items-center">
                         <img src='assets/images/logo/logofull-dark.webp' className='me-3' alt='ISCE' height={33} />
                         {'EVENT'}
                    </h1>
                    <div className="row pb-md-5">
                         <div className="col-md-6  pt-md-5 mt-md-5">
                              <div className="py-5 px-2">
                                   <h2 className="py-2">Personal Use</h2>
                                   <div className="py-2 isce_text">
                                        <p>
                                             Organise your personal parties and social events with ease and style using ISCE Events.
                                             Get to invite guests in a more unique way and so much more, creating unforgettable experiences.
                                        </p>
                                        <p>
                                             After all, isn’t that why you throw a party?
                                        </p>
                                   </div>
                                   <h2 className="py-2">For Business</h2>
                                   <div className="py-2 isce_text">
                                        <p>
                                             As an event planner, you can use ISCE events to get a simpler and more effective way of sending invites, and what more?
                                        </p>
                                        <p>
                                             You get to have a detailed list of all guest check-ins without moving a muscle.
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div className="col-md-6 overflow-hidden">
                              <img src='assets/images/background/event.png' alt='' className='pt-5 mx-auto' style={{
                                   maxHeight: '700px'
                              }} />
                         </div>
                    </div>
               </div>
          </>
     )
}
