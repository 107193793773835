import React, { useState, useEffect } from 'react'
import axios, { axiosWithHeaders } from '../../../hooks/axios';
import { useNavigate } from "react-router-dom";
import { API, DATA_STORAGE } from '../../../api/Request';
import useStorage from '../../../hooks/useStorage';
import LoadingButton from '../../../components/LoadingButton.component';
import { toast } from 'react-toastify';
import ROUTES from '../../../Routes';

const confirmCardToken = async (data) => {
     return axios.post(API.confirmToken, data);
}

const addDeviceToUser = async (data, auth) => {
     return axiosWithHeaders(auth).post(API.addCardToDevice, data)
}

const EnterToken = ({ query, authToken }) => {
     //App Data
     const [token, setToken] = useState('');
     const [formSubmitted, setFormSubmitted] = useState(false)
     const auth = useStorage(DATA_STORAGE.auth);
     const navigate = useNavigate();

     //Form Watchers
     const [loading, setLoading] = useState(false);

     useEffect(() => {
          const fetchAuthProcess = async ({ card_id, token, type }) => {
               const tokenCheck = toast.loading("Verifying token...");
               const { data } = await confirmCardToken({ card_id, token, type });
               setTimeout(() => toast.dismiss(), 2000);
               if (data && data.success === 'true') {
                    setLoading(false);
                    const { data: deviceAdded } = await addDeviceToUser({ card_id, type, token }, authToken);
                    if (deviceAdded.success === 'true') {
                         toast.update(tokenCheck, { render: "Successfully added device!", type: "success", isLoading: false, autoClose: 3000 });
                         auth.setData(authToken);
                         navigate('/' + ROUTES.user_connect.path, { replace: true });
                    }
                    else toast.update(tokenCheck, { render: "Unable to add device!", type: "error", isLoading: false, autoClose: 3000 });
               } else {
                    setLoading(false);
                    toast.update(tokenCheck, { render: (data.message || "Invalid Token"), type: "error", isLoading: false, autoClose: 3000 });
               }
          }

          if (formSubmitted) {
               fetchAuthProcess({
                    card_id: query.id,
                    token: token,
                    type: query.type
               });
               setFormSubmitted(false);
          }
     }, [query, token, formSubmitted, setFormSubmitted, auth, authToken, navigate]);

     const handleSubmit = (e) => {
          e.preventDefault();
          setLoading(true);
          setFormSubmitted(true);
     }

     return (
          <div>
               <div className=''>
                    <div className='bg-white text-center' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image'>
                                   <img src="assets/images/background/security.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'end bottom'
                                   }} />
                              </div>
                              <div className='col-md login-screen-mobile'>
                                   <div className='pt-5'>
                                        <h1 className='fw-bold'>Enter Token</h1>
                                        <span>Scratch and enter the token provided on your card</span>
                                   </div>
                                   <form className='pt-4' onSubmit={handleSubmit} hidden={!query.id}>
                                        <div className="d-grid gap-2">
                                             <input className='form-control border-dark' type={'text'} placeholder='Enter Card Token' onChange={e => setToken(e.target.value)} value={token} />
                                        </div>

                                        <div className='py-4 d-grid'>
                                             {
                                                  (loading) ?
                                                       <LoadingButton /> :
                                                       <button type='submit' className='btn btn-dark btn-md'>Confirm Token</button>
                                             }
                                        </div>
                                   </form>
                                   <hr className='my-3 mx-auto w-100' />
                                   <p className='fw-light' hidden={query.id}>
                                        You need to attach a valid device to this card
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default EnterToken

const Styles = {
     height: '100vh'
}