import React, { useState } from 'react'
import './index.css'
import { addAreaCode } from '../../../helpers/functions.helpers';
import { Modal } from 'react-bootstrap'
import ICEIcon from '../../../components/icons/ICEIcon';


function SocialCard(props) {
   const [showAddress, setShowAddress] = useState(false)
   if (!props) return null;
   const isAddress = props.params.type === 'address'

   const getCardLink = (type, content) => {
      const cleanPhone = addAreaCode(content, '234')
      const label = props.params.label;
      switch (type) {
         case 'email':
            return `mailto:${content}`;
         case 'tel':
            return `tel:${content}`;
         case 'wa':
            return `http://wa.me/${cleanPhone}`;
         case 'url':
            // return `${content}`;
            if (label === 'Instagram') {
               if (props.params.content.includes('instagram.com/')) {
                  return `${content}`;
               }
               else {
                  return `https://instagram.com/${content}`;
               }
            } else {
               return `${content}`;
            }
         case 'link':
            return `${content}`;
         default:
            return null;
      }
   }

   const handleElementClick = (e) => {
      e.preventDefault();
      isAddress && setShowAddress(true)
      const link = getCardLink(props.params.type, props.params.content)
      if (!isAddress && link) {
         window.open(link);
      }
   }

   return (<>
      <div className='position-relative quick-link-card rounded' onClick={handleElementClick}>
         <div className='position-absolute top-50 start-50 text-center translate-middle'>
            <div className='social-icon fa-xl'><i className={props.params.icon}></i></div>
            {/* <div className='fw-bold ts-xs truncate text-uppercase'>{props.params.label}</div> */}
         </div>
      </div>
      <Modal show={showAddress} onHide={() => setShowAddress(false)}>
         <Modal.Body>
            <div className="text-center pb-4">
               <div className="d-flex align-items-center mb-3">
                  <button className='btn btn-light position-absolute' onClick={() => setShowAddress(false)}>
                     <ICEIcon iconType='close' size={20} />
                  </button>
                  <h5 className='text-center w-100'>Address</h5>
               </div>
               <hr />
               {props.params.content}


            </div>
         </Modal.Body>
      </Modal>
   </>
   )
}

export default SocialCard;