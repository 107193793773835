import React, { Component } from "react";
import { toast } from "react-toastify";
import { API, REQUEST } from "../../api/Request";
import { compressImage, uniqid } from "../../helpers/functions.helpers";
import { http } from "../../http-common";
import ICEIcon from "../icons/ICEIcon";
import LoadingButton from "../LoadingButton.component";

class GalleryEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: this.props?.images || [],
            folder: '',
            allowUpload: this.props.settings.allowUpload,
            loadingImage: false
        }
        this.fileUploader = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        let state_obj = {};
        if (newProps.images !== this.props.images) {
            state_obj = { ...state_obj, images: newProps.images }
        }
        this.setState(state_obj);
    }

    handleClick = event => {
        event.preventDefault();
        this.fileUploader.current.click();
        // this.setState({ loadingImage: true })
    }
    // deleteImage = (imageId) => {
    //     console.log(`deleted this image ${imageId}`)
    // }

    handleChange = async (event) => {
        const input = event.target;
        const imageOriginal = input.files[0];
        const maxAllowedSize = 10 * 1024 * 1024;

        // console.log(imageOriginal)

        this.setState({ loadingImage: true })
        if (input.files[0].size > maxAllowedSize) {
            // Here you can ask your users to load correct file
            toast.warn('Image size must not be larger than 10MB', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            input.value = ''
            this.setState({ loadingImage: false })
        } else if (input.files && input.files[0]) {
            const compressedImage = await compressImage(imageOriginal, 0.7)
            // console.log(compressedImage)
            const image = compressedImage;
            const formData = new FormData();
            const image_name = uniqid();
            formData.append("file", image);
            formData.append("folder", 'events_gallery');
            formData.append("name", image_name);
            const { data: response } = await this.props.uploadImage(formData);
            if (response?.success === 'true') {
                this.setState({
                    images: [...this.state.images, {
                        name: response?.data?.name,
                        image: response?.data?.url
                    }]
                }, () => {
                    this.setState({ loadingImage: false })
                    this.props.updateGallery([...this.state.images]);
                });
            }
        } else {
            this.setState({ loadingImage: false })
        }
    }

    render() {
        return (
            <>
                <div className='ts-md text-uppercase'>Media Gallery</div>
                <input
                    type="file"
                    ref={this.fileUploader}
                    onChange={this.handleChange}
                    style={{ display: 'none' }}
                />
                {/* {
                    !this.state.allowUpload && !this?.state?.images?.length && <div className="text-center ts-md">No gallery Image Uploaded</div>
                } */}
                <div className='row row-cols-3 g-2'>
                    {
                        this.state?.images?.map((obj, index) => {
                            return <div key={`gallery_image_${index}`} className="">
                                <div className='m1 mb-2 border bg-secondary rounded position-relative overflow-hidden' style={{
                                    height: '100px', width: '100%',
                                }}>
                                    <button onClick={() => this.deleteImage(obj?.image)} className='btn delete_gallery_image position-absolute end-0'>
                                        <ICEIcon iconType="delete" size={20} fcolor="red" scolor="none" />
                                    </button>
                                    <img src={obj?.image} className="images" alt="gallery" />
                                </div>
                            </div>;
                        })
                    }
                    <div className='mb-2 overflow-hidden' hidden={!this.state.allowUpload}>
                        <div className="text-center border rounded bg-light d-flex justify-content-center align-items-center" style={{
                            height: '100px', width: '100%',
                        }}>
                            {
                                (!this.state.loadingImage) ?
                                    <div className=""
                                        onClick={this.handleClick}
                                    >
                                        <ICEIcon iconType="image_upload" size={100} />
                                    </div>
                                    :
                                    <div className="d-flex `justify-content-center align-items-center" style={{ height: '100px', objectFit: 'cover', objectPosition: 'center' }}>
                                        <LoadingButton />
                                    </div>
                            }
                        </div>

                    </div>
                </div>
            </>
        );
    }
};

GalleryEditor.defaultProps = {
    images: [],
    settings: {
        allowUpload: true
    },
    uploadImage: (data) => {
        return http({
            'baseURL': REQUEST.url,
            "Content-Type": "multipart/form-data"
        }).post(API.uploadProfile, data)
    }
}

export default GalleryEditor;