import React from 'react'
import { Link, useParams } from 'react-router-dom';
import ICEIcon from '../../../components/icons/ICEIcon';
import LoadingButton from '../../../components/LoadingButton.component';
import { displayTime, isToday } from '../../../helpers/functions.helpers';
import ROUTES from '../../../Routes';


const AdminImage = 'https://w7.pngwing.com/pngs/311/944/png-transparent-padlock-padlock-technic-silhouette-%D0%B7%D0%B0%D0%BC%D0%BE%D0%BA-%D1%81%D0%B8%D0%BC%D0%B2%D0%BE%D0%BB-thumbnail.png';

const OtherMessage = ({ chat, showName = false }) => {
    return (
        <li className="clearfix">
            <div className="message-data">
                <img src={chat?.image} alt="avatar" />
                <span hidden={chat?.name}>{chat?.name}</span>
                <span className="message-data-time">{displayTime(chat?.updatedAt)}</span>
            </div>
            <div className="message other-message">{chat?.message}</div>
        </li>
    )
}
const AdminMessage = ({ chat }) => {
    return (
        <li className="clearfix">
            <div className="message-data">
                <img src={AdminImage} alt="avatar" />
                <span className="message-data-time fw-bold">ADMIN</span>
                <span className="message-data-time">{displayTime(chat?.updatedAt)}</span>
            </div>
            <div className="admin-message message"> {chat?.message} </div>
        </li>
    )
}

const UserMessage = ({ chat }) => {
    return (
        <li className="clearfix">
            <div className="message-data text-end">
                <span className="message-data-time">{displayTime(chat?.updatedAt)}</span>
                <img src={chat?.image} alt="avatar" />
            </div>
            <div className="user-message message float-end"> {chat?.message} </div>
        </li>
    )
}

const PrivateChat = ({ user_id, data, submitChat, back }) => {
    const [message, setMessage] = React.useState('');
    const params = useParams()
    const scrollToBottom = () => window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    React.useEffect(() => {
        scrollToBottom();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        submitChat({
            params,
            message
        });
        setMessage('');
        scrollToBottom();
    }

    const handleKeyDown = event => {

        if (event.key === 'Enter') {
            // 👇️ your logic here
            submitChat({
                params,
                message
            });
            setMessage('');
            scrollToBottom();
        }
    };

    return (
        <section>
            <div className="col-lg-12">
                <div className="chat rounded-3">
                    <div className="chat-header bg-secondary clearfix ">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <div className="">
                                <Link to={back || ROUTES.link?.event_chat?.replace(':tid', user_id)} data-toggle="modal" data-target="#view_info">
                                    <ICEIcon iconType='back' fcolor='white' scolor='none' size={20} />
                                </Link>
                            </div>
                            <div className="ps-2">
                                <Link to={"#!"} data-toggle="modal" data-target="#view_info">
                                    <img hidden={!data?.event?.image} src={data?.event?.image} alt="avatar" className='bg-dark rounded-circle' />
                                </Link>
                                <div className="chat-about">
                                    <h6 className="mb-0 text-uppercase">{data?.event?.title || 'Loading...'}</h6>
                                    <div hidden={!data?.event?.start_date} className='ts-sm pt-1'>{isToday(data?.event?.start_date) ? 'Ongoing' : 'Yet to start'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!data && <div className='mt-4'><LoadingButton /></div>}
                    <div className="chat-history scrollingContainer mb-5 pb-3">
                        <ul className="mb-0">
                            {
                                data?.private_chats?.map((chat, index) => {
                                    if (chat?.attendee_id?.includes('admin-')) {
                                        return (
                                            <React.Fragment key={index}>
                                                {<AdminMessage chat={chat} />}
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <React.Fragment key={index}>
                                                {chat?.attendee_id === user_id && <UserMessage chat={chat} />}
                                                {chat?.attendee_id !== user_id && <OtherMessage chat={chat} />}
                                            </React.Fragment>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div className="chat-message clearfix px-2 fixed-bottom">
                        <div className="input-group mb-0">
                            <input type="text" className="ts-md form-control border border-dark" placeholder="type your message" aria-label="type your message" aria-describedby="send_message"
                                onChange={(e) => setMessage(e.target.value)} onKeyDown={handleKeyDown} value={message || ''} />
                            <button className="btn bg-dark border border-dark" type="button" id="button-addon1" onClick={handleSubmit}>
                                <ICEIcon iconType='share' size={20} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivateChat;