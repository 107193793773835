import React from 'react'
import ICEIcon from '../../../components/icons/ICEIcon';
import './index.css'

function OtherCard(props) {
     const handleElementClick = (e) => {
          e.preventDefault();
          if (!props.editable) props.handleClick(props.params);
     }

     if (!props) return null;
     if (props.editable) return null;

     return (
          <div className='quick-link-card rounded' onClick={handleElementClick} data-bs-toggle={(!props.editable) ? 'modal' : ''} data-bs-target="#exampleModal" style={{ cursor: 'pointer' }}>
               <div className='position-absolute top-50 start-50 text-center translate-middle'>
                    <ICEIcon iconType='add' size={30} />
               </div>
          </div>
     )
}

export default OtherCard