import React from 'react'

export default function Network() {
     return (
          <div className='overflow-hidden'>
               <div className="container">
                    <div className="row justify-content-center align-items-center py-5">
                         <div className="col-md-6 my-0" >
                              <h1 className="isce_h1 mb-3 fw-bold">Networking made Smarter and Secure</h1>
                              <div className="isce_text mb-5">
                                   Why print countless complementary cards yearly? Save trees, exchange contact information with one tap.
                              </div>
                              <a href={'get-started'} className='btn btn-dark rounded-pill mb-3'>Create Account</a>
                         </div>
                         <div className="col-md-6 my-0" style={{
                              minHeight: '500px',
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundImage: "url('assets/images/background/card.png')"
                         }}>
                         </div>
                    </div>
               </div>
          </div>
     )
}
