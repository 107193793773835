import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../../../api/Request';
import axios from '../../../hooks/axios';
import ROUTES from '../../../Routes';
import Navbar from '../Navbar';

function ResetPassword() {
     const { token } = useParams();
     const [form, setForm] = React.useState({
          password: '', confirm_password: '', token
     });
     const [formSubmitted, setFormSubmitted] = React.useState(false);
     const navigate = useNavigate();

     React.useEffect(() => {
          const resetPassword = async () => {
               toast.dismiss();
               //console.log(form);
               const resettingPassword = toast.loading('Resetting password...');
               const gotoLogin = () => navigate('/' + ROUTES.login.path, { replace: true });
               const { data: response } = await axios.post(
                    API.resetPassword, { ...form }
               );
               //console.log(response);
               setTimeout(() => toast.dismiss, 2000);
               if (response.success === 'true') {
                    toast.update(resettingPassword, { render: "Password reset successfully!", type: "success", isLoading: false, autoClose: 3000 });
                    gotoLogin();
               } else {
                    toast.update(resettingPassword, { render: "Unable to reset Password!", type: "error", isLoading: false, autoClose: 3000 });
               }
          }
          if (formSubmitted) {
               resetPassword();
               setFormSubmitted(false);
          }
     }, [form, formSubmitted, navigate]);

     const updateForm = (e) => {
          e.preventDefault();
          setForm({
               ...form,
               [e.target.name]: e.target.value
          });
     }

     const handleSubmit = (e) => {
          e.preventDefault();
          setFormSubmitted(true);
     }

     return (
          <div className=''>
               <div className=''>
                    <div className='bg-white text-center' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image'>
                                   <img src="assets/images/background/pink.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'center center'
                                   }} />
                              </div>
                              <Navbar backlink='/login' step={0} />
                              <div className='col-md login-screen-mobile'>
                                   <div className='pt-5'>
                                        <h1 className='fw-bold'>Reset Password</h1>
                                   </div>

                                   <hr className='my-3 mx-auto w-50' />
                                   <form className='pt-4 login-form' onSubmit={handleSubmit}>
                                        <div className="input-group mb-3 mt-2">
                                             <input name="password" className='form-control border-dark' type={'password'} placeholder='New Password' onChange={updateForm} />
                                             {/* <span className="input-group-text border-dark" ><i className={'far fa-eye-slash'}></i></span> */}
                                        </div>

                                        <div className="input-group mb-3 mt-2">
                                             <input name="confirm_password" className='form-control border-dark' type={'password'} placeholder='Confirm Pasword' onChange={updateForm} />
                                             {/* <span className="input-group-text border-dark" ><i className={'far fa-eye-slash'}></i></span> */}
                                        </div>
                                        <div className='pt-4 pb-2 d-grid'>
                                             <button type="submit" className='btn btn-dark'>
                                                  {'Reset Password'}
                                             </button>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}
const Styles = {
     height: '100vh'
}

export default ResetPassword;