import React, { memo, useEffect, useState } from 'react'
import './index.css'
import { API, REQUEST } from '../../../api/Request'
import useAuth from '../../../hooks/useAuth'
import LoadingButton from '../../../components/LoadingButton.component'
import { Link, useParams } from 'react-router-dom';
import ViewContact from './ViewContact'
import { http } from '../../../http-common'
import ICEIcon from '../../../components/icons/ICEIcon'
import ROUTES from '../../../Routes'
import ContactCard from '../component/ContactCard'
import { SuperSEO } from 'react-super-seo'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'
import SideBar from '../component/nav/SideBar'
import Result from './Result'
import SearchBar from './SearchBar'
import Notification from '../component/Notification'


const Contact = () => {
	const [contacts, setContacts] = useState(null);
	const [user, setUser] = useState(null);
	const auth = useAuth();
	const { id } = useParams();

	const [searchResults, setSearchResults] = useState([]);
	const [isSearching, setIsSearching] = useState(false);
	const data = contacts;

	const fetchUserContacts = async () => {
		const { data: response } = await http({
			baseURL: REQUEST.url,
			Authorization: true
		}).get(API.getUserContacts);
		if (response.success === 'true') {
			setContacts(response?.data?.contacts);
			setUser(response?.data?.user);
		}
	}
	//Get Google Authentication URL
	useEffect(() => {
		fetchUserContacts();
	}, []);

	if (auth.hasToken())
		if (id) {
			const current_contact = contacts?.filter((contact) => {
				return id === contact.contact_id
			});
			return <ViewContact
				contact={current_contact?.[0] || null}
				user={user}
			/>
		}

	return (
		<>
			<div className="container mx-auto p-0 h-screen">
				<div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
					maxWidth: '1080px'
				}}>
					<SuperSEO
						title={(user?.name || 'Users') + " - Contact"}
					></SuperSEO>
					<SideBar />
					<div className="col overflow-scroll h-100 border-0 border-start border-end">
						<div className="py-3 d-flex justify-content-between">
							<Link to={ROUTES.link.user_connect} className='btn btn-light text-decoration-none'>
								<ICEIcon iconType='back' size={25} /> <span className='ts-md'>Connect</span>
							</Link>
							<div className="">
								<Dropdown as={ButtonGroup}>
									<Button variant="light">
										<ICEIcon iconType='download' size={20} />
									</Button>
									<Dropdown.Toggle split variant="light" id="dropdown-split-basic" />
									<Dropdown.Menu>
										<Dropdown.Item href="#/action-1">Export All</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div>
							<div className='mb-3 mx-auto overflow-hidden'>
								<div className='d-flex justify-content-between mb-2'>
									<div className='fs-5 me-5 fw-light mb-3'>Contacts</div>
									<SearchBar
										data={data}
										searchProp="full_name"
										onSearch={setSearchResults}
										isSearching={setIsSearching}
									/>
								</div>
								<div>
									{
										!contacts && <LoadingButton />
									}
									{
										contacts?.length === 0 ?
											<div>No contacts yet</div> :
											''
									}

									{
										!isSearching
											?
											contacts?.map((contact, index) => {
												return (
													<ContactCard
														key={"contacts" + index}
														data={contact}
													/>
												)
											})
											:
											<Result data={searchResults} />
									}
								</div>
							</div>
						</div>
					</div>
					<div className="col d-xl-block d-none p-3" style={{
						maxWidth: '300px'
					}}>
						<Notification />
					</div>
				</div>
			</div>
		</>
	)
}

export default memo(Contact);