import RequireAuth from "./components/RequireAuth";
import EnterToken from "./pages/app/enter-token/EnterToken";
import GoogleCallback from "./pages/app/login/GoogleCallback";
import Login from "./pages/app/login/Login";
import OnboardingStepOne from "./pages/app/onboarding-stepone/OnboardingStepOne";
import OnboardingStepThree from "./pages/app/onboarding-stepthree/OnboardingStepThree";
import OnboardingStepTwo from "./pages/app/onboarding-steptwo/OnboardingStepTwo";
import OnboardingStepFour from "./pages/app/onboarding-stepfour/OnboardingStepFour";
import OnboardingStepFive from "./pages/app/onboarding-stepfive/OnboardingStepFive";
import Connect from "./pages/user/connect";
import Contact from "./pages/user/contact";
import ConnectVC from './pages/vcard/connect';
import EventVC from './pages/vcard/event';
import ExchangeContact from "./pages/vcard/connect/ExchangeContact";
import Preorder from "./pages/preorder";
import Event from './pages/user/event';
import Settings from "./pages/user/settings";
import ForgotPassword from "./pages/app/forgot-password";
import ChangePassword from "./pages/user/settings/ChangePassword";
import ResetPassword from "./pages/app/reset-password";
import Onboarding from "./pages/app/onboarding-stepone/Onboarding";

import ViewEvent from "./pages/user/event/ViewEvent";
import UpdateEvent from "./pages/user/event/UpdateEvent";
import NetworkError from "./components/NetworkError";
import Error404 from "./components/Error404.component";
import GetCards from "./pages/user/event/GetCards";
import ViewAttendees from "./pages/user/event/ViewAttendees";
import EventChips from "./pages/vcard/event/chips/EventChips";
import EventToken from "./pages/vcard/event/EventToken";
import EventChipChat from "./pages/vcard/event/chips/EventChipChat";
import PaystackSuccess from "./pages/vcard/event/PaystackSuccess";
import EventArena from "./pages/vcard/event/arena/EventArena";
import EventOpen from "./pages/vcard/event/open/EventOpen";
import Dashboard from "./pages/user/component/nav/Dashboard";
import DashboardVC from "./pages/vcard/component/Dashboard";
import Faq from "./pages/preorder/Faq";
import AdminEventChat from "./pages/user/event/AdminEventChat";
import Home from "./pages/home";
import PurchaseCards from "./pages/home/PurchaseCards";
import CheckOutCard from "./pages/home/checkout";
import Payment from "./pages/payment";
import PaymentStatusPage from "./pages/payment/status";
import TransactionDetails from "./pages/payment/transaction-details.js";
import GetCard from "./pages/home/GetCard";

//import Identity from './pages/user/identity'

const ROUTES = {
    //Application Paths
    index: { path: '', component: <Home /> },
    getStarted: { path: 'get-started', component: <PurchaseCards /> },
    getCard: { path: 'get-card', component: <GetCard /> },
    chat: { path: 'chat', component: <EventChipChat /> },
    faq: { path: 'faq', component: <Faq /> },
    card: { path: 'card', component: <Home /> },
    checkout: { path: 'checkout', component: <CheckOutCard /> },
    payment: { path: 'payment', component: <Payment /> },
    status: { path: 'payment/status', component: <PaymentStatusPage /> },
    order_details: { path: 'payment/order-details', component: <TransactionDetails /> },

    //Login
    login: { path: 'login', component: <Login /> },
    googleSignin: { path: 'sign-in-with-google', component: <GoogleCallback /> },

    //Signup Paths
    signup: { path: 'signup', component: <EnterToken /> },
    enterToken: { path: 'enter-token', component: <EnterToken /> },
    onboarding: { path: 'onboarding', component: <Onboarding /> },
    onboardingStep1: { path: 'onboarding/step-one', component: <OnboardingStepOne /> },
    onboardingStep2: { path: 'onboarding/step-two', component: <OnboardingStepTwo /> },
    onboardingStep3: { path: 'onboarding/step-three', component: <OnboardingStepThree /> },
    onboardingStep4: { path: 'onboarding/step-four', component: <OnboardingStepFour /> },
    onboardingStep5: { path: 'onboarding/step-five', component: <OnboardingStepFive /> },
    forgotPassword: { path: 'forgot-password', component: <ForgotPassword /> },
    resetPassword: { path: 'reset-password/:token/', component: <ResetPassword /> },

    /* User Paths */
    //Connect Paths
    user_connect: { path: 'connect', component: <RequireAuth><Connect /></RequireAuth> },

    //Contact Paths
    user_contact: { path: 'contact', component: <RequireAuth><Contact /></RequireAuth> },
    user_contact_view: { path: 'contact/:id', component: <RequireAuth><Contact /></RequireAuth> },

    //Event 
    dashboard: { path: 'dashboard', component: <RequireAuth><Dashboard /></RequireAuth> },
    event: { path: 'event', component: <RequireAuth><Event /></RequireAuth> },
    new_event: { path: 'event/add', component: <RequireAuth><Event /></RequireAuth> },
    update_event: { path: 'event/:id/update', component: <RequireAuth><UpdateEvent /></RequireAuth> },
    view_event: { path: 'event/:id', component: <RequireAuth><ViewEvent /></RequireAuth> },
    admin_event_chat: { path: 'event/:id/chat', component: <RequireAuth><AdminEventChat /></RequireAuth> },
    event_attendees: { path: 'event/:id/view-attendees', component: <RequireAuth><ViewAttendees /></RequireAuth> },
    get_cards: { path: 'event/:id/get-cards', component: <RequireAuth><GetCards /></RequireAuth> },

    //Identity
    //identity: { path: 'identity', component: <identity /> },
    preorder: { path: 'preorder', component: <Preorder /> },

    /* VCard Paths */
    //vcard connect paths
    vcard_dashboard: { path: 'card/dashboard', component: <DashboardVC /> },
    vcard_connect: { path: 'card/connect', component: <ConnectVC /> },
    vcard_contact_exchange: { path: 'card/exchange-contact', component: <ExchangeContact /> },

    //vcard event paths
    vcard_event: { path: 'card/event', component: <EventVC /> },
    vcard_event_view: { path: 'card/event/:id', component: <EventVC /> },
    vcard_request_card: { path: 'card/event/:id/register', component: <EventVC /> },
    vcard_attendees: { path: 'card/event/:id/attendees', component: <EventVC /> },
    vcard_event_token: { path: 'event/u/token/:tid', component: <EventToken /> },
    vcard_event_ticket: { path: 'event/u/ticket/:tid', component: <EventToken /> },
    vcard_event_payment: { path: 'event/p/ticket/successful', component: <PaystackSuccess /> },

    // Settings Path
    settings: { path: 'settings', component: <RequireAuth><Settings /></RequireAuth> },
    changePassword: { path: 'settings/change-password', component: <RequireAuth><ChangePassword /></RequireAuth> },

    no_page: { path: 'error/404', component: <Error404 /> },
    network_error: { path: 'error/no-internet', component: <NetworkError /> },

    /* Open events */
    event_open: { path: 'events/l/:id', component: <EventOpen /> },
    event_open_register: { path: 'events/l/:id/register', component: <EventOpen /> },

    /* Arena Manager */
    event_arena: { path: 'event/u/arena/:tid', component: <EventArena /> },
    event_chat: { path: 'event/u/arena/:tid/chat', component: <EventArena /> },
    event_privatechat: { path: 'event/u/arena/:tid/chat/:aid', component: <EventArena /> },

    /* Token Manager */
    event_chip: { path: 'events/d/chip/i/:chip_id', component: <EventChips /> },
    event_chip_chat: { path: 'events/d/chip/i/:chip_id/chat', component: <EventChipChat /> },

}

const ROUTER = {};
Object.keys(ROUTES).forEach((key) => {
    ROUTER[key] = `/${ROUTES[key].path}`;
});
ROUTES.link = ROUTER;

export default ROUTES;