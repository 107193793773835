import React, { Component } from "react";
import "./index.css";
import SocialCard from "./SocialCard";
import OtherCard from "./OtherCard";
import Modal from "./Modal";
import { API, REQUEST } from "../../../api/Request";
import { withUseStorageHOC } from "../../../hoc/withUseStorageHOC";
import ConnectListView from "./ConnectListView";
import LoadingButton from "../../../components/LoadingButton.component";
import { serverUrl } from "../../../helpers/functions.helpers";
import { getCardQueryParams } from "../../../helpers/queryParams";
import { http } from "../../../http-common";
import ICEIcon from "../../../components/icons/ICEIcon";
import { Link } from "react-router-dom";
import ROUTES from "../../../Routes";
import { SuperSEO } from "react-super-seo";
import Notification from "../component/Notification";
import SideBar from "../component/nav/SideBar";
import AddService from "./AddService";
import ImageCard from "../component/ImageCard";
import DashboardCard from "../component/nav/DashboardCard";

const getCard = async () => {
	return http({
		baseURL: REQUEST.url,
		Authorization: true
	}).get(API.getCard);
}

const saveCard = async (data) => {
	return http({
		baseURL: REQUEST.url,
		Authorization: true
	}).post(API.saveCard, data)
}

const queryParams = getCardQueryParams();

class Connect extends Component {
	constructor(props) {
		super(props);
		this.state = cardData;
		this.handleContentEditable = this.handleContentEditable.bind(this);
		this.handleCardClick = this.handleCardClick.bind(this);
		this.receiveCardFields = this.receiveCardFields.bind(this);
		this.saveToDatabase = this.saveToDatabase.bind(this);
		this.canModifyCard = this.canModifyCard.bind(this);
		this.deleteCardField = this.deleteCardField.bind(this);
		this.changeListView = this.changeListView.bind(this);
		this.queryParams = queryParams;
	}

	componentDidMount() {
		const fetchCardData = async () => {
			const { data: response } = await getCard();
			if (response?.success === 'true') {
				const cardD = response?.data;
				// console.log(cardD);
				this.setState({
					image: serverUrl(cardD.card.profile),
					title: cardD.card.title,
					description: cardD.card.description,
					sub_title: cardD.card.sub_title,
					fields: cardD.fields,
					loaded: true,
					card_id: cardD.card.card_id,
					services: cardD.services
				});
				this.props.dashboard.setData({
					name: cardD.card.title,
					description: cardD.card.description,
					image: serverUrl(cardD.card.profile)
				});
			}
		};
		if (!this.state.loaded) fetchCardData();
	}

	canModifyCard(editable) {
		this.setState({
			canModifyCard: editable
		})
	}

	handleContentEditable(values) {
		this.setState({
			...values,
			save: { ...this.state.save, can_save: true },
		});
		if (values?.saveToDatabase) this.saveToDatabase();
	}

	handleCardClick(field) {
		this.setState({ current_field: field });
	}

	receiveCardFields(newField) {
		if (!newField) return null;
		let fields_arr = this.state.fields;
		let fieldIndex = fields_arr.findIndex(
			(field) => field.position === newField.position
		);
		if (fieldIndex > -1) {
			fields_arr[fieldIndex] = newField;
		} else {
			const updatedFields = { ...newField, position: fields_arr.length };
			fields_arr = [...this.state.fields, updatedFields];
		}

		this.setState({
			fields: fields_arr,
		}, async () => {
			await saveCard({
				image: this.state.image,
				title: this.state.title,
				description: this.state.description,
				sub_title: this.state.sub_title,
				fields: this.state.fields
			});
		});
	}

	deleteCardField(fieldData) {
		if (!fieldData) return null;

		let fields_arr = this.state.fields;
		let fieldIndex = fields_arr.findIndex(
			(field) => field.position === fieldData.position
		);
		if (fieldIndex > -1) {
			fields_arr.splice(fieldIndex, 1);
		}
		this.setState({
			fields: fields_arr,
		}, async () => {
			await saveCard({
				image: this.state.image,
				title: this.state.title,
				description: this.state.description,
				sub_title: this.state.sub_title,
				fields: this.state.fields
			});
		});
	}

	saveToDatabase() {
		this.setState(
			{ save: { ...this.state.save, can_save: false } },
			async () => {
				await saveCard({
					image: this.state.image,
					title: this.state.title,
					description: this.state.description,
					sub_title: this.state.sub_title,
					fields: this.state.fields
				});
			}
		);
	}

	getCurrentAddress() {
		const address = this.state.fields.filter((field) => {
			return field.type === 'address';
		})
		if (address.length < 1) return '';
		return address[0].content;
	}

	changeListView(listView) {
		this.setState({ listView })
	}
	openGraph = () => {
		return {
			ogImage: {
				ogImage: this.state?.image,
				ogImageAlt: 'isce nfc card',
				ogImageWidth: 512,
				ogImageHeight: 512,
				ogImageType: 'image/jpeg',
			},
		}
	}

	twitter = () => {
		return {
			twitterSummaryCard: {
				summaryCardImage: this.state?.image,
				summaryCardImageAlt: 'isce nfc card',
				summaryCardSiteUsername: 'isceapp',
			},
		};
	}

	render() {
		return (
			<>
				<section className="" onContextMenu={(e) => e.preventDefault()}>
					<SuperSEO
						title={"ISCE - " + (this.state.title || 'Connect')}
						description={this.state.description}
						lang="en"
						openGraph={this.openGraph()}
						twitter={this.twitter()}
					></SuperSEO>
					<div className="container mx-auto p-0 h-screen">
						<div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
							maxWidth: '1080px'
						}}>
							<SideBar />
							<div className="col overflow-scroll h-100 border-0 border-start border-end">
								<div className="py-3 d-flex justify-content-between">
									<div className='fs-5'>Connect</div>
									<div className="d-flex">
										<div>
											<span className='d-md-none'>
												<Link to={ROUTES.link?.dashboard} className='text-decoration-none'>
													<ICEIcon iconType='dashboard' fcolor='black' scolor='none' size={20} />
												</Link>
											</span>
										</div>
									</div>
								</div>

								<div className="mb-3 scrollbar-hidden mx-auto">
									<div className="position-relative mb-3">
										<ConnectListView
											image={this.state?.image}
											title={this.state?.title}
											sub_title={this.state?.sub_title}
											description={this.state?.description}
											handleEdit={this.handleContentEditable}
											showEdit={this.state.save.can_save}
										/>
										<div className='ts-lg'>Quick Links</div>
										<div className="fw-light ts-sm text-secondary mb-3">Long press to delete</div>
										{
											!this.state.loaded && <LoadingButton />
										}
										{
											this.state.loaded && (<div className='row row-cols-4 g-0 rounded-3'>
												{this.state.fields.map((field, index) => {
													return (
														<div className="mb-2 px-1"
															key={"social-card" + index}
														>
															<SocialCard
																params={field}
																handleClick={this.handleCardClick}
																editableModal={this.canModifyCard}
																editable={this.state.canModifyCard}
																onDelete={this.deleteCardField}
															>
															</SocialCard>
														</div>
													);
												})}
												<OtherCard
													params={emptyField}
													handleClick={this.handleCardClick}
													editable={this.state.canModifyCard}
												/>
											</div>)
										}
									</div>
									<div className='d-flex justify-content-between align-items-top'>
										<div className="ts-lg">Services</div>
										<AddService cardId={this.state.card_id} />
									</div>
									{
										this.state.loaded && (
											<nav className='my-3'>
												<div className="nav_dasboard_tab text-center d-flex overflow-scroll position-relative">
													{
														this.state.services.map(service => {
															return (
																<div className='me-2' key={service.id}>
																	<div className="d-flex services_tab">
																		<ImageCard
																			img={service.service_image}
																			title={service.service_name}
																			description={service.service_description}
																			id={service.id}
																		/>
																	</div>
																</div>
															);
														})
													}
												</div>
											</nav>
										)
									}
								</div>
								<div className="tab-content px-2" id="nav-tabContent">
									<div className="tab-pane fade show active" id="nav-socials" role="tabpanel" aria-labelledby="nav-socials-tab" tabIndex="0">
										<div className='row row-cols-2'>
											<DashboardCard
												href={ROUTES.link.user_connect}
												ic='#FFEE56'
												bc1='#1B2233'
												bc2='#192546'
											/>
											<DashboardCard
												href={ROUTES.link.event}
												name='Events'
												iconType='event'
												ic='#3BFF7E'
												bc1='#1B2233'
												bc2='#192546'
											/>
											<DashboardCard
												name='Payment'
												iconType='payment'
												ic='#999999'
												bc1='#f0f0f0'
												bc2='#f0f0f0'
												fc='#999999'
												href='#'
											/>
											<DashboardCard
												name='Store'
												iconType='cart'
												ic='#999999'
												bc1='#f0f0f0'
												bc2='#f0f0f0'
												fc='#999999'
												href='#'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col d-xl-block d-none p-3" style={{
								maxWidth: '300px'
							}}>
								<Notification />
							</div>
						</div>
					</div>




					<Modal
						params={this.state.current_field}
						receiveFields={this.receiveCardFields}
					/>
				</section>
			</>
		);
	}
}

const emptyField = {
	label: "",
	content: "",
	icon: "",
	status: "1",
	type: "",
	position: "",
};

const cardData = {
	image: '',
	title: '',
	sub_title: '',
	description: '',
	current_field: emptyField,
	address: 'Loading',
	save: {
		can_save: false,
		saving: false,
	},
	fields: [
	],
	loaded: false,
	canModifyCard: false,
	listView: false,
	card_id: null,
	services: {}
};

export default withUseStorageHOC(Connect);
