import React from 'react'
import { Link } from 'react-router-dom'
import { SuperSEO } from 'react-super-seo';
import { API, REQUEST2 } from '../../../api/Request';
import ICEIcon from '../../../components/icons/ICEIcon';
import { formatDate, imageAvatar } from '../../../helpers/functions.helpers';
import { getCardQueryParams } from '../../../helpers/queryParams';
import { httpNoInterceptor } from '../../../http-common';
import ROUTES from '../../../Routes'
import ContactUs from '../component/ContactUs';
import SideBarVC from '../component/SideBarVC';

const RequestCard = ({ event }) => {
	const [state, setState] = React.useState({
		event_id: '', name: '', phone: '', email: '', event_prices_id: ''
	});
	const [responseMessage, SetResponseMessage] = React.useState('');

	const query = getCardQueryParams();

	const updateForm = (e) => {
		e.preventDefault();
		setState({
			...state,
			[e.target.name]: e.target.value
		});
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const image = imageAvatar(state.name);
		const data = { ...state, event_id: event?.id, image }
		const { data: response } = await httpNoInterceptor({
			baseURL: REQUEST2.url
		}).post(API.events.register_user, data);
		if (response.success === 'true')
			window.location.href = response?.link;
		else {
			SetResponseMessage(response?.message);
		}
	}

	return (
		<>
			<div className="container mx-auto p-0">
				<div className="row border-0 border-start border-end mx-auto overflow-hidden" style={{
					maxWidth: '1080px'
				}}>
					<SuperSEO
						title={event?.title + " REGISTRATION"}
						description={event?.description}
						lang="en"
					/>
					<SideBarVC />

					<div className="col overflow-scroll h-100">
						<div className='mb-3 scrollbar-hidden mx-auto'>
							<div className="py-1 d-flex justify-content-between border-bottom">
								<div>
									<Link to={ROUTES.link.vcard_event_view.replace(':id', event?.id) + query?.params_link} className='text-decoration-none btn btn-light'>
										<ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Events</span>
									</Link>
								</div>
								<div>
									<span className='d-md-none'>
										<Link to={ROUTES.link.vcard_dashboard + query?.params_link} className='text-decoration-none btn btn-light'>
											<ICEIcon iconType='dashboard' size={20} />
										</Link>
									</span>
								</div>
							</div>


							<div className='overflow-hidden mb-3 mx-2' style={{ height: '200px', width: '100%', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
								<img src={event?.image} alt={event?.title} className={'images'} />
							</div>

							<div className={'btn btn-light mb-2'}>
								<i className="fa-solid fa-calendar-day me-2"></i> <span className='text-uppercase'>{'Date: '}{formatDate(event?.start_date)} </span>
							</div>
							<div className='fw-bold ts-lg mb-2'>
								{event?.title}
							</div>
							<div className='ts-md mb-3'>
								{event?.description}
							</div>

							<div>
								<div className='ts-md mb-3'>
									<label htmlFor='name'>Full Name</label>
									<input className='form-control border-dark ts-md' name='name' type={'type'} placeholder='Enter Full Name' value={state.name} onChange={updateForm} />
								</div>
								<div className='ts-md mb-3'>
									<label htmlFor='email'>Email Address</label>
									<input className='form-control border-dark ts-md' name='email' type={'type'} placeholder='Enter Email Address' value={state.email} onChange={updateForm} />
								</div>
								<div className='ts-md mb-3'>
									<label htmlFor='email'>Phone Number</label>
									<input className='form-control border-dark ts-md' name='phone' type={'type'} placeholder='Enter Phone Number' value={state.phone} onChange={updateForm} />
								</div>
								<div className='ts-md mb-3'>
									<label htmlFor='email'>Choose Ticket</label>
									<select name="event_prices_id" className="form-select ts-md" aria-label="Default select example" value={state.event_prices_id} onChange={updateForm}>
										<option value=''>Select Category </option>
										{
											event?.prices?.map((price, index) => {
												return (
													<option key={`register_card_${index}_${event.id}`} value={price?.id}>{price?.title} - N{price?.amount}</option>
												)
											})
										}
									</select>
								</div>
								<div className='bg-black text-white text-center py-2 rounded' onClick={handleFormSubmit}>Register</div>
								<div className='text-danger ts-md text-center mt-2'>{responseMessage}</div>
							</div>
						</div>
					</div>

					<ContactUs />
				</div>
			</div>
		</>
	)
}

export default RequestCard