import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ICEIcon from "../../../components/icons/ICEIcon";
import FileUploader from "../../../components/images/fileuploader";
import ROUTES from "../../../Routes";
import styles from './connectlistview.module.css'

const ConnectListView = (props) => {
	const titleRef = useRef(null);
	const [iseditable, setIseditable] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [image, setImage] = useState(null)
	const [errors, setErrors] = useState({});
	const hasErrors = Object.keys(errors).length > 0;

	const imageUpload = (file) => {
		const change = {
			image: file.url
		}
		setImage(file.url);
		props.handleEdit(change)
	}

	const handleFocus = () => {
		setIsActive(true);
	};

	const handleContentEditable = (e) => {
		e.preventDefault();
		const name = e.target.getAttribute("name");
		setIsActive(false)
		const content = e.currentTarget.textContent.trim();

		if (content !== '') {
			const change = {
				[name]: content,
			};
			props.handleEdit(change);
			setErrors((prevErrors) => {
				// remove the error for the current field if it exists
				const { [name]: removedError, ...rest } = prevErrors;
				return rest;
			});
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: `This field cannot be empty`,
			}));
		}

		// props.handleEdit(change);
	}

	const handleCardEdit = (e) => {
		e.preventDefault();
		setIseditable((prev) => !prev);

		const getclickeditem = e.currentTarget.getAttribute('name');
		if (getclickeditem === 'save') props.handleEdit({ saveToDatabase: true });
	}

	useEffect(() => {
		setImage(props.image)
		if (iseditable) titleRef.current.focus();
		if (!iseditable) setIseditable(false);
	}, [iseditable, titleRef, props.image]);

	return (
		<div>
			<div className='mb-3 mx-auto'>
				<div className='connect-card rounded-3 position-relative mb-3 overflow-hidden bg-black text-end p-2'>
					<img src="https://isce-image.fra1.digitaloceanspaces.com/public/Rectangle%20138.jpg" className="position-absolute top-0 start-0 w-100 h-100" alt="background" />
					<div className="position-relative">
						<div className="d-flex justify-content-between ">
							<div className='connect-profile-container'>
								<div className='connect-profile rounded-3 bg-dark overflow-hidden'>
									{
										!image &&
										<div className="connect_profile_pulse pulse"></div>
									}
									{
										image &&
										<FileUploader
											image={image}
											canUpload={iseditable}
											handleFile={imageUpload}
											folder={'profile'}
											fileName={image?.split("/")?.pop()?.split('.')?.slice(0, -1)?.join('') || null}
										/>
									}
								</div>
							</div>
							<div name="edit" hidden={iseditable} onClick={handleCardEdit} style={{ cursor: 'pointer' }}>
								<span className="me-1 text-white" >EDIT</span><ICEIcon iconType="edit" size={20} fcolor='white' scolor='none' />
							</div>
							{
								!hasErrors &&
								<div name="save" hidden={!iseditable} onClick={handleCardEdit} style={{ cursor: 'pointer' }}>
									<span className="me-1 text-white" >SAVE</span><ICEIcon iconType="save" size={20} fcolor='white' scolor='none' />
								</div>
							}
						</div>
						<div className="mb-3">


							<div className={'my-2 connect-user-container text-end ' + ((iseditable) ? 'editable' : '')} >
								{
									!props?.title &&
									<div className="connect_user_title pulse"></div>
								}
								{
									props?.title &&
									(
										<>
											<div
												className={`text-white text-uppercase fs-3 ${iseditable ? ' border-bottom' : ''} ${isActive ? styles.active : ''}`}
												name="title"
												contentEditable={iseditable}
												suppressContentEditableWarning={true}
												onBlur={handleContentEditable}
												onFocus={handleFocus}
												dataplaceholder="Enter Name"
												ref={titleRef}
											>
												{props?.title}
											</div>
											{errors.title && <div className="text-danger text-sm">{errors.title}</div>}
										</>
									)
								}
							</div>

							<div className={'connect-user-container fw-bold text-end ' + ((iseditable) ? 'editable' : '')} >
								{
									!props?.title &&
									<div className="connect_user_subtitle pulse"></div>
								}
								{
									props?.title &&
									(
										<>
											<div
												className={`text-white fw-light ts-md ${iseditable ? ' border-bottom' : ''} ${isActive ? styles.active : ''}`}
												name="sub_title"
												contentEditable={iseditable}
												suppressContentEditableWarning={true}
												onBlur={handleContentEditable}
												dataplaceholder="Enter Title/Position"
												onFocus={handleFocus}>
												{props?.sub_title}
											</div>
											{errors.sub_title && <div className="text-danger text-sm fw-light">{errors.sub_title}</div>}
										</>
									)
								}
							</div>
						</div>
						<div className={'connect-user-container fw-bold text-end ' + ((iseditable) ? 'editable' : '')} >
							{
								!props?.title &&
								<div>
									<div className="connect_user_description mb-1 pulse"></div>
								</div>
							}
							{
								props?.title &&
								(
									<>
										<div className={`text-white fw-light ts-sm text-center ${iseditable ? 'border-bottom' : ''} ${isActive ? styles.active : ''}`}
											name="description"
											contentEditable={iseditable}
											suppressContentEditableWarning={true}
											dataplaceholder="Give a short description of yourself"
											onBlur={handleContentEditable}
											onFocus={handleFocus}
										>
											{props?.description}
										</div>
										{errors.description && <div className="text-danger text-sm fw-light text-center">{errors.description}</div>}
									</>
								)
							}
						</div>
					</div>
				</div>
				<div className='quick-buttons row g-2'>
					<Link to={ROUTES.link.user_contact} className='text-decoration-none d-grid'>
						<div className='btn bg-black text-white'>Contacts</div>
					</Link>
				</div>
			</div>
		</div >
	);
}

export default ConnectListView;