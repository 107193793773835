import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import ICEIcon from '../../../components/icons/ICEIcon';
import { addAreaCode } from '../../../helpers/functions.helpers';
import FullLogo from '../../../images/airpod-pro.webp'

export default function ImageCardVC({
     height = '100px',
     width = '150px',
     img = FullLogo,
     title = 'Service One',
     description = 'Test Description',
     phone,
     link,
}) {
     const [show, setShow] = useState(false);
     const cleanPhone = addAreaCode(phone, '234')
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
     return (
          <>
               <div className='mb-3 position-relative overflow-hidden rounded-3' onClick={handleShow} style={{ width: width, height: height }}>
                    <div className='bg-black opacity-50 position-absolute' style={{ height: height, width: width }}></div>
                    <img src={img} className="images" alt={title} />
                    <div className='position-absolute top-50 start-50 text-white translate-middle'>{title}</div>
               </div>
               <Modal
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
               >
                    <Modal.Body>
                         <div className="">
                              <div className="d-flex justify-content-between mb-3">
                                   <button className='btn btn-light' onClick={handleClose}>
                                        <ICEIcon iconType='close' size={20} />
                                   </button>
                              </div>
                              <div className='mb-2 mx-auto rounded overflow-hidden border border-dark' style={{
                                   height: '200px',
                                   width: '100%'
                              }}>
                                   <img src={img} alt={title} className="images" />
                              </div>
                              <h5 className='text-uppercase'>{title}</h5>
                              <p className="mb-3 ts-md overflow-hidden">
                                   {description}
                              </p>
                              <hr />
                              {/* <div className="text-start">MAKE AN ENQUIRY</div> */}
                              <div className="d-grid mb-2 text-uppercase">
                                   <a href={`tel:${cleanPhone}`} rel={'noreferrer'} className='btn btn-dark'>Call</a>
                              </div>
                              <div className="d-grid mb-2 text-uppercase">
                                   <a href={`sms:${cleanPhone}`} rel={'noreferrer'} className='btn btn-dark'>TEXT</a>
                              </div>
                              {/* <div className="d-grid mb-2 text-uppercase">
                                   <a href={`http://wa.me/${cleanPhone}`} rel={'noreferrer'} className='btn btn-dark'>WHATSAPP</a>
                              </div> */}
                              {
                                   link && <div className="d-grid mb-2 text-uppercase">
                                        <a href={link} rel={'noreferrer'} className='btn btn-dark'>VISIT</a>
                                   </div>
                              }
                         </div>
                    </Modal.Body>
               </Modal>
          </>
     )
}
