import React from 'react'
import pinkCard from '../../../images/pink-card-edited.png'
import blackCard from '../../../images/black-card-edited.png'

function Cardsection(props) {
  return (
     <section className='h-screen position-relative bg-dark overflow-hidden' id='about'>
          <div className='container position-absolute top-50 start-50 translate-middle'>
               <div>
                    <h1 className='text-center text-light pb-5 mb-4 text-uppercase'>
                         <span className='fw-bold agrad-color'>Store and Access Your</span><br/>
                         <span className='header-text'>Connect <i className="fa-solid fa-circle-dot fa-xxs"></i> Business <i className="fa-solid fa-circle-dot fa-xxs"></i> Events <i className="fa-solid fa-circle-dot fa-xxs"></i> Payment <i className="fa-solid fa-circle-dot fa-xxs"></i> Identity <i className="fa-solid fa-circle-dot fa-xxs"></i> Contact <i className="fa-solid fa-circle-dot fa-xxs"></i> Store</span>
                    </h1>
               </div>
               <div className='row mx-auto conbg-circle position-relative'>
                    <div className='cl-rel'>
                         <img src={pinkCard} alt="" height="200" className="d-inline-block align-text-top rounded-3"/>
                    </div>
                    <div className='cr-rel'>
                         <img src={blackCard} alt="" height="200" className="d-inline-block align-text-top rounded-3"/>
                    </div>
               </div>
                    <div className="pt-5 text-center">
                         <a href={ props.store || '#isce'} type="button" className="btn btn-dark agrad">Order Now</a>
                    </div>
          </div>
     </section>
  )
}

export default Cardsection