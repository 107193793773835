import { facebook } from './facebook';
import { twitter } from './twitter';
import { snapchat } from './snapchat';
import { website } from './website';
import { instagram } from './instagram';

export const PREDEFINED_PROFILES = [
  facebook,
  snapchat,
  instagram,
  twitter,
  website
];