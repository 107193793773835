import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { checkDate, displayDate } from '../../../../helpers/functions.helpers'
import { API, REQUEST2 } from '../../../../api/Request'
import { httpNoInterceptor } from '../../../../http-common'
import LoadingIndicator from '../../../../components/LoadingIndicator.component'
import ROUTES from '../../../../Routes';
import Chat from '../../component/Chat'
import ICEIcon from '../../../../components/icons/ICEIcon'
import { SuperSEO } from 'react-super-seo'
import PrivateChat from '../../component/PrivateChat'

const EventArena = () => {

    const [data, setData] = React.useState(null);
    const { tid } = useParams();
    const path = window?.location?.pathname;

    const fetchUserEvents = async (id) => {
        const link = API?.events?.event_token?.replace(':id', id);
        const { data: response } = await httpNoInterceptor({
            baseURL: REQUEST2.url
        }).get(link);
        if (response?.success === 'true') {
            setData(response?.data);
        }
    }

    const handleSubmittedChat = async (message) => {
        const saveChatMessage = async (message) => {
            const link = API?.events?.createChat?.replace(':id', tid);
            await httpNoInterceptor({
                baseURL: REQUEST2.url
            }).post(link, {
                event_id: data?.event?.id,
                attendee_id: data?.id,
                message,
                image: data?.image
            });
            //fetchUserEvents(tid);
        }
        saveChatMessage(message)
    }

    const handlePrivateSubmittedChat = async (result) => {
        const saveChatMessage = async (result) => {
            const link = API?.events?.createPrivateChat?.replace(':id', tid);
            await httpNoInterceptor({
                baseURL: REQUEST2.url
            }).post(link, {
                event_id: data?.event?.id,
                attendee_id_1: result?.params?.tid,
                attendee_id_2: result?.params?.aid,
                message: result?.message,
                image: data?.image
            });
            // console.log(response);
            //fetchUserEvents(tid);
        }
        saveChatMessage(result)
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            fetchUserEvents(tid);
        }, 1000);
        return () => clearInterval(interval);
    }, [tid]);

    if (path === ROUTES.link?.event_chat.replace(':tid', tid)) {
        return <Chat user_id={tid} data={data} submitChat={handleSubmittedChat} />
    }

    //console.log(path)
    const pattern = /^\/event\/u\/arena\/([\w-]+)\/chat\/([\w-]+)$/;
    if (pattern.test(path)) {
        return <PrivateChat user_id={tid} data={data} submitChat={handlePrivateSubmittedChat} />
    }

    if (!data) return <LoadingIndicator />

    /* 

    

    if(checkDate(data?.event?.start_date) < 0){
        return <PurchaseCards 
                  title={ 'ISCE Digital Lifestyle' } 
                  description={ "Don't have an ISCE card? Let's get you started" }
                />
    } */

    if (checkDate(data?.event?.start_date) > 0) { // Future event
        return (
            <section className="tabs_page h-screen overflow-scroll scrollbar-hidden mx-auto px-3" onContextMenu={(e) => e.preventDefault()}>
                <SuperSEO
                    title={data?.event?.title + ' Powered By ISCE Events'}
                    description={'Your Passport to the ISCE Ecosysytem'}
                    lang="en"
                    openGraph={{
                        ogImage: {
                            ogImage: data?.event?.image,
                            ogImageAlt: 'isce nfc card',
                            ogImageWidth: 1080,
                            ogImageHeight: 720,
                            ogImageType: 'image/jpeg',
                        },
                    }}
                    twitter={{
                        twitterSummaryCard: {
                            summaryCardImage: data?.event?.image,
                            summaryCardImageAlt: 'isce nfc card',
                            summaryCardSiteUsername: 'isceapp',
                        },
                    }}
                />
                <div className=''>
                    <div className=''>
                        <div className="pt-3">
                            <img
                                className='rounded'
                                alt=''
                                src={data?.event?.image}
                                style={{
                                    height: '300px',
                                    width: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'top',
                                }}
                            />
                        </div>
                        <div className=''>
                            <h3 className="my-3 text-center text-uppercase">This event has been concluded</h3>
                            <hr />
                            <div className='text-uppercase'><span className='fw-bold'>Event Name: </span>{data?.event?.title}</div>
                            <div className='text-uppercase'><span className='fw-bold'>Event Date: </span>{displayDate(data?.event?.start_date)}</div>
                            <div className=''><span className='fw-bold text-uppercase'>Event Description: </span>{data?.event?.description}</div>
                            <blockquote>POWERED BY ISCE EVENTS</blockquote>
                            <div className="text-center text-uppercase">Contact us to Get A Card Now.</div>
                            <div className="row px-2 py-2 mt-2">
                                <div className="d-grid col-md mb-3">
                                    <a href='https://wa.me/2348124339827' className='btn bg-black text-white'>
                                        <ICEIcon iconType='whatsapp' size={40} />
                                    </a>
                                </div>
                                <div className="d-grid col-md mb-3">
                                    <a href='mailto:support@isce.app' className='btn bg-black text-white'>
                                        <ICEIcon iconType='mail' size={40} />
                                    </a>
                                </div>
                                <div className="d-grid col-md mb-3">
                                    <a href='tel:08124339827' className='btn bg-black text-white'>
                                        <ICEIcon iconType='phone' size={40} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }


    if (checkDate(data?.event?.start_date) < 0) { //event is in the past
        return (
            <section className="tabs_page h-screen overflow-scroll scrollbar-hidden mx-auto px-3" onContextMenu={(e) => e.preventDefault()}>
                <SuperSEO
                    title={data?.event?.title + ' Powered By ISCE Events'}
                    description={'Your Passport to the ISCE Ecosysytem'}
                    lang="en"
                    openGraph={{
                        ogImage: {
                            ogImage: data?.event?.image,
                            ogImageAlt: 'isce nfc card',
                            ogImageWidth: 1080,
                            ogImageHeight: 720,
                            ogImageType: 'image/jpeg',
                        },
                    }}
                    twitter={{
                        twitterSummaryCard: {
                            summaryCardImage: data?.event?.image,
                            summaryCardImageAlt: 'isce nfc card',
                            summaryCardSiteUsername: 'isceapp',
                        },
                    }}
                />
                <div className=''>
                    <div className=''>
                        <div className="pt-3">
                            <img
                                className='rounded'
                                alt=''
                                src={data?.event?.image}
                                style={{
                                    height: '300px',
                                    width: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'top',
                                }}
                            />
                        </div>
                        <div className=''>
                            <h3 className="my-3 text-center text-uppercase">This event is yet to start</h3>
                            <hr />
                            <div className='text-uppercase'><span className='fw-bold'>Event Name: </span>{data?.event?.title}</div>
                            <div className='text-uppercase'><span className='fw-bold'>Event Date: </span>{displayDate(data?.event?.start_date)}</div>
                            <div className=''><span className='fw-bold text-uppercase'>Event Description: </span>{data?.event?.description}</div>
                            <blockquote>POWERED BY ISCE EVENTS</blockquote>
                            <div className="text-center text-uppercase">Contact us to Get A Card Now.</div>
                            <div className="row px-2 py-2 mt-2">
                                <div className="d-grid col-md mb-3">
                                    <a href='https://wa.me/2348124339827' className='btn bg-black text-white'>
                                        <ICEIcon iconType='whatsapp' size={40} />
                                    </a>
                                </div>
                                <div className="d-grid col-md mb-3">
                                    <a href='mailto:support@isce.app' className='btn bg-black text-white'>
                                        <ICEIcon iconType='mail' size={40} />
                                    </a>
                                </div>
                                <div className="d-grid col-md mb-3">
                                    <a href='tel:08124339827' className='btn bg-black text-white'>
                                        <ICEIcon iconType='phone' size={40} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <>
            <SuperSEO
                title={data?.event?.title + ' Powered By ISCE Events'}
                description={'Your Passport to the ISCE Ecosysytem'}
                lang="en"
                openGraph={{
                    ogImage: {
                        ogImage: data?.event?.image,
                        ogImageAlt: 'isce nfc card',
                        ogImageWidth: 1080,
                        ogImageHeight: 720,
                        ogImageType: 'image/jpeg',
                    },
                }}
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: data?.event?.image,
                        summaryCardImageAlt: 'isce nfc card',
                        summaryCardSiteUsername: 'isceapp',
                    },
                }}
            />
            <section className=''>
                <div className="container p-0">


                    <div className="d-flex justify-content-center p-3 ">
                        <div className=''>
                            <span className="text-uppercase fw-bold ms-1" style={{
                                fontSize: '1.1rem',
                            }}>
                                {data?.event?.title} Arena
                            </span>
                        </div>
                    </div>


                    <div className='col-xl-4 col-lg-6 col-md-7 px-lg-2 mb-3 scrollbar-hidden mx-auto rounded-bottom '
                        style={{
                            overflow: 'hidden',
                            backgroundColor: 'black',
                        }}
                    >


                        <img
                            className='img-fluid'
                            alt=''
                            src={data?.event?.image}
                            style={{
                                height: '300px',
                                width: '100%',
                                objectFit: 'cover',
                                objectPosition: 'top',
                            }}
                        />
                        <div className='p-3'>
                            <div className="text-start">
                                <div className='text-white d-flex flex-column'>
                                    <span className='fw-bold'>{data?.event?.title}</span>
                                    <span className='fw-light ts-md text-light'>{data?.event?.description}</span>
                                    <span className='fw-light ts-md text-light'>{displayDate(data?.event?.start_date)}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-xl-4 col-lg-6 col-md-7 px-lg-2 mb-3 scrollbar-hidden mx-auto rounded-bottom px-3'>
                        {/* <div className="text-uppercase ts-md mb-2">Your Name</div> */}
                        <div className='d-grid mb-3'>
                            <div className="d-flex justify-content-between align-items-top mb-3">
                                <img src={data?.image} alt="user" className='rounded-circle me-3' style={{ width: 100 }} />
                                <div className="text-end">
                                    <div className='text-uppercase'>Your Name</div>
                                    <div className='mb-3 text-uppercase fw-bold'>{data?.name}</div>
                                </div>
                            </div>
                            <div>
                                <Link to={'chat'} className='text-decoration-none'>
                                    <div className='d-grid mx-2'>
                                        <span className='btn btn-dark btn-sm text-uppercase'><ICEIcon iconType='chat' size={20} /> Event Chat</span>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default EventArena;