import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { API } from '../../../api/Request'
import Error404 from '../../../components/Error404.component'
import axios from '../../../hooks/axios'
import useCardQuery from '../../../hooks/useCardQuery'
import { toast } from 'react-toastify';
import ROUTES from '../../../Routes'
import ICEIcon from '../../../components/icons/ICEIcon'
import { validateEmail, validateFullName, validatePhone, validateTitle } from '../../../helpers/forms/validationFrontend'
import ContactUs from '../component/ContactUs'

const exchangeContact = async (data) => {
     return axios.post(API.exchangeContact, data)
}

const ExchangeContact = (props) => {
     const [fullName, setFullName] = useState('');
     const [phone, setPhone] = useState('');
     const [email, setEmail] = useState('');
     const [title, setTitle] = useState('');
     const [errors, setErrors] = useState({});

     const handleFullNameInput = (event) => {
          validateFullName(event, setErrors);
     }

     const handleTitleInput = (event) => {
          validateTitle(event, setErrors);
     }

     const handlePhoneInput = (event) => {
          validatePhone(event, setErrors);
     }

     const handleEmailInput = (event) => {
          validateEmail(event, setErrors);
     }

     /* const [ socials, setSocials ] = useState({
          facebook: '', twitter: '', snapchat: '', website: ''
     }); */
     const query = useCardQuery();
     /* const handleSocialsUpdate = (e) => {
          e.preventDefault();
          const input = {
               ...socials,
               [e.target.name]: e.target.value
          }
          setSocials(input);
     } */

     const handleFormSubmit = async (e) => {
          e.preventDefault();

          if (!query.link()) return null;
          // const gotoVcard = () => Navigate('/card/connect' + query.link(), { replace: true });
          const exchangingContact = toast.loading("Please wait...")
          const { data } = await exchangeContact({
               card_id: query.id,
               device_type: query.type,
               full_name: fullName,
               phone,
               email,
               title,
               //socials
          });
          setTimeout(() => {
               toast.dismiss();
          }, 2000);
          if (data.success === 'true') {
               // window.location.reload();
               window.history.back();
               toast.update(exchangingContact, { render: "Contact saved", type: "success", isLoading: false, autoClose: 3000 });
               // gotoVcard();
          }
          else
               toast.update(exchangingContact, { render: "Unable to save contact", type: "text-danger ts-md ps-3", isLoading: false, autoClose: 3000 });
     }

     if ((!query.link())) return <Error404 />;
     return (
          <>
               <div className="container mx-auto p-0 h-screen">
                    <div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
                         maxWidth: '1080px'
                    }}>
                         <div className="col d-md-block d-none ps-0 pe-2 py-3" style={{
                              maxWidth: '300px'
                         }}>
                              <a href={ROUTES.link.vcard_connect + (query.link())} className='side_bar_link d-flex'>
                                   <ICEIcon iconType='connect' /> <span className="py-1">Connect</span>
                              </a>
                              <a href={ROUTES.link.vcard_event + (query.link())} className='side_bar_link d-flex'>
                                   <ICEIcon iconType='event' />  <span className="py-1">Events</span>
                              </a>
                         </div>

                         <div className="col overflow-scroll h-100 border-0 border-start border-end py-3">

                              <div className="py-3 px-2 d-flex justify-content-between">
                                   <div>
                                        <span className=''>
                                             <Link to={ROUTES.link.vcard_connect + (query.link())} className='text-decoration-none btn btn-light'>
                                                  <ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Connect</span>
                                             </Link>
                                        </span>
                                   </div>
                                   <div>
                                        <span className='d-md-none'>
                                             <Link to={ROUTES.link.vcard_dashboard + (query.link())} className='text-decoration-none'>
                                                  <ICEIcon iconType='dashboard' size={20} />
                                                  {/* <span className='ts-sm'>Dashboard</span> */}
                                             </Link>
                                        </span>
                                   </div>
                              </div>

                              <div className="px-2 mb-3 scrollbar-hidden mx-auto">
                                   <form>
                                        <div className='mb-3'>
                                             <label htmlFor='name' className='ts-md'>Full Name</label>
                                             <input
                                                  className='form-control border-dark ts-md'
                                                  name='name'
                                                  type={'type'}
                                                  placeholder='Enter Full Name'
                                                  value={fullName || ''}
                                                  onChange={(e) => setFullName(e.target.value)}
                                                  onInput={handleFullNameInput}
                                             />
                                             {errors.fullName && <p className="text-danger ts-md ps-3">{'* ' + errors.fullName}</p>}

                                        </div>
                                        <div className='mb-3'>
                                             <label htmlFor='title' className='ts-md'>Job Title</label>
                                             <input
                                                  className='form-control border-dark ts-md'
                                                  name='title'
                                                  type={'type'}
                                                  placeholder='Job Title e.g. Frontend Software Engineer'
                                                  value={title || ''}
                                                  onChange={(e) => setTitle(e.target.value)}
                                                  onInput={handleTitleInput}
                                             />
                                             {errors.title && <p className="text-danger ts-md ps-3">{'* ' + errors.title}</p>}
                                        </div>
                                        <div className='mb-3'>
                                             <label htmlFor='phone' className='ts-md'>Phone Number</label>
                                             <input
                                                  className='form-control border-dark ts-md'
                                                  name='phone'
                                                  type={'type'}
                                                  placeholder='Enter Phone Number'
                                                  value={phone || ''}
                                                  onChange={(e) => setPhone(e.target.value)}
                                                  onInput={handlePhoneInput}
                                             />
                                             {errors.phone && <p className="text-danger ts-md ps-3">{'* ' + errors.phone}</p>}
                                        </div>
                                        <div className='mb-4'>
                                             <label htmlFor='email' className='ts-md'>Email</label>
                                             <input
                                                  className='form-control border-dark ts-md'
                                                  name='email'
                                                  type={'type'}
                                                  placeholder='Enter Email Address'
                                                  value={email || ''}
                                                  onChange={(e) => setEmail(e.target.value)}
                                                  onInput={handleEmailInput}
                                             />
                                             {errors.email && <p className="text-danger ts-md ps-3">{'* ' + errors.email}</p>}
                                        </div>
                                        <div className="d-grid">
                                             <button
                                                  // disabled={hasErrors}
                                                  onClick={handleFormSubmit}
                                                  className='btn btn-dark text-uppercase ts-md'
                                             >
                                                  Share
                                                  <span className="ms-2">
                                                       <ICEIcon iconType='share' size={20} />
                                                  </span>
                                             </button>
                                        </div>
                                   </form>
                              </div>
                         </div>

                         <ContactUs />
                    </div>
               </div>
          </>
     )
}

export default ExchangeContact