import axios from "axios";
import { COOKIE_STORE, REQUEST } from "./api/Request";
import * as ls from 'local-storage';
import ROUTES from "./Routes";

const  API_KEY = 'SOMEKEY';
const SECRET_KEY = 'SECRET-TOKEN';

export default axios.create({
  baseURL: REQUEST.url,
  headers: {
    'Content-Type': 'application/json',
    'API-KEY': API_KEY,
    'API-SECRET': SECRET_KEY
  }
});

/**
* Server request with authentication
* server({ 'Authorization': true })

* Server request with file 
* server({ 'Content-Type': 'multipart/form-data' })

**/

export const http = (params = { }) => {
    const { baseURL, timeout, Authorization, ...headers } = params;
    const auth = ls.get(COOKIE_STORE.auth) ? ls.get(COOKIE_STORE.auth) : null;
    const instance = axios.create({
        baseURL: (baseURL) ? baseURL : REQUEST.url,
        headers: {
          'Authorization': ((Authorization) ? auth : null),
          'Content-Type': 'application/json',
          'API-KEY': API_KEY,
          'API-SECRET': SECRET_KEY,
          ...headers
        }
    });
    instance.interceptors.response.use(responseHandler, errorHandler);
    return instance;
};

export const httpNoInterceptor = (params = { }) => {
  const { baseURL, ...headers } = params;
  const instance = axios.create({
      baseURL: (baseURL) ? baseURL : REQUEST.url,
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': API_KEY,
        'API-SECRET': SECRET_KEY,
        ...headers
      }
  });
  return instance;
};

/* const requestHandler = (request) => {
  console.log('making request');
  //request.headers.Authorization = ls.get(COOKIE_STORE.auth) ? ls.get(COOKIE_STORE.auth) : null;
  return request;
} */

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = '/login';
  }
  return response;
}

const errorHandler = (error) => {
  //console.log(error)
  if (error.message === 'Network Error') {
     // The user doesn't have internet
     window.location.href = ROUTES.link.network_error;
     return;
   }
   switch (error.response.status) {
      case 400:
        break;
      case 401:
        ls.remove(COOKIE_STORE.dashboard);
        ls.remove(COOKIE_STORE.auth);
        window.location.href = ROUTES.link.login;
        break;
      case 404:
        // Show 404 page
        break;
      case 500:
        // Server Error redirect to 500
        break;
      default:
        // Unknow Error
       break;
    }
    return Promise.reject(error);
}

/* http().interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

http().interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
); */