import React, { Component } from "react";
import OnboardingStepFive from "../onboarding-stepfive/OnboardingStepFive";
import OnboardingStepFour from "../onboarding-stepfour/OnboardingStepFour";
import OnboardingStepThree from "../onboarding-stepthree/OnboardingStepThree";
import OnboardingStepTwo from "../onboarding-steptwo/OnboardingStepTwo";
import OnboardingStepOne from "./OnboardingStepOne";
import { getCardQueryParams } from "../../../helpers/queryParams";
import GetStarted from "./GetStarted";
import CreatingAccount from "./CreatingAccount";
import LocalStorage from "../../../helpers/LocalStorage";

const queryParams = getCardQueryParams();

class Onboarding extends Component {
     constructor(props) {
          super(props);
          this.queryParams = queryParams;
          const auth = LocalStorage('authToken');
          const dashboard = LocalStorage('dashboardData');
          auth.delete();
          dashboard.delete();
          this.state = {
               step: this.props.params.step || 1,
               card_id: this.queryParams.id,
               type: this.queryParams.type
          }
     }

     nextStep = () => {
          const { step } = this.state;
          this.setState({ step: step + 1 });
     };

     prevStep = () => {
          const { step } = this.state;
          this.setState({ step: step - 1 });
     };

     inputChange = stateValue => {
          this.setState({
               ...this.state,
               ...stateValue
          })
     };


     render() {
          switch (this.state.step) {
               case 1:
                    return (
                         <GetStarted
                              nextStep={this.nextStep}
                              inputChange={this.inputChange}
                              token={this.state.token}
                              query={this.queryParams}
                         />
                    );
               case 2:
                    return (
                         <OnboardingStepOne
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              inputChange={this.inputChange}
                              user={this.state.user}
                              query={this.queryParams}
                         />
                    );
               case 3:
                    return (
                         <OnboardingStepTwo
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              inputChange={this.inputChange}
                              user={this.state.user}
                              query={this.queryParams}
                         />
                    );
               case 4:
                    return (
                         <OnboardingStepThree
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              inputChange={this.inputChange}
                              card={this.state.card}
                              query={this.queryParams}
                         />
                    );
               case 5:
                    return (
                         <OnboardingStepFour
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              inputChange={this.inputChange}
                              user={this.state.user}
                              card={this.state.card}
                              query={this.queryParams}
                         />
                    );
               case 6:
                    return (
                         <OnboardingStepFive
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              inputChange={this.inputChange}
                              profileImage={this.state.image}
                              user={this.state.user}
                              card={this.state.card}
                              query={this.queryParams}
                         />
                    );
               default:
                    return (
                         <CreatingAccount
                              newAccount={this.state}
                         />
                    );
          }
     }

}

Onboarding.defaultProps = {
     params: {
          step: 1,
          card_id: queryParams.id,
          type: queryParams.type
     },
     queryParams: queryParams
}

export default Onboarding;