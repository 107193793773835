import React from 'react'

import { Route, Routes } from 'react-router-dom';

import Error404 from './components/Error404.component'
// import { Alert } from 'react-bootstrap';

import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ROUTES from './Routes'

// import UserStore from './pages/user/userstore';
// import LandingPage from './pages/user/userstore/LandingPage';
// import AddProduct from './pages/user/userstore/AddProduct';
// import ProductsPage from './pages/user/userstore/ProductsPage';

const App = () => {
	// const timeoutDuration = 1000 * 60 * 60 * 24; // Set the timeout duration (1 day in this example)
	const timeoutDuration = 1000 * 60 * 5 // Set the timeout duration (1 day in this example)
	setTimeout(() => {
		localStorage.clear(); // Delete all items from local storage
	}, timeoutDuration);
	return (
		<>
			<Routes>
				{
					Object.keys(ROUTES).filter((route) => {
						if (route === 'get') return false;
						if (route === 'link') return false;
						return true;
					})
						.map((current_route) => {
							const { path, component } = ROUTES[current_route];
							return <Route key={path} path={path} element={component} />
						})
				}

				{/* <Route path="/user-store" element={<UserStore />}>
					<Route path="landing" element={<LandingPage />} />
					<Route path="add" element={<AddProduct />} />
					<Route path="product" element={<ProductsPage />} />
				</Route> */}

				{/* <Route path="user-store" element={<UserStore />} />

				</Route> */}
				<Route path="*" element={<Error404 />} />
			</Routes>

			{/* <div className="App">
     
      <Alert type="Kyc">
        <p>Do you want to enable Kyc?</p>
      </Alert>
	  </div> */}
			<ToastContainer
				transition={Flip}
				position={"bottom-center"}
				hideProgressBar={false}
				// options= light, dark, colored
				theme={'dark'}
			// autoClose={2000}
			/>
		</>
	);
}

export default App;
