import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { displayDate } from '../../../helpers/functions.helpers'
import QRCode from "react-qr-code";
import { API, REQUEST2 } from '../../../api/Request'
import { httpNoInterceptor } from '../../../http-common'
import LoadingIndicator from '../../../components/LoadingIndicator.component'
import ICEIcon from '../../../components/icons/ICEIcon';
import ROUTES from '../../../Routes';
import { SuperSEO } from 'react-super-seo';

const EventToken = () => {
     const [data, setData] = React.useState(null);
     const { tid } = useParams();

     React.useEffect(() => {
          const fetchUserEvents = async (id) => {
               const link = API.events?.event_token?.replace(':id', id);
               const { data: response } = await httpNoInterceptor({
                    baseURL: REQUEST2?.url
               }).get(link);
               if (response?.success === 'true') {
                    setData(response?.data);
               }
          }
          fetchUserEvents(tid);
     }, [tid]);

     if (!data) return <LoadingIndicator />
     const back_link = ROUTES.link?.event_open?.replace(':id', data?.event?.clean_name)
     return (
          <>
               <section className='col-xl-4 col-lg-6 col-md-7 px-lg-2 mb-3 scrollbar-hidden mx-auto event_token_page bg-white'>
                    <SuperSEO
                         title={"ISCE - Enter Activation Token"}
                         description={'Your Passport to the ISCE Ecosysytem'}
                         lang="en"
                         openGraph={{
                              ogImage: {
                                   ogImage: 'https://isce-image.fra1.digitaloceanspaces.com/public/isce_cards.jpg',
                                   ogImageAlt: 'isce nfc card',
                                   ogImageWidth: 1080,
                                   ogImageHeight: 720,
                                   ogImageType: 'image/jpeg',
                              },
                         }}
                         twitter={{
                              twitterSummaryCard: {
                                   summaryCardImage: 'https://isce-image.fra1.digitaloceanspaces.com/public/isce_cards.jpg',
                                   summaryCardImageAlt: 'isce nfc card',
                                   summaryCardSiteUsername: 'isceapp',
                              },
                         }}
                    />
                    <div className="">
                         <div className='mt-5 pt-5'>
                              <div className="scrollbar-hidden mx-auto">
                                   <div className='text-center fw-bold py-3 mx-3 bg-success text-white shadow-midi'>
                                        Registration Successful!
                                   </div>
                                   <div className="mx-3 mb-4 rounded-bottom-3 overflow-hidden position-relative bg-dark shadow-midi">
                                        <img src={data?.event?.image} className="position-absolute" alt="event" style={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'top' }} />
                                        <div className="position-absolute bg-black opacity-75" alt="event" style={{ height: '100%', width: '100%' }}></div>
                                        <div className='text-white position-relative'>
                                             <div className='d-flex justify-content-between mb-3 pt-4 px-3'>
                                                  <div className="event_details">
                                                       <div className="fs-4">{data?.event?.title}</div>
                                                       <div className="ts-sm">{displayDate(data?.event?.start_date)}</div>
                                                       <div className='fs-5'>{data?.name}</div>
                                                  </div>
                                                  <div className='rounded-circle overflow-hidden'>
                                                       <img src={data?.image} alt={data?.event?.title} style={{ width: '80px', height: '80px' }} />
                                                  </div>
                                             </div>
                                             <div className='text-center p-3 bg-white text-black'>
                                                  <div className='overflow-hidden mx-auto' style={{ width: '150px', height: '150px' }}>
                                                       <QRCode
                                                            size={150}
                                                            bgColor='none'
                                                            fgColor='#000'
                                                            value={back_link}
                                                            style={{ height: "100%", width: "100%" }}
                                                       />
                                                  </div>
                                             </div>
                                             <p className='text-secondary'>Thank you for registering. Your event access has been sent to your email.</p>
                                        </div>
                                   </div>
                                   <div className="px-5">
                                        <Link to={back_link} >
                                             <div className="d-grid">
                                                  <div className="btn bg-black text-white">
                                                       <ICEIcon iconType='back' fcolor='white' scolor='none' className='me-2' size={20} /> Go Back
                                                  </div>
                                             </div>
                                        </Link>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          </>
     )
}

export default EventToken;