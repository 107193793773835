import React from 'react'
import ICEIcon from '../../components/icons/ICEIcon'

export default function Host() {
     return (
          <div id='' className='overflow-hidden my-2 py-5 bg-white text-black'>
               <div className="container py-3">
                    <div className="justify-content-center align-items-center">

                         <div className="col-md-6 text-center mx-auto">
                              <div className="justify-content-center align-items-center">
                                   <h1 className="fw-bold isce_h1">
                                        Host/Build Your Digital Lifestyle
                                   </h1>
                                   <p className="isce_text mb-5">
                                        Now you have all your digital needs and assests catered for.
                                   </p>

                                   <div className="row px-4">
                                        <div className="px-2 col-6 d-grid">
                                             <a href={'get-started'} className="btn bg-black text-white rounded-pill">Get Cards</a>
                                        </div>
                                        <div className="px-2 col-6 d-grid">
                                             <a href={'login'} className="btn btn-light ms-2 rounded-pill">Login <ICEIcon iconType='forward' size={20} />  </a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}
