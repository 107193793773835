export const getCardQueryParams = () => {
    const locationQuery = new URLSearchParams(window.location.search);
    let card_url = (locationQuery.get('id')) ? `?id=${locationQuery.get('id')}` : null;
    card_url = (locationQuery.get('type')) ? card_url + `&type=${locationQuery.get('type')}` : null;
    card_url = (locationQuery.get('iud')) ? card_url + `&iud=${locationQuery.get('iud')}` : card_url;
    return {
        id: locationQuery.get('id'),
        type: locationQuery.get('type'),
        user_id: locationQuery.get('iud'),
        link: card_url,
        params_link: (card_url) ? card_url : ''
    }
}

export const allQueryParams = () => {
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    return params;
}