import React, { useState } from 'react'
import Navbar from '../Navbar'
import ROUTES from '../../../Routes'
import { checkValidity, validatePersonalDataSchema } from '../../../helpers/forms/validator.schema';
import ProgressBar from '../../../components/progressBar/ProgressBar';

const OnboardingStepThree = ({ query, inputChange, nextStep }) => {
     const [name, setName] = useState("");
     const [subTitle, setSubTitle] = useState("");
     const [description, setDescription] = useState("");
     const [phone, setPhone] = useState("");
     const [errors, setErrors] = useState([])

     const handleSubmit = e => {
          e.preventDefault();
          // check validity
          async function fetchData() {
               const isValid = await checkValidity(validatePersonalDataSchema, {
                    name, subTitle, description, phone
               });
               // console.log(isValid);
               if (isValid?.success === false) {
                    setErrors(isValid.errors);
                    return null;
               }

               inputChange({
                    card: { name, subTitle, description, phone },
               })
               nextStep();
          }

          fetchData();
     }

     return (
          <div>
               <div className=''>
                    <div className='text-center' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image'>
                                   <img src="assets/images/background/cardprofile.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'bottom'
                                   }} />
                              </div>
                              <div className='col-md login-screen-mobile h-screen'>
                                   <Navbar
                                        backlink={'/' + ROUTES.onboarding.path + query.link}
                                   />
                                   <div className="d-flex justify-content-center flex-column py-5 mx-auto" style={{ height: 630, maxWidth: 500 }}>
                                        <div className='pt-5'>
                                             <h4 className='fw-bold fs-5'>Your Personal Info</h4>
                                             <span className='ts-sm'>Add your personal information</span>
                                        </div>
                                        <form className='pt-4' onSubmit={handleSubmit}>
                                             <div className="d-grid gap-2">
                                                  <input className='form-control border-dark' type={'type'} placeholder='Full Name' value={name} onChange={e => setName(e.target.value)} />
                                                  <small className='text-start text-danger'>{errors?.find(e => e.includes('name'))}</small>
                                                  <input className='form-control border-dark' type={'type'} placeholder='What do you do?' value={subTitle} onChange={e => setSubTitle(e.target.value)} />
                                                  <small className='text-start text-danger'>{errors?.find(e => e.includes('Tell'))}</small>
                                                  <textarea className='form-control border-dark' rows='4' placeholder='Short Bio' value={description} onChange={e => setDescription(e.target.value)} />
                                                  <small className='text-start text-danger'>{errors?.find(e => e.includes('description'))}</small>
                                                  <input className='form-control border-dark' type={'type'} placeholder='Phone Number' value={phone} onChange={e => setPhone(e.target.value)} />
                                                  <small className='text-start text-danger'>{errors?.find(e => e.includes('phone'))}</small>
                                             </div>

                                             <div className='py-4 d-grid gap-2'>
                                                  <button type='submit' className='btn btn-dark'>Next</button>
                                                  {/* <button href='#googlesignin' className='btn'>Skip</button> */}
                                             </div>
                                        </form>
                                        <ProgressBar currentState={'3'} />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default OnboardingStepThree



const Styles = {
     height: '100vh'
}