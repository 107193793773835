import React from 'react';

const Swipedown = (props) => {
    if(props.handleClick == null) return;
    return (
        <div onClick={ event => props.handleClick(event) } className={'position-relative d-sm-none'} >
            <span className="menuBtn btn position-absolute top-0 start-50 translate-middle place-content-center">
                <span className="material-symbols-rounded position-absolute top-50 start-50 translate-middle">
                    grid_view
                </span>    
            </span>
        </div>
    );
}

export default Swipedown;