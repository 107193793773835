import React, { useEffect } from 'react'
import LogoDark from '../../../images/logo-dark.webp'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import useStorage from '../../../hooks/useStorage';

import axios from '../../../hooks/axios'
import { API } from '../../../api/Request';
import ROUTES from '../../../Routes';

const SignupUser = async (data) => {
     try {
          return axios.post(API.signupUser, data);
     } catch (error) {
          return { data: null, message: 'Server error', success: 'false' }
     }
}

const createCard = async (data, config) => {
     try {
          return axios.post(API.createCard, data, config);
     } catch (error) {
          return { data: null, message: 'Server error', success: 'false' }
     }
}

const CreatingAccount = ({ newAccount, displayText }) => {
     const navigate = useNavigate();
     const auth = useStorage('authToken');

     useEffect(() => {
          async function fetchData() {
               if (!newAccount) return;
               const signingUpUser = toast.loading('Creating  your card...');

               const { data } = await SignupUser(newAccount);
               if (!data || data.success !== 'true') {
                    toast.update(signingUpUser, { render: data.message || "Unable to signup", type: "error", isLoading: false, autoClose: 3000 });
                    setTimeout(() => toast.dismiss(), 2000);
                    return { data: null }
               }

               const auth_response = data.data;
               const auth_token = `${auth_response.token_type} ${auth_response.access_token}`;
               const { data: card } = await createCard(newAccount, {
                    headers: {
                         Authorization: auth_token
                    }
               });

               setTimeout(() => toast.dismiss(), 2000);
               if (card && card.success === 'true') {
                    toast.update(signingUpUser, { render: "Card created successfully!", type: "success", isLoading: false, autoClose: 3000 });
                    auth.setData(auth_token);
                    navigate('/' + ROUTES.user_connect.path, { replace: true });
               } else {
                    toast.update(signingUpUser, { render: "Unable to create card!", type: "error", isLoading: false, autoClose: 3000 });
               }
          }
          fetchData();
     }, [newAccount, auth, navigate]);

     return (
          (<section className='loading-indicator position-relative'>
               <div className='spinner-circle spinner-border position-absolute'>
                    <div className='spinner-dot bg-black rounded-circle'></div>
               </div>
               <div className='spinner-icon position-absolute overflow-hidden'>
                    <img className='img-fluid' src={LogoDark} alt='' />
               </div>
               <div className='loading-text position-absolute p-0'>
                    <small className='px-2'>{displayText || 'Creating your account...'}</small>
               </div>
          </section>)
     )
}

export default CreatingAccount