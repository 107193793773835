import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import ReactGA from "react-ga4";
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize("G-FSXMCK6P7D");
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);

const SendAnalytics = () => {
	ReactGA.send({
		hitType: "pageview",
		page: window.location.pathname,
	});
}
reportWebVitals(SendAnalytics);