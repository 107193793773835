import { REQUEST } from "../api/Request";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import Compressor from 'compressorjs';

export const empty = (obj) => {
    if (obj === undefined) return true;
    if (obj === '') return true;
    if (obj === null) return true;

    return obj
        && Object.keys(obj).length === 0
        && Object.getPrototypeOf(obj) === Object.prototype
}

export const areAllEmptyStrings = (obj) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== "") {
            return false;
        }
    }
    return true;
}

export const isObj = (item) => {
    return typeof item === 'object' &&
        !Array.isArray(item)
        && item !== null;
}

export const serverUrl = (image) => {
    return (image.indexOf("/backend/") === 0) ? REQUEST.url + `/images/${image}` : image;
}

export const displayDate = (d) => {
    const date = new Date(d);
    if (!date) return null;
    return date.toDateString();
}

export const changeTabColor = (c) => {
    document.querySelector('meta[name="theme-color"]').setAttribute('content', c);
}

export const displayTime = (d) => {
    const date = new Date(d);
    const [, time] = date.toLocaleString()?.split(',');
    return time?.trim();
}

export const isToday = (inputDate) => {
    const isDate = (sDate) => {
        if (!sDate) return false;
        if (sDate.toString() === parseInt(sDate).toString()) return false;
        var tryDate = new Date(sDate);
        return (tryDate && tryDate.toString() !== "NaN" && tryDate !== "Invalid Date");
    }
    if (!isDate(inputDate)) return false;

    // Get today's date
    var todaysDate = new Date();
    return new Date(inputDate)?.setHours(0, 0, 0, 0) === todaysDate?.setHours(0, 0, 0, 0);
}

export const imageAvatar = (name) => {
    const TestImg = 'https://firebasestorage.googleapis.com/v0/b/iscecard.appspot.com/o/profile-icon.png?alt=media&token=68ffd48c-66df-44c8-98c8-8655af4a2057'
    if (!name) return TestImg;
    const image_name = name?.split(' ').join('-');
    return `https://avatars.dicebear.com/api/initials/${image_name}.svg`;
}

export const uniqid = () => {
    const v1options = {
        node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
        clockseq: 0x1234,
        msecs: new Date().getTime(),
        nsecs: 5678,
    };
    return uuidv4(v1options);
}

export const groupBy = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        const curGroup = acc[key] ?? [];

        return { ...acc, [key]: [...curGroup, obj] };
    }, {});
}

export const firstname = (fn) => {
    const result = fn.split(' ');
    return result[0];
}

export const getHostUrl = () => {
    const host = window.location?.origin + window.location?.pathname;
    return host;
}

export const truncate = (text, lenght) => text.length > lenght ? `${text.substring(0, lenght)}...` : text;

export const checkDate = (d) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const currentTime = new Date(d).setHours(0, 0, 0, 0);
    // console.log(currentTime);
    // console.log(today);
    let result = 0
    if (currentTime > today) {
        result = -1;
    } else if (currentTime < today) {
        result = 1;
    } else {
        result = 0;
    }
    return result;
}
export const checkDate2 = (date) => {
    const today = new Date();
    const inputDate = new Date(date);

    // Set inputDate to the start of the day (midnight)
    inputDate.setHours(0, 0, 0, 0);

    // Set today to the end of the day (23:59:59)
    today.setHours(23, 59, 59, 999);
    // console.log(today, inputDate)

    if (inputDate < today) {
        return -1;
    } else if (inputDate > today) {
        return 1;
    } else {
        return 0;
    }
}

export const addAreaCode = (phoneNumber, areaCode) => {
    var cleaned = phoneNumber.replace(/\D/g, '');

    while (cleaned.charAt(0) === '0' || cleaned.charAt(0) === '+') {
        cleaned = cleaned.substr(1);
    }

    if (cleaned.length > 10 && cleaned.startsWith(areaCode)) {
        return '+' + cleaned;
    } else {
        var updated = '+' + areaCode + cleaned;
        return updated;
    }
}

export const notifyStore = () => toast("STORE IS COMING SOON");
export const notifyPayment = () => toast("PAYMENT IS COMING SOON");
export const notifyIdentity = () => toast("IDENTITY IS COMING SOON");


export async function compressImage(file, quality) {
    return await new Promise((resolve, reject) => {
        new Compressor(file, {
            quality,
            success: resolve,
            error: reject,
        });
    });
}

export function formatDate(dateString, options = { longDate: true, includeYear: true }) {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: options.longDate ? 'long' : 'short' });
    const day = date.getDate();
    const year = date.getFullYear();

    if (options.includeYear) {
        return `${month} ${day}, ${year}`;
    } else {
        return `${month} ${day}`;
    }
}

export function formatCurrency(number, showDecimals = true, currency = 'NGN') {
    // format the number with commas as thousands separators and the specified currency
    const formattedNumber = number.toLocaleString('en-US', { style: 'currency', currency });

    // remove the decimal places if needed
    const currencyWithoutDecimals = showDecimals ? formattedNumber : formattedNumber.replace(/\.00/g, '');

    return currencyWithoutDecimals;
}

export function formatCurrencys(number) {
    // format the number with commas as thousands separators
    const formattedNumber = number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // add the dollar sign and decimal places if needed
    const currency = `₦ ${formattedNumber}`;

    return currency;
}

