import React from 'react';
import { API, REQUEST2 } from '../../../../api/Request';
import ICEIcon from '../../../../components/icons/ICEIcon';
import LoadingButton from '../../../../components/LoadingButton.component';
import { changeTabColor, displayDate } from '../../../../helpers/functions.helpers';
import { http } from '../../../../http-common';

const EventChipAttending = ({ data }) => {
   const [ checkedIn, setCheckedIn ] = React.useState(false);
   changeTabColor('#ffffff')
   
   React.useEffect(() => {
    const fetchUserEvents = async (data) => {
        const link = API.events?.checkinUser?.replace(':id', data?.event_attendee_id);
        const { data: response } = await http({
            baseURL: REQUEST2.url,
            Authorization: true
        }).post(link, {
            event_id: data?.event?.id,
            url_id: data?.event_id
        });
        if (response?.success === 'true') setCheckedIn(response?.data?.status);
        else setCheckedIn(false)
    }
    if(data?.event_attendee_id && !checkedIn) fetchUserEvents(data);
   }, [ data, checkedIn ]);

   return (
      <section className='col-xl-4 col-lg-6 col-md-7 px-lg-2 scrollbar-hidden mx-auto h-screen overflow-hidden'>
         <img
            className='img-fluid'
            alt=''
            src={ data?.event?.image  || 'https://isce-image.fra1.digitaloceanspaces.com/public/Rectangle%20138.jpg' }
            style={{
               height: '250px',
               width: '100%',
               objectFit: 'cover',
               objectPosition: 'top',
            }}
         />
         <div className='px-3 my-2'>
            <div className="text-start mb-3">
               <div className=''>
                  <div hidden={ !data?.event?.title } className='ts-xl'>{ data?.event?.title }</div>
                  {/* <div className='fw-light ts-md'>{data?.event?.description || 'Event discription goes here. Anything you want to write at all about the event'}</div> */}
                  <div hidden={ !data?.event?.start_date } className='fw-bold ts-md'>{ displayDate(data?.event?.start_date) }</div>
               </div>
            </div>

            <div className="mb-3">
               <div className='d-flex flex-row justify-content-center'>
                  <div className="text-center">
                     <img 
                        hidden={ !data?.attendee?.image }
                        src={ data?.attendee?.image } alt="user" className='rounded-circle mb-2' style={{
                        width: 100,
                        height: 100,
                        objectFit: 'cover',
                        objectPosition: 'top',
                        borderBottomLeftRadius: '20px',
                        borderBottomRightRadius: '20px',
                     }} />
                     <div hidden={ !data?.attendee?.name } className='display-6'>{data?.attendee?.name}</div>
                     <div hidden={ !data?.price?.title } className='h5 text-uppercase fw-light'>{data?.price?.title}</div>
                  </div>
               </div>
            </div>
            
            <div hidden={ !data?.event_attendee_id } className="text-center">
                <span hidden={ checkedIn }><LoadingButton /></span>
               <span hidden={ !checkedIn }><ICEIcon iconType='check' fcolor='#00ff00' size={30} /></span>
               <span hidden={ !checkedIn } className="text-black fw-bold text-capitalize">
                  Checked In
               </span>
            </div>

            <div hidden={ data?.event_attendee_id } className="text-center">
               {/* <ICEIcon iconType='check' fcolor='#00ff00' size={30} /> */}
               <ICEIcon iconType='close' fcolor='red' size={100} />
               <div className="text-black fw-bold text-capitalize">
                  Not checked in
               </div>
            </div>
         </div>
      </section>
   )
}

export default EventChipAttending;

