import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
	return (
		(
			<section className='error-404 position-relative'>
				<div className='error-404-icon position-absolute overflow-hidden'>
					<img className='img-fluid' src={'/assets/images/logo_full_black.svg'} alt='' />
				</div>
				<div className='error-404-message p-0 position-absolute'>
					<h3 className='px-2 h2 text-center text-uppercase mb-3'>You have found a rare gem!.</h3>
					<span className='text-start'>
						But sadly, its of no use to you, please go back.
					</span>
					<div className='d-grid mt-5 text-uppercase'>
						<Link className='text-decoration-none mb-2 btn btn-outline-dark' to={'/'}>Homepage</Link>
						<Link className='text-decoration-none mb-2 btn btn-outline-dark' to={'https://paystack.com/buy/isce-digital-cards'}>Get a Card</Link>
						<Link className='text-decoration-none mb-2 btn btn-outline-dark' to={'/faq'}>Faqs</Link>
					</div>
				</div>
			</section>
		)
	)
}

export default Error404
