import React from 'react'
import { SuperSEO } from 'react-super-seo'
import LogoDark from '../images/logo-dark.webp'
//import { usePromiseTracker } from "react-promise-tracker";

const LoadingIndicator = () => {
	//const { promiseInProgress } = usePromiseTracker();

	return (
		//promiseInProgress && 
		(<section className='loading-indicator position-relative'>
			<SuperSEO
				title={"ISCE - Loading"}
				description={'Loading Page for ISCE App'}
				lang="en"
			/>
			<div className='spinner-circle spinner-border position-absolute'>
				<div className='spinner-dot bg-black rounded-circle'></div>
			</div>
			<div className='spinner-icon position-absolute overflow-hidden'>
				<img className='img-fluid' src={LogoDark} alt='' />
			</div>
			<div className='loading-text position-absolute p-0'>
				<small className='px-2'>Loading...</small>
			</div>
		</section>)
	)
}

export default LoadingIndicator
