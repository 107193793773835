import useLocalStorage, { deleteLocalStorage } from './useLocalStorage';
import { empty } from '../helpers/functions.helpers'
import axios from './axios';
import { DATA_STORAGE, REQUEST } from '../api/Request';

const useAuth = () => {
    const [token, setToken] = useLocalStorage(DATA_STORAGE?.auth, {});
    
    const deleteItem = () => {
        deleteLocalStorage(DATA_STORAGE.auth)
    }

    const hasToken = () => {
        if(empty(token)) return false;
        return true;
    }

    const HTTP_REQUEST = axios.create({
        baseURL: REQUEST.url,
        headers: {
          'Authorization': hasToken() ? token : '',
          'API-KEY': 'AHJSDJFSHSJSHD'
        }
    });
    
    return {
        setToken,
        token,
        delete: deleteItem,
        hasToken,
        HTTP_REQUEST
    }
}

export default useAuth