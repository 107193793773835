import React from 'react'
import { changeTabColor } from '../../helpers/functions.helpers'
import Footer from '../home/Footer'
import Navbar from '../home/Navbar'

export default function Faq() {
     changeTabColor('#000000')
     return (
          <section className='bg-white'>
               <Navbar />
               <div className='h-screen pt-5 overflow-scroll'>
                    <div className="container">
                         <div className='row g-0 pt-5'>
                              {/* <div className='col-md d-lg-flex d-none position-relative'>
                                   <img src="assets/images/background/eco.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'top'
                                   }} />
                              </div> */}
                              <div className='col-md position-relative overflow-scroll px-3'>
                                   <div className="">
                                        <div className="text-center h1 fw-bold">
                                             FAQ
                                        </div>
                                        <div className="text-center display-6  mb-3">
                                             Frequently Asked Questions
                                        </div>

                                        <div className="accordion mb-3" id="accordionExample">
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingOne">
                                                       <button className="accordion-button bg-black text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            HOW DOES THIS WORK?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            The ISCE card has an NFC chip inside that wirelessly sends your information.If you tap the card on compatible phones, a link will open up with your contact information etc., ready to be saved to the phone.For older phones that don't have NFC, there is a QR code on the back of the card for you to scan and save.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingTwo">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            WHAT CAN I DO WITH MY ISCE CARD?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            The possibilities are virtually endless as this technology has many case studies for its use. However, we find that the most common would be the sharing and exchanging of contact details, social media handles, websites & sales of product information. Although anything with a digital presence can be transferred.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingThree">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            HOW DO I ADD MY DETAILS?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            After purchasing the card, you will then proceed to create your profile which would contain any detail you choose to fill in.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingFour">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                            HOW DO I CHANGE MY DETAILS?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            You can change your details in real time in a matter of seconds within your online profile as you edit.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingFive">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                            IS THERE A MONTHLY SUBSCRIPTION FEE? DO I ADD MY DETAILS?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            There is no subscription fee to use our cards after your initial purchase. However, we do have a pro version for larger corporations.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingSix">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            IS ISCE SECURE?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            ISCE is as secure as any new-age technology and as safe as the information you put on it. It only works within an inch of a compatible device, and can only share, rather than extract information.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingSeven">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                            CAN I GET A CUSTOM-DESIGNED CARD FOR MY COMPANY?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            All our cards are fully customizable and so after your purchase, you can choose to design your cards in whatever way you want.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingEight">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                            WHAT PHONES ARE COMPATIBLE?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            All Androids and all newer-aged iPhones are compatible with our ISCE  tap feature. For older iPhones, we recommend using the QR code feature.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingNine">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                            DO SMARTPHONES NEED AN APP TO USE THIS TECHNOLOGY?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            No, it's as simple as tap and go. As this technology is similar to Apple and Android Pay, 9/10 phones are now compatible with all of ISCE’s features.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingTen">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsTen" aria-expanded="false" aria-controls="collapsTen">
                                                            DOES THE OTHER PERSON NEED A ISCE CARD OR APP TO RECEIVE MY INFO
                                                       </button>
                                                  </h2>
                                                  <div id="collapsTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            No! The other person does not need an ISCE card or app to receive your ISCE profile. That’s the beauty of ISCE.
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="accordion-item">
                                                  <h2 className="accordion-header" id="headingEleven">
                                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                            WHAT HAPPENS IF I MISPLACE,  DAMAGE OR HAVE MY CARD STOLEN?
                                                       </button>
                                                  </h2>
                                                  <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                                       <div className="accordion-body">
                                                            In the case of a damaged or misplaced card, there is no need to worry as your information is still very much safe, so all that would be done is to simply purchase another ISCE card.
                                                            In the case of a stolen card, there is a feature on your profile that locks your profile, preventing it from being shared through the card. You can also choose to use this feature in the case of a misplaced card to protect your information.
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <Footer />
          </section>
     )
}
