import React, { Fragment, useState } from 'react'

export default function Payment() {
     const [cardNumber, setCardNumber] = useState('');
     const [expiry, setExpiry] = useState('');
     const [cvc, setCvc] = useState('');

     const handleCardNumberChange = (e) => {
          const formattedCardNumber = e.target.value
               .replace(/ /g, '')
               .replace(/(\d{4})/g, '$1 ')
               .trim();
          setCardNumber(formattedCardNumber);
     };

     const handleExpiryChange = (e) => {
          const formattedExpiry = e.target.value
               .replace(/ /g, '')
               .replace(/(\d{2})(\d{1,2})/, '$1/$2')
               .trim();
          setExpiry(formattedExpiry);
     };

     const handleCvcChange = (e) => {
          const formattedCvc = e.target.value
               .replace(/\D/g, '')
               .slice(0, 3);
          setCvc(formattedCvc);
     };

     const handleSubmit = (e) => {
          e.preventDefault();
          // handle form submission here
          // console.log(cardNumber, '\n', expiry, '\n', cvc)
     };

     return (
          <Fragment>
               <div
                    className="modal"
                    style={{ display: 'block' }}
               >
                    <div className="modal-dialog modal-fullscreen-md-down align-items-center">
                         <div className="modal-content bg-black overflow-hidden rounded-xl">
                              <div className="modal-header justify-between border-light text-light p-0 m-0">
                                   <h6 className="modal-title text-sm m-0 ps-3 py-3" style={{
                                        width: '200px',
                                   }}>PAY WITH</h6>
                                   <div className="bg-dark ps-3 py-3" style={{
                                        width: '100%',
                                        height: '100%'
                                   }}>
                                        ISCE
                                   </div>

                              </div>
                              <div className="modal-body p-0 bg-dark">
                                   <div className="d-flex align-items-start">
                                        <div className="nav flex-column  nav-pills bg-black d-none d-md-flex flex-shrink-0 m-0 p-0" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{
                                             width: '145px',
                                        }}>
                                             <button className="nav-link my-3 me-2 ps-3 text-white text-start active" id="v-pills-card-tab" data-bs-toggle="pill" data-bs-target="#v-pills-card" type="button" role="tab" aria-controls="v-pills-card" aria-selected="true">
                                                  <i className="fa-solid fa-credit-card"></i>
                                                  <span className="ps-2">
                                                       Card
                                                  </span>
                                             </button>
                                             <button className="nav-link my-3 me-2 ps-3 text-white text-start" id="v-pills-Bank-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Bank" type="button" role="tab" aria-controls="v-pills-Bank" aria-selected="false">
                                                  <i className="fa-solid fa-building-columns"></i>
                                                  <span className="ps-2">
                                                       Bank
                                                  </span>
                                             </button>
                                             <button className="nav-link my-3 me-2 ps-3 text-white text-start" id="v-pills-transfer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-transfer" type="button" role="tab" aria-controls="v-pills-transfer" aria-selected="false">
                                                  <i className="fa-solid fa-money-bill-transfer"></i>
                                                  <span className="ps-2">
                                                       Transfer
                                                  </span>
                                             </button>
                                             <button className="nav-link my-3 me-2 ps-3 text-white text-start" id="v-pills-ussd-tab" data-bs-toggle="pill" data-bs-target="#v-pills-ussd" type="button" role="tab" aria-controls="v-pills-ussd" aria-selected="false">
                                                  <i className="fa-solid fa-asterisk"></i>
                                                  <span className="ps-2">
                                                       USSD
                                                  </span>
                                             </button>
                                        </div>
                                        <div className="tab-content m-2" id="v-pills-tabContent">
                                             <div className="p-2 tab-pane fade show active text-white" id="v-pills-card" role="tabpanel" aria-labelledby="v-pills-card-tab" tabIndex="0">
                                                  <form onSubmit={handleSubmit} className="fs-sm row">
                                                       <div className="col-12 mb-2 text-center text-uppercase">
                                                            Your payment details
                                                       </div>
                                                       <div className="mb-2 col-12">
                                                            <label htmlFor="cardNumber">Card Number</label>
                                                            <input
                                                                 type="text"
                                                                 id="cardNumber"
                                                                 value={cardNumber}
                                                                 onChange={handleCardNumberChange}
                                                                 placeholder="**** **** **** ****"
                                                                 maxLength="19"
                                                                 autoComplete="off"
                                                                 required
                                                                 className="w-100 p-2"
                                                            />
                                                       </div>
                                                       <div className="col-12">
                                                            <div className="mb-4 row">
                                                                 <div className="d-flex flex-column w-50">
                                                                      <label htmlFor="expiry">Expiration Date</label>
                                                                      <input
                                                                           type="text"
                                                                           id="expiry"
                                                                           value={expiry}
                                                                           onChange={handleExpiryChange}
                                                                           placeholder="MM/YY"
                                                                           maxLength="5"
                                                                           autoComplete="off"
                                                                           required
                                                                           className='p-2'
                                                                      />
                                                                 </div>
                                                                 <div className="d-flex flex-column w-50">
                                                                      <label htmlFor="cvc">CVC</label>
                                                                      <input
                                                                           type="text"
                                                                           id="cvc"
                                                                           value={cvc}
                                                                           onChange={handleCvcChange}
                                                                           placeholder="***"
                                                                           maxLength="3"
                                                                           autoComplete="off"
                                                                           required
                                                                           className='p-2'
                                                                      />
                                                                 </div>
                                                            </div>
                                                            <button type='submit' className="col-12 btn bg-black text-white text-center rounded-0">
                                                                 Pay NGN 27,578.00
                                                            </button>
                                                       </div>

                                                  </form>
                                             </div>
                                             <div className="p-2 tab-pane fade" id="v-pills-Bank" role="tabpanel" aria-labelledby="v-pills-Bank-tab" tabIndex="0">...</div>
                                             <div className="p-2 tab-pane fade" id="v-pills-transfer" role="tabpanel" aria-labelledby="v-pills-transfer-tab" tabIndex="0">...</div>
                                             <div className="p-2 tab-pane fade" id="v-pills-ussd" role="tabpanel" aria-labelledby="v-pills-ussd-tab" tabIndex="0">...</div>
                                             <div className="dropdown d-md-none px-2 mt-4 justify-content-center d-flex gap-2">
                                                  <button className="btn bg-black text-white text-uppercase dropdown-toggle mb-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ fontSize: '0.7rem', padding: '10px' }}>
                                                       Change Payment Method
                                                  </button>
                                                  <button className="btn bg-black text-white text-uppercase mb-3" type="button" style={{ fontSize: '0.7rem', padding: '10px' }}>
                                                       Cancel Payment
                                                  </button>
                                                  <ul className="dropdown-menu p-0 overflow-hidden">
                                                       <div className="nav flex-column nav-pills me-3 py-3 pe-3 bg-black dropdown-item" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                            <button className="nav-link text-start active" id="v-pills-card-tab" data-bs-toggle="pill" data-bs-target="#v-pills-card" type="button" role="tab" aria-controls="v-pills-card" aria-selected="true">
                                                                 <i className="fa-solid fa-credit-card"></i>
                                                                 <span className="ps-2">
                                                                      Card
                                                                 </span>
                                                            </button>
                                                            <button className="nav-link text-start" id="v-pills-Bank-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Bank" type="button" role="tab" aria-controls="v-pills-Bank" aria-selected="false">
                                                                 <i className="fa-solid fa-building-columns"></i>
                                                                 <span className="ps-2">
                                                                      Bank
                                                                 </span>
                                                            </button>
                                                            <button className="nav-link text-start" id="v-pills-transfer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-transfer" type="button" role="tab" aria-controls="v-pills-transfer" aria-selected="false">
                                                                 <i className="fa-solid fa-money-bill-transfer"></i>
                                                                 <span className="ps-2">
                                                                      Transfer
                                                                 </span>
                                                            </button>
                                                            <button className="nav-link text-start" id="v-pills-ussd-tab" data-bs-toggle="pill" data-bs-target="#v-pills-ussd" type="button" role="tab" aria-controls="v-pills-ussd" aria-selected="false">
                                                                 <i className="fa-solid fa-asterisk"></i>
                                                                 <span className="ps-2">
                                                                      USSD
                                                                 </span>
                                                            </button>
                                                       </div>
                                                  </ul>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              {/* <div className="modal-footer">
                                   <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                   <button type="button" className="btn btn-primary">Save changes</button>
                              </div> */}
                         </div>
                         <div className="text-center mt-4 text-white">
                              <i className="fa-solid fa-lock me-1"></i> Secured by ISCE
                         </div>
                    </div>
               </div>
          </Fragment>
     )
}
