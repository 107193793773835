import React from 'react'
import { IconsData } from './IconsData'

export default function ICEIcon({
	size = 30,
	fcolor = 'currentColor',
	scolor = '#fff',
	swidth = '5px',
	iconType = 'connect',
}) {
	return (
		<svg style={{ fill: fcolor, stroke: scolor, strokeMiterlimit: 10, strokeWidth: swidth, }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" height={size} >
			<g>
				{
					IconsData[iconType]?.map((icon, index) => {
						if (icon?.property === 'path') {
							return (<React.Fragment key={index}>
								<path d={icon?.value} />
							</React.Fragment>)
						}
						return null;
					})
				}
			</g>
		</svg >
	)
}