import React from 'react'

export default function About() {
     return (
          <section className='py-5' id='about'>
               <div className="container">
                    <div className="row justify-content-center align-items-center" style={{ minHeight: '500px' }}>
                         <div className="text-center w-100">
                              <h1 className="fw-bold isce_h1 mb-3 d-flex justify-content-center align-items-center">
                                   {'About'}
                                   <img src='assets/images/logo/logofull-dark.webp' className='ms-3' alt='ISCE' height={33} />
                              </h1>
                              <p className="mb-5 isce_text">
                                   ISCE is a digital platform that equips you with innovative software and hardware aimed at providing ease, simplicity and security in the digital world.
                              </p>
                         </div>
                         <div className="parallax" style={{
                              minHeight: '500px',
                              backgroundImage: "url('assets/images/parallax/isce1.webp')"
                         }}>
                         </div>
                    </div>
               </div>
          </section>
     )
}
