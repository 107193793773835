import React from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../../Routes'
import DeleteContact from './DeleteContact'
import DownloadContact from './DownloadContact'


export default function ContactCard({ data }) {

     return (
          <div className="">
               <div className="d-flex align-items-center position-relative">
                    <div className="py-2 pe-2">
                         <Link className='text-decoration-none' to={ROUTES.link.user_contact_view.replace(':id', data.contact_id)}>
                              <img className='rounded-circle me-2' src={'https://avatars.dicebear.com/api/initials/' + data.full_name + '.svg'} alt='' style={{ width: 40, height: 40 }} />
                         </Link>
                    </div>
                    <div className="py-2 flex-grow-1 ">
                         <Link className='text-decoration-none' to={ROUTES.link.user_contact_view.replace(':id', data.contact_id)}>
                              <div className='truncate-md'>{data.full_name}</div>
                              <div className='ts-sm'>{data.title || data?.phone}</div>
                         </Link>
                    </div>
                    <div className="">
                         <DeleteContact contactId={data.contact_id} reload />
                         <DownloadContact contactId={data.contact_id} name={data.full_name} />
                    </div>
               </div>
          </div>
     )
}
