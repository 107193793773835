import React from 'react'

function DasboardCard(props) {
     return (
          <div className='col p-1'>
               <div className={'text-start sc-card rounded p-2 position-relative ' + props.color}>
                    <div className='db-cards-content text-white bottom-elememts position-absolute'>
                         <div className='pb-2'><i className={props.icon + ' fa-3x'}></i></div>
                         <div className='pb-1 fw-bold text-uppercase'>{props.name}</div>
                         <div className='pb-1'>{props.description}</div>
                    </div>
               </div>
          </div>
     )
}

export default DasboardCard