let errors = {};

export const validateFullName = (event, setErrors) => {
     if (!event.target.value) {
          setErrors({ ...errors, fullName: 'Full name is required' });
     } else if (!/^[a-zA-Z ]+$/.test(event.target.value)) {
          setErrors({ ...errors, fullName: 'Full name must only contain alphabets' });
     } else if (event.target.value.split(" ").filter(e => e.trim()).length < 2) {
          setErrors({ ...errors, fullName: 'Full name must be at least two words' });
     } else {
          setErrors({ ...errors, fullName: '' });
     }
}

export const validateTitle = (event, setErrors) => {
     if (!event.target.value) {
          setErrors({ ...errors, title: 'Job Title is required' });
     } else if (!/^[a-zA-Z\s]+$/.test(event.target.value)) {
          setErrors({ ...errors, title: 'Job Title can only contain alphabets' });
     } else if (event.target.value.length < 3) {
          setErrors({ ...errors, title: 'Job Title must be at least 4 characters' });
     } else {
          setErrors({ ...errors, title: '' });
     }
}

export const validatePhone = (event, setErrors) => {
     if (!event.target.value) {
          setErrors({ ...errors, phone: 'Phone number is required' });
     } else if (!/^\+?[0-9]{9,15}$/.test(event.target.value)) {
          setErrors({ ...errors, phone: 'Phone number must contain between 9 and 15 digits' });
     } else {
          setErrors({ ...errors, phone: '' });
     }
}

export const validateEmail = (event, setErrors) => {
     if (!event.target.value) {
          setErrors({ ...errors, email: 'Email is required' });
     } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
          setErrors({ ...errors, email: 'Email is not valid' });
     } else {
          setErrors({ ...errors, email: '' });
     }
}