import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import Navbar from "../home/Navbar";
import styles from "./styles.module.css";
import ICEIcon from "../../components/icons/ICEIcon";
import axios from "../../hooks/axios";
import { Link } from "react-router-dom";

const PaymentStatus = ({ status }) => {
     const isSuccess = status === "success";
     const isDeclined = status === "declined";

     const messageClasses = classNames({
          "text-success": isSuccess,
          "text-danger": isDeclined,
          "text-secondary": !isSuccess && !isDeclined,
     });

     const message =
          isSuccess ? "Payment Successful!" : isDeclined ? "Payment Declined." : "Loading...";

     return (
          <div className="text-center" style={{
               paddingTop: '50px',
               paddingBottom: '50px',
          }}>

               <div className={`rounded-circle d-flex justify-content-center align-items-center mx-auto mb-3 ${isSuccess ? 'bg-success' : isDeclined ? 'bg-danger' : 'bg-secondary'}`} style={{
                    height: '100px',
                    width: '100px',
               }}>
                    {
                         isSuccess
                              ? <ICEIcon iconType="check" fcolor="#fff" size={60} /> :
                              isDeclined
                                   ? <ICEIcon iconType="close" fcolor="#fff" size={60} /> :
                                   <ICEIcon iconType="refresh" fcolor="#fff" size={60} />
                    }
               </div>

               <h4 className={messageClasses}>{message}</h4>
               <p className="text-white mx-auto" style={{ fontSize: '12px ', maxWidth: '250px' }}>
                    {isSuccess ? "Your payment was succesful, you ISCE card is on its way." : isDeclined ? "Your payment was not successful, please check the cart to remake payment" : "Checking Transaction"}

               </p>
               {/* {
                    (isDeclined || isSuccess) &&
                    <Link to='/payment/order-details' className="text-decoration-none">
                         <div className={`${isSuccess ? styles.sucBtn : isDeclined ? styles.decBtn : styles.othBtn} ${buttonClasses}`}>
                              {isSuccess ? "View Order" : isDeclined ? "Remake Payment" : ""}
                         </div>
                    </Link>
               } */}
               <Link to='/' className="text-decoration-none">
                    <div className={'text-uppercase mt-3 text-white'} style={{ fontSize: '10px' }}>
                         Go to homepage
                    </div>
               </Link>
          </div>
     );
};

const PaymentStatusPage = () => {
     const [paymentStatus, setPaymentStatus] = useState('');

     useEffect(() => {
          const getTransactionRefFromLocalStorage = () => {
               const transactionRef = localStorage.getItem('transactionRef');
               if (transactionRef) {
                    return JSON.parse(transactionRef);
               } else {
                    return [];
               }
          };
          const transactionRef = getTransactionRefFromLocalStorage()

          if (transactionRef) {
               const statusUrl = `https://developer.isce.app/v1/connect/api/orders/${transactionRef}`;
               axios.get(statusUrl)
                    .then(response => {
                         const status = response.data;
                         // console.log(status)
                         status.success === 'true' && setPaymentStatus('success');
                         status.success === 'false' && setPaymentStatus('declined');
                    })
                    .catch(error => {
                         // console.error(error);
                    });
          }
     }, []);

     return (
          <Fragment>
               <Navbar />
               <div className="min-vh-100 pt-5 bg-black">
                    <div className="container">
                         <div className={styles.zoomIn}>
                              <div className={styles.status}>
                                   <PaymentStatus status={paymentStatus} />
                              </div>
                         </div>
                    </div>
               </div>
          </Fragment>
     );
};

export default PaymentStatusPage;