import React, { useState } from 'react'
import Navbar from '../Navbar'
import ROUTES from '../../../Routes'
import { checkValidity, passwordValidationSchema } from '../../../helpers/forms/validator.schema'
import ProgressBar from '../../../components/progressBar/ProgressBar'

const OnboardingStepTwo = ({ query, inputChange, nextStep, user }) => {
     const [password, setPassword] = useState("")
     const [confirmPassword, setConfirmpassword] = useState("");
     const [errors, setErrors] = useState([]);

     const handleSubmit = async (e) => {
          e.preventDefault();
          const isValid = await checkValidity(passwordValidationSchema, { password, confirm_password: confirmPassword });

          if (isValid?.success === false) {
               setErrors(isValid.errors);
               return null;
          }

          inputChange({
               user: {
                    ...user,
                    password
               }
          });
          nextStep();
     }

     return (
          <div>
               <div className=''>
                    <div className='text-center' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image'>
                                   <img src="assets/images/background/green.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'center center'
                                   }} />
                              </div>
                              <div className='col-md login-screen-mobile h-screen'>
                                   <Navbar
                                        backlink={'/' + ROUTES?.onboarding?.path + query?.link}
                                   />
                                   <div className="d-flex justify-content-center flex-column py-5 mx-auto" style={{ height: 630, maxWidth: 500 }}>
                                        <div className='pt-5'>
                                             <h4 className='fw-bold fs-5'>Choose a Password</h4>
                                             <span className='ts-sm'>Must be at least 8 characters</span>
                                        </div>
                                        <form className='pt-4' onSubmit={handleSubmit}>
                                             <div className="d-grid gap-2">
                                                  <input className='form-control border-dark' autoComplete='new-password' type={'password'} placeholder='Password' onChange={e => setPassword(e.target.value)} value={password} />
                                                  <input className='form-control border-dark' autoComplete='new-password' type={'password'} placeholder='Retype Pasword' onChange={e => setConfirmpassword(e.target.value)} value={confirmPassword} />
                                                  {
                                                       errors.map((error, index) => {
                                                            return <small key={'error-' + index} className='text-start text-danger p-0'>{error}</small>
                                                       })
                                                  }
                                             </div>

                                             <div className='py-4 d-grid'>
                                                  <button type='submit' className='btn btn-dark'>Next</button>
                                             </div>
                                        </form>
                                        <ProgressBar currentState={'2'} />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default OnboardingStepTwo

const Styles = {
     height: '100vh'
}