import React from 'react'
//import EventImage from '../../../images/cover-image-white-music.jpg'
//import { axiosWithAuth } from '../../../hooks/axios'
//import { API, REQUEST2 } from '../../../api/Request'
import { httpNoInterceptor } from '../../../../http-common'
import { API, REQUEST2 } from '../../../../api/Request'
import LoadingIndicator from '../../../../components/LoadingIndicator.component'
import EventToken from '../EventToken'
import { Link } from 'react-router-dom'
import ROUTES from '../../../../Routes'
import { displayDate, checkDate } from '../../../../helpers/functions.helpers'
import EventOpenRegister from './EventOpenRegister'
import EventOpenAttendees from './EventOpenAttendees'
import ICEIcon from '../../../../components/icons/ICEIcon'
import { SuperSEO } from 'react-super-seo'

const TestImg = 'https://via.placeholder.com/200'
const EventOpen = () => {
     const [data, setData] = React.useState(null);
     const [id, goto] = window.location.pathname.split('/').filter((path) => {
          return !['', 'events', 'l'].includes(path)
     });

     React.useEffect(() => {
          const fetchUserEvents = async (id) => {
               const link = API.events.card_get_open + '?id=' + encodeURIComponent(id);
               const { data: response } = await httpNoInterceptor({
                    baseURL: REQUEST2.url
               }).get(link);
               if (response?.success === 'true') {
                    setData(response?.data);
               }
          }
          fetchUserEvents(id);
     }, [id]);

     const current_event = data?.event;
     // console.log(current_event);
     if (!current_event) return <LoadingIndicator />

     if (checkDate(data?.event?.start_date) > 0) { //||======>> THIS EVENT IS IN THE PAST <<===========||
          return (
               <section className="tabs_page h-screen overflow-scroll scrollbar-hidden mx-auto px-3" onContextMenu={(e) => e.preventDefault()}>
                    <SuperSEO
                         title={data?.event?.title + ' Powered By ISCE Events'}
                         description={'Your Passport to the ISCE Ecosysytem'}
                         lang="en"
                         openGraph={{
                              ogImage: {
                                   ogImage: data?.event?.image,
                                   ogImageAlt: 'isce nfc card',
                                   ogImageWidth: 1080,
                                   ogImageHeight: 720,
                                   ogImageType: 'image/jpeg',
                              },
                         }}
                         twitter={{
                              twitterSummaryCard: {
                                   summaryCardImage: data?.event?.image,
                                   summaryCardImageAlt: 'isce nfc card',
                                   summaryCardSiteUsername: 'isceapp',
                              },
                         }}
                    />
                    <div className=''>
                         <div className=''>
                              <div className="pt-3">
                                   <img
                                        className='rounded'
                                        alt=''
                                        src={data?.event?.image}
                                        style={{
                                             height: '300px',
                                             width: '100%',
                                             objectFit: 'cover',
                                             objectPosition: 'top',
                                        }}
                                   />
                              </div>
                              <div className=''>
                                   <h3 className="my-3 text-center text-uppercase">This event has been concluded</h3>
                                   <hr />
                                   <div className='text-uppercase'><span className='fw-bold'>Event Name: </span>{data?.event?.title}</div>
                                   <div className='text-uppercase'><span className='fw-bold'>Event Date: </span>{displayDate(data?.event?.start_date)}</div>
                                   <div className=''><span className='fw-bold text-uppercase'>Event Description: </span>{data?.event?.description}</div>
                                   <blockquote>POWERED BY ISCE EVENTS</blockquote>
                                   <div className="text-center text-uppercase">Contact us to Get A Card Now.</div>
                                   <div className="row px-2 py-2 mt-2">
                                        <div className="d-grid col-md mb-3">
                                             <a href='https://wa.me/2348124339827' className='btn bg-black text-white'>
                                                  <ICEIcon iconType='whatsapp' size={40} />
                                             </a>
                                        </div>
                                        <div className="d-grid col-md mb-3">
                                             <a href='mailto:support@isce.app' className='btn bg-black text-white'>
                                                  <ICEIcon iconType='mail' size={40} />
                                             </a>
                                        </div>
                                        <div className="d-grid col-md mb-3">
                                             <a href='tel:08124339827' className='btn bg-black text-white'>
                                                  <ICEIcon iconType='phone' size={40} />
                                             </a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          )
     }


     if (checkDate(data?.event?.start_date) < 0) { //||======>> THIS EVENT IS IN THE FUTURE <<===========||
          return (
               <section className="tabs_page h-screen overflow-scroll scrollbar-hidden mx-auto px-3" onContextMenu={(e) => e.preventDefault()}>
                    <SuperSEO
                         title={data?.event?.title + ' Powered By ISCE Events'}
                         description={'Your Passport to the ISCE Ecosysytem'}
                         lang="en"
                         openGraph={{
                              ogImage: {
                                   ogImage: data?.event?.image,
                                   ogImageAlt: 'isce nfc card',
                                   ogImageWidth: 1080,
                                   ogImageHeight: 720,
                                   ogImageType: 'image/jpeg',
                              },
                         }}
                         twitter={{
                              twitterSummaryCard: {
                                   summaryCardImage: data?.event?.image,
                                   summaryCardImageAlt: 'isce nfc card',
                                   summaryCardSiteUsername: 'isceapp',
                              },
                         }}
                    />
                    <div className=''>
                         <div className=''>
                              <div className="pt-3">
                                   <img
                                        className='rounded'
                                        alt=''
                                        src={data?.event?.image}
                                        style={{
                                             height: '300px',
                                             width: '100%',
                                             objectFit: 'cover',
                                             objectPosition: 'top',
                                        }}
                                   />
                              </div>
                              <div className=''>
                                   <h3 className="my-3 text-center text-uppercase">This event is yet to start</h3>
                                   <hr />
                                   <div className='text-uppercase'><span className='fw-bold'>Event Name: </span>{data?.event?.title}</div>
                                   <div className='text-uppercase'><span className='fw-bold'>Event Date: </span>{displayDate(data?.event?.start_date)}</div>
                                   <div className=''><span className='fw-bold text-uppercase'>Event Description: </span>{data?.event?.description}</div>

                                   <div className="d-grid text-center my-3">
                                        <Link className='text-decoration-none text-white p-0' to={ROUTES.link.event_open_register.replace(':id', current_event?.clean_name)}>
                                             <div className='rounded ts-md bg-black p-2'>REGISTER NOW</div>
                                        </Link>
                                   </div>
                                   <div className="mx-2 rounded border border-1 shadow-mini p-3 mb-3">
                                        <div className='ticket-card ts-md fw-bold mb-2'>
                                             <span><ICEIcon iconType='ticket' size={20} /> Tickets</span>
                                        </div>
                                        {
                                             current_event?.prices?.map((price, index) => {
                                                  return (
                                                       <div className='d-flex justify-content-between ms-3 border-1 border-bottom mb-2' key={`${price?.title}_${index}`} data-id={`${index}`}>
                                                            <div>{price?.title} - ₦{price?.amount}</div>
                                                            <div>{price?.attendees} in stock</div>
                                                       </div>
                                                  );
                                             })
                                        }
                                   </div>
                                   <div className="mb-3 px-2">
                                        <div className="d-flex justify-content-between">
                                             <div className='ticket-card ts-md fw-bold mb-2'>
                                                  <span><ICEIcon iconType='attendees' size={20} /> Attendees</span>
                                             </div>
                                             <div className="ts-md">
                                                  <span className='fw-bold'>{current_event?.attendees?.length}</span>
                                                  <span className="ps-1">attending</span>
                                             </div>

                                        </div>
                                        <div className='position-relative attendees_4 w-100'>
                                             {
                                                  !current_event?.attendees?.length && <div className="text-center">No attendees yet</div>
                                             }
                                             {
                                                  current_event?.attendees?.slice(0, 4)?.map((attendee, index) => {
                                                       return (
                                                            <div
                                                                 key={`attendee_${index}_${current_event?.id}`}
                                                                 className={`attendee_card border border-3 rounded-circle shadow-sm position-absolute sphere${index + 1}`}
                                                            >
                                                                 <img
                                                                      src={attendee?.image || TestImg}
                                                                      className='img-fluid rounded-circle'
                                                                      alt='attendees'
                                                                 />
                                                            </div>
                                                       );
                                                  })
                                             }
                                        </div>
                                   </div>
                                   {
                                        current_event?.gallery.length > 0 &&
                                        <div className="mb-3 px-2">
                                             <div className='ticket-card ts-md fw-bold mb-2'>
                                                  <span><ICEIcon iconType='collage' size={20} /> Media Gallery</span>
                                             </div>
                                             <div className="row">
                                                  {
                                                       current_event?.gallery?.map((item, index) => {
                                                            return (
                                                                 <div key={`gallery_view_${index}_${current_event?.id}`} className='col-4 mb-2 rounded overflow-hidden'>
                                                                      <div className="text-center border border-1 rounded overflow-hidden">
                                                                           <img src={item?.image || TestImg} className="w-100 mx-auto" style={{ height: '100px', objectFit: 'cover' }} alt="preview"></img>
                                                                      </div>
                                                                 </div>
                                                            );
                                                       })
                                                  }
                                             </div>
                                             {/* <div className='mt-2 bg-black text-white'>View All</div> */}
                                        </div>
                                   }
                                   <blockquote className=''>POWERED BY ISCE EVENTS</blockquote>
                              </div>
                         </div>
                    </div>
               </section>
          )
     }

     if (goto === 'register') {
          return <EventOpenRegister
               event={current_event || null}
          />
     }

     if (goto === 'token') {
          return <EventToken
               event={current_event || null}
          />
     }

     if (goto === 'attendees') {
          return <EventOpenAttendees
               event={current_event || null}
          />
     }

     return ( //||======>> THIS EVENT IS IN TODAY <<===========||
          <section className="tabs_page h-screen overflow-scroll scrollbar-hidden mx-auto px-3" onContextMenu={(e) => e.preventDefault()}>
               <SuperSEO
                    title={data?.event?.title + ' Powered By ISCE Events'}
                    description={'Your Passport to the ISCE Ecosysytem'}
                    lang="en"
                    openGraph={{
                         ogImage: {
                              ogImage: data?.event?.image,
                              ogImageAlt: 'isce nfc card',
                              ogImageWidth: 1080,
                              ogImageHeight: 720,
                              ogImageType: 'image/jpeg',
                         },
                    }}
                    twitter={{
                         twitterSummaryCard: {
                              summaryCardImage: data?.event?.image,
                              summaryCardImageAlt: 'isce nfc card',
                              summaryCardSiteUsername: 'isceapp',
                         },
                    }}
               />
               <div className=''>
                    <div className='mb-2'>
                         <div className="pt-3">
                              <img
                                   className='rounded'
                                   alt=''
                                   src={data?.event?.image}
                                   style={{
                                        height: '300px',
                                        width: '100%',
                                        objectFit: 'cover',
                                        objectPosition: 'top',
                                   }}
                              />
                         </div>
                         <div className=''>
                              <hr />
                              <div className='text-uppercase'><span className='fw-bold'>Event Name: </span>{data?.event?.title}</div>
                              <div className='text-uppercase'><span className='fw-bold'>Event Date: </span>{displayDate(data?.event?.start_date)}</div>
                              <div className=''><span className='fw-bold text-uppercase'>Event Description: </span>{data?.event?.description}</div>

                              <div className="d-grid text-center my-3">
                                   <Link className='text-decoration-none text-white p-0' to={ROUTES.link.event_open_register.replace(':id', current_event?.clean_name)}>
                                        <div className='rounded ts-md bg-black p-2'>REGISTER NOW</div>
                                   </Link>
                              </div>
                              <div className="mx-2 rounded border border-1 shadow-mini p-3 mb-3">
                                   <div className='ticket-card ts-md fw-bold mb-2'>
                                        <span><ICEIcon iconType='ticket' size={20} /> Tickets</span>
                                   </div>
                                   {
                                        current_event?.prices?.map((price, index) => {
                                             return (
                                                  <div className='d-flex justify-content-between ms-3 border-1 border-bottom mb-2' key={`${price?.title}_${index}`} data-id={`${index}`}>
                                                       <div>{price?.title} - ₦{price?.amount}</div>
                                                       <div>{price?.attendees} in stock</div>
                                                  </div>
                                             );
                                        })
                                   }
                              </div>
                              <div className="mb-3 px-2">
                                   <div className="d-flex justify-content-between">
                                        <div className='ticket-card ts-md fw-bold mb-2'>
                                             <span><ICEIcon iconType='attendees' size={20} /> Attendees</span>
                                        </div>
                                        <div className="ts-md">
                                             <span className='fw-bold'>{current_event?.attendees?.length}</span>
                                             <span className="ps-1">attending</span>
                                        </div>

                                   </div>
                                   <div className='position-relative attendees_4 w-100'>
                                        {
                                             !current_event?.attendees?.length && <div className="text-center">No attendees yet</div>
                                        }
                                        {
                                             current_event?.attendees?.slice(0, 4)?.map((attendee, index) => {
                                                  return (
                                                       <div
                                                            key={`attendee_${index}_${current_event?.id}`}
                                                            className={`attendee_card border border-3 rounded-circle shadow-sm position-absolute sphere${index + 1}`}
                                                       >
                                                            <img
                                                                 src={attendee?.image || TestImg}
                                                                 className='img-fluid rounded-circle'
                                                                 alt='attendees'
                                                            />
                                                       </div>
                                                  );
                                             })
                                        }
                                   </div>
                              </div>
                              {
                                   current_event?.gallery.length > 0 &&
                                   <div className="mb-3 px-2">
                                        <div className='ticket-card ts-md fw-bold mb-2'>
                                             <span><ICEIcon iconType='collage' size={20} /> Media Gallery</span>
                                        </div>
                                        <div className="row">
                                             {
                                                  current_event?.gallery?.map((item, index) => {
                                                       return (
                                                            <div key={`gallery_view_${index}_${current_event?.id}`} className='col-4 mb-2 rounded overflow-hidden'>
                                                                 <div className="text-center border border-1 rounded overflow-hidden">
                                                                      <img src={item?.image || TestImg} className="w-100 mx-auto" style={{ height: '100px', objectFit: 'cover' }} alt="preview"></img>
                                                                 </div>
                                                            </div>
                                                       );
                                                  })
                                             }
                                        </div>
                                        {/* <div className='mt-2 bg-black text-white'>View All</div> */}
                                   </div>
                              }
                              <blockquote className=''>POWERED BY ISCE EVENTS</blockquote>
                         </div>
                    </div>
               </div>
          </section>
     )
}

export default EventOpen