import React from 'react'
import { Link } from 'react-router-dom'
import ICEIcon from '../../../components/icons/ICEIcon'
import { displayDate } from '../../../helpers/functions.helpers'
import { getCardQueryParams } from '../../../helpers/queryParams'
import ROUTES from '../../../Routes'
import ContactUs from '../component/ContactUs'
import SideBarVC from '../component/SideBarVC'

const TestImg = 'https://isce-image.fra1.digitaloceanspaces.com/public/Rectangle%20138.jpg';

const ViewEventVC = ({ event }) => {
     const query = getCardQueryParams();

     return (
          <>
               <div className="container mx-auto p-0 h-screen">
                    <div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
                         maxWidth: '1080px'
                    }}>
                         <SideBarVC />
                         <div className="col overflow-scroll h-100 border-0 border-start border-end py-3">
                              <div className='px-2 mb-3 scrollbar-hidden mx-auto'>
                                   <div className="py-3 d-flex justify-content-between">
                                        <div>
                                             <span className=''>
                                                  <Link to={ROUTES.link.vcard_event + query?.params_link} className='text-decoration-none btn btn-light'>
                                                       <ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Events</span>
                                                  </Link>
                                             </span>
                                        </div>
                                        <div>
                                             <span className='d-md-none'>
                                                  <Link to={ROUTES.link.vcard_dashboard + query?.params_link} className='text-decoration-none'>
                                                       <ICEIcon iconType='dashboard' size={20} />
                                                       {/* <span className='ts-sm'>Dashboard</span> */}
                                                  </Link>
                                             </span>
                                        </div>
                                   </div>
                                   {
                                        <div className='mb-2'>
                                             <div className='latest_card position-relative my-2 rounded overflow-hidden'>
                                                  <Link className='event-image' to={ROUTES.link.vcard_event_view.replace(':id', event?.id) + query?.params_link}>
                                                       <img className='img-fluid' alt='' src={event?.image} style={{ width: '100%', height: '300px', objectFit: 'cover', objectPosition: 'top' }} />
                                                  </Link>
                                                  <div className='position-absolute bottom-0 latest-card-details w-100 bg-black text-white'>
                                                       <div className='p-2'>
                                                            <div className="d-grid text-start">
                                                                 <Link className='fw-bold text-white text-decoration-none' to={ROUTES.link.vcard_event_view.replace(':id', event?.id) + query?.params_link}>
                                                                      <div className='fw-bold ts-md text-uppercase'>{event?.title}</div>
                                                                 </Link>
                                                                 <div className='ts-sm'>{event?.description}</div>
                                                                 <div className='ts-md text-uppercase'>{displayDate(event?.start_date)}</div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="d-grid text-center mx-2 mb-3">
                                                  <Link className='text-decoration-none text-white' to={ROUTES.link.vcard_request_card.replace(':id', event?.id) + query?.params_link}>
                                                       <div className='rounded ts-md agrad p-2'>Register</div>
                                                  </Link>
                                             </div>
                                             <div className="mx-2 rounded border border-1 shadow-mini p-3 mb-3">
                                                  <div className='ticket-card ts-md fw-bold mb-2'>
                                                       <span><ICEIcon iconType='ticket' size={20} /> Tickets</span>
                                                  </div>
                                                  {
                                                       event?.prices?.map((price, index) => {
                                                            return (
                                                                 <div className='d-flex justify-content-between ms-3 border-1 border-bottom mb-2' key={`${price?.title}_${index}`} data-id={`${index}`}>
                                                                      <div>{price?.title} - ₦{price?.amount}</div>
                                                                      <div>{price?.attendees} in stock</div>
                                                                 </div>
                                                            );
                                                       })
                                                  }
                                             </div>
                                             <div className="mb-3">
                                                  <div className='ticket-card ts-md fw-bold mb-2'>
                                                       <span><ICEIcon iconType='attendees' size={20} /> Attendees</span>
                                                  </div>
                                                  <div className='position-relative attendees_4 w-100'>
                                                       {
                                                            !event?.attendees?.length && <div className="text-center">No attendees yet</div>
                                                       }
                                                       {
                                                            event?.attendees?.slice(0, 4)?.map((attendee, index) => {
                                                                 return (
                                                                      <div
                                                                           key={`attendee_${index}_${event?.id}`}
                                                                           className={`attendee_card border border-3 rounded-circle shadow-sm position-absolute sphere${index + 1}`}
                                                                      >
                                                                           <img
                                                                                src={attendee?.image || TestImg}
                                                                                className='img-fluid rounded-circle'
                                                                                alt='attendees'
                                                                           />
                                                                      </div>
                                                                 );
                                                            })
                                                       }
                                                  </div>
                                             </div>
                                             <div className="mb-3">
                                                  <div className='ticket-card ts-md fw-bold mb-2'>
                                                       <span><ICEIcon iconType='collage' size={20} /> Media Gallery</span>
                                                  </div>
                                                  <div className="row">
                                                       {
                                                            event?.gallery?.map((item, index) => {
                                                                 return (
                                                                      <div key={`gallery_view_${index}_${event?.id}`} className='col-4 mb-2 rounded overflow-hidden'>
                                                                           <div className="text-center border border-1 rounded overflow-hidden">
                                                                                <img src={item?.image || TestImg} className="w-100 mx-auto" style={{ height: '100px', objectFit: 'cover' }} alt="preview"></img>
                                                                           </div>
                                                                      </div>
                                                                 );
                                                            })
                                                       }
                                                  </div>
                                                  {/* <div className='mt-2 bg-black text-white'>View All</div> */}
                                             </div>
                                        </div>
                                   }
                              </div>
                         </div>

                         <ContactUs />
                    </div>
               </div>
          </>
     )
}

export default ViewEventVC