import React from 'react'
import EventCard from './EventCard'
import { Link } from 'react-router-dom'

import ROUTES from '../../../Routes'

import EventsService from '../../../services/events.service';
import ICEIcon from '../../../components/icons/ICEIcon'
import { displayDate, truncate } from '../../../helpers/functions.helpers'
import AddEvent from './AddEvent'
import SideBar from '../component/nav/SideBar';
import Notification from '../component/Notification';

function Event() {
	const [data, setData] = React.useState(null);
	const [latestEvent, setLatestEvent] = React.useState(null);
	const [counter, setCounter] = React.useState(0);
	const path = window.location.pathname;

	React.useEffect(() => {
		const fetchUserEvents = async () => {
			const { data: response } = await EventsService.getAll();
			if (response?.success === 'true') {
				setData(response?.data);
				setLatestEvent(response?.data?.upcoming[0]);
			}
		}
		fetchUserEvents();
	}, []);

	const forceUpdate = () => {
		setCounter(counter + 1);
	}

	const rerender = () => {
		forceUpdate();
	}


	if (path === ROUTES.link?.new_event) {
		return <AddEvent user={data?.user} />
	}

	return (
		<div className="container mx-auto p-0 h-screen">
			<div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
				maxWidth: '1080px'
			}}>
				<SideBar />

				<div className="col overflow-scroll h-100 border-0 border-start border-end">

					<div className="py-2 d-flex justify-content-between">
						<div>
							<span className='d-md-none'>
								<Link to={'/dashboard'} className='text-decoration-none'>
									<ICEIcon iconType='dashboard' size={20} /> <span className='ts-sm'>Dashboard</span>
								</Link>
							</span>
						</div>
					</div>
					<div>
						<div className='mb-3 mx-auto overflow-hidden'>
							<div className='d-flex justify-content-between'>
								<div className='fs-4 fw-light'>Events</div>
								<div className='btn btn-light'>
									<span className='me-1'>
										<ICEIcon size={20} iconType='add' fcolor={'green'} />
									</span>
									<Link className='text-decoration-none text-black' to={ROUTES.link.new_event}><span className='ts-sm'>Create Event</span></Link>
								</div>
							</div>
						</div>
					</div>

					{
						data?.all?.length === 0 &&
						<div className="mb-3 mx-auto text-center">
							<div className='event_card_pulse rounded-3 mb-3 d-flex justify-content-center align-items-center'>
								No Events For Now. Check back Later
							</div>
						</div>
					}
					{
						!data && !latestEvent &&
						<div className="mb-5 mx-auto ">
							<div className='latest_card_pulse rounded-3 mb-3 pulse'></div>
							<div className='event_card_pulse rounded-3 mb-3 pulse'></div>
							<div className='event_card_pulse rounded-3 mb-3 pulse'></div>
						</div>
					}
					<div>
						{
							latestEvent &&
							<div className='mb-3 mx-auto overflow-hidden'>
								<div className='latest_card rounded-3 position-relative mb-3 overflow-hidden'>
									<Link className='event-image shadow-sm position-relative text-decoration-none' to={ROUTES.link.view_event.replace(':id', latestEvent?.id)}>
										<img src={latestEvent?.image} alt='Latest event' className='h-100 w-100' style={{
											objectFit: 'cover',
											objectPosition: 'top',
										}} />
									</Link>

									<div className='position-absolute bottom-0 latest-card-details w-100 bg-black text-white'>
										<div className='p-2'>
											<div className="text-start">
												<Link className='fw-bold text-white text-decoration-none' to={ROUTES.link.view_event.replace(':id', latestEvent?.id)}>
													<div className='fw-bold ts-sm text-uppercase'>{latestEvent?.title}</div>
												</Link>
												<div className='ts-sm'>{truncate(latestEvent?.description, 100)}</div>
												<div className="d-flex justify-content-between align-items-center">
													<div className='ts-sm text-uppercase'>{displayDate(latestEvent?.start_date)}</div>
													<Link className='text-decoration-none text-white' to={ROUTES.link.get_cards.replace(':id', latestEvent?.id)}>
														<div className='rounded ts-sm text-uppercase bg-primary p-2'>Get Cards</div>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						{data &&
							<div className='mx-auto overflow-hidden'>
								<nav>
									<div className="text-center row mb-3" id="nav-tab" role="tablist">
										<div className='col-6 events_tab'>
											<div className="text-black py-2 rounded-top active" id="nav-upcoming-tab" data-bs-toggle="tab" data-bs-target="#nav-upcoming" role="tab" aria-controls="nav-upcoming" aria-selected="true">
												Upcoming
											</div>
										</div>
										<div className='col-6 events_tab'>
											<div className="text-black py-2 rounded-top " id="nav-past-tab" data-bs-toggle="tab" data-bs-target="#nav-past" role="tab" aria-controls="nav-past" aria-selected="false">
												Past
											</div>
										</div>
									</div>
								</nav>
								<div className="tab-content" id="nav-tabContent">
									<div className="tab-pane fade show active py-2" id="nav-upcoming" role="tabpanel" aria-labelledby="nav-upcoming-tab" tabIndex="0">
										{
											data.upcoming.length !== 0 ?
												data?.upcoming?.map((event) => {
													return <EventCard
														key={event.id}
														event={event}
														rerender={rerender}
													/>
												}) :
												<div className='rounded-3 mb-3 d-flex justify-content-center align-items-center'>
													<div className='text-center'>
														<img
															src='/assets/images/flaticons/flaticons_event_cancelled.svg'
															alt='No Events'
															height={100}
														/>
														<div className="text-center fs-3">No Upcoming Events.</div>
														<div className="text-center">Your upcoming events will appear here</div>
														<div className='btn btn-light'>
															<span className='me-1'>
																<ICEIcon size={20} iconType='add' fcolor={'green'} />
															</span>
															<Link className='text-decoration-none text-black' to={ROUTES.link.new_event}><span className='ts-sm'>Create Event</span></Link>
														</div>
													</div>
												</div>
										}
									</div>
									<div className="tab-pane fade py-2" id="nav-past" role="tabpanel" aria-labelledby="nav-past-tab" tabIndex="0">
										{
											data?.past.length === 0 ?
												<div className='rounded-3 mb-3 d-flex justify-content-center align-items-center'>
													<div className='text-center'>
														<img
															src='/assets/images/flaticons/flaticons_event_cancelled.svg'
															alt='No Events'
															height={100}
														/>
														<div className="text-center fs-3">No Past Events.</div>
														<div className="text-center">Your past events will appear here</div>
														<div className='btn btn-light'>
															<span className='me-1'>
																<ICEIcon size={20} iconType='add' fcolor={'green'} />
															</span>
															<Link className='text-decoration-none text-black' to={ROUTES.link.new_event}><span className='ts-sm'>Create Event</span></Link>
														</div>
													</div>
												</div> :
												data?.past?.map((event) => {
													return <EventCard
														key={event.id}
														event={event}
														rerender={rerender}
														past={true}
													/>
												})
										}
									</div>
								</div>
							</div>
						}
					</div>
				</div>

				<div className="col d-xl-block d-none p-3" style={{
					maxWidth: '300px'
				}}>
					<Notification />
				</div>
			</div>
		</div>
	)
}


export default Event