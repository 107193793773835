import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API, DATA_STORAGE } from '../../../api/Request';
import LoadingIndicator from '../../../components/LoadingIndicator.component';
import axios from '../../../hooks/axios';
import { toast } from 'react-toastify'
import useStorage from '../../../hooks/useStorage';
import { empty } from '../../../helpers/functions.helpers';
import { ONBOARDING } from '../Onboarding';
import ROUTES from '../../../Routes';
import Onboarding from '../onboarding-stepone/Onboarding';
import EnterToken from '../enter-token/EnterToken';


const googleLoginCallback = async (append_link) => {
    let link = API.googleCallback;
    if (append_link) link += append_link;
    return axios.get(link);
}

const GoogleCallback = (props) => {
    const [state, setState] = useState({
        step: 1
    });
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useStorage(DATA_STORAGE.auth, {});

    // On page load, we take "search" parameters 
    // and proxy them to /api/auth/callback on our Laravel API
    useEffect(() => {
        const fetchgoogleCallback = async () => {
            const loggingIn = toast.loading("Logging in...");
            const { data: googleauth } = await googleLoginCallback(location.search);
            setLoading(false);
            setTimeout(() => toast.dismiss(), 2000);

            if (googleauth.success === 'true') {
                toast.update(loggingIn, { render: "Login Success", type: "success", isLoading: false, autoClose: 3000 });
                const auth_response = googleauth.data;
                const auth_token = `${auth_response.token_type} ${auth_response.access_token}`;
                if (empty(auth_response.card_url)) {
                    auth.setData(auth_token);
                    navigate('/' + ROUTES.user_connect.path, { replace: true });
                }
                else {
                    setState({ ...state, step: 'add-device' });
                    //navigate('/' + ROUTES.enterToken.path + auth_response.card_url, { replace: true });
                }
            } else {
                toast.update(loggingIn, { render: "Creating your account...", type: "success", isLoading: false, autoClose: 3000 });
                const auth_response = googleauth.signup;
                if (empty(auth_response)) {
                    toast.update(loggingIn, { render: "Unable to create account!", type: "error", isLoading: false, autoClose: 3000 });
                    navigate('/' + ROUTES.login.path, { replace: true });
                }
                const google_data = {
                    step: 1,
                    card_id: auth_response.card_id,
                    token: auth_response.token,
                    type: auth_response.type,
                    user: {
                        email: auth_response.user.email,
                        auth_type: auth_response.user.auth_type,
                        password: auth_response.user.password,
                    },
                    card: { name: auth_response.user.name, subTitle: '', description: '', phone: '' },
                    image: {
                        image: auth_response.user.profile_image, name: `google_image_${auth_response.user.name}`
                    },
                    nextStep: ONBOARDING.step3
                }
                setState(google_data);
                /* ls.set(
                    (LOCAL_STORAGE_KEY + DATA_STORAGE.signup), google_data);
                navigate(ONBOARDING.enterToken + auth_response.card_url, { replace: true }); */
            }
        }
        fetchgoogleCallback();
    }, [auth, location, navigate, state]);

    if (loading) {
        return <LoadingIndicator />
    }

    switch (state.step) {
        case 1:
            return <Onboarding
                params={state}
            />;
        case 'add-device':
            return <EnterToken />;
        default:
            return <LoadingIndicator />
    }
}

export default GoogleCallback;