import React from 'react';
import { API, REQUEST2 } from '../../../../api/Request';
import { checkValidity, validateEventTokenFormValidation, validationError } from '../../../../helpers/forms/validator.schema';
import { displayDate } from '../../../../helpers/functions.helpers';
import { httpNoInterceptor } from '../../../../http-common';

const EventChipToken = ({ chip_id }) => {
   const [token, setToken] = React.useState(null);
   const [errors, setErrors] = React.useState([]);

   const handleSubmit = async (e) => {
      e.preventDefault();
      const isValid = await checkValidity(validateEventTokenFormValidation, { 'token': token });
      if (isValid?.success === false) {
         setErrors(isValid?.errors);
         return null;
      }

      const fetchUserEvents = async (id) => {
         const link = API.events.event_chip?.replace(':id', id);
         const { data: response } = await httpNoInterceptor({
            baseURL: REQUEST2.url
         }).post(link, { token });
         if (response?.success === 'true') window.location.reload();
      }
      fetchUserEvents(chip_id);
   }

   return (
      <section className='col-xl-4 col-lg-6 col-md-12 px-lg-2 mb-3 mx-auto overflow-hidden'>
         <div className="" style={{
         }}>
            <div className="position-relative bg-black overflow-hidden mb-5" style={{
               height: '300px',
               borderBottomLeftRadius: '20px',
               borderBottomRightRadius: '20px',
            }}>
               <img src="https://isce-image.fra1.digitaloceanspaces.com/public/Rectangle%20138.jpg" alt="" className="position-absolute top-0 w-100" style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  height: '300px',
               }} />
               <div className="d-flex justify-content-between p-3 position-absolute top-0 w-100">
                  <div className="ts-md text-white">{displayDate(new Date())}</div>
                  {/* <div className="ts-md bg-light p-2 rounded-circle"><ICEIcon iconType='close' size={20} fcolor='black' /> </div> */}
               </div>
               <div className="ts-md w-100 bg-black opacity-50 position-absolute bottom-0 text-white p-3 ">
                  <div className="h3 fw-bold mb-3">
                     EVENT ARENA
                  </div>
                  <div className="">
                     Welcome to event arena. Enter your token to join others in having the experience you possibly can.
                  </div>
               </div>
               <div className="ts-md w-100 position-absolute bottom-0 text-white p-3 ">
                  <div className="h3 fw-bold mb-3">
                     EVENT ARENA
                  </div>
                  <div className="">
                     Welcome to event arena. Enter your token to join others in having the experience you possibly can.
                  </div>
               </div>
            </div>
         </div>
         <div className=" mb-4 px-5">
            <label className='ts-sm text-center text-uppercase'>KINDLY ENTER YOUR ACCESS TOKEN FOR THIS EVENT</label>
            <input type="text" className="form-control border-dark" placeholder="Event token" aria-label="Eventtoken" aria-describedby="basic-addon1" onChange={(e) => setToken(e.target.value)} />
            <small className='text-start text-danger ts-sm text-capitalize'>{validationError(errors, 'token')}</small>
         </div>
         <div className="d-grid">
            <button className="btn bg-black text-white mx-5 mb-5" type="button" onClick={handleSubmit}>Next</button>
            <label className='ts-sm text-center mx-4'>An access token is required to physically access this event. if you do not have a token you can generate one now by tapping your isce card again on the event’s barcode to redirect to its registration page. Or follow this invitation link.</label>
         </div>
      </section>
   )
}

export default EventChipToken;

