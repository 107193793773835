import React from 'react'
import { Link, useParams } from 'react-router-dom'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import eventsService from '../../../services/events.service';
import ROUTES from '../../../Routes';
import { displayDate } from '../../../helpers/functions.helpers';
import ICEIcon from '../../../components/icons/ICEIcon';
import Notification from '../component/Notification';
import SideBar from '../component/nav/SideBar';
import { SuperSEO } from 'react-super-seo';

const GetCards = () => {
	const [event, setEvent] = React.useState(null);
	const { id } = useParams();

	React.useEffect(() => {
		const fetchUserEvents = async (id) => {
			const { data: response } = await eventsService.get(id);
			if (response?.success === 'true') {
				setEvent(response?.data);
			}
		}
		fetchUserEvents(id);
	}, [id]);

	return (
		<>
			<div className="container mx-auto p-0 h-screen">
				<div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
					maxWidth: '1080px'
				}}>
					<SuperSEO
						title={"Get Card"}
						description={'Jump on the ISCE ecosystem, get your ISCE card now!!!'}
					></SuperSEO>
					<SideBar />

					<div className="col overflow-scroll h-100 border-0 border-start border-end">
						<div className="py-3 d-flex justify-content-between">
							<Link to={ROUTES.link.view_event.replace(':id', event?.id)} className='text-decoration-none btn btn-light'>
								<ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Events</span>
							</Link>
						</div>
						<div>
							<div className="pt-3">
								<img
									className='rounded'
									alt=''
									src={event?.image}
									style={{
										height: '300px',
										width: '100%',
										objectFit: 'cover',
										objectPosition: 'top',
									}}
								/>
							</div>
							<div className=''>
								<h3 className="my-3 text-center text-uppercase">Get Cards For This Event</h3>
								<hr />
								<div className='text-uppercase'><span className='fw-bold'>Event Name: </span>{event?.title}</div>
								<div className='text-uppercase'><span className='fw-bold'>Event Date: </span>{displayDate(event?.start_date)}</div>
								<div className=''><span className='fw-bold text-uppercase'>Event Description: </span>{event?.description}</div>
								<blockquote>POWERED BY ISCE EVENTS</blockquote>
								<div className="text-center text-uppercase">Contact us to Get A Card Now.</div>
								<div className="row px-2 py-2 mt-2">
									<div className="d-grid col-md mb-3">
										<a href='https://wa.me/2348124339827' className='btn bg-black text-white'>
											<ICEIcon iconType='whatsapp' size={40} />
										</a>
									</div>
									<div className="d-grid col-md mb-3">
										<a href='mailto:support@isce.app' className='btn bg-black text-white'>
											<ICEIcon iconType='mail' size={40} />
										</a>
									</div>
									<div className="d-grid col-md mb-3">
										<a href='tel:08124339827' className='btn bg-black text-white'>
											<ICEIcon iconType='phone' size={40} />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col d-xl-block d-none p-3" style={{
						maxWidth: '300px'
					}}>
						<Notification />
					</div>

				</div>
			</div>
		</>
	)
}

export default GetCards