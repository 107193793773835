import React from 'react'
import './progressBar.css'

const stages = [
     { 'stage': '1', 'title': 'Email' },
     { 'stage': '2', 'title': 'Password' },
     { 'stage': '3', 'title': 'Personal Info' },
     { 'stage': '4', 'title': 'Social' },
     { 'stage': '5', 'title': 'Profile Image' }
]

const ProgressBar = (props) => {
     if (!props) return null;

     const getList = (number) => {
          return stages.map((stage, index) => {
               let dotVal = (index < number) ? 'active' : '';
               return <li key={'scrollspy' + index} className={dotVal}>{stage.title}</li>;
          });
     }

     return (
          <div>
               <ul id='progressbar' hidden={props.currentState < 1}>
                    {getList(props.currentState)}
               </ul>
          </div>
     )
}

export default ProgressBar