import React from 'react'
import { NavLink } from 'react-router-dom'
import ICEIcon from '../../../../components/icons/ICEIcon'
import { notifyPayment, notifyStore } from '../../../../helpers/functions.helpers'
import ROUTES from '../../../../Routes'

export default function NavLinkList() {
     return (
          <>
               <NavLink to={ROUTES.link?.user_connect} className={'side_bar_link d-flex '}>
                    <ICEIcon iconType='connect' /> <span className="py-1">Connect</span>
               </NavLink>
               <NavLink to={ROUTES.link?.user_contact} className={'side_bar_link d-flex '}>
                    <i className="fa-solid fa-address-book fa-2x"></i> <span className="py-1">Contacts</span>
               </NavLink>
               <NavLink to={ROUTES.link?.event} className={'side_bar_link d-flex '}>
                    <ICEIcon iconType='event' />  <span className="py-1">Events</span>
               </NavLink>
               <div onClick={notifyPayment} className={'side_bar_link d-flex text-secondary '}>
                    <ICEIcon iconType='payment' />  <span className="py-1">Payment</span>
               </div>
               <div onClick={notifyStore} className={'side_bar_link d-flex text-secondary '}>
                    <ICEIcon iconType='cart' />  <span className="py-1">Store</span>
               </div>
               <NavLink to={ROUTES.link?.settings} className={'side_bar_link d-flex '}>
                    <ICEIcon iconType='settings' />  <span className="py-1">Settings</span>
               </NavLink>
          </>
     )
}
