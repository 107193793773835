import React from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../../Routes'
import ICEIcon from '../../../components/icons/ICEIcon'
import { SuperSEO } from 'react-super-seo'
import SideBar from '../component/nav/SideBar'
import DeleteContact from '../component/DeleteContact'
import DownloadContact from '../component/DownloadContact'
import Notification from '../component/Notification'


const ViewContact = ({ contact, user }) => {

     const getImage = () => {
          if (!contact) return '';
          const image_name = contact?.full_name?.split(' ').join('-');
          return `https://avatars.dicebear.com/api/initials/${image_name}.svg`;
     }

     return (
          <>
               <div className="container mx-auto p-0 h-screen">
                    <div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
                         maxWidth: '1080px'
                    }}>
                         <SuperSEO
                              title={'ISCE - ' + (contact?.full_name || 'View Contact')}
                         ></SuperSEO>
                         <SideBar />
                         <div className="col overflow-scroll h-100 border-0 border-start border-end">
                              <div className="d-flex justify-content-between pt-2">
                                   <Link to={ROUTES.link.user_contact} className='text-decoration-none btn btn-light'>
                                        <ICEIcon iconType='back' size={25} />
                                   </Link>
                                   <div className="">
                                        <DeleteContact contactId={contact?.contact_id} />
                                   </div>
                              </div>
                              <div className="d-flex pt-2 align-items-top">
                                   <div className='mx-2 shadowinner rounded-circle' style={{ height: 80, width: 80 }}>
                                        <img src={getImage()} alt='' className='img-fluid bg-dark rounded-circle' />
                                   </div>
                                   <div className='text-start text-uppercase ps-3'>
                                        <div className=''>{contact?.full_name}</div>
                                        <a href={`mailto:${contact?.email}?subject=Invitation to join the ISCE Community&body=You have been invited to join the isce community, kindly visit https://www.isce.app/login to get started.`} className='btn ts-sm btn-outline-dark rounded-pill mt-1'>Invite to ISCE</a>
                                   </div>
                              </div>
                              <hr />
                              <div className="row g-1 mb-3">
                                   <div className='col d-grid'>
                                        <a href={`tel:${contact?.phone}`} rel='noreferrer' className='btn ts-md btn-outline-dark rounded mt-1'>
                                             <i className="fa-solid fa-phone fa-md"></i>
                                             <span className='ps-1'>Call</span>
                                        </a>
                                   </div>
                                   <div className='col d-grid'>
                                        <a href={`sms:${contact?.phone}`} rel='noreferrer' className='btn ts-md btn-outline-dark rounded mt-1'>
                                             <i className="fa-solid fa-message fa-md"></i>
                                             <span className='ps-1'>SMS</span>
                                        </a>
                                   </div>
                                   <div className='col d-grid'>
                                        <a href={`mailto:${contact?.email}`} rel='noreferrer' className='btn ts-md btn-outline-dark rounded mt-1'>
                                             <i className="fa-solid fa-envelope fa-md"></i>
                                             <span className='ps-1'>Mail</span>
                                        </a>
                                   </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-top py-2 border-bottom">
                                   <div>
                                        <div className="text-start">Phone Number</div>
                                        <div className="text-muted ts-sm">{contact?.phone}</div>
                                   </div>
                                   <div className="">
                                        <a href={`tel:${contact?.phone}`} className='text-decoration-none btn btn-light me-2'>
                                             <i className="fa-solid fa-phone fa-md"></i>
                                        </a>
                                        <a href={`sms:${contact?.phone}`} className='text-decoration-none btn btn-light'>
                                             <i className="fa-solid fa-message fa-md"></i>
                                        </a>
                                   </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-top py-2 border-bottom mb-5">
                                   <div>
                                        <div className="text-start">Email Address</div>
                                        <div className="text-muted ts-sm">{contact?.email}</div>
                                   </div>
                                   <div className="">
                                        <a href={`mailto:${contact?.email}`} className='text-decoration-none btn btn-light'>
                                             <i className="fa-solid fa-envelope fa-md"></i>
                                        </a>
                                   </div>
                              </div>
                              <DownloadContact contactId={contact?.contact_id} name={contact.full_name} full />
                         </div>
                         <div className="col d-xl-block d-none p-3" style={{
                              maxWidth: '300px'
                         }}>
                              <Notification />
                         </div>
                    </div>
               </div>
          </>
     )
}

export default ViewContact