import React, { useState } from 'react'
import Navbar from '../Navbar'
import { SocialLinks } from '../../../helpers/social-links'
import ROUTES from '../../../Routes';
import ProgressBar from '../../../components/progressBar/ProgressBar'


const social_icons = {
     'facebook': 'fa-brands fa-facebook',
     'twitter': 'fa-brands fa-twitter',
     'snapchat': 'fa-brands fa-snapchat',
     'website': 'fa-solid fa-link'
}
const socialLinks = new SocialLinks();

function OnboardingStepFour({ query, inputChange, nextStep, card, user }) {
     const [state, setState] = useState({
          facebook: '', twitter: '', snapchat: '', website: ''
     });

     const getInitialFields = () => {
          return [
               { label: 'Phone', content: card.phone, icon: 'fa-solid fa-phone', status: '1', type: 'tel', position: '1' },
               { label: 'Whatsapp', content: card.phone, icon: 'fa-brands fa-whatsapp', status: '1', type: 'wa', position: '2' },/* 
               { label: 'Email', content: user.email, icon: 'fa-solid fa-envelope', status: '1', type: 'email', position: '3' }, */
          ];
     }

     const handleInputChange = (e) => {
          e.preventDefault();

          const input = {
               ...state,
               [e.target.name]: e.target.value
          }

          setState(input);
     }

     const handleSubmit = (e) => {
          e.preventDefault();

          let fields = getInitialFields();
          const links = Object.keys(state).filter((key) => {
               return state[key] && state[key].length > 0;
          }).map((social, index) => {
               const sanitized_link = (state[social].length > 0) ? socialLinks.sanitize(social, state[social]) : ''
               return {
                    label: social.toUpperCase(),
                    content: sanitized_link,
                    icon: social_icons[social],
                    status: '1',
                    type: 'url',
                    position: (fields.length + index + 1).toString()
               }
          })

          fields = [...fields, ...links];
          inputChange({
               fields
          });
          nextStep();
     }

     // const skipForm = (e) => {
     //      e.preventDefault();
     //      const fields = getInitialFields();
     //      signup.setData({
     //           ...signupData,
     //           fields,
     //           nextStep: ONBOARDING.step5
     //      }, () => {
     //           navigate(ONBOARDING.step5);
     //      });
     // }

     return (
          <div>
               <div className=''>
                    <div className='text-center' style={Styles}>
                         <div className='row g-0'>

                              <div className='col-md d-lg-flex d-none half-screen-image'>
                                   <img src="assets/images/background/security.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'bottom'
                                   }} />
                              </div>
                              <div className='col-md login-screen-mobile h-screen'>
                                   <Navbar
                                        backlink={'/' + ROUTES.onboarding.path + query.link}
                                   />
                                   <div className="d-flex justify-content-center flex-column py-5 mx-auto" style={{ height: 630, maxWidth: 500 }}>
                                        <div className='pt-5'>
                                             <h4 className='fw-bold fs-5'>Add your socials</h4>
                                             <span className='ts-sm'>Share your social accounts</span>
                                        </div>
                                        <form className='pt-4' onSubmit={handleSubmit}>
                                             <div className="d-grid gap-2">
                                                  <input className='form-control border-dark' name='facebook' type={'type'} placeholder='Your Facebook url / Username' value={state.facebook || ''} onChange={handleInputChange} />
                                                  <input className='form-control border-dark' name='twitter' type={'type'} placeholder='Your Twitter url / Username' value={state.twitter || ''} onChange={handleInputChange} />
                                                  <input className='form-control border-dark' name='snapchat' type={'type'} placeholder='Your Snapchat url / Username' value={state.snapchat || ''} onChange={handleInputChange} />
                                                  <input className='form-control border-dark' name='website' type={'type'} placeholder='Your website link / Username' value={state.website || ''} onChange={handleInputChange} />
                                             </div>

                                             <div className='py-4 d-grid gap-2'>
                                                  <button type='submit' className='btn btn-dark'>Next</button>
                                                  {/* <button onClick={skipForm} className='btn'>Skip</button> */}
                                             </div>
                                        </form>
                                        <ProgressBar currentState={'4'} />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default OnboardingStepFour

const Styles = {
     height: '100vh'
}