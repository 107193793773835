import { useSearchParams } from 'react-router-dom'
import { empty } from '../helpers/functions.helpers'

export const useCardQuery = () => {
    const [ query ] = useSearchParams({});
    const id = query.get('id');
    const type = query.get('type');


    const link = () => {
        let token_link =  (!empty(id)) ? '?id=' + id : null;
        token_link = (token_link && !empty(type)) ? token_link + `&type=${type}` : null;
        return token_link;
    }
    
    return {
        id,
        type,
        link
    }
}

export default useCardQuery;