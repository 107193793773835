import React, { Fragment } from 'react'
import Navbar from '../home/Navbar'
import styles from "./styles.module.css";
const sampleStatus = {
     "success": "true",
     "message": "Order data retrieved successfully",
     "order": {
          "id": 62,
          "name": "Ayobami Oyeniran",
          "phone_number": "+2349070569335",
          "email": "info@rexedge.com",
          "address": "212, ipaja road, mulero b/stop, agege, lagos",
          "city": "Agege",
          "state": "Lagos",
          "total": "150000",
          "final_total": "166250",
          "vat": "11250",
          "shipping": "5000",
          "payment_status": "success",
          "created_at": "2023-04-06T09:31:18.000000Z",
          "updated_at": "2023-04-06T09:31:38.000000Z",
          "payment_url": "https://checkout.paystack.com/w2l8zh3r8hjtjxv",
          "payment_reference": "37wtrzxplh",
          "payment_access_code": "w2l8zh3r8hjtjxv",
          "payment_metadata": "{\"id\":2696730507,\"domain\":\"test\",\"status\":\"success\",\"reference\":\"37wtrzxplh\",\"amount\":16625000,\"message\":null,\"gateway_response\":\"Successful\",\"paid_at\":\"2023-04-06T09:31:35.000Z\",\"created_at\":\"2023-04-06T09:31:18.000Z\",\"channel\":\"card\",\"currency\":\"NGN\",\"ip_address\":\"105.112.51.177\",\"metadata\":\"\",\"log\":{\"start_time\":1680773492,\"time_spent\":3,\"attempts\":1,\"errors\":0,\"success\":true,\"mobile\":false,\"input\":[],\"history\":[{\"type\":\"action\",\"message\":\"Attempted to pay with card\",\"time\":3},{\"type\":\"success\",\"message\":\"Successfully paid with card\",\"time\":3}]},\"fees\":200000,\"fees_split\":null,\"authorization\":{\"authorization_code\":\"AUTH_4ra6ldrotd\",\"bin\":\"408408\",\"last4\":\"4081\",\"exp_month\":\"12\",\"exp_year\":\"2030\",\"channel\":\"card\",\"card_type\":\"visa \",\"bank\":\"TEST BANK\",\"country_code\":\"NG\",\"brand\":\"visa\",\"reusable\":true,\"signature\":\"SIG_cdSxibThdEggisOe2XFT\",\"account_name\":null},\"customer\":{\"id\":118706562,\"first_name\":null,\"last_name\":null,\"email\":\"info@rexedge.com\",\"customer_code\":\"CUS_0g9zxuab5ujmf1u\",\"phone\":null,\"metadata\":null,\"risk_action\":\"default\",\"international_format_phone\":null},\"plan\":null,\"split\":{},\"order_id\":null,\"paidAt\":\"2023-04-06T09:31:35.000Z\",\"createdAt\":\"2023-04-06T09:31:18.000Z\",\"requested_amount\":16625000,\"pos_transaction_data\":null,\"source\":null,\"fees_breakdown\":null,\"transaction_date\":\"2023-04-06T09:31:18.000Z\",\"plan_object\":{},\"subaccount\":{}}"
     }
}

export default function TransactionDetails({
     status = sampleStatus
}) {
     return (
          <Fragment>
               <Navbar />
               <div className="min-vh-100 pt-5 bg-black">
                    <div className="container">
                         <div className={styles.zoomIn}>
                              <div className={styles.status}>
                                   <div className="text-center" style={{
                                        paddingTop: '50px',
                                        paddingBottom: '50px',
                                   }}>
                                        <ul className='text-white'>
                                             {/* <li>{status.order.id}</li> */}
                                             <li>{status.order.name}</li>
                                             <li>{status.order.phone_number}</li>
                                             <li>{status.order.email}</li>
                                             <li>{status.order.address}</li>
                                             <li>{status.order.city}</li>
                                             <li>{status.order.state}</li>
                                             <li>{status.order.total}</li>
                                             <li>{status.order.final_total}</li>
                                             <li>{status.order.vat}</li>
                                             <li>{status.order.shipping}</li>
                                             <li>{status.order.payment_status}</li>
                                             {/* <li>{status.order.created_at}</li>
                                             <li>{status.order.updated_at}</li>
                                             <li>{status.order.payment_url}</li>
                                             <li>{status.order.payment_reference}</li>
                                             <li>{status.order.payment_access_code}</li>
                                             <li>{status.order.payment_metadata}</li> */}
                                        </ul>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </Fragment>
     )
}
