import React, { useState, useEffect } from 'react'
import axios from '../../../hooks/axios';
import { API } from '../../../api/Request';
import LoadingButton from '../../../components/LoadingButton.component';
import { toast } from 'react-toastify';
import { checkValidity, tokenValidationSchema } from '../../../helpers/forms/validator.schema';
import { Link } from 'react-router-dom';
import ROUTES from '../../../Routes';
import ICEIcon from '../../../components/icons/ICEIcon';

const confirmCardToken = async (data) => {
     return axios.post(API.confirmToken, data);
}

const GetStarted = ({ query, inputChange, nextStep }) => {
     //App Data
     const [token, setToken] = useState('');
     const [formSubmitted, setFormSubmitted] = useState(false);
     const [getStarted, setGetStarted] = useState(true);

     //Form Watchers
     const [loading, setLoading] = useState(false);

     useEffect(() => {
          const fetchAuthProcess = async ({ card_id, token, type }) => {
               const tokenCheck = toast.loading("Verifying token...");
               setTimeout(() => {
                    toast.dismiss();
               }, 2000);
               const isValid = await checkValidity(tokenValidationSchema, { token });
               if (isValid?.success === false) {
                    setLoading(false);
                    toast.update(tokenCheck, { render: ("Invalid Token"), type: "error", isLoading: false, autoClose: 3000 });
                    return null;
               }

               const { data } = await confirmCardToken({ card_id, token, type });
               if (data && data.success === 'true') {
                    setLoading(false);
                    toast.update(tokenCheck, { render: "Get Started...", type: "success", isLoading: false, autoClose: 3000 });
                    inputChange({ token });
                    nextStep();
               } else {
                    setLoading(false);
                    toast.update(tokenCheck, { render: (data.message || "Invalid Token"), type: "error", isLoading: false, autoClose: 3000 });
               }
          }
          if (formSubmitted) {
               fetchAuthProcess({
                    card_id: query.id,
                    token: token,
                    type: query.type
               });
               setFormSubmitted(false);
          }
     }, [query, inputChange, nextStep, token, formSubmitted, setFormSubmitted]);

     const handleGetStarted = (e) => {
          e.preventDefault();
          setGetStarted(current => !current);
     }
     const handleSubmit = (e) => {
          e.preventDefault();
          setLoading(true);
          setFormSubmitted(true);
     }

     const logoStyle = {
          marginLeft: getStarted ? '0px' : '-40px'
     }

     return (
          <div>
               <div className=''>
                    <div className='bg-white text-center' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image overflow-hidden'>
                                   <img src="assets/images/background/black.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'center center'
                                   }} />
                              </div>
                              <div className='col-md login-screen-mobile h-screen'>
                                   {
                                        !getStarted && (<button onClick={handleGetStarted} className={'btn btn-light float-start'}>
                                             <ICEIcon iconType='back' size={20} /> <span className="ps-2">BACK</span>
                                        </button>)
                                   }
                                   <div className="d-flex justify-content-center flex-column my-5 mx-auto" style={{ height: 630, maxWidth: 500 }}>
                                        {getStarted && (<div className='mb-4'>
                                             <Link to={'/'} className={'text-decoration-none '} style={logoStyle}>
                                                  <img src='/assets/images/logo_full_black.svg' height={30} alt='' />
                                             </Link>
                                        </div>)}
                                        <div hidden={!getStarted}>
                                             <div className='fs-4 mb-2 text-uppercase'>Welcome</div>
                                             <div className='text-start mb-5 text-muted'>
                                                  We're glad you're here. To get started,
                                                  simply follow the process to continue.
                                                  If you have any questions,
                                                  don't hesitate to ask our <a href="https://wa.link/br1ne3">SUPPORT TEAM</a>.
                                                  We're excited to have you join us!
                                             </div>
                                        </div>
                                        <div className="" hidden={!getStarted}>
                                             <div className='d-grid'>
                                                  <Link to={ROUTES?.link?.login + query?.link} className='btn btn-dark text-capitalize'>
                                                       Link card to an Existing Account
                                                  </Link>

                                                  <div className="d-block text-center my-4 text-muted">— or —</div>

                                                  <button onClick={handleGetStarted} className='btn btn-dark mb-2'>
                                                       Create a New ISCE Account
                                                  </button>
                                             </div>
                                        </div>
                                        <div hidden={getStarted}>
                                             <div className=''>
                                                  <div className='fs-4 mb-4'>ACTIVATE CARD</div>
                                                  <span>Scratch and enter the token provided on your card package</span>
                                             </div>
                                             <form className='pt-2' onSubmit={handleSubmit} hidden={!query.id}>
                                                  <div className="d-grid gap-2">
                                                       <input className='form-control border-dark' type={'text'} placeholder='Enter Activation Code' onChange={e => setToken(e.target.value)} value={token} />
                                                  </div>

                                                  <div className='py-4 d-grid'>
                                                       {
                                                            (loading) ?
                                                                 <LoadingButton /> :
                                                                 <button type='submit' className='btn btn-dark btn-md'>Confirm Token</button>
                                                       }
                                                  </div>
                                             </form>
                                             <div hidden={query.id}>
                                                  <hr className='my-3 mx-auto w-100' />
                                                  <p className='fw-light'>
                                                       You need to attach a valid device to this card
                                                  </p>
                                             </div>
                                        </div>

                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default GetStarted

const Styles = {
     height: '100vh'
}