import React from 'react'
import { Link } from 'react-router-dom'
import isceLogo from '../images/logo-dark.webp'
import ROUTES from '../Routes'
import Swipe from './swipe/swipe.component'


const NetworkError = () => {
    if(navigator.onLine){
        window.location.href = ROUTES.link.login;
        return;
    }

	return (
        <>
            <div className="modal modal-sheet position-absolute d-block bg-white pb-5 h-100" tabIndex="-1" role="dialog" id="modalSheet">

                <div className='bg-white shadow-sm px-3 py-2'>
                    <Link className='text-decoration-none text-black p-0' to={'/'}>
                        <div className='fw-bold'>
                            <span className='ps-2 fs-5'>Network Error</span>
                        </div>
                    </Link>
                </div>

                <div className="modal-dialog" role="document">
                    <div className="modal-content bg-dark text-white shadow">
                        <div className="modal-header border-bottom-0 position-relative">
                            <h5 className="modal-title d-flex flex-row justify-content-center" style={{ position: 'absolute', top: '20px', left: 'calc( 50% - 35px )' }}>
                                <img className="" src={isceLogo} alt="ISCE Logo dark" width="70" />
                            </h5>
                        </div>
                        <div className="modal-body py-0 pt-5 mt-5 pb-3">
                            <p className='fs-1 mb-3 text-center'>Unable to connect to the internet.</p>
                            {/* <p className='text-center'>And we can't wait for you to see it. <br /> Please check back soon.</p> */}
                            <p className='small'>Please check your device connection and try again.</p>
                        </div>
                    </div>
                </div>
                <Swipe />
            </div>
        </>
    )
}
export default NetworkError;