import React from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../../../api/Request';
import axios from '../../../hooks/axios';
import ROUTES from '../../../Routes';
import Navbar from '../Navbar';

function ForgotPassword() {
     const [email, setEmail] = React.useState('');
     const navigate = useNavigate();
     const [formSubmitted, setFormSubmitted] = React.useState(false);

     React.useEffect(() => {
          const forgotPassword = async () => {
               const sendMailing = toast.loading('Sending mail...');
               const gotoLogin = () => navigate('/' + ROUTES.login.path, { replace: true });
               const { data: response } = await axios.post(
                    API.forgotPassword, { email }
               );
               setTimeout(() => toast.dismiss, 2000);
               if (response.success === 'true') {
                    toast.update(sendMailing, { render: "Mail sent successfully!", type: "success", isLoading: false, autoClose: 3000 });
                    gotoLogin();
               } else {
                    toast.update(sendMailing, { render: "Unable to send mail!", type: "error", isLoading: false, autoClose: 3000 });
               }
          }
          if (formSubmitted) {
               forgotPassword();
               setFormSubmitted(false);
          }
     }, [email, formSubmitted, navigate]);

     const handleSubmit = (e) => {
          e.preventDefault();
          setFormSubmitted(true);
     }

     return (
          <div className=''>
               <div className=''>
                    <div className='bg-white text-center' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image'>
                                   <img src="assets/images/background/pink.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'center center'
                                   }} />
                              </div>
                              <Navbar backlink='/login' step={0} />
                              <div className='col-md login-screen-mobile'>
                                   <div className='pt-5'>
                                        <h1 className='fw-bold'>Forgot Password</h1>
                                   </div>
                                   <hr className='my-3 mx-auto w-50' />
                                   <form onSubmit={handleSubmit} className='pt-4 login-form'>
                                        <div className="d-grid g">
                                             <input className='form-control border-dark' type={'text'} placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                                        </div>

                                        <div className='pt-4 pb-2 d-grid'>
                                             {

                                                  <button type="submit" className='btn btn-dark'>
                                                       {'Send Email'}
                                                  </button>
                                             }
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}
const Styles = {
     height: '100vh'
}

export default ForgotPassword;