import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, DATA_STORAGE } from '../../../api/Request';
import ICEIcon from '../../../components/icons/ICEIcon';
import useAuth from '../../../hooks/useAuth';
import useStorage from '../../../hooks/useStorage';
import ROUTES from '../../../Routes';
import SideBar from '../component/nav/SideBar';
import Notification from '../component/Notification';

function ChangePassword() {
     const [form, setForm] = React.useState({
          password: '',
          confirm_password: ''
     });
     const [formSubmitted, setFormSubmitted] = React.useState(false);
     const navigate = useNavigate();
     const auth = useAuth();
     const dashboard = useStorage(DATA_STORAGE?.dashboard);

     //Change User Password
     React.useEffect(() => {
          const changeUserPassword = async () => {
               toast.dismiss();
               const updatingPassword = toast.loading('Updating password');
               const gotoLogin = () => navigate('/' + ROUTES.login.path, { replace: true });
               const { data: response } = await auth.HTTP_REQUEST.post(
                    API.changeUserPassword, { ...form }
               );
               setTimeout(() => toast.dismiss, 2000);
               if (response.success === 'true') {
                    toast.update(updatingPassword, { render: "Password Updated successfully!", type: "success", isLoading: false, autoClose: 3000 });
                    dashboard.delete();
                    auth.delete();
                    gotoLogin();
               } else {
                    toast.update(updatingPassword, { render: "Unable to update password!", type: "error", isLoading: false, autoClose: 3000 });
               }
          }
          if (formSubmitted) {
               changeUserPassword();
               setFormSubmitted(false);
          }
     }, [auth, form, formSubmitted, dashboard, navigate]);

     const updateForm = (e) => {
          setForm({
               ...form,
               [e.target.name]: e.target.value
          });
     }

     const handleSubmit = (e) => {
          e.preventDefault();
          setFormSubmitted(true);
     }

     return (
          <>
               <div className="container mx-auto p-0 h-screen">
                    <div className="row h-100 border-0 border-start border-end mx-auto overflow-hidden" style={{
                         maxWidth: '1080px'
                    }}>
                         <SideBar />
                         <div className='tabs_page'>
                              <div className=''>
                                   <div className="py-3 d-flex justify-content-between">
                                        <Link to={ROUTES.link.settings} className='text-decoration-none btn btn-light'>
                                             <ICEIcon iconType='back' size={20} /> <span className='ts-sm'>Settings</span>
                                        </Link>
                                   </div>
                                   <div>
                                        <div className='mb-3 mx-auto overflow-hidden px-2'>
                                             <div className="fw-bold mt-2 ts-md text-uppercase">Change Password</div>
                                             <div className='mb-1'>
                                                  <form className='pt-4 login-form' onSubmit={handleSubmit}>

                                                       {/* <div className="mb-2 mt-2">
                                                            <label htmlFor='password' className='ts-md fw-bold'>Old Password</label>
                                                            <input name="password" autoComplete='old-password' className='form-control border-dark ts-md' type={'password'} placeholder='Enter Old Password' />
                                                       </div> */}

                                                       <div className="mb-2 mt-2">
                                                            <label htmlFor='password' className='ts-md fw-bold'>New Password</label>
                                                            <input name="password" autoComplete='new-password' className='form-control border-dark ts-md' type={'password'} placeholder='Enter New Password' onChange={updateForm} />
                                                       </div>

                                                       <div className="mb-2 mt-2">
                                                            <label htmlFor='confirm_password' className='ts-md fw-bold'>Confirm Password</label>
                                                            <input name="confirm_password" autoComplete='new-password' className='form-control border-dark ts-md' type={'password'} placeholder='Retype Pasword' onChange={updateForm} />
                                                       </div>

                                                       <div className='pt-2 pb-2 d-grid'>
                                                            {

                                                                 <button type="submit" className='btn btn-dark text-uppercase'>
                                                                      {'Change Password'}
                                                                 </button>
                                                            }
                                                       </div>
                                                  </form>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="col d-xl-block d-none p-3" style={{
                              maxWidth: '300px'
                         }}>
                              <Notification />
                         </div>
                    </div>
               </div>
          </>

     )
}

export default ChangePassword;
