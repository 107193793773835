import React from 'react'
import ContactCard from '../component/ContactCard'

export default function Result({ data }) {
     return (
          <div>
               {
                    data.map((data, index) => {
                         return <ContactCard
                              key={"contact" + index}
                              data={data}
                         />
                    })
               }
          </div>
     )
}
