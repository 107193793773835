import React from 'react'
import { useParams } from 'react-router-dom'
import { API, REQUEST2 } from '../../../api/Request';
import { httpNoInterceptor } from '../../../http-common';
import ROUTES from '../../../Routes';
import EventsService from '../../../services/events.service';
import Chat from '../../vcard/component/Chat';

const AdminEventChat = () => {
    const [event, setEvent] = React.useState(null);
    const { id } = useParams();

    const fetchUserEvents = async (id) => {
        const { data: response } = await EventsService.get(id);
        //console.log(response)
        if (response?.success === 'true') {
            setEvent(response?.data);
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            fetchUserEvents(id);
        }, 1000);
        return () => clearInterval(interval);
    }, [id]);

    const handleSubmittedChat = async (message) => {
        const saveChatMessage = async (message) => {
            const link = API.events?.createChat?.replace(':id', id);
            await httpNoInterceptor({
                baseURL: REQUEST2.url
            }).post(link, {
                event_id: event?.id,
                attendee_id: 'admin-' + event?.user_id,
                message,
                image: event?.image
            });
            fetchUserEvents(id);
        }
        saveChatMessage(message)
    }

    return <Chat
        user_id={'admin-' + event?.user_id}
        data={{ event: event, chats: event?.chats }}
        submitChat={handleSubmittedChat}
        back={ROUTES.link?.view_event?.replace(':id', id)}
    />
}

export default AdminEventChat