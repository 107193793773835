import React from 'react';
import { toast } from 'react-toastify';
import { http } from '../../../http-common';

const DeleteService = (props) => {

     const handleDelete = async (event) => {
          event.preventDefault();
          try {
               const url = `https://developer.isce.app/v1/connect/api/services/${props.id}`;
               await http({
                    Authorization: true
               }).delete(url);
               window.location.reload();
               toast.success("Service deleted successfully!");
          } catch (error) {
               toast.error("Error deleting service. Please try again.");
          }
     }

     return (
          <button className="btn btn-light me-1" onClick={handleDelete}>
               <i className="fa-solid fa-trash fa-md"></i>
          </button>
     )
}

export default DeleteService;
