import React, { useState, useEffect } from 'react'

//import ImageEditor from '../../../components/images/image-editor.component'
import Navbar from '../Navbar'
import "./OnboardingStepFive.css"
import { empty, uniqid } from '../../../helpers/functions.helpers'
import ROUTES from '../../../Routes';
import FileUploader from '../../../components/images/fileuploader'
import Placeholder from '../../../images/upload.jpg';
import ProgressBar from '../../../components/progressBar/ProgressBar'

const OnboardingStepFive = ({ query, inputChange, nextStep, profileImage, user, card }) => {
     const [image, setImage] = useState(Placeholder);
     const [imageName, setImageName] = useState('');

     useEffect(() => {
          if (!empty(profileImage)) {
               setImage(profileImage.image)
               setImageName(profileImage.name)
          }
     }, [profileImage]);

     const handleSubmit = (e) => {
          e.preventDefault();
          nextStep();
     }

     // const skipForm = e => {
     //      e.preventDefault();
     // }

     const handleFileSelect = async (file) => {
          setImage(file.url);
          setImageName(file.name);
          inputChange({
               image: {
                    image: file.url, name: file.name
               }
          });
     }

     return (
          <div>
               <div className=''>
                    <div className='text-center' style={Styles}>
                         <div className='row g-0'>
                              <div className='col-md d-lg-flex d-none half-screen-image'>
                                   <img src="assets/images/background/pink.webp" className="img-fluid img-onboarding" alt='' style={{
                                        height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'bottom'
                                   }} />
                              </div>
                              <div className='col-md login-screen-mobile h-screen'>
                                   <Navbar
                                        backlink={'/' + ROUTES.onboarding.path + query.link}
                                   />
                                   <div className="d-flex justify-content-center flex-column py-5 mx-auto" style={{ height: 630, maxWidth: 500 }}>
                                        <div className='pt-5'>
                                             <h4 className='fw-bold fs-5'>Profile Image</h4>
                                             <span className='ts-sm'>Upload your profile photo</span>
                                        </div>
                                        <form className='pt-4' onSubmit={handleSubmit}>
                                             <div className="d-grid gap-2">
                                                  <div className='photo-square mx-auto border rounded position-relative overflow-hidden'>
                                                       <FileUploader
                                                            image={image}
                                                            handleFile={handleFileSelect}
                                                            canUpload={true}
                                                            folder={'profile'}
                                                            fileName={uniqid()}
                                                       />
                                                  </div>
                                             </div>

                                             <div className='py-4 d-grid gap-2'>
                                                  <button disabled={!imageName} type={'submit'} className='btn btn-dark'>Done</button>
                                             </div>
                                        </form>
                                        <ProgressBar currentState={'5'} />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default OnboardingStepFive

const Styles = {
     height: '100vh'
}