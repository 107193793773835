import React from 'react'
import { Link } from 'react-router-dom'
import { API, REQUEST2 } from '../../../../api/Request';
import { checkValidity, validateRegisterAttendee, validationError } from '../../../../helpers/forms/validator.schema';
import ICEIcon from '../../../../components/icons/ICEIcon';
import { displayDate, imageAvatar } from '../../../../helpers/functions.helpers';
import { httpNoInterceptor } from '../../../../http-common';
import ROUTES from '../../../../Routes'
import Swal from 'sweetalert2';

const EventOpenRegister = ({ event }) => {
	const [state, setState] = React.useState({
		event_id: '', name: '', phone: '', email: '', event_prices_id: ''
	});
	const [errors, setErrors] = React.useState([]);

	const updateForm = (e) => {
		e.preventDefault();
		setState({
			...state,
			[e.target.name]: e.target.value
		});
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault();

		const image = imageAvatar(state.name);
		const data = { ...state, event_id: event?.id, image }
		const isValid = await checkValidity(validateRegisterAttendee, data);
		if (isValid?.success === false) {
			setErrors(isValid?.errors);
			return null;
		}

		const { data: response } = await httpNoInterceptor({
			baseURL: REQUEST2.url
		}).post(API.events?.register_user, data);
		if (response.success === 'true')
			window.location.href = response?.link;
		else {
			Swal.fire({
				icon: 'error',
				iconColor: '#ff0000',
				title: '<div style="font-size: 16px;"> Something Went Wrong! </div>',
				text: response?.message,
				confirmButtonColor: '#ff0000',
				background: '#fff',
				width: '300px',
				confirmButtonText:
					'<i className="fa fa-thumbs-up me-2"></i> Try Again!',
			})
		}
	}

	return (
		<section className='container p-0'>
			<div className="py-3 px-3 d-flex justify-content-between">
				<div>
					<span className=''>
						<Link to={ROUTES.link.event_open.replace(':id', event?.clean_name)} className='text-decoration-none'>
							<ICEIcon iconType='back' size={20} /> <span className='ts-sm'>{event?.title}</span>
						</Link>
					</span>
				</div>
			</div>
			<div className='col-xl-4 col-lg-6 col-md-7 px-lg-2 mb-3 scrollbar-hidden mx-auto'>
				<div className='d-flex mb-3 px-3'>
					<div className='text-start'>
						<div className='d-grid'>
							<div className='fw-bold ts-md'>
								{event?.title}
							</div>
							<div>
								{displayDate(event?.start_date)}
							</div>
						</div>
					</div>
				</div>
				<div className="px-3">
					<div className='latest_card position-relative mb-4 rounded overflow-hidden' style={{ width: '100%', height: '300px', }}>
						<img className='img-fluid' alt='' src={event?.image} style={{ width: '100%', height: '300px', objectFit: 'cover', objectPosition: 'top' }} />
						<div className='position-absolute bottom-0 latest-card-details w-100 bg-black text-white'>
							<div className='p-2'>
								<div className="d-grid text-start">
									<div className='fw-bold ts-md text-uppercase'>{event?.title}</div>
									<div className='ts-sm'>{event?.description}</div>
									<div className='ts-md text-uppercase'>{displayDate(event?.start_date)}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<form className='px-3'>
					<div className='ts-md mb-3'>
						<label className='ts-md' htmlFor='name'>Full Name</label>
						<input className='ts-md form-control border-dark' name='name' type={'type'} placeholder='Enter Full Name' value={state.name} onChange={updateForm} />
						<div className='text-start text-danger ts-sm text-capitalize'>{validationError(errors, 'name')}</div>
					</div>
					<div className='ts-md mb-3'>
						<label className='ts-md' htmlFor='email'>Email Address</label>
						<input className='ts-md form-control border-dark' name='email' type={'type'} placeholder='Enter Email' value={state.email} onChange={updateForm} />
						<div className='text-start text-danger ts-sm text-capitalize'>{validationError(errors, 'email')}</div>
					</div>
					<div className='ts-md mb-3'>
						<label className='ts-md' htmlFor='email'>Phone</label>
						<input className='ts-md form-control border-dark' name='phone' type={'type'} placeholder='Phone Number' value={state.phone} onChange={updateForm} />
						<div className='text-start text-danger ts-sm text-capitalize'>{validationError(errors, 'phone')}</div>
					</div>
					<div className='ts-md mb-3'>
						<label className='ts-md' htmlFor='email'>Ticket Category</label>
						<select name="event_prices_id" className="ts-md form-select border-dark" aria-label="Default select example" value={state.event_prices_id} onChange={updateForm}>
							<option value=''>Choose One </option>
							{
								event?.prices?.map((price, index) => {
									return (
										<option key={`register_card_${index}_${event.id}`} value={price?.id}>{price?.title} - N{price?.amount}</option>
									)
								})
							}
						</select>
						<div className='text-start text-danger ts-sm text-capitalize'>{validationError(errors, 'event_prices_id')}</div>
					</div>
					<div className='d-grid'>
						<button onClick={handleFormSubmit} className='btn bg-black text-white d-grid'>Register</button>
						{/* <PaystackButton className='btn bg-black text-white d-none' { ...componentProps } /> */}
					</div>
				</form>



			</div>
		</section>
	)
}

export default EventOpenRegister